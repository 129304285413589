import React from "react"
import { Switch } from "react-router-dom"
import LoadableComponent from "../utils/LoadableComponent"
import PrivateRoute from "../components/PrivateRoute/privateRoute"
import { Modal, Button, Select, DatePicker, message, notification } from 'antd';
import ModelManager from "../domain/ModelManager";
import AppStatus from "../domain/AppStatus";
import I18n from "../language/I18n";
import Net from "../utils/net";
import moment from "moment";
import 'moment/locale/zh-cn';

const AdminHome = LoadableComponent(() => import("../pages/admin/home/index"));
const AdminFixApp = LoadableComponent(() => import("../pages/admin/fixApp/index"));
const AdminReplace = LoadableComponent(() => import("../pages/admin/replace/index"));
const AdminSetting = LoadableComponent(() => import("../pages/admin/setting/index"));
// const AdminUsers = LoadableComponent(() => import("../pages/admin/users/index"));
const PartInfo = LoadableComponent(() => import("../pages/admin/partInfo/index"));
const Checkout = LoadableComponent(() => import("../pages/admin/checkout/index"));
const Maintenance = LoadableComponent(() => import("../pages/admin/maintenance/index"));
const CoffeeAppQuery = LoadableComponent(() => import("../pages/admin/maintenance/coffeeAppQuery"));
const OrderApp = LoadableComponent(() => import("../pages/admin/orderApp/index"));
const Awards = LoadableComponent(() => import("../pages/admin/awards/index"));
const AuditList = LoadableComponent(() => import("../pages/admin/audit/index"));
const FollowUp = LoadableComponent(() => import("../pages/admin/followup/index"));
const FollowUp400 = LoadableComponent(() => import("../pages/admin/followup400/index"));
const Assessment = LoadableComponent(() => import("../pages/admin/assessment/index"));

export default class Routes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disableSubmit: true,
            disableSubmit2: true,
        };
        this.openedNotification = [];
        global.showProcessView = this.showProcessView.bind(this);
        global.hideProcessView = this.hideProcessView.bind(this);
        global.clearRoutesInterval = this.clearRoutesInterval.bind(this);
        global.startGetRoutesData = this.startGetRoutesData.bind(this);
    }
    componentDidMount() {
    }

    clearRoutesInterval = () => {
        this.iTimer && clearInterval(this.iTimer);
    };

    startGetRoutesData = () => {
        // if (!global.isHK) {
        if (global.openEpidemicModule()) {
            this.getWorkScope();
            this.setRoutesInterval();
        } else {
            this.getWorkBulletin();
        }
        // }
    };

    setRoutesInterval = () => {
        this.iTimer && clearInterval(this.iTimer);
        this.iTimer = setInterval(() => {
            console.log("setRoutesInterval")
            this.getWorkScope();
        }, 90 * 1000);
    };

    /**节假日休假统计 公告 */
    getWorkBulletin = (bulletin, callback) => {
        let url = global.ServerUrl + "workBulletin/list";
        let data = { status: 1, };
        if (bulletin) {
            data.id = bulletin.id;
        }
        Net.post(url, data, (res) => {
            if (callback) {
                return callback(res);
            }
            if (res.result === 0 && res.ret.length > 0) {
                for (let i = 0; i < res.ret.length; i++) {
                    const item = res.ret[i];
                    this.loadWorkScopeByBulletin(item);
                }
            }
        }, error => {
            console.log("getWorkBulletin", error)
        })
    };

    /**获取维修站对应的节假日休假统计公告 上报记录 */
    loadWorkScopeByBulletin = (bulletin, callback) => {
        let url = global.ServerUrl + "workScope/list";
        let data = {
            bulletinId: bulletin.id,
            type: AppStatus.STATISTIC_HOLIDAY,
        }
        if (ModelManager.loginUser && ModelManager.loginUser.regionId !== 2) {
            data.regionId = ModelManager.loginUser.regionId;
        }
        Net.post(url, data, (res) => {
            console.log("loadWorkScopeByBulletin", res.ret)
            if (callback) {
                return callback(res);
            }
            if (res.result === 0) {
                if (res.ret.length === 0) { // 未上报
                    if (ModelManager.loginUser && ModelManager.loginUser.regionId !== 2) {
                        this.showBulletinModal(bulletin);
                    }
                } else { // 已上报
                    const key = `open${bulletin.id}`;
                    this.closeNotification(key);
                    let { isInput, workBulletin, isVisible } = this.state;
                    if (workBulletin != null && workBulletin.id === bulletin.id) {
                        isVisible = false;
                        isInput = false;
                    }
                    this.setState({ dataSource: res.ret, isVisible, isInput })
                }
            }
        }, error => {
            console.log("loadWorkScopeByBulletin", error)
        })
    };

    /**显示弹窗进行填报 */
    showBulletinModal = (bulletin) => {
        this.state.isVisible = true;
        this.state.isInput = true;
        this.setState({ workBulletin: bulletin, isVisible: true, isInput: true });
    };

    goToInput = (bulletin, key) => {
        let { isInput } = this.state;
        if (isInput) return;
        notification.close(key);
        this.state.isVisible = true;
        this.state.isInput = true;
        this.setState({ workBulletin: bulletin, isVisible: true, isInput: true });
        this.getWorkBulletin(bulletin, res => {
            if (res.result === 0 && res.ret.length === 0) {
                console.log("goToInput", "已删除，无需填报")
                this.state.isVisible = false;
                this.state.isInput = false;
                this.setState({ workBulletin: null, isVisible: false, isInput: false });
                return message.info(I18n.t("已删除，无需填报"));
            }
            this.loadWorkScopeByBulletin(bulletin, (res) => {
                if (res.result === 0 && res.ret.length > 0) {
                    console.log("goToInput", "其他人已填报")
                    this.state.isVisible = false;
                    this.state.isInput = false;
                    this.setState({ workBulletin: null, isVisible: false, isInput: false });
                    return message.info(I18n.t("其他人已填报"));
                }
            })
        });
    };

    closeNotification = (key) => {
        notification.close(key)
        let index = this.openedNotification.findIndex(item => item === key);
        if (index !== -1) {
            this.openedNotification.splice(index, 1);
        }
    };

    openNotification = (bulletin) => {
        const key = `open${bulletin.id}`;
        if (this.openedNotification.some(item => item === key)) {
            return;
        }
        this.openedNotification.push(key);
        notification.open({
            key, duration: 0,
            closeIcon: <div />,
            message: bulletin.title,
            description: bulletin.description,
            btn: (
                <Button type="primary" size="small" onClick={() => this.goToInput(bulletin, key)}>
                    {I18n.t("前往填报")}
                </Button>
            ),
            onClose: () => this.closeNotification(key),
        });
    };


    /** 维修站获取自己站点的疫情工作范围记录 */
    getWorkScope = () => {
        if (global.openEpidemicModule() && ModelManager.loginUser && ModelManager.loginUser.regionId !== 2) {
            let url = global.ServerUrl + "workScope/list";
            let data = {
                regionId: ModelManager.loginUser.regionId,
                type: AppStatus.STATISTIC_EPIDEMIC,
            }
            Net.post(url, data, (res) => {
                if (res.result === 0 && res.ret.length > 0) {
                    this.setState({ doc: res.ret[0] })
                } else {
                    this.setState({ doc: null })
                }
                this.loadWorkScope();
            }, error => {
                console.log("getWorkScope", error)
            })
        }
    };

    /** 维修站获取自己站点当天设置的疫情工作范围记录 */
    loadWorkScope = () => {
        if (ModelManager.loginUser && ModelManager.loginUser.regionId !== 2) {
            let url = global.ServerUrl + "workScope/list";
            /** 维修站获取自己站点当天设置的工作范围记录 */
            let startTime = moment().startOf("days").format("YYYY-MM-DD HH:mm:ss.SSS");
            let endTime = moment().endOf("days").format("YYYY-MM-DD HH:mm:ss.SSS");
            let data = {
                startTime, endTime,
                regionId: ModelManager.loginUser.regionId,
                type: AppStatus.STATISTIC_EPIDEMIC,
            };
            Net.post(url, data, (res) => {
                if (res.result === 0) {
                    if (res.ret.length === 0) {
                        this.setState({ isModalVisible: true });
                    } else {
                        this.setState({ isModalVisible: false });
                        this.getWorkBulletin();
                    }
                }
            }, error => {
                console.log("loadWorkScope", error)
            })
        }
    };

    showProcessView = (message) => {
        this.setState({ showProcess: true, message: message })
    };

    hideProcessView = () => {
        this.setState({ showProcess: false })
    };

    handleSubmit = () => {
        let { workBulletin, workScope, transferService, startTime, endTime } = this.state;
        if (workScope == null) {
            return message.error(I18n.t("请选择营业状态"));
        }
        if (workScope === 0 && (!startTime || !endTime)) {
            return message.error(I18n.t("请选择休假起止日期"));
        }
        let data = {
            workScope, transferService,
            startDate: startTime,
            endDate: endTime,
            bulletinId: workBulletin.id,
            bulletinTitle: workBulletin.title,
            regionId: ModelManager.loginUser.regionId,
            regionCity: ModelManager.loginUser.city,
            regionProvince: ModelManager.loginUser.province,
            empId: ModelManager.loginUser.empId,
            empName: ModelManager.loginUser.empName,
            type: AppStatus.STATISTIC_HOLIDAY,
        }
        let region = ModelManager.getRegionById(ModelManager.loginUser.regionId);
        if (region) {
            data.regionName = region.name;
            data.regionCity = region.city;
            data.regionProvince = region.province;
        }
        this.getWorkBulletin(workBulletin, res => {
            if (res.result === 0 && res.ret.length === 0) {
                console.log("goToInput", "已删除，无需填报")
                this.state.isVisible = false;
                this.state.isInput = false;
                this.setState({
                    workBulletin: null, isVisible: false, isInput: false,
                    workScope: null, transferService: null, rangeTime: null,
                    startTime: null, endTime: null, disableSubmit2: true,
                }, () => {
                    this.setSubmitBtnStatus();
                });
                return message.info(I18n.t("已删除，无需填报"));
            }
            this.loadWorkScopeByBulletin(workBulletin, (res) => {
                if (res.result === 0 && res.ret.length > 0) {
                    console.log("goToInput", "其他人已填报")
                    this.state.isVisible = false;
                    this.state.isInput = false;
                    this.setState({
                        workBulletin: null, isVisible: false, isInput: false,
                        workScope: null, transferService: null, rangeTime: null,
                        startTime: null, endTime: null, disableSubmit2: true,
                    }, () => {
                        // this.setSubmitBtnStatus();
                    });
                    return message.info(I18n.t("其他人已填报"));
                }
                this.updateWorkScope(data);
            })
        });
    };

    handleOk = () => {
        let { workScope, restoreDate, transferService, doc } = this.state;
        if (!workScope) {
            return message.error(I18n.t("请选择工作范围"));
        }
        if (workScope !== 1 && !restoreDate) {
            return message.error(I18n.t("请选择预计恢复时间"));
        }
        let data = {
            workScope, restoreDate, transferService,
            regionId: ModelManager.loginUser.regionId,
            regionCity: ModelManager.loginUser.city,
            regionProvince: ModelManager.loginUser.province,
            empId: ModelManager.loginUser.empId,
            empName: ModelManager.loginUser.empName,
            type: AppStatus.STATISTIC_EPIDEMIC,
        }
        let region = ModelManager.getRegionById(ModelManager.loginUser.regionId);
        if (region) {
            data.regionName = region.name;
            data.regionCity = region.city;
            data.regionProvince = region.province;
        }
        if (doc && doc.id) {
            data.id = doc.id;
        }
        this.updateWorkScope(data);
    };
    updateWorkScope = (data) => {
        this.updateTime = this.updateTime || 0;
        let now = new Date().getTime();
        if (now - this.updateTime < 2000) {
            return message.warning(I18n.t("请不要重复操作"));
        }
        this.updateTime = new Date().getTime();
        let url = global.ServerUrl;
        if (data.id) {
            url += "workScope/update";
        } else {
            url += "workScope/add";
        }
        Net.post(url, data, res => {
            if (res.result === 0) {
                this.handleCancel();
                this.getWorkScope();
            } else {
                message.error(I18n.t("数据提交失败，请重试"))
            }
        }, error => {
            console.log("updateWorkScope", error)
            message.error(I18n.t("数据提交失败，请重试"))
        });
    };
    handleCancel = () => {
        this.setState({
            isInput: false,
            isVisible: false,
            isModalVisible: false,
            workScope: null,
            restoreDate: null,
            transferService: null,
            rangeTime: null,
            startTime: null,
            endTime: null,
        });
    };
    setSubmitBtnStatus = () => {
        let { disableSubmit, disableSubmit2, workScope, restoreDate, transferService, startTime, endTime } = this.state;
        disableSubmit = true;
        disableSubmit2 = true;
        if (workScope) {
            if (workScope === 1) {
                disableSubmit = false;
                disableSubmit2 = false;
            } else if (workScope === 5) {
                if (transferService != null && restoreDate != null) {
                    disableSubmit = false;
                }
                if (transferService != null && startTime != null && endTime != null) {
                    disableSubmit2 = false;
                }
            } else if (restoreDate != null) {
                disableSubmit = false;
            } else if (startTime != null && endTime != null) {
                disableSubmit2 = false;
            }
        }
        this.setState({ disableSubmit, disableSubmit2 })
    };
    onWorkScopeChange = (workScope) => {
        // console.log("onWorkScopeChange", workScope)
        let { restoreDate, rangeTime, startTime, endTime, transferService } = this.state;
        if (workScope === 1) {
            restoreDate = null;
            rangeTime = null;
            startTime = null;
            endTime = null;
        }
        if (workScope !== 5) {
            transferService = null;
        }
        this.setState({ workScope, restoreDate, rangeTime, startTime, endTime, transferService }, () => {
            this.setSubmitBtnStatus();
        });
    };
    onTransferServiceChange = (transferService) => {
        this.setState({ transferService }, () => {
            this.setSubmitBtnStatus();
        });
    };
    onRestoreDateChange = (date, dateString) => {
        // console.log(date, dateString);
        this.setState({ restoreDate: date }, () => {
            this.setSubmitBtnStatus();
        });
    };
    onRangeChange = (value, dateString) => {
        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);
        this.setState({ rangeTime: value });
    };
    onRangeOk = (value) => {
        // console.log('onRangeOk: ', value);
        let { startTime, endTime } = this.state;
        if (value && value.length > 0) {
            startTime = value[0];
            endTime = value[1];
        }
        this.setState({ startTime, endTime }, () => {
            this.setSubmitBtnStatus();
        });
    };
    render() {
        return (
            <div style={{ position: "relative" }}>
                <Switch>
                    <PrivateRoute exact path="/sys/home" component={AdminHome} />
                    <PrivateRoute exact path="/sys/replace" data={{ test: true }} component={AdminReplace}
                        render={(props) => <AdminFixApp {...props} appointments={true} />} />
                    <PrivateRoute exact path="/sys/fixApp" component={AdminFixApp} />
                    <PrivateRoute exact path="/sys/partInfo" component={PartInfo} />
                    <PrivateRoute exact path="/sys/setting" component={AdminSetting} />
                    <PrivateRoute exact path="/sys/checkout" component={Checkout} />
                    <PrivateRoute exact path="/sys/maintenance" component={Maintenance} />
                    <PrivateRoute exact path="/sys/coffeeAppQuery" component={CoffeeAppQuery} />
                    <PrivateRoute exact path="/sys/order" component={OrderApp} />
                    <PrivateRoute exact path="/sys/awards" component={Awards} />
                    <PrivateRoute exact path="/sys/audit" component={AuditList} />
                    <PrivateRoute exact path="/sys/followup" component={FollowUp} />
                    <PrivateRoute exact path="/sys/followup_400" component={FollowUp400} />
                    <PrivateRoute exact path="/sys/assessment" component={Assessment} />
                    {/* <PrivateRoute exact path="/sys/users" component={AdminUsers} /> */}
                </Switch>
                {this.state.showProcess &&
                    <div style={{ position: "fixed", top: 0, left: 0, zIndex: 99999, color: 'red', padding: 3, paddingLeft: 17 }}>{this.state.message || "**************"}</div>
                }
                <Modal
                    visible={this.state.isModalVisible}
                    closable={false}
                    onOk={this.handleOk}
                    footer={[
                        <Button key="submit" type="primary"
                            loading={this.state.loading}
                            disabled={this.state.disableSubmit}
                            onClick={this.handleOk}
                        >
                            {I18n.t("确认提交")}
                        </Button>,
                    ]}
                >
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <p style={{ fontWeight: "bold", color: "red", fontSize: 15 }}>{I18n.t("因疫情需要，我们将每天统计您站的工作范围")}</p>
                        <p>{I18n.t("请告知您站目前的工作范围")}</p>
                        <Select style={{ width: 200, marginBottom: 10 }}
                            value={this.state.workScope}
                            onChange={this.onWorkScopeChange}
                        >
                            {ModelManager.WorkScopeOption.map(item => {
                                if (item.label === "请选择") {
                                    return (
                                        <Select.Option key={"WorkScope" + item.value} value={item.value} style={{ display: "none" }}>{I18n.t(item.label)}</Select.Option>
                                    )
                                }
                                return (
                                    <Select.Option key={"WorkScope" + item.value} value={item.value}>{I18n.t(item.label)}</Select.Option>
                                )
                            })}
                        </Select>
                        {this.state.workScope === 5 &&
                            <>
                                <p>{I18n.t("请问您站是否有电话转接服务")}</p>
                                <Select style={{ width: 200, marginBottom: 10 }}
                                    value={this.state.transferService}
                                    onChange={this.onTransferServiceChange}
                                >
                                    <Select.Option value={1}>{I18n.t("是")}</Select.Option>
                                    <Select.Option value={0}>{I18n.t("否")}</Select.Option>
                                </Select>
                            </>
                        }
                        {this.state.workScope && this.state.workScope > 1 &&
                            <>
                                <p>{I18n.t("请问您站预计恢复工作的时间")}</p>
                                <DatePicker locale={global.DateLocal} style={{ width: 200 }}
                                    allowClear={false} format={I18n.t("YYYY年MM月DD日")}
                                    value={this.state.restoreDate}
                                    onChange={this.onRestoreDateChange}
                                />
                            </>
                        }
                    </div>
                </Modal>
                <Modal
                    visible={this.state.isVisible}
                    closable={false}
                    onOk={this.handleSubmit}
                    // onCancel={this.handleCancel}
                    footer={[
                        <Button key="submit" type="primary"
                            loading={this.state.loading}
                            disabled={this.state.disableSubmit2}
                            onClick={this.handleSubmit}
                        >
                            {I18n.t("确认提交")}
                        </Button>,
                    ]}
                >
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <p style={{ fontWeight: "bold", color: "red", fontSize: 15 }}>
                            {this.state.workBulletin && this.state.workBulletin.title}
                        </p>
                        <p>{I18n.t("请告知您站休假期间是否正常工作")}</p>
                        <Select style={{ width: 200, marginBottom: 10 }}
                            value={this.state.workScope}
                            onChange={this.onWorkScopeChange}
                        >
                            {ModelManager.WorkScopeOption.map(item => {
                                if (item.label === "请选择" || item.label === "部分区域上门") {
                                    return (
                                        <Select.Option key={"WorkScope" + item.value} value={item.value} style={{ display: "none" }}>{I18n.t(item.label)}</Select.Option>
                                    )
                                }
                                return (
                                    <Select.Option key={"WorkScope" + item.value} value={item.value}>{I18n.t(item.label)}</Select.Option>
                                )
                            })}
                        </Select>
                        {this.state.workScope === 5 &&
                            <>
                                <p>{I18n.t("请问您站是否有电话转接服务")}</p>
                                <Select style={{ width: 200, marginBottom: 10 }}
                                    value={this.state.transferService}
                                    onChange={this.onTransferServiceChange}
                                >
                                    <Select.Option value={1}>{I18n.t("是")}</Select.Option>
                                    <Select.Option value={0}>{I18n.t("否")}</Select.Option>
                                </Select>
                            </>
                        }

                        {this.state.workScope && this.state.workScope > 1 &&
                            <>
                                <p>{I18n.t("请选择您站休假起止时间")}</p>
                                <DatePicker.RangePicker locale={global.DateLocal}
                                    style={{ width: 400 }}
                                    allowClear={false}
                                    showTime={{ format: 'HH:mm' }}
                                    format={I18n.t("YYYY年MM月DD日 HH:mm")}
                                    onChange={this.onRangeChange}
                                    onOk={this.onRangeOk}
                                />
                            </>
                        }
                    </div>
                </Modal>
            </div >
        )
    }
}