import React from "react";
import { Modal, Image, Button, Input, message } from "antd";
import { Link } from "react-router-dom";
import MD5 from "crypto-js/md5";
import moment from "moment";
import { clearRemember, saveDefaultLang } from "../../utils/Session";
import ModelManager from "../../domain/ModelManager";
import Net from "../../utils/net";
import I18n from "../../language/I18n";

import EmergencyFixApp from "../../pages/admin/fixApp/EmergencyFixApp.js"

export default class HeaderBar extends React.Component {
    state = {
        pwdVisible: false,
        logoutVisible: false,
        loginUser: global.loginUser || null,
        isTestDB: false,
    };

    componentDidMount() {
        global.EE.addListener("LOAD_USERINFO", this.reloadUserInfo);
        global.EE.addListener("HAS_EMERGENCY", this.onHasEmergency);
        this.getDBName();
        if (!this.checkPwdUpdateTime()) {
            this.showChangePwdAlert();
        } else {
            console.log("checkPwdUpdateTime PASS")
        }
    }

    componentWillUnmount() {
        global.EE.removeListener("LOAD_USERINFO", this.reloadUserInfo);
        global.EE.removeListener("HAS_EMERGENCY", this.onHasEmergency);
    }
    checkPwdUpdateTime = () => {
        if (!ModelManager.loginUser || !ModelManager.loginUser.lastPwdUpdateTime) {
            return false;
        }
        let lastUpdateTime = ModelManager.loginUser.lastPwdUpdateTime;
        console.log("checkPwdUpdateTime lastUpdateTime", lastUpdateTime, moment(Number(lastUpdateTime)).add(90, "days").format("YYYY-MM-DD"))
        let timeStamp = moment(Number(lastUpdateTime)).add(90, "days").valueOf();
        let now = moment().valueOf();
        if (now > timeStamp) {
            return false;
        }
        return true;
    }
    showChangePwdAlert = () => {
        if (ModelManager.loginUser) {
            Modal.error({
                title: I18n.t("提示"),
                content: I18n.t("您已超过90天没有修改密码，为了您的账号安全，请前往修改"),
                okText: I18n.t("前往修改"),
                onOk: () => this.changePwd(),
                closable: (ModelManager.userIsAdmin() || global.isDev) ? true : false,
            });
        }
    }
    getDBName() {
        console.log("getDBName global.ServerUrl", global.ServerUrl)
        let url = global.ServerUrl + "db/name";
        Net.get(url, (res) => {
            console.log("getDBName", res)
            if (res && res.result == null && !["10.0.1.9", "192.168.168.156"].includes(res)) {
                this.setState({ isTestDB: true });
            }
        }, (error) => {
            console.log("getDBName", error)
        })
    }
    onHasEmergency = (value) => {
        console.log("onHasEmergency")
        if (ModelManager.isDataImport() || ModelManager.isZBPDY()) {
            this.setState({ hasEmergency: false });
            return;
        }
        if (value && global.emergencyList && global.emergencyList.length > 0) {
            this.setState({ hasEmergency: true });
        } else {
            this.setState({ hasEmergency: false });
        }
    };

    reloadUserInfo = (info) => {
        this.setState({ loginUser: info });
        if (info) {
            this.getStaff(info.loginName);
        }
    };
    getStaff = (loginName) => {
        let url = global.ServerUrl + "staff/getStaffByLoginName";
        Net.post(url, { loginName: loginName }, (res) => {
            console.log("getStaff", res);
            if (res.result === 0) {
                let userInfo = res.ret[0];
                if (userInfo) {
                    global.loginUser = userInfo;
                    ModelManager.loginUser = userInfo;
                }
            }
        }, error => {
            console.log("getStaff", error);
        })
    }
    onLogout = () => {
        this.setState({ logoutVisible: true });
    };
    clearLogout = () => {
        // this.props.history.push({pathname: '/login'});
        clearRemember();
        console.log('HeaderBar Language:', global.lang);
        saveDefaultLang(global.lang);
        global.loginUser = null;
        ModelManager.loginUser = null;
        let url = global.delParam("token");
        url = url.substring(0, url.indexOf("#"));
        window.location.href = url + '#/login';
        global.clearAppInterval();
        global.clearRoutesInterval();
    };
    setPwdValue = (prop, value) => {
        console.log("setPwdValue", prop, value)
        if (prop === "old") {
            this.setState({ oldPwd: value });
        } else if (prop === "new") {
            if (value) {
                value = value.replace(/[^\w_@.$%]/g, '');
            }
            console.log("setPwdValue value", value)
            this.setState({ newPwd: value });
        } else if (prop === "confirm") {
            if (value) {
                value = value.replace(/[^\w_@.$%]/g, '');
            }
            this.setState({ confirmPwd: value });
        }
    };
    changePwd = () => {
        this.setState({ pwdVisible: true, logoutVisible: false });
    };
    handleCancel = () => {
        this.setState({ logoutVisible: false, pwdVisible: false });
    };
    confirmChangePwd = () => {
        let { oldPwd, newPwd, confirmPwd } = this.state;
        let oldPassword = MD5(oldPwd).toString();
        if (ModelManager.loginUser.password !== oldPassword) {
            message.error("密码输入不正确");
        }
        newPwd = newPwd || "";
        confirmPwd = confirmPwd || "";
        if (newPwd.length === 0) {
            return message.error("请输入新密码");
        }
        if (newPwd !== confirmPwd) {
            message.error("两次密码输入不一致");
        }
        if (!global.checkPwdComplex(newPwd)) {
            return;
        }
        if (newPwd !== "" && newPwd === confirmPwd && ModelManager.loginUser.password === oldPassword) {
            let newPassword = MD5(confirmPwd).toString();
            let data = {
                loginName: ModelManager.loginUser.loginName,
                password: newPassword,
                oldPassword: oldPassword,
            }
            let url = global.ServerUrl + 'staff/changePassword'
            Net.post(url, data, res => {
                console.log("confirmChangePwd", res);
                if (res.result === 0) {
                    let userInfo = res.ret;
                    global.loginUser = userInfo;
                    ModelManager.loginUser = userInfo;
                    this.handleCancel();
                } else {
                    if (res.ret) {
                        return message.error(res.ret);
                    }
                    message.error(I18n.t("密码修改失败！"));
                }
            }, error => {
                console.log('confirmChangePwd', error);
                message.error(I18n.t("密码修改失败，请重试！"));
            });
        } else {
            message.error(I18n.t("旧密码输入不正确或两次密码输入不一致！"))
        }
    };
    onShowEmergency = (value) => {
        this.setState({ showEmergency: value });
        if (value === false) {
            global.loadEmergency();
        }
    };
    render() {
        const { loginUser } = this.state;
        const { location } = this.props;
        const notLogin = (
            <div style={{ display: 'flex' }}>
                <Link to={{ pathname: "/login", state: { from: location } }}
                    style={{ color: "#FFF" }}
                >{I18n.t("登录")}&nbsp;
                    <Image width="20px" src={require("../../assets/images/defaultUser.png").default} />
                </Link>
            </div>
        );
        return (
            <div id="headerbar" style={{ display: 'flex', flexDirection: 'row', height: 77, marginBottom: 3, alignItems: 'center', position: 'relative', zIndex: 99, backgroundColor: "#FFF" }}>
                <Image src={require("../../assets/images/logo.png").default} width={350} preview={false} />
                <div style={{ fontSize: 23, fontWeight: 'bold' }}>{I18n.t("网络售后维护")}</div>
                <span>{this.state.isTestDB ? "【测试】" : ""}</span>
                <div style={{ flex: 1 }} />
                <div style={{ height: 77, display: 'flex', flexDirection: "column" }}>
                    <div style={{ display: 'flex', }}>
                        {loginUser ? null : notLogin}
                        <span style={{ marginRight: 10, fontSize: 12, lineHeight: "30px", color: "#1A3061" }}>{loginUser ? global.transLabel("当前用户") + loginUser.empName : ""}</span>
                        <span style={{ marginRight: 10, fontSize: 12, lineHeight: "30px", color: "#1A3061" }}>{global.transLabel("版本")} {global.version}</span>
                        <div style={{ color: "#333", fontSize: 12, lineHeight: "30px", cursor: "pointer" }} onClick={() => this.onLogout()}>{I18n.t("【注销】")}</div>
                    </div>
                    {this.state.hasEmergency &&
                        <div style={{ height: 47, display: "flex", justifyContent: "flex-end", alignItems: "flex-end", }}>
                            <span className="blink" style={{ padding: 10, lineHeight: "27px", }} onClick={() => this.onShowEmergency(true)}>{I18n.t("加急工单")}</span>
                        </div>
                    }
                </div>
                <Modal
                    visible={this.state.logoutVisible}
                    title={I18n.t("注销账号")}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="password" danger onClick={this.changePwd}>{I18n.t("修改密码")}</Button>,
                        <Button key="back" onClick={this.handleCancel}>{I18n.t("取消")}</Button>,
                        <Button key="submit" type="primary" onClick={this.clearLogout}>{I18n.t("注销")}</Button>,
                    ]}
                >
                    <p>{I18n.t("确定要注销当前账号吗？")}</p>
                </Modal>
                <Modal
                    visible={this.state.pwdVisible}
                    title={I18n.t("修改密码")}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>{I18n.t("取消")}</Button>,
                        <Button key="submit" type="primary" onClick={this.confirmChangePwd}>{I18n.t("修改")}</Button>,
                    ]}
                >
                    <div>
                        <div>
                            <div style={{ marginBottom: 5, marginTop: 10 }}>{I18n.t("旧密码")}</div>
                            <Input.Password autoComplete="new-password" placeholder={I18n.t("原密码")}
                                value={this.state.oldPwd}
                                onChange={(e) => this.setPwdValue("old", e.target.value)} />
                        </div>
                        <div>
                            <div style={{ marginBottom: 5, marginTop: 10 }}>{I18n.t("新密码")}</div>
                            <Input.Password className="pwdInput" autoComplete="new-password"
                                placeholder={I18n.t("英文大小写字母、数字、_@.$%")}
                                value={this.state.newPwd}
                                onChange={(e) => this.setPwdValue("new", e.target.value)} />
                            <div style={{ color: "#F00" }}>{I18n.t("密码长度不小于8位，并包括大小写字母、特殊符号和数字")}</div>
                        </div>
                        <div>
                            <div style={{ marginBottom: 5, marginTop: 10 }}>{I18n.t("密码确认")}</div>
                            <Input.Password className="pwdInput" autoComplete="new-password"
                                placeholder={I18n.t("英文大小写字母、数字、_@.$%")}
                                value={this.state.confirmPwd}
                                onChange={(e) => this.setPwdValue("confirm", e.target.value)} />
                        </div>
                    </div>
                </Modal>
                <Modal
                    visible={this.state.showEmergency}
                    title={I18n.t("加急工单")}
                    width={"calc(100vw)"}
                    style={{
                        width: "calc(100vw)", maxWidth: "calc(100vw)",
                        height: "calc(100vh)", maxHeight: "calc(100vh)",
                        top: 0, padding: 0,
                    }}
                    bodyStyle={{ overflowY: "auto" }}
                    destroyOnClose
                    closable={false}
                    footer={null}
                >
                    <EmergencyFixApp onClose={() => this.onShowEmergency(false)} />
                </Modal>
            </div >
        )
    }
}


