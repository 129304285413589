//const S_DRAFT = 0;// 草稿
const S_COMMIT = 1;//提交
const S_REGION = 2;//区域经理
const S_NATIONAL = 3;//全国经理
const S_CHANNELADMIN = 4;//渠道 营销部
const S_SALEMANAGER = 5;//渠道 营销部
const S_MARKET = 6;//市场部
const S_GENERAL = 7;//总经理
//const S_FINISH = 8;//结束
//const S_DENY = 9;//否决
const S_REIMBERSEMENT = 10;// 已提交报销
//const S_DISCARD = -99;// 已作废
// const S_RETURN = 12;// 退回修改
const S_KA = 13;// KA经理
const S_SUPERVIOSOR = 14; //上级主管

//1 专柜费用申请;2 市场费用申请;3 专柜评估;4 促销评估;5 样机请购申请
const TYPE_DISPLAYTOOL = 1;
const TYPE_MARKET = 2;
const TYPE_EVALUE = 3;
const TYPE_PROMOTION = 4;
const TYPE_SAMPLE = 5;
const TYPE_APSAMPLE = 6;

//Willis add
const MKT_DL = 1;	//Delonghi
const MKT_KW = 2;	//KENWOOD
const MKT_BL = 3;	//博朗

const TYPE_PART = 0x0001;		//更换配件
const TYPE_MAINTEN = 0x0002;	//维护保养
const TYPE_NONE = 0x0004;		//无问题
const TYPE_DEBUG = 0x0008;	//调试
const TYPE_MACHINE = 0x0010;	//更换整机
const TYPE_DETECT = 0x0020;	//检测
const TYPE_FIX = 0x0040;	//维修

const S_DRAFT = 0;	//保存
const S_ASSIGN = 1;	//指派维修站
const S_APPOINT = 2; //预约上门
const S_WORKER = 3;	//指派维修工
const S_FINISH = 4;	//维修完成
const S_NOTICE = 5;	//通知取货
const S_PICKUP = 6;	//已取货
const S_RETURN1 = 7; //维修站回访
const S_RETURN2 = 8; //总部回访

const S_VISIT = 9; //上门

const S_DISCARD = 99; //已作废
const S_APPLY_DISCARD = 100; //申请作废
const S_TOBECONFIRM = 101; //待确认
const S_CANCEL_APPLY_DISCARD = 102; //取消作废申请
const S_RETURN_APPLY_DISCARD = 103; //退回作废申请
const S_HANDLE_COMPLAIN = 104; //投诉处理

//For checkout 结账
const S_TOBESENT = 9;	//旧件待寄出
const S_TOBECHECK = 10;	//待盘点
const S_APPLY = 11;	//已申请结账
const S_AUDIT = 12;	//已审核
const S_AGREE = 13;	//已通过
const S_INVOICE = 14;	//已开票
const S_REIMBURSE = 15;//已报销
const S_PAUDIT = 16;	//主站已审核
const S_SUBAPPLY = 17;	//子站已申请结账
const S_OVERSEASAPPLY = 18;	//官方海外购已申请结账
const S_DENY = 19;	//否决

const S_BACK = 20;	//退回修改
const S_SEAINVOICE = 23;	//已开票
const S_SEAREIMBURSE = 24; //已报销

//For 取消回访
const S_CANCEL_RETURN1 = 21;	//取消维修站回访
const S_CANCEL_RETURN2 = 22;	//取消总部回访

const S_TOBESEND1 = 25;// 待顾客送修
const S_TOBESEND2 = 26;// 待顾客寄出
const S_TOBEREPAIRED = 27; // 取货待维修


const TYPE_VISIT = 1; //上门处理
const TYPE_ACCESS = 2; //接修
const TYPE_OTHER = 3; //其他
const TYPE_PART_SUPPLY = 9; // 配件补发
const TYPE_EXPRESS = 10; //快递寄修

const STATISTIC_EPIDEMIC = 1;   // 疫情统计
const STATISTIC_HOLIDAY = 2;   // 节假日统计

const O_Submitted = 1; // 已提交
const O_Returned = 2; // 已退回
const O_Uploaded = 3; // 已上传
const O_DENY = 4; // 已否决
const O_Uploaded_Again = 5;

const TYPE_FIXAPP = 1;
const TYPE_COFFEEAPP = 2;

// const statusArray = [
//     S_DRAFT, S_ASSIGN, S_APPOINT, S_FINISH, S_NOTICE, S_PICKUP,
//     S_RETURN1, S_RETURN2, S_TOBESENT, S_TOBECHECK, S_APPLY, S_AUDIT, S_AGREE, S_DENY,
//     S_DISCARD, S_INVOICE, S_REIMBURSE, S_CANCEL_RETURN1, S_CANCEL_RETURN2,
//     S_PAUDIT, S_SUBAPPLY, S_BACK, S_SEAINVOICE, S_SEAREIMBURSE, S_APPLY_DISCARD,
//     S_TOBECONFIRM, S_CANCEL_APPLY_DISCARD, S_RETURN_APPLY_DISCARD, S_TOBESEND1, S_TOBESEND2, S_TOBEREPAIRED,
//     S_HANDLE_COMPLAIN
// ];

// const statusLabelArray = [
//     "接单", "已派单", "已预约上门", "维修完成", "已通知取货", "用户已取货",
//     "维修站已回访", "总部已回访", "旧件待寄出", "待盘点旧件", "待结账审核", "待结账审批", "已批准结账", "已否决",
//     "已作废", "已开票", "已报销", "取消维修站回访", "取消总部回访",
//     "待结账审核", "提交主站审核", "退回修改", "公司已开票", "经销商已报销", "申请作废",
//     "待确认", "取消作废申请", "退回作废申请", "待顾客送修", "待顾客寄出", "取货待维修",
//     "投诉已处理"
// ];

// const processLabelArray = [
//     "保存维修单", "派单处理", "预约上门", "维修完成", "通知取货", "用户取货",
//     "待继续回访", "完成回访", "申请结账", "旧件已寄出", "旧件已盘点", "审核结账", "批准结账", "否决处理",
//     "作废处理", "开票确认", "报销确认", "取消维修站回访", "取消总部回访",
//     "主站审核", "申请结账", "退回修改", "公司已开票", "经销商已报销", "申请作废",
//     "待确认", "取消作废申请", "退回作废申请", "待顾客送修", "待顾客寄出", "取货待维修",
//     "投诉已处理"
// ];

let AppStatus = {
    MKT_DL,	//Delonghi
    MKT_KW,	//KENWOOD
    MKT_BL,	//博朗

    TYPE_PART,		//更换配件
    TYPE_MAINTEN,	//维护保养
    TYPE_NONE,		//无问题
    TYPE_DEBUG,	//调试
    TYPE_MACHINE,	//更换整机
    TYPE_DETECT,	//检测
    TYPE_FIX,	//维修

    TYPE_VISIT, // 上门处理
    TYPE_ACCESS, // 接修
    TYPE_OTHER, // 其他

    TYPE_PART_SUPPLY, // 配件补发
    TYPE_EXPRESS, // 快递寄修

    S_DRAFT,//保存
    S_ASSIGN,//指派维修站
    S_APPOINT, //预约上门
    S_WORKER,//指派维修工
    S_FINISH,//维修完成
    S_NOTICE,//通知取货
    S_PICKUP,//已取货
    S_RETURN1,//维修站回访
    S_RETURN2,//总部回访
    S_VISIT, // 上门
    S_DISCARD,//已作废
    S_APPLY_DISCARD,//申请作废
    S_CANCEL_APPLY_DISCARD,//取消作废申请
    S_RETURN_APPLY_DISCARD,//退回作废申请
    S_TOBECONFIRM,//待确认

    S_TOBESENT, // 旧件待寄出
    S_TOBECHECK, // 待盘点旧件
    S_APPLY,	//已申请结账
    S_AUDIT,	//已审核
    S_AGREE,	//已通过
    S_INVOICE,	//已开票
    S_REIMBURSE,//已报销
    S_SEAINVOICE, // 公司已开票
    S_SEAREIMBURSE, // 经销商已报销
    S_DENY,	//否决
    S_PAUDIT,	//主站已审核
    S_SUBAPPLY,	//子站已申请结账
    S_OVERSEASAPPLY,	//官方海外购已申请结账

    S_BACK, //退回修改

    S_TOBESEND1, // 待顾客送修
    S_TOBESEND2, // 待顾客寄出
    S_TOBEREPAIRED, // 取货待维修

    S_HANDLE_COMPLAIN, // 投诉处理

    STATISTIC_EPIDEMIC, // 疫情统计
    STATISTIC_HOLIDAY, // 假期统计

    O_Submitted, // 已提交
    O_Returned, // 已退回
    O_Uploaded, // 已上传
    O_DENY, // 否决
    O_Uploaded_Again, // 再次上传

    TYPE_FIXAPP,
    TYPE_COFFEEAPP,

    statusArray: [
        S_DRAFT, S_ASSIGN, S_APPOINT, S_FINISH, S_NOTICE, S_PICKUP,
        S_RETURN1, S_RETURN2, S_TOBESENT, S_TOBECHECK, S_APPLY, S_AUDIT, S_AGREE, S_DENY,
        S_DISCARD, S_INVOICE, S_REIMBURSE, S_CANCEL_RETURN1, S_CANCEL_RETURN2,
        S_PAUDIT, S_SUBAPPLY, S_BACK, S_SEAINVOICE, S_SEAREIMBURSE, S_APPLY_DISCARD,
        S_TOBECONFIRM, S_CANCEL_APPLY_DISCARD, S_RETURN_APPLY_DISCARD, S_TOBESEND1, S_TOBESEND2, S_TOBEREPAIRED,
        S_HANDLE_COMPLAIN
    ],
    statusLabelArray: [
        "接单", "已派单", "已预约上门", "维修完成", "已通知取货", "用户已取货",
        "维修站已回访", "总部已回访", "旧件待寄出", "待盘点旧件", "待结账审核", "待结账审批", "已批准结账", "已否决",
        "已作废", "已开票", "已报销", "取消维修站回访", "取消总部回访",
        "待结账审核", "提交主站审核", "退回修改", "公司已开票", "经销商已报销", "申请作废",
        "待确认", "取消作废申请", "退回作废申请", "待顾客送修", "待顾客寄出", "取货待维修",
        "投诉已处理"
    ],
    processLabelArray: [
        "保存维修单", "派单处理", "预约上门", "维修完成", "通知取货", "用户取货",
        "待继续回访", "完成回访", "申请结账", "旧件已寄出", "旧件已盘点", "审核结账", "批准结账", "否决处理",
        "作废处理", "开票确认", "报销确认", "取消维修站回访", "取消总部回访",
        "主站审核", "申请结账", "退回修改", "公司已开票", "经销商已报销", "申请作废",
        "待确认", "取消作废申请", "退回作废申请", "待顾客送修", "待顾客寄出", "取货待维修",
        "投诉已处理"
    ],
    CheckoutProcessLabelList: [
        "保存维修单", "派单处理", "预约上门", "维修完成", "通知取货", "用户取货",
        "待继续回访", "完成回访", "申请结账", "旧件已寄出", "旧件已盘点", "审核结账", "批准结账", "否决处理",
        "作废处理", "开票确认", "报销确认", "取消维修站回访", "取消总部回访",
        "主站审核", "申请结账", "退回修改", "公司已开票", "经销商已报销", "申请作废",
        "待确认", "取消作废申请", "退回作废申请", "待顾客送修", "待顾客寄出", "取货待维修",
        "投诉已处理"
    ],
    GetStatusLabel: function (s, type) {
        // if (type !== TYPE_VISIT && type !== TYPE_ACCESS && s === S_DRAFT && type !== TYPE_EXPRESS)
        if (![TYPE_VISIT, TYPE_ACCESS, TYPE_EXPRESS].includes(type) && s === S_DRAFT)
            return "已完成";

        for (let i = 0; i < this.statusArray.length; i++) {
            if (this.statusArray[i] === s)
                return this.statusLabelArray[i];
        }
        return "";
    },
    GetProcessLabel: function (p) {
        for (let i = 0; i < this.statusArray.length; i++) {
            if (this.statusArray[i] === p)
                return this.processLabelArray[i];
        }
        return "";
    },
    GetCoffeeStatusLabel: function (s, type) {
        // if (type === 0 || type === 1 || type === 6)
        if ([0, 1].includes(type)) return "已完成";
        for (let i = 0; i < this.statusArray.length; i++) {
            if (this.statusArray[i] === s)
                return this.statusLabelArray[i];
        }
        return "";
    },
    OrderStatusArray: [S_DRAFT, O_Submitted, O_Returned, O_Uploaded, O_DENY, S_DISCARD, O_Uploaded_Again],
    OrderStatusLabelArray: ["待提交", "已提交", "已退回", "已上传", "已否决", "已作废", "重新上传"],
    OrderProcessLabelArray: ["保存订单数据", "提交订单数据", "退回订单数据", "上传订单数据", "否决订单数据", "作废订单数据", "重新上传订单数据"],
    GetOrderStatusLabel: function (s) {
        for (let i = 0; i < this.OrderStatusArray.length; i++) {
            if (this.OrderStatusArray[i] === s)
                return this.OrderStatusLabelArray[i];
        }
        return "";
    },
    GetOrderProcessLabel: function (p) {
        for (let i = 0; i < this.OrderStatusArray.length; i++) {
            if (this.OrderStatusArray[i] === p)
                return this.OrderProcessLabelArray[i];
        }
        return "";
    },
    GetStatusList: function () {
        let list = [];
        for (let i = 0; i < this.statusArray.length; i++) {
            list.push({ label: this.statusLabelArray[i], value: this.statusArray[i] });
        }
        return list;
    },
};

export default AppStatus;
