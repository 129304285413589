import ModelManager from "./ModelManager";

let Staff = {
    ROLE_SALES: 1, // 销售代表
    ROLE_REGION_MANAGER: 2, // 区域销售经理
    ROLE_NATIONALMANAGER: 3, // 全国销售经理
    ROLE_CHANNELADMIN: 4, // 市场部助理
    ROLE_CHANNELMANAGER: 5, // 产品经理
    ROLE_MARKETMANAGER: 6, // 市场部经理
    ROLE_GENERALMANAGER: 7, // 董事总经理
    ROLE_KA: 8, // 助理KA经理
    ROLE_STAFF: 10, // 普通员工
    ROLE_SALES_MANAGER: 11, // 销售经理

    ROLE_FINANCE_MANAGER: 21, // 财务经理
    ROLE_FINANCE_STAFF: 22, // 财务人员

    ROLE_WORKER: 1,
    ROLE_STATION_ADMIN: 3, // 维修站主管
    ROLE_ASSISTANT: 5, // 维修部助理
    ROLE_MANAGER: 7, // 维修部经理
    ROLE_ADMIN: 99, // 系统管理员
    ROLE_VIEW: 88, // 数据查询员
    ROLE_OLD_PARTS_CHECK: 100, // 旧件盘点员
    ROLE_ORDER_DATA_IMPORT: 101, // 订单数据导入员
    ROLE_AGENCY: 102, // 经销商
    ROLE_AUDIT: 103, // 审计管理员
    ROLE_SAFE: 104, // 安全管理员

    empId: null,
    regionId: null,
    empName: null,
    empPhone: null,
    empSex: null,
    role: null,
    province: null,
    city: null,
    email: null,
    password: null,
    loginName: null,
    supervisor: null,
    remark: null,
    oaUserName: null,

    //TODO del
    region: null,
    supervisorStaff: null,
    departmentId: null,
    isEngineer: null,
    canExport: null,
    engineerNo: null,
    getName: function () {
        return this.empName;
    },

    getEmployee: function () {
        for (let i = 0; i < ModelManager.staffList.length; i++) {
            const s = ModelManager.staffList[i];
            if (s.empId == this.empId) {
                return this.empId + "(" + s.empName + ")";
            }
        }
        return "";
    },
    getArea: function () {
        for (let i = 0; i < ModelManager.RegionList.length; i++) {
            const d = ModelManager.RegionList[i];
            if (d.id == this.regionId) {
                return d.name;
            }
        }
        return "";
    },
    Staff: function () {
    },
    toString: function () {
        return this.empName;
    },
    getStaffWithDeptName: function () {
        return this.empName;
    }
};

export default Staff; 
