// 定义所有省级名称
const provinces = ["北京", "天津", "河北", "山西", "内蒙古", "辽宁", "吉林", "黑龙江", "上海", "江苏", "浙江", "安徽", "福建", "江西", "山东", "河南", "湖北", "湖南", "广东", "广西", "海南", "重庆", "四川", "贵州", "云南", "西藏", "陕西", "甘肃", "青海", "宁夏", "新疆", "台湾", "香港", "澳门", "国外"];

// 定义所有省级可能出现的后缀
const provinceKey = ['特别行政区', '维吾尔自治区', '壮族自治区', '回族自治区', '自治区', '省省直辖', '省', '市'];

// 定义所有地级市可能出现的后缀
const cityKey = ['布依族苗族自治州', '苗族侗族自治州', '自治州', '行政单位', '市', '盟', '地区', '县'];

// 定义所有县级可能出现的后缀
const areaKey = ['县', '旗', '海域', '岛', '市', '区'];

// 定义详细地址可能出现的后缀
const addrKey = ['室', '楼', '单元', '元', '号', '幢', '门', '户', '米', '北', '南', '东', '西', '路口', '路', '处', '街道', '街'];

// 自定义去除关键字，可自行添加
const search = ['地址', '收货地址', '收货人', '收件人', '收货', '邮编', '电话', '：', ':', '；', ';', '，', ',', '。', ' '];

// 整理电话格式
const mobileRegexs = [
  /(\d{3})-(\d{4})-(\d{4})/g,
  /(\d{3}) (\d{4}) (\d{4})/g,
];

const mobileReg = /(86-[1][0-9]{10})|(86[1][0-9]{10})|([1][0-9]{10})/g;

// 直辖市
const municipalityDirectly = '北京|天津|重庆|上海';

/**
 * 解析地址
 * @param {Object} obj 
 * @param {String} obj.parseText 要解析的地址文本。
 * @param {function({addr: '富康路姚家园3楼5单元3305室',area: '朝阳区',city: '北京市',mobile: '15000000000',name: '马云',province: '北京市',})=} obj.success
 * @param {function(String)=} obj.fail
 * @param {function({addr: '富康路姚家园3楼5单元3305室',area: '朝阳区',city: '北京市',mobile: '15000000000',name: '马云',province: '北京市',}|String)=} obj.complete
 * @return {()=>void}
 * @example parse({
 * parseText: '北京市朝阳区富康路姚家园3楼5单元3305室马云150-0000-0000',
 * success(res) {},
 * fail(res) {},
 * complete(res) {}
 * })
 * @example parse({
 * parseText: '北京市朝阳区富康路姚家园3号楼5单元3305室马云15000000000',
 * success(res) {},
 * fail(res) {},
 * complete(res) {}
 * })
 * @example parse({
 * parseText: '北京市朝阳区富康路姚家园3号楼5单元3305室马云15000000000',success(res) {},
 * fail(res) {},
 * complete(res) {}
 * })
 * @example parse({
 * parseText: '马云,1351111111,北京市朝阳区富康路姚家园3楼',
 * success(res) {},
 * fail(res) {},
 * complete(res) {}
 * })
 * @example parse({
 * parseText: '马云1351111111北京市朝阳区富康路姚家园3楼',
 * success(res) {},
 * fail(res) {},
 * complete(res) {}
 * })
 * @example parse({
 * parseText: '北京市朝阳区富康路姚家园3楼1351111111马云',
 * success(res) {},
 * fail(res) {},
 * complete(res) {}
 * })
 * @example parse({
 * parseText: '北京市朝阳区富康路姚家园3楼150-0000-0000马云',
 * success(res) {},
 * fail(res) {},
 * complete(res) {}
 * })
 */
function parseAddr({parseText, success=function(){}, fail=function(){}, complete=function(){}}) {
  try {
    if (parseText.trim() === '') {
      throw '无法识别';
    }

    let parse = {
      addr: '',
      area: '',
      city: '',
      mobile: '',
      name: '',
      province: '',
    };

    // 过滤关键字词
    search.forEach(str => {
      parseText = parseText.replace(new RegExp(str, 'g'), '')
    });

    // 先识别出手机或电话号码
    mobileRegexs.forEach(regex => {
      parseText = parseText.replace(regex, '$1$2$3')
    });

    let mobile = mobileReg.exec(parseText);
    if (mobile) {
      parse.mobile = mobile[0];
      // 识别完电话手机号码，要把对应的号码从待识别里面去掉
      parseText = parseText.replace(mobile[0], '')
    }

    // 地区地址
    let address = parseText;

    // 检测是哪个省份或直辖市
    for (let i in provinces) {
      let index = address.indexOf(provinces[i]);

      if (index > -1) {
        address = address.substring(index);
      }

      /**
       * 如果地址是直辖市，如“北京市朝阳区”，则补全为“北京市北京市朝阳区”。否则下面的正则表达式匹配不到
       * 但如果地址是“北京市北京市朝阳区”，则不能再修改了
       */
      if (new RegExp(municipalityDirectly, 'g').test(address) && address.match(new RegExp(municipalityDirectly, 'g')).length === 1) {
        let tmp = address.substring(0, 2) + '市' + address;

        parseText = parseText.replace(new RegExp(address, 'g'), tmp);
        
        address = tmp;
      }
    }

    // 匹配地址的正则表达式。包含：省级、地级市级、县级、详细地址等四部分
    let addrRegex = '(.*?)';

    // 省级
    addrRegex = addrRegex + '(?';
    provinceKey.forEach(item=>{
      addrRegex = addrRegex + '[^'+item+']+'+item+'|';
    });
    addrRegex = addrRegex.substring(0, addrRegex.length-1);
    addrRegex = addrRegex + ')';

    // 地级市级
    addrRegex = addrRegex + '(?';
    cityKey.forEach(item=>{
      addrRegex = addrRegex + '[^'+item+']+'+item+'|';
    });
    addrRegex = addrRegex.substring(0, addrRegex.length-1);
    addrRegex = addrRegex + ')';

    // 县级
    addrRegex = addrRegex + '(?';
    areaKey.forEach(item=>{
      addrRegex = addrRegex + '[^'+item+']+'+item+'|';
    });
    addrRegex = addrRegex.substring(0, addrRegex.length-1);
    addrRegex = addrRegex + ')';

    // 详细地址
    addrRegex = addrRegex + '(?';
    addrKey.forEach(item=>{
      addrRegex = addrRegex + '[^'+item+']+'+item+'|';
    });
    addrRegex = addrRegex + '.*?';
    addrRegex = addrRegex + ')';

    // 地址的匹配结果
    let addrRet = address.match(new RegExp(addrRegex));

    if (new RegExp(addrRegex, 'g').test(address)) {
      parse.province = addrRet.groups.province;
      parse.city = addrRet.groups.city;
      parse.area = addrRet.groups.county;
      parse.addr = addrRet.groups.village;
      // 去掉手机电话号码和地址之后，剩下的就是姓名了
      parseText = parseText.replace(new RegExp(addrRet[0], 'g'), '');
    }

    // 姓名
    parse.name = parseText;

    let flag = false;
    for (let i in parse) {
      if (parse[i]) {
        flag = true;
      }
    }

    if (flag) {
      complete && complete(parse);
      success && success(parse);
    } else {
      throw '识别失败';
    }
  } catch (err) {
    complete && complete(err);
    fail && fail(err);
  }
}


function accMul(arg1, arg2) {
    let m = 0;
    const s1 = arg1.toString();
    const s2 = arg2.toString();
    m += s1.split(".").length > 1 ? s1.split(".")[1].length : 0;
    m += s2.split(".").length > 1 ? s2.split(".")[1].length : 0;
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / 10 ** m;
}

export function digitUppercase(n) {
    const fraction = ['角', '分'];
    const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
    const unit = [['元', '万', '亿'], ['', '拾', '佰', '仟', '万']];
    let num = Math.abs(n);
    let s = '';
    fraction.forEach((item, index) => {
        s += (digit[Math.floor(accMul(num, 10 * 10 ** index)) % 10] + item).replace(/零./, '');
    });
    s = s || '整';
    num = Math.floor(num);
    for (let i = 0; i < unit[0].length && num > 0; i += 1) {
        let p = '';
        for (let j = 0; j < unit[1].length && num > 0; j += 1) {
            p = digit[num % 10] + unit[1][j] + p;
            num = Math.floor(num / 10);
        }
        s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
    }

    return s
        .replace(/(零.)*零元/, '元')
        .replace(/(零.)+/g, '零')
        .replace(/^整$/, '零元整');
}


/**
 * 生成指定区间的随机整数
 * @param min
 * @param max
 * @returns {number}
 */
export function randomNum(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}

/**
 * 计算提示框的宽度
 * @returns {number}
 * @param arr
 */
export function calculateWidth(arr) {
    return 30 + arr[0].length * 15
}

/**
 * 图片预加载
 * @param arr
 * @constructor
 */
export function preloadingImages(arr) {
    arr.forEach(item => {
        const img = new Image();
        img.src = item
    })
}

/**
 * 动态验证码
 * @param canvas
 * @returns {code}
 */
export function createCode(canvas) {
    const ctx = canvas.getContext('2d');
    const chars = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'n', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    let code = '';
    ctx.clearRect(0, 0, 80, 39);
    for (let i = 0; i < 4; i++) {
        const char = chars[randomNum(0, 57)];
        code += char;
        ctx.font = randomNum(20, 25) + 'px SimHei'; //设置字体随机大小
        ctx.fillStyle = '#D3D7F7';
        ctx.textBaseline = 'middle';
        ctx.shadowOffsetX = randomNum(-3, 3);
        ctx.shadowOffsetY = randomNum(-3, 3);
        ctx.shadowBlur = randomNum(-3, 3);
        ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
        let x = 80 / 5 * (i + 1);
        let y = 39 / 2;
        let deg = randomNum(-25, 25);
        /**设置旋转角度和坐标原点**/
        ctx.translate(x, y);
        ctx.rotate(deg * Math.PI / 180);
        ctx.fillText(char, 0, 0);
        /**恢复旋转角度和坐标原点**/
        ctx.rotate(-deg * Math.PI / 180);
        ctx.translate(-x, -y)
    }

    return code
}

window.parseAddr = parseAddr;

/**
 * 格式化日期
 * @param date
 * @param format
 * @returns {dateStr}
 */
export function formatDate(date, format) {
    let o = {
        "M+": date.getMonth() + 1,//月份
        "d+": date.getDate(),//日
        "h+": date.getHours(),//小时
        "m+": date.getMinutes(),//分
        "s+": date.getSeconds(),//秒
        "q+": Math.floor((date.getMonth() + 3) / 3),//季度
        "S": date.getMilliseconds()//毫秒
    };

    if (/(y+)/.test(format))
        format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(format))
            format = format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return format;
}

/**
 * 将json字符串转换为object
 * @param data
 * @return trans
 */
export function transJson(data) {
    let trans = data;

    if (typeof data === 'string' && (data.indexOf('{') === 0 || data.indexOf('[') === 0)) {
        trans = JSON.parse(data);
    }

    return trans;
}

/**
 * 判断字符串是否为空
 * @param str
 * @return boolean
 */
export function isEmpty(str) {
    if (typeof str === "undefined" || str === null || str === "") {
        return true;
    } else {
        return false;
    }
}
