import Moment from 'moment';

import AppStatus from "./AppStatus";
import Staff from "./Staff";
import I18n from "../language/I18n";

const AutoProductNames = ["自动咖啡机", "自動咖啡機",];
const ProductNames = ["泵压式咖啡机EC系列", "泵壓式咖啡機EC系列",];

// const zh_HK_provinceList = [
//     "请选择",
//     "新界",
//     "离岛",
//     "九龙",
//     "香港岛",
// ];
// const zh_HK_cityList = [
//     ["请选择"],
//     ["大围", "沙田", "火炭", "马鞍山", "大埔", "太和", "粉岭", "上水", "罗湖", "落马洲", "葵芳", "葵涌", "葵兴", "荃湾", "青衣", "马湾", "深井", "屯门", "元朗", "天水围", "流浮山", "西贡", "将军澳", "宝琳", "坑口",],
//     ["愉景湾", "东涌", "赤𫚭角", "大屿山", "大澳", "长洲", "坪洲", "南丫岛", "蒲苔岛",],
//     ["美孚", "荔枝角", "长沙湾", "深水埗", "太子", "旺角", "大角咀", "油麻地", "佐敦", "尖沙咀", "红磡", "何文田", "土瓜湾", "石硖尾", "九龙塘", "九龙城", "乐富", "黄大仙", "新蒲岗", "钻石山", "慈云山", "彩虹", "九龙湾", "牛头角", "观塘", "蓝田", "油塘", "鲤鱼门",],
//     ["西环", "石塘咀", "西营盘", "坚尼地城", "上环", "中环", "半山", "山顶", "金钟", "湾仔", "跑马地", "铜锣湾", "大坑", "天后", "北角", "鲗鱼涌", "太古", "西湾河", "筲箕湾", "杏花邨", "柴湾", "薄扶林", "数码港", "香港仔", "鸭脷洲", "深水湾", "黄竹坑", "浅水湾", "石澳", "赤住",],
// ];

let ModelManager = {
    loginUser: null,
    staffList: [],
    departmentList: [],
    RegionList: [],//维修站
    RegionMap: {},
    accessoryList: [],	//零件列表
    accessoryListForFilter: [], //零件列表的拷贝，用于输入零件时的筛选列表
    productList: [],	//产品列表
    OrgList: [],
    userName: function () {
        if (this.loginUser && this.loginUser.loginName === "Admin")
            return this.loginUser.loginName;
        return this.loginUser.loginName;
    },
    ERROR: "ERROR",
    OK: "OK",
    OP_OK: "成功",
    OP_ERROR: "失败",
    dateFormat: "YYYY年MM月DD日",
    dateFormatForOrder: "YYYY-MM-DD",
    /**城市列表*/
    CityList: [],
    mailSetting: null,
    isSubRegion: false,
    /**部门列表*/
    ChannelList: [
        { data: 0, label: "" },
        { data: 1, label: "TM" },
        { data: 2, label: "DL MTK" },
        { data: 3, label: "KW MTK" }
    ],
    thicknessList: [
        { data: 0, label: "请选择" },
        { data: 1, label: "很粗" },
        { data: 2, label: "略粗" },
        { data: 3, label: "正常" },
        { data: 4, label: "略细" },
        { data: 5, label: "很细" }
    ],
    looseList: [
        { data: 0, label: "请选择" },
        { data: 1, label: "不成形" },
        { data: 2, label: "略松散" },
        { data: 3, label: "成形" }
    ],
    checkoutAppTypeList: [
        { label: "维修", data: AppStatus.TYPE_FIXAPP },
        { label: "保养", data: AppStatus.TYPE_COFFEEAPP },
    ],
    setStaffList: function (list) {
        this.staffList = list;
    },
    setRegionList: function (list) {
        this.RegionList = list;
    },
    getStaffName: function (num) {
        let array = this.staffList || [];
        for (let i = 0; i < array.length; i++) {
            if (array[i].empId === num) {
                return array[i].empName;
            }
        }
        return null;
    },
    /*
    let roleList:Array = [{label: "销售代表", data:Staff.ROLE_SALES},
                                        {label: "区域销售经理", data:Staff.ROLE_REGION_MANAGER},
                                        {label: "全国销售经理", data:Staff.ROLE_NATIONALMANAGER},
                                        {label: "市场部助理", data:Staff.ROLE_CHANNELADMIN},
                                        {label: "产品经理", data:Staff.ROLE_CHANNELMANAGER},
                                        {label: "市场部经理", data:Staff.ROLE_MARKETMANAGER},
                                        {label: "董事总经理", data:Staff.ROLE_GENERALMANAGER},
                                        {label: "助理KA经理", data:Staff.ROLE_KA},
                                        {label: "部门经理", data:Staff.ROLE_MANAGER},
                                        {label: "销售经理", data:Staff.ROLE_SALES_MANAGER},
                                        {label: "财务经理", data:Staff.ROLE_FINANCE_MANAGER},
                                        {label: "财务人员", data:Staff.ROLE_FINANCE_STAFF},
                                        {label: "系统管理员", data:Staff.ROLE_ADMIN},
                                        {label: "普通员工", data:Staff.ROLE_STAFF}];//职务
    */
    departmentListForSearch: function () {
        let a = [];
        a.addItem("请选择");
        a.addAll(this.departmentList);
        return a;
    },
    userIsAdmin: function () {
        if (this.loginUser && this.loginUser.loginName === "admin") {
            return true;
        }
        return false;
    },
    // 总部用户
    userIsZB: function () {
        if (this.loginUser && this.loginUser.regionId === 2) {
            return true;
        }
        return false;
    },
    // 系统管理员
    isAdmin: function () {
        if (!this.loginUser) {
            return false;
        }
        if (this.loginUser.role === Staff.ROLE_ADMIN) {
            return true;
        }
        return false;
    },
    // 维修站主管
    isStationAdmin: function () {
        if (!this.loginUser) {
            return false;
        }
        if (this.loginUser.role === Staff.ROLE_STATION_ADMIN) {
            return true;
        }
        return false;
    },
    // 数据查询员
    isViewer: function () {
        if (!this.loginUser) {
            return false;
        }
        if (this.loginUser.role === Staff.ROLE_VIEW) {
            return true;
        }
        return false;
    },
    // 旧件盘点员
    isZBPDY: function () {
        if (this.loginUser && this.loginUser.regionId == 2 && this.loginUser.role === Staff.ROLE_OLD_PARTS_CHECK) {
            return true;
        }
        return false;
    },
    // 订单数据导入员
    isDataImport: function () {
        if (this.loginUser && this.loginUser.role === Staff.ROLE_ORDER_DATA_IMPORT) {
            return true;
        }
        return false;
    },
    // 经销商
    isAgency: function () {
        if (this.loginUser && this.loginUser.role === Staff.ROLE_AGENCY) {
            return true;
        }
        return false;
    },
    // 审计管理员
    isAudit: function () {
        if (this.loginUser && this.loginUser.role === Staff.ROLE_AUDIT) {
            return true;
        }
        return false;
    },
    // 安全管理员
    isSafe: function () {
        if (this.loginUser && this.loginUser.role === Staff.ROLE_SAFE) {
            return true;
        }
        return false;
    },
    // 订单上传审批权限
    orderAuditAuth: function () {
        if (this.loginUser && this.loginUser.isOrderAudit) {
            return true;
        }
        return false;
    },
    getMyProcessStatus: function () {
        if (this.loginUser && this.loginUser.role === Staff.ROLE_REGION_MANAGER) {
            return AppStatus.S_REGION;
        }
        if (this.loginUser && this.loginUser.role === Staff.ROLE_NATIONALMANAGER) {
            return AppStatus.S_NATIONAL;
        }
        if (this.loginUser && this.loginUser.role === Staff.ROLE_CHANNELADMIN) {
            return AppStatus.S_CHANNELADMIN;
        }
        if (this.loginUser && this.loginUser.role === Staff.ROLE_CHANNELMANAGER) {
            return AppStatus.S_SALEMANAGER;
        }
        if (this.loginUser && this.loginUser.role === Staff.ROLE_MARKETMANAGER) {
            return AppStatus.S_MARKET;
        }
        if (this.loginUser && this.loginUser.role === Staff.ROLE_GENERALMANAGER) {
            return AppStatus.S_GENERAL;
        }
        if (this.loginUser && this.loginUser.role === Staff.ROLE_KA) {
            return AppStatus.S_KA;
        }
        return AppStatus.S_COMMIT;
    },

    year: function () {
        let i = 1;
        let monthes = [];
        for (; i <= 12; i++) {
            monthes.push(i + "月");
        }
        return monthes;
    },
    workTypes: ["", "上班", "出差", "休息", "病假", "事假", "婚假", "产假", "丧假", "年假", "其它"],
    purposeList: ["请选择", "展览用", "测试用", "免费", "收费"],
    printing: false,
    NumberRestrict: "0-9.\\-",
    //Add for 维修服务
    getRegionName: function (num) {
        for (let i = 0; i < this.RegionList.length; i++) {
            const r = this.RegionList[i];
            if (r.id === num)
                return r.name;
        }
        return "";
    },
    getRegionNumber: function (num) {
        for (let i = 0; i < this.RegionList.length; i++) {
            const r = this.RegionList[i];
            if (r.id === num)
                return r.deptNumber;
        }
        return "";
    },
    getRegion: function () {
        if (!this.loginUser)
            return {};
        for (let i = 0; i < this.RegionList.length; i++) {
            const r = this.RegionList[i];
            if (r.id === this.loginUser.regionId)
                return r;
        }
        return {};
    },
    typeList: [
        { data: 0, label: "请选择" },
        { data: 1, label: "上门处理" }, // TYPE_VISIT
        { data: 2, label: "站点接修" }, // TYPE_ACCESS
        { data: 3, label: "咨询产品使用" },
        { data: 4, label: "咨询维修状况" },
        { data: 5, label: "咨询当地维修站或购买配件" },
        { data: 6, label: "咨询产品潜在客户" },
        { data: 7, label: "投诉" },
        { data: 8, label: "称赞" },
        { data: 9, label: "配件补发" }, // TYPE_PART_SUPPLY
        // { data: 10, label: "异地寄修" }
        { data: 10, label: "快递寄修" } // TYPE_EXPRESS
    ],
    typeListForAdd: [
        { data: 0, label: "请选择" },
        { data: 1, label: "上门处理" },
        { data: 2, label: "站点接修" },
        { data: 9, label: "配件补发" },
        // { data: 10, label: "异地寄修" }
        { data: 10, label: "快递寄修" }
    ],
    getTypeLabel: function (type, array) {
        array = array || [];
        for (let i = 0; i < array.length; i++) {
            const element = array[i];
            if (element.data === type) {
                return element.label;
            }
        }
        return "";
    },
    userTypeList: [
        { data: 0, label: "请选择" },
        { data: 1, label: "新增潜在用户" },
        { data: 2, label: "单次个人用户" },
        { data: 3, label: "单次公司用户" },
        { data: 4, label: "合同用户" },
        { data: 5, label: "优惠券用户" },
        { data: 6, label: "其他" },
        { data: 7, label: "微信小程序用户" },
    ],
    cityList: [],
    provinceList: [],
    zh_CN_cityList: [
        ["请选择"], ["北京市"], ["天津市"], ["上海市"],
        ["重庆市", "开县", "綦江区", "南川区", "长寿区", "涪陵区", "黔江区", "铜梁区", "北碚区", "大足区", "璧山区", "其他"],
        ["香港特别行政区"], ["澳门特别行政区"],
        ["合肥市", "芜湖市", "蚌埠市", "淮南市", "马鞍山市", "淮北市", "铜陵市", "宿州市", "安庆市", "滁州市", "阜阳市", "巢湖市", "六安市", "池州市", "宣城市", "黄山市", "亳州市", "桐城市", "天长市", "明光市", "宁国市", "广德县", "蒙城县", "其他"],
        ["福州市", "厦门市", "莆田市", "三明市", "泉州市", "漳州市", "南平市", "龙岩市", "宁德市", "晋江市", "石狮市", "武夷山", "长乐市", "平潭县", "其他"],
        ["兰州市", "嘉峪关市", "金昌市", "白银市", "天水市", "武威市", "张掖市", "平凉市", "酒泉市", "庆阳市", "定西市", "临夏回族自治州", "甘南藏族自治州", "陇南市", "临夏市", "其他"],
        ["广州市", "韶关市", "深圳市", "珠海市", "汕头市", "佛山市", "江门市", "湛江市", "茂名市", "肇庆市", "惠州市", "梅州市", "汕尾市", "河源市", "阳江市", "清远市", "东莞市", "中山市", "潮州市", "揭阳市", "云浮市", "其他"],
        ["南宁市", "柳州市", "桂林市", "梧州市", "北海市", "防城港市", "钦州市", "贵港市", "玉林市", "百色市", "贺州市", "河池市", "来宾市", "崇左市", "龙胜市", "阳朔市", "其他"],
        ["贵阳市", "六盘水市", "遵义市", "安顺市", "铜仁地区", "黔西南布依族苗族自治州", "毕节地区", "黔南布依族苗族自治州", "黔东南苗族侗族自治州", "其他"],
        ["海口市", "三亚市", "五指山市", "琼海市", "儋州市", "文昌市", "万宁市", "东方市", "澄迈县", "定安县", "屯昌县", "临高县", "保亭县", "乐东县", "琼中县", "白沙县", "昌江县", "陵水县", "其他"],
        ["石家庄市", "唐山市", "秦皇岛市", "邯郸市", "邢台市", "保定市", "张家口市", "承德市", "沧州市", "廊坊市", "衡水市", "涿州市", "其他"],
        ["郑州市", "开封市", "洛阳市", "平顶山市", "安阳市", "鹤壁市", "新乡市", "濮阳市", "焦作市", "许昌市", "漯河市", "三门峡市", "南阳市", "商丘市", "信阳市", "周口市", "驻马店市", "济源市", "其他"],
        ["哈尔滨市", "齐齐哈尔市", "鸡西市", "鹤岗市", "双鸭山市", "大庆市", "伊春市", "佳木斯市", "七台河市", "牡丹江市", "黑河市", "绥化市", "大兴安岭地区", "其他"],
        ["武汉市", "十堰市", "宜昌市", "荆门市", "孝感市", "随州市", "襄阳市", "咸宁市", "荆州市", "黄冈市", "仙桃市", "鄂州市", "恩施市", "其他"],
        ["长沙市", "株洲市", "湘潭市", "衡阳市", "邵阳市", "岳阳市", "益阳市", "郴州市", "永州市", "怀化市", "娄底市", "湘西土家族苗族自治州", "常德市", "张家界市", "凤凰市", "浏阳县", "其他"],
        ["长春市", "吉林市", "四平市", "辽源市", "通化市", "白山市", "白城市", "延边朝鲜族自治州", "松原市", "其他"],
        ["南京市", "无锡市", "徐州市", "常州市", "苏州市", "南通市", "连云港市", "淮安市", "盐城市", "扬州市", "镇江市", "泰州市", "宿迁市", "昆山市", "吴江市", "常熟市", "江阴市", "仪征市", "宜兴市", "张家港市", "太仓市", "其他"],
        ["南昌市", "新余市", "鹰潭市", "赣州市", "吉安市", "宜春市", "抚州市", "上饶市", "景德镇市", "萍乡市", "九江市", "婺源县", "其他"],
        ["沈阳市", "大连市", "鞍山市", "抚顺市", "本溪市", "丹东市", "锦州市", "营口市", "阜新市", "辽阳市", "盘锦市", "铁岭市", "朝阳市", "葫芦岛市", "其他"],
        ["呼和浩特市", "包头市", "乌海市", "赤峰市", "通辽市", "鄂尔多斯市", "呼伦贝尔市", "巴彦淖尔市", "乌兰察布市", "兴安盟", "锡林郭勒盟", "阿拉善盟", "满洲里市", "其他"],
        ["银川市", "石嘴山市", "吴忠市", "固原市", "中卫市", "其他"],
        ["西宁市", "海东地区", "海北藏族自治州", "黄南藏族自治州", "果洛藏族自治州", "玉树藏族自治州", "海西蒙古族藏族自治州", "海南藏族自治州", "其他"],
        ["济南市", "青岛市", "淄博市", "枣庄市", "东营市", "烟台市", "潍坊市", "济宁市", "泰安市", "威海市", "日照市", "莱芜市", "临沂市", "德州市", "聊城市", "滨州市", "菏泽市", "安丘市", "滕州市", "其他"],
        ["太原市", "大同市", "阳泉市", "长治市", "晋城市", "朔州市", "晋中市", "运城市", "忻州市", "临汾市", "吕梁市", "古交市", "清徐县", "其他"],
        ["西安市", "铜川市", "宝鸡市", "咸阳市", "渭南市", "延安市", "汉中市", "榆林市", "安康市", "商洛市", "杨陵区", "其他"],
        ["成都市", "自贡市", "攀枝花市", "泸州市", "德阳市", "绵阳市", "遂宁市", "南充市", "宜宾市", "达州市", "巴中市", "阿坝藏族羌族自治州", "凉山彝族自治州", "广元市", "内江市", "乐山市", "眉山市", "广安市", "雅安市", "资阳市", "甘孜藏族自治州", "九寨沟", "万州区", "永川区", "江津区", "合川区", "绵竹市", "南部县", "阆中市", "江油县", "什邡市", "广汉市", "其他"],
        ["拉萨市", "昌都地区", "山南地区", "日喀则地区", "那曲地区", "阿里地区", "林芝地区", "其他"],
        ["乌鲁木齐市", "克拉玛依市", "吐鲁番地区", "哈密地区", "昌吉回族自治州", "伊犁哈萨克自治州", "塔城地区", "博尔塔拉蒙古自治州", "巴音郭楞蒙古自治州", "阿克苏地区", "克孜勒苏柯尔克孜自治州", "喀什地区", "和田地区", "阿勒泰地区", "石河子市", "阜康市", "奎屯市", "独山子区", "库尔勒市", "乌苏市", "其他"],
        ["昆明市", "曲靖市", "玉溪市", "保山市", "昭通市", "丽江市", "临沧市", "楚雄彝族自治州", "红河哈尼族彝族自治州", "文山壮族苗族自治州", "西双版纳傣族自治州", "大理白族自治州", "怒江傈僳族自治州", "迪庆藏族自治州", "普洱市", "德宏傣族景颇族自治州", "其他"],
        ["杭州市", "宁波市", "温州市", "嘉兴市", "湖州市", "绍兴市", "金华市", "舟山市", "台州市", "丽水市", "衢州市", "慈溪市", "义乌市", "象山城", "乌镇市", "瑞安市", "乐清市", "余姚市", "长兴市", "德清市", "安吉市", "永康市", "兰溪市", "东阳市", "海宁市", "平湖市", "平阳县", "苍南县", "其他"]
    ],
    zh_CN_provinceList: [
        "请选择",
        "北京市",
        "天津市",
        "上海市",
        "重庆市",
        "香港特别行政区",
        "澳门特别行政区",
        "安徽省",
        "福建省",
        "甘肃省",
        "广东省",
        "广西壮族自治区",
        "贵州省",
        "海南省",
        "河北省",
        "河南省",
        "黑龙江省",
        "湖北省",
        "湖南省",
        "吉林省",
        "江苏省",
        "江西省",
        "辽宁省",
        "内蒙古自治区",
        "宁夏回族自治区",
        "青海省",
        "山东省",
        "山西省",
        "陕西省",
        "四川省",
        "西藏自治区",
        "新疆维吾尔自治区",
        "云南省",
        "浙江省"
    ],
    // zh_HK_provinceList: [
    //     "请选择",
    //     "新界",
    //     "离岛",
    //     "九龙",
    //     "香港岛",
    // ],
    // zh_HK_cityList: [
    //     ["请选择"],
    //     ["大围", "沙田", "火炭", "马鞍山", "大埔", "太和", "粉岭", "上水", "罗湖", "落马洲", "葵芳", "葵涌", "葵兴", "荃湾", "青衣", "马湾", "深井", "屯门", "元朗", "天水围", "流浮山", "西贡", "将军澳", "宝琳", "坑口",],
    //     ["愉景湾", "东涌", "赤𫚭角", "大屿山", "大澳", "长洲", "坪洲", "南丫岛", "蒲苔岛",],
    //     ["美孚", "荔枝角", "长沙湾", "深水埗", "太子", "旺角", "大角咀", "油麻地", "佐敦", "尖沙咀", "红磡", "何文田", "土瓜湾", "石硖尾", "九龙塘", "九龙城", "乐富", "黄大仙", "新蒲岗", "钻石山", "慈云山", "彩虹", "九龙湾", "牛头角", "观塘", "蓝田", "油塘", "鲤鱼门",],
    //     ["西环", "石塘咀", "西营盘", "坚尼地城", "上环", "中环", "半山", "山顶", "金钟", "湾仔", "跑马地", "铜锣湾", "大坑", "天后", "北角", "鲗鱼涌", "太古", "西湾河", "筲箕湾", "杏花邨", "柴湾", "薄扶林", "数码港", "香港仔", "鸭脷洲", "深水湾", "黄竹坑", "浅水湾", "石澳", "赤住",],
    // ],
    roleList: [
        { label: "系统管理员", data: Staff.ROLE_ADMIN },
        { label: "审计管理员", data: Staff.ROLE_AUDIT },
        { label: "安全管理员", data: Staff.ROLE_SAFE },
        //{label: "维修人员", data:Staff.ROLE_WORKER},
        { label: "维修站主管", data: Staff.ROLE_STATION_ADMIN },
        { label: "维修部助理", data: Staff.ROLE_ASSISTANT },
        { label: "维修部经理", data: Staff.ROLE_MANAGER },
        { label: "数据查询员", data: Staff.ROLE_VIEW },
        { label: "旧件盘点员", data: Staff.ROLE_OLD_PARTS_CHECK },
        { label: "订单数据导入员", data: Staff.ROLE_ORDER_DATA_IMPORT },
        { label: "经销商", data: Staff.ROLE_AGENCY },
    ],//职务
    warrantyList: ["请选择", "保修", "非保修"],	//检修
    natureList: ["请选择", "售前", "售后", "样机", "折扣机"],
    natureListShort: ["请选择", "售前", "售后", "样机"],
    brandList: ["请选择", "德龙", "凯伍德", "博朗"],
    modelList: [
        "请选择", "自动咖啡机", "咖啡机", "厨师机", "抽湿机", "榨汁机", "吸尘器", "暖风机", "磨豆机", "面包机", "料理机", "空气清新机", "空气净化器", "搅拌机", "加湿器", "多士炉", "电蒸锅", "电炸锅", "电熨斗", "电油汀", "电水壶", "电烤箱", "电烤机", "电风扇", "电饭煲", "冰淇淋机", "冰激凌机", "高速切丝切片器", "谷物研磨器", "果蔬压榨器", "胶囊咖啡机", "绞肉器", "搅拌杯", "搅拌器", "慢速切片/切丝器", "切面机", "食物处理器", "压面器", "研磨机", "榨橙汁器", "制面器", "真空罐", "电子称", "泵压式咖啡机EC系列"
    ],
    fixTypeList: [
        "", "更换配件", "维护保养", "无问题", "调试", "更换整机", "检测", "维修"
    ],
    FixType: [
        { value: AppStatus.TYPE_PART, label: "更换配件" },
        { value: AppStatus.TYPE_MAINTEN, label: "维护保养" },
        { value: AppStatus.TYPE_NONE, label: "无问题" },
        { value: AppStatus.TYPE_DEBUG, label: "调试" },
        { value: AppStatus.TYPE_MACHINE, label: "更换整机" },
        { value: AppStatus.TYPE_DETECT, label: "检测" },
        { value: AppStatus.TYPE_FIX, label: "维修" },
    ],
    monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    dayNames: ['日', '一', '二', '三', '四', '五', '六'],
    overtimeList: [
        { value: 1, label: "预约时间超时", },
        { value: 2, label: "上门服务超时" },
        { value: 3, label: "维修完成超时" },
        { value: 4, label: "维修站回访超时" },
        { value: 5, label: "总部回访超时" }
    ],//超时种类

    ecommercePt: [
        '请选择', '京东', '亚马逊', '我买网', '苏宁易购', '国美在线', '一号店', '淘宝', '电视购物', '其他'
    ],
    ecommerceList: [
        ['请选择'],
        ['上海技嘉咖啡有限公司', '浙江速网电子商务有限公司', '上海埃普咖啡有限公司', '南京易佰购电子科技有限公司', '南京美富隆贸易有限公司', '上海创龙贸易有限公司', '北京融欣伟业科技发展有限公司', '北京和美佳业经贸有限公司'],
        ['北京京东世纪信息技术有限公司', '江苏圆周电子商务有限公司上海分公司', '广州晶东贸易有限公司', '上海圆迈贸易有限公司', '成都京东世纪贸易有限公司', '武汉京东世纪贸易有限公司', '昆山京东尚信贸易有限公司', '广州晶东贸易有限公司', '北京京东信息技术有限公司', '沈阳京东世纪贸易有限公司', '西安华讯得贸易有限公司'],
        ['北京世纪卓越信息技术有限公司', '北京融欣伟业科技发展有限公司', '上海创龙贸易有限公司', '南京易佰购电子科技有限公司'],
        ['中粮海优（北京）有限公司'],
        ['南京亿飞科技有限公司', '南京宜美阁网络科技有限公司', '南京睿嘉融信电子科技有限公司', '南京易柏通商贸有限责任公司', '南京易佰购电子科技有限公司'],
        ['南京易佰购电子科技有限公司', '南京亿飞科技有限公司'],
        ['南京美富隆贸易有限公司', '上海创龙贸易有限公司', '纽海电子商务（上海）有限公司'],
        ['南京美富隆贸易有限公司', '上海创龙贸易有限公司', '上海伊维萨国际贸易有限公司', '上海优骐商贸有限公司', '上海舒阳贸易有限公司', '上海迪慧经贸有限公司', '上海浦美电器有限公司', '上海娄江经贸有限公司', '上海广龙家电有限公司']
    ],
    StationListForSearch: function () {
        let a = [];
        a.push("请选择");
        a = a.concat(this.RegionList);
        return a;
    },
    loginUserStation: function () {
        for (let i = 0; i < this.RegionList.length; i++) {
            const r = this.RegionList[i];
            if (this.loginUser && r.id === this.loginUser.regionId)
                return r;
        }
        return null;
    },
    getRegionById: function (id) {
        let array = this.RegionList || [];
        for (let i = 0; i < array.length; i++) {
            if (array[i].id === id) {
                return array[i];
            }
        }
        return null;
    },
    getStaffById: function (id) {
        let array = this.staffList || [];
        for (let i = 0; i < array.length; i++) {
            if (array[i].empId === id) {
                return array[i];
            }
        }
        return null;
    },
    formatDate: function (d, format) {
        if (d) {
            format = format || "YYYY/MM/DD";
            return Moment(d).format(format);
        }
        return "";
    },
    formatDateTime: function (d, format) {
        if (d) {
            format = format || "YYYY年MM月DD日 HH:mm";
            return Moment(d).format(format);
        }
        return "";
    },
    getDBDate: function (dateText) {
        if (dateText) {
            return dateText.substring(0, dateText.length - 5);
        }
        return dateText;
    },
    getDBDateFormat: function (d, format) {
        if (!d) return d;
        format = format || "YYYY-MM-DD";
        if (d.includes("T") && d.includes("Z")) {
            d = d.substring(0, d.length - 5);
        }
        return Moment(d).format(format);
    },
    getDBDateTimeFormat: function (d, format) {
        if (!d) return d;
        format = format || "YYYY-MM-DD HH:mm:ss";
        if (d.includes("T") && d.includes("Z")) {
            d = d.substring(0, d.length - 5);
        }
        return Moment(d).format(format);
    },
    getModelListIndex: function (productName) {
        let index = 0;
        for (let i = 0; i < this.modelList.length; i++) {
            const element = this.modelList[i];
            if (element === productName || I18n.t(element) === productName) {
                return i;
            }
        }
        return index;
    },
    showFlowerPhoto: function (item) {
        // 显示条件：1、上门售后工单 2、产品名称为：自动咖啡机、泵压式咖啡机EC系列
        const Names = AutoProductNames.concat(ProductNames);
        if (item.type === AppStatus.TYPE_VISIT && item.nature === 2 && Names.includes(item.productName)) {
            return true;
        }
        return false;
    },
    getFlowerFee: function (app) {
        const Names = AutoProductNames.concat(ProductNames);
        if (app && app.type === AppStatus.TYPE_VISIT && app.nature === 2 && Names.includes(app.productName)) {
            if (app.photo8 != null && app.photo8 != "") {
                if (app.flowerFee != null) {
                    return app.flowerFee;
                }
                return 5;
            }
        }
        return null;
    },
    guaranteeOptionsList: [
        { value: 0, label: "请选择" },
        { value: 1, label: "国内发票" },
        { value: 2, label: "订单号查询" },
        { value: 3, label: "手机号查询" },
        { value: 4, label: "保修卡号查询" },
        { value: 5, label: "港澳地区凭证" },
        { value: 6, label: "其他凭证上传（需总部确认）" },
        { value: 7, label: "国内出仓日期保修" },
        { value: 8, label: "海外出仓日期保修" },
        { value: 9, label: "总部凭证确认", },
        { value: 10, label: "售前机" },
        { value: 11, label: "德龙Delonghi京东自营旗舰店" },
        { value: 12, label: "总部保修确认" },
        // { value: 13, label: "折扣机" },
    ],
    guaranteeList: [
        "请选择", "国内发票", "订单号查询", "手机号查询", "保修卡号查询", "港澳地区凭证", "其他凭证上传（需总部确认）", "国内出仓日期保修", "海外出仓日期保修", "总部凭证确认", "售前机", "德龙Delonghi京东自营旗舰店", "总部保修确认",
        // "折扣机",
    ],
    zbOptionList: ["请选择", "国内销售", "海外旗舰店", "海外分销商", "港澳销售"],
    noWarrantOptionList: ["海外水货", "行货过保-过保修期", "行货过保-保内非质量问题", "行货过保-购买配件"],
    IrisCodeList: [],
    rpList: [
        "请选择", "奖励", "处罚"
        // { label: "请选择", value: 0 },
        // { label: "奖励", value: 1 },
        // { label: "处罚", value: 2 },
    ],
    rewardList: [
        { value: 0, label: "请选择" },
        { value: 1, label: "顾客来电表扬" },
        { value: 2, label: "特殊贡献奖" },
        { value: 99, label: "其他" }
    ],
    punishList: [
        { value: 0, label: "请选择", },
        { value: 1, label: "工单延误" },
        { value: 2, label: "服务态度" },
        { value: 3, label: '维修质量' },
        { value: 99, label: '其他' }
    ],
    discardReasons: [
        { value: 0, label: "请选择", },
        { value: 1, label: "重复建单" },
        { value: 2, label: "顾客取消/无时间/无法联系上顾客" },
        { value: 3, label: "顾客自行解决" },
        { value: 4, label: "其他" }
    ],
    discardReasonsList: [
        "请选择", "重复建单", "顾客取消/无时间/无法联系上顾客", "顾客自行解决", "其他"
    ],
    outletFailureList: [
        "请选择", "正确", "缺地线", "缺零线", "缺火线", "火地线错位", "火零线错位", "相地错并缺地"
    ],
    opinionList: [
        "用户电话不接，稍后联系",
        "用户出差，稍后联系",
        "用户出差，等用户来电",
        "等用户来电确认时间",
        "已经电话/视频指导，稍后联系确认",
        "等用户提供有效凭证",
        "用户申请费用中，等用户来电",
        "配件已订购，待配件到货",
        "机器已修复，待客户付款/客户待取/待送回",
        "总部确认",
    ],
    GetBackHowList: [
        // "请选择",
        "客戶自攜",
        "順豐站點",
        "上門收機(保修內)",
        "付費上門收機",
        "800元換機",
        "三個月內返修",
    ],
    workScopeList: [],
    workScopeMap: {
        [AppStatus.STATISTIC_EPIDEMIC]: {},
        [AppStatus.STATISTIC_HOLIDAY]: {},
    },
    WorkScopeOption: [
        { value: 0, label: "请选择" },
        { value: 1, label: "正常营业" },
        { value: 2, label: "部分区域上门" },
        { value: 3, label: "仅接修" },
        { value: 4, label: "仅接机" },
        { value: 5, label: "站点关闭" },
    ],
    WorkScopeHolidayOption: [
        { value: 0, label: "站点休假" },
        { value: 1, label: "正常营业" },
    ],
    WorkBulletinStatus: [
        { value: 0, label: "未统计", },
        { value: 1, label: "统计中" },
        { value: 2, label: "已结束" },
    ],
    EstimationOption: [
        { value: 10, label: "满意" },
        { value: 6, label: "一般" },
        { value: 0, label: "不满意" },
    ],
};

export default ModelManager;
