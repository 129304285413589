import React from "react";
import {
    Form, Input, Row, Col, Checkbox, Button, Image, message
} from "antd";
import { createCode } from "../../utils/utils";
import ModelManager from "../../domain/ModelManager";
import { getRemember, setRemember, getDefaultLang, saveDefaultLang, setLoginTime } from "../../utils/Session";
import config from "../../config";
import MD5 from "crypto-js/md5";
import moment from "moment";
import Net from "../../utils/net";
import "./style.css";
import I18n from "../../language/I18n";

global.isDev = process.env.NODE_ENV === 'development';

export default class Login extends React.Component {
    state = {
        focusItem: -1,//保存当前聚焦的input
        code: "",//验证码
        username: global.isDev ? "admin" : "",
        password: global.isDev ? "Asdf@3.14" : "",
        checkAutoLogin: global.isDev ? true : false,
    };

    componentDidMount() {
        const autoLogin = global.isDev ? config.autoLogin.dev : config.autoLogin.pro;
        let rememberMe = getRemember();
        console.log("Login rememberMe", rememberMe, autoLogin)
        if (rememberMe && rememberMe.autoLogin && rememberMe.user && autoLogin) {
            global.loginUser = rememberMe.user;
            global.loginUser.lang = rememberMe.lang;
            ModelManager.loginUser = rememberMe.user;
            if (ModelManager.loginUser.token) {
                window.token = ModelManager.loginUser.token;
            }
            this.goHome(rememberMe.user);
        }
        let lang = getDefaultLang();
        this.setLang(lang);
        this.setState({ code: createCode(this.canvas) })
    }

    goToPage = () => {
        if (ModelManager.isZBPDY()) {
            this.goCheckout();
        } else if (ModelManager.isDataImport()) {
            // if (global.openNewFunc("20240715")) {
            this.goOrderPage();
            // } else {
            //     this.goSettingPage();
            // }
        } else if (ModelManager.isSafe()) {
            this.goSettingPage();
        } else if (ModelManager.isAudit()) {
            this.goAuditPage();
        } else {
            this.goHome();
        }
    }

    goHome = (userInfo) => {
        this.props.history.push({ pathname: '/sys/home' });
        setTimeout(() => {
            // global.EE.emitEvent("LOAD_USERINFO", [userInfo]);
            global.EE.emitEvent("MENU_CHANGE", [{ key: "/sys/home", keyPath: ["/sys/home"] }]);
        }, 200)
        global.loadBaseInfo && global.loadBaseInfo();
        global.reLoginInterval && global.reLoginInterval();
        global.startGetRoutesData && global.startGetRoutesData();
        global.loadEmergency && global.loadEmergency();
    };

    goCheckout = () => {
        this.props.history.push({ pathname: '/sys/checkout' });
        setTimeout(() => {
            global.EE.emitEvent("MENU_CHANGE", [{ key: "/sys/checkout", keyPath: ["/sys/checkout"] }]);
        }, 200)
        global.loadBaseInfo && global.loadBaseInfo();
        global.reLoginInterval && global.reLoginInterval();
        global.startGetRoutesData && global.startGetRoutesData();
    };

    goOrderPage = () => {
        this.props.history.push({ pathname: '/sys/order' });
        setTimeout(() => {
            global.EE.emitEvent("MENU_CHANGE", [{ key: "/sys/order", keyPath: ["/sys/order"] }]);
        }, 200)
        global.loadBaseInfo && global.loadBaseInfo();
        global.reLoginInterval && global.reLoginInterval();
        global.startGetRoutesData && global.startGetRoutesData();
    };

    goSettingPage = () => {
        this.props.history.push({ pathname: '/sys/setting' });
        setTimeout(() => {
            global.EE.emitEvent("MENU_CHANGE", [{ key: "/sys/setting", keyPath: ["/sys/setting"] }]);
        }, 200)
        global.loadBaseInfo && global.loadBaseInfo();
        global.reLoginInterval && global.reLoginInterval();
        global.startGetRoutesData && global.startGetRoutesData();
    };

    goAuditPage = () => {
        this.props.history.push({ pathname: '/sys/audit' });
        setTimeout(() => {
            global.EE.emitEvent("MENU_CHANGE", [{ key: "/sys/audit", keyPath: ["/sys/audit"] }]);
        }, 200)
        global.loadBaseInfo && global.loadBaseInfo();
        global.reLoginInterval && global.reLoginInterval();
        global.startGetRoutesData && global.startGetRoutesData();
    };

    versionCompare = (v1, v2) => {
        //去掉收尾空格
        v1 = v1.replace(/(^\s+)|(\s+$)/gi, "");
        v2 = v2.replace(/(^\s+)|(\s+$)/gi, "");
        //空检查
        if (!v1 || !v2) {
            return;
        }
        //截取v1,v2中的版本数字
        v1 = /\d(\.|\d)*\d/gi.exec(v1)[0];
        v2 = /\d(\.|\d)*\d/gi.exec(v2)[0];
        //版本比较，我们分为三个数组然后比较
        let arr1 = v1.split('.');
        arr1 = arr1.map(function (item) {
            // console.log(parseInt(item));
            return parseInt(item);
        });
        let arr2 = v2.split('.');
        arr2 = arr2.map(function (item) {
            // console.log(parseInt(item));
            return parseInt(item);
        });
        if (arr1[0] > arr2[0]) {
            return true;
        } else if (arr1[0] === arr2[0]) {
            if (arr1[1] > arr2[1]) {
                return true;
            } else if (arr1[1] === arr2[1]) {
                if (arr1[2] > arr2[2]) {
                    return true;
                } else if (arr1[2] === arr2[2]) {
                    return false;
                }
            }
        }
        return false;
    };
    loginSubmit = (info) => {
        console.log("loginSubmit info", info)
        let password = MD5(info.password).toString();
        Net.post(global.ServerUrl + 'staff/login', { loginName: info.username, password: password }, res => {
            console.log("loginSubmit", res)
            if (res.result === 0) {
                if (res.versionInfo) {
                    let minVersion = res.versionInfo.minVersion;
                    console.log("minVersion", minVersion, global.version)
                    let versionUp = this.versionCompare(minVersion, global.version);
                    console.log("versionUp", versionUp)
                    if (versionUp) {
                        return global.showRefreshModal();
                    }
                }
                if (res.region && res.region.isWarehouseScan) {
                    return message.warn(I18n.t("没有访问权限"));
                }
                let userInfo = res.ret;
                if (info.autoLogin) {
                    let data = {
                        autoLogin: info.autoLogin,
                        autoLoginTime: new Date().getTime(),
                        user: userInfo,
                        lang: global.lang
                    };
                    setRemember(data);
                }
                global.lastLoginTime = moment().format("YYYY-MM-DD");
                setLoginTime(global.lastLoginTime);
                global.loginUser = userInfo;
                window.token = userInfo.token;
                ModelManager.loginUser = userInfo;
                this.goToPage();
            } else {
                if (res.msg) {
                    return message.error(res.msg);
                }
                message.error(I18n.t("用户名或密码错误，登录失败！"));
            }
        }, error => {
            message.error(I18n.t("无法连接服务器"));
        });
    };

    onFinish = (values) => {
        if (values.loginName === "" || values.password === "") {
            return message.warn(I18n.t("请输入用户名/密码"));
        }
        if (values.code === "") {
            return message.warn(I18n.t("请输入验证码"));
        }
        if (values.code.length < 4) {
            return message.warn(I18n.t("验证码错误"));
        }
        this.loginSubmit(values);
    };

    onFinishFailed = (errorInfo) => {
    };

    setLang = (lang) => {
        console.log('login Select Language:', lang);
        if (!lang) {
            return;
        }
        global.lang = lang;
        console.log('login setLang Language:', lang);
        saveDefaultLang(lang);
        I18n.setLanguage(lang);
        this.setState({ lang }, () => {
            if (global.setLang) global.setLang();
        });
    };

    onFieldsChange = (changedFields, allFields) => {
        if (changedFields.length > 0) {
            let { name, value } = changedFields[0];
            // console.log("name", name, value);
            this.state[name[0]] = value;
            if (global.isDev && name[0] === "username") {
                let value2;
                if (value === "ceshi") {
                    value2 = "Asdf@3.14";
                } else if (value === "jjcoffeedddr") {
                    value2 = "Asdf@3.14";
                } else if (value === "dlgfzkd") {
                    value2 = "pass";
                } else if (value === "qinfeng") {
                    value2 = "Asdf@3.14";
                } else if (value === "admin") {
                    value2 = "Asdf@3.14";
                }
                if (value2) {
                    this.state.password = value2;
                    this.formEdit.setFieldsValue({ password: value2 });
                }
            }
            this.forceUpdate();
        }
    }

    render() {
        const autoLogin = global.isDev ? config.autoLogin.dev : config.autoLogin.pro;
        const { code, lang } = this.state;
        return (
            <div style={{
                position: 'fixed',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                zIndex: 99,
            }} className="wrap">
                <div style={{
                    margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center', height: autoLogin ? 440 : 400,
                    marginLeft: "calc(78vw - 200px)", marginTop: "calc(50vh - 220px)",
                }}>
                    {/* <Image width="190px" height="440px" src={require("../../assets/images/delong.png").default} style={{ display: "flex", marginLeft: 10 }} /> */}
                    <div style={{
                        backgroundColor: '#FFF', display: 'flex',
                        position: 'relative',
                        flexDirection: 'column', justifyContent: 'center',
                        width: 400, height: autoLogin ? 440 : 400,
                    }} >
                        <Button type={lang === "zh_CN" ? "primary" : "default"} onClick={() => this.setLang('zh_CN')}
                            style={{ position: 'absolute', right: 10, top: 10 }}>
                            {"简体"}
                        </Button>
                        <Button type={lang === "zh_HANT" ? "primary" : "default"} onClick={() => this.setLang('zh_HANT')}
                            style={{ position: 'absolute', right: 90, top: 10 }}>
                            {"繁体"}
                        </Button>
                        <Button type={lang === "en_US" ? "primary" : "default"} onClick={() => this.setLang('en_US')}
                            style={{ position: 'absolute', right: 170, top: 10 }}>
                            {"English"}
                        </Button>
                        <Form autoComplete="off"
                            style={{ padding: 40 }}
                            initialValues={global.isDev ? {
                                autoLogin: this.state.checkAutoLogin,
                                username: this.state.username,
                                password: this.state.password,
                            } : {}}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            ref={inst => this.formEdit = inst}
                            onFieldsChange={this.onFieldsChange}
                        >
                            <Form.Item style={{ marginBottom: 20 }}
                                name="username"
                                rules={[{ required: true, message: I18n.t("请输入用户名") }]}
                            >
                                <Input
                                    // value={this.state.username}
                                    onFocus={() => this.setState({ focusItem: 0 })}
                                    onBlur={() => this.setState({ focusItem: -1 })}
                                    placeholder={I18n.t("用户名")}
                                    onChange={e => this.setState({ username: e.target.value })}
                                    addonBefore={<Image width="17px" height="17px" src={require("../../assets/images/icon_user.png").default} style={{ display: "flex", marginTop: 3 }} />}
                                />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 20 }}
                                name="password"
                                rules={[{ required: true, message: I18n.t("请输入密码") }]}
                            >
                                <Input.Password
                                    // value={this.state.password}
                                    ref={inst => this.pwdInput = inst}
                                    readOnly
                                    onFocus={() => {
                                        this.pwdInput && this.pwdInput.input.removeAttribute('readOnly');
                                        this.setState({ focusItem: 1 });
                                    }}
                                    onBlur={() => {
                                        this.pwdInput && this.pwdInput.input.setAttribute('readOnly', true)
                                        this.setState({ focusItem: -1 });
                                    }}
                                    autoComplete="new-password"
                                    placeholder={I18n.t("密码")}
                                    onChange={e => this.setState({ password: e.target.value })}
                                    addonBefore={<Image width="17px" height="17px" src={require("../../assets/images/icon_password.png").default} style={{ display: "flex", marginTop: 3 }} />}
                                />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 15, height: 40 }}
                                name="code"
                                rules={[
                                    { required: true, message: I18n.t("请输入验证码") },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value.length <= 4 && code.toUpperCase() !== value.toUpperCase()) {
                                                return Promise.reject(I18n.t('验证码错误'));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Row>
                                    <Col span={15}>
                                        <Input
                                            onFocus={() => this.setState({ focusItem: 2 })}
                                            onBlur={() => this.setState({ focusItem: -1 })}
                                            maxLength={4}
                                            placeholder={I18n.t("验证码")}
                                            addonBefore={<Image width="17px" height="17px" src={require("../../assets/images/icon_certificate.png").default} style={{ display: "flex", marginTop: 3 }} />}
                                        />
                                    </Col>
                                    <Col span={9}>
                                        <canvas
                                            onClick={e => this.setState({ code: createCode(e.target) })}
                                            width="80" height="32"
                                            style={{ marginLeft: 10, backgroundColor: 'rgba(0,0,0,0.4)' }}
                                            ref={el => this.canvas = el}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                            {autoLogin &&
                                <Row>
                                    <Col span={24}>
                                        <Form.Item style={{ marginBottom: 15 }} name="autoLogin" valuePropName="checked">
                                            <Checkbox style={{ color: "#424242" }}>{I18n.t("自动登录")}</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                            <Button htmlType="submit"
                                style={{
                                    width: '100%', height: 35,
                                    border: 'none', borderRadius: 5,
                                    color: '#FFF', backgroundColor: '#096DD9',
                                }}
                            >{I18n.t("登录")}</Button>
                        </Form>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Image width="353px" height="44px" src={require("../../assets/images/logo.png").default} />
                        </div>
                        <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 5, fontSize: 13 }}>{I18n.t("网站备案/许可证号：沪ICP备20004800号-1")}</div>
                        <div style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 13 }}>{I18n.t("沪公网安备31010702006927号")}</div>
                    </div>
                </div>
                <div style={{ position: "fixed", top: 5, right: 5, color: "#FFF", fontSize: 13 }}>{global.transLabel("版本")}{global.version}</div>
            </div>
        )
    }
}

