import React from "react";
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { ConfigProvider, notification, Button } from 'antd';

import "./App.css";
import "./config/GlobalData"; // 全局变量配置文件

import moment from "moment";
import I18n from "./language/I18n";
import ModelManager from "./domain/ModelManager";
import AppStatus from "./domain/AppStatus";
import { getRemember, getDefaultLang, saveDefaultLang, getLoginTime, clearRemember } from "./utils/Session";
import PrivateRoute from "./components/PrivateRoute/privateRoute";
import Routes from "./routes";
import config from "./config";
import Net from "./utils/net";
import Login from "./pages/login";
import ViewImage from "./pages/view";

global.openNotification = (info) => {
	const key = `${info.type}${Date.now()}`;
	const btn = (
		<Button onClick={() => notification.close(key)} type="primary" size="small">
			{info.btnString || I18n.t("关闭")}
		</Button>
	);
	notification[info.type]({
		message: info.message || "",
		description: info.description || "",
		duration: 0,
		key,
		btn,
		onClick: () => {
			// console.log('Notification Clicked!');
		},
	});
};

document.onkeydown = function (event) {
	// return;
	var e = event || window.event || arguments.callee.caller.arguments[0];
	if (e && e.keyCode == 27) { // 按 Esc 
		//要做的事情
	}
	if (e && e.keyCode == 113) { // 按 F2 
		//要做的事情
	}
	if (e && e.keyCode == 13) { // enter 键
		//要做的事情
	}
	if (e && e.keyCode == 8) { // backSpace键
		stopBack(e)
	}
};

// 禁止点击backspace 返回上一页
function stopBack(event) {
	//获取事件对象
	var elem = event.relatedTarget || event.srcElement || event.target || event.currentTarget;

	//获取按键按下时光标做指向的element
	var elem = event.srcElement || event.currentTarget;

	//判断是否需要阻止按下键盘的事件默认传递
	var name = elem.nodeName;
	if (name != 'INPUT' && name != 'TEXTAREA') {
		return _stopIt(event);
	}
	var type_e = elem.type.toUpperCase();
	// console.log("stopBack", name, type_e)
	if (name == 'INPUT' && (type_e != 'TEXT' && type_e != 'SEARCH' && type_e != 'TEXTAREA' && type_e != 'PASSWORD' && type_e != 'FILE')) {
		return _stopIt(event);
	}
	if (name == 'INPUT' && (elem.readOnly == true || elem.disabled == true)) {
		return _stopIt(event);
	}
}

function _stopIt(e) {
	if (e.returnValue) {
		e.returnValue = false;
	}
	if (e.preventDefault) {
		e.preventDefault();
	}

	return false;
}

export default class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			locale: global.zhCN,
		};

		global.loadBaseInfo = this.loadData.bind(this);
		global.reloadBaseInfo = this.reloadBaseInfo.bind(this);
		global.reloadInTime = this.reloadInTime.bind(this);
		global.loadAllAccessory = this.loadAllAccessory.bind(this);
		global.loadProducts = this.loadProducts.bind(this);
		global.loadIris = this.loadIris.bind(this);
		global.loadAllWorkScope = this.loadAllWorkScope.bind(this);
		global.clearAppInterval = this.clearAppInterval.bind(this);
		global.reLoginInterval = this.reLoginInterval.bind(this);
		global.loadRegions = this.loadRegions.bind(this);
		global.loadEmergency = this.loadEmergency.bind(this);
		global.loadComplainUnHandle = this.loadComplainUnHandle.bind(this);
		global.goToLogin = this.goToLogin.bind(this);
		global.setLang = this.setLang.bind(this);
	};
	UNSAFE_componentWillMount() {
	}
	componentDidMount() {
		// this.loadOrgs();
		console.log("window.location.href", window.location.href, global.isDev)
		this.setLang();
		this.reloadInterval && clearInterval(this.reloadInterval);
		this.reloadInterval = setInterval(() => {
			this.reloadInTime();
		}, 60 * 60 * 1000)
		this.onCheckLogin();
	}
	componentWillUnmount() {
		this.reloadInterval && clearInterval(this.reloadInterval);
	}
	onCheckLogin = () => {
		const autoLogin = global.isDev ? config.autoLogin.dev : config.autoLogin.pro;
		let rememberMe = getRemember();
		global.lastLoginTime = getLoginTime();
		console.log("App rememberMe", rememberMe, autoLogin)
		if (rememberMe && rememberMe.autoLogin && rememberMe.user && autoLogin) {
			let isReLogin = this.reLogin();
			console.log("reLogin isReLogin", isReLogin)
			console.log("reLogin rememberMe.user", rememberMe.user)
			if (!isReLogin) {
				global.loginUser = rememberMe.user;
				ModelManager.loginUser = rememberMe.user;
				if (ModelManager.loginUser.token) {
					window.token = ModelManager.loginUser.token;
				}
				global.EE.emitEvent("LOAD_USERINFO", [global.loginUser]);
				this.loadData();
				this.reLoginInterval();
				global.startGetRoutesData && global.startGetRoutesData();
			}
			this.loadEmergency();
			this.renewToken();
		} else {
			console.log("goToLogin111")
			this.goToLogin();
		}
	}

	reLoginInterval = () => {
		this.logoutTimer && clearInterval(this.logoutTimer);
		this.logoutTimer = setInterval(() => {
			this.reLogin();
		}, 1000 * 60)
	};
	reLogin = () => {
		let today = moment().format("YYYY-MM-DD");
		if (!global.lastLoginTime || global.lastLoginTime !== today) {
			this.goToLogin();
			return true;
		}
		return false;
	};

	goToLogin = () => {
		console.log("goToLogin window.token", window.token)
		console.log("goToLogin window.location.href", window.location.href, window.location.href.includes("view?id="))
		if (window.location.href.indexOf("view?id=") !== -1) {
			return;
		}
		clearRemember();
		if (global.lang) {
			saveDefaultLang(global.lang);
		}
		let url = global.delParam("token");
		url = url.substring(0, url.indexOf("#"));
		if (url.indexOf('login') < 0) {
			window.location.href = url + '#/login';
		}
		global.clearAppInterval && global.clearAppInterval();
		global.clearRoutesInterval && global.clearRoutesInterval();
	};

	setLang = () => {
		let lang = getDefaultLang();
		console.log('setLang Language:', lang);
		let { locale } = this.state;
		if (lang === "zh_HANT") {
			locale = global.zhHK;
			global.DateLocal = global.zhHK;
		} else if (lang === "en_US") {
			locale = global.enUS;
			global.DateLocal = global.enUS;
		} else {
			locale = global.zhCN;
			global.DateLocal = global.zhCN;
		}
		global.lang = lang;
		I18n.setLanguage(lang);
		this.setState({ locale, lang });
	};

	reloadInTime = () => {
		this.loadStaffs();
		this.reloadBaseInfo();
		this.loadEmergency();
	};

	reloadBaseInfo = () => {
		this.loadRegions();
		this.loadProducts();
		this.loadAllAccessory();
		this.loadIris();
	};

	loadData = () => {
		this.loadOrgs();
		this.loadRegions();
		this.loadStaffs();
		this.loadProducts();
		this.loadAllAccessory();
		this.getMailSetting();
		this.loadIris();
		// if (!global.isHK) {
		this.loadAllWorkScope();
		// }
	};

	clearAppInterval = () => {
		this.logoutTimer && clearInterval(this.logoutTimer);
	};

	loadAllWorkScope = () => {
		let url = global.ServerUrl + "workScope/list";
		Net.post(url, {}, (res) => {
			if (res.result === 0) {
				ModelManager.workScopeList = res.ret;
				ModelManager.workScopeMap = {
					[AppStatus.STATISTIC_EPIDEMIC]: {},
					[AppStatus.STATISTIC_HOLIDAY]: {},
				}
				if (res.ret.length > 0) {
					res.ret.forEach(item => {
						if (item.type === AppStatus.STATISTIC_EPIDEMIC) {
							ModelManager.workScopeMap[item.type][item.regionId] = item;
						} else {
							ModelManager.workScopeMap[item.type][item.regionId] = ModelManager.workScopeMap[item.type][item.regionId] || [];
							ModelManager.workScopeMap[item.type][item.regionId].push(item);
						}
					});
					// console.log("workScopeMap STATISTIC_EPIDEMIC", ModelManager.workScopeMap[AppStatus.STATISTIC_EPIDEMIC])
					// console.log("workScopeMap STATISTIC_HOLIDAY", ModelManager.workScopeMap[AppStatus.STATISTIC_HOLIDAY])
				}
			}
		}, error => {
			console.log("loadAllWorkScope", error)
		})
	};

	getStaff = () => {
		let url = global.ServerUrl + "staff/getStaffByLoginName";
		Net.post(url, { loginName: "njlwk" }, (res) => {
			if (res.result === 0) {
				for (let i = 0; i < res.ret.length; i++) {
					const element = res.ret[i];
					if (element.regionId === null) {
						this.deleteStaff(element)
					}
				}
			}
			console.log("getStaff", res);
		}, error => {
			console.log("getStaff", error);
		})
	}

	renewToken = () => {
		if (global.isDev) return;
		let url = global.ServerUrl + "staff/renewToken";
		console.log("renewToken global.loginUser", global.loginUser);
		if (!global.loginUser || !global.loginUser.token) {
			return this.goToLogin();
		}
		Net.post(url, { token: global.loginUser.token }, (res) => {
			console.log("renewToken res", res);
			if (res.result === 0) {
				const element = res.ret;
			} else {
				this.goToLogin();
			}
		}, error => {
			console.log("renewToken", error);
		})
	}

	deleteStaff = (staff) => {
		let url = global.ServerUrl + "staff/delete";
		Net.post(url, { empId: staff.empId }, (res) => {
			if (res.result === 0) {

			}
			console.log("deleteStaff", res);
		}, error => {
			console.log("deleteStaff", error);
		})
	}

	getMailSetting = () => {
		let url = global.ServerUrl + "mailSetting";
		Net.get(url, (res) => {
			// console.log("getMailSetting", res);
			if (res.result === 0) {
				ModelManager.mailSetting = res.ret;
			}
		}, error => {
			console.log("getMailSetting", error);
		})
	};

	loadEmergency = () => {
		let url = global.ServerUrl + "fixApp/emergency";
		Net.post(url, { staff: ModelManager.loginUser }, (res) => {
			console.log("loadEmergency", res);
			if (res.result === 0) {
				global.emergencyList = res.ret;
				global.EE.emitEvent("HAS_EMERGENCY", [true]);
			} else {
				global.emergencyList = [];
				global.EE.emitEvent("HAS_EMERGENCY", [false]);
			}
		}, error => {
			console.log("loadEmergency", error);
			global.emergencyList = [];
			global.EE.emitEvent("HAS_EMERGENCY", [false]);
		})
	};

	loadComplainUnHandle = () => {
		let url = global.ServerUrl + "fixApp/complainUnHandle";
		Net.post(url, { staff: ModelManager.loginUser }, (res) => {
			console.log("loadComplainUnHandle", res);
			if (res.result === 0) {
				global.complainList = res.ret;
				global.EE.emitEvent("HAS_COMPLAIN", [true]);
			} else {
				global.complainList = [];
				global.EE.emitEvent("HAS_COMPLAIN", [false]);
			}
		}, error => {
			console.log("loadComplainUnHandle", error);
			global.complainList = [];
			global.EE.emitEvent("HAS_COMPLAIN", [false]);
		})
	};

	loadOrgs = () => {
		global.org = {};
		let url = global.ServerUrl + "org/list";
		Net.post(url, null, (res) => {
			console.log("loadOrgs", res);
			if (res.result === 0) {
				ModelManager.OrgList = res.ret;
				if (res.ret && res.ret.length > 0) {
					global.org = res.ret[0];
				}
			}
		}, error => {
			console.log("loadOrgs", error);
		})
	};

	loadRegions = (callback) => {
		let url = global.ServerUrl + "region/list";
		Net.post(url, null, (res) => {
			// console.log("loadRegions", res)
			if (res.result === 0) {
				res.ret.sort(function (a, b) {
					return a.name.localeCompare(b.name, 'zh-CN');
				})
				if (callback) {
					return callback(res.ret);
				}
				ModelManager.RegionList = res.ret;
				global.setRegions && global.setRegions(res.ret);
			}
		}, error => {
			console.log("loadRegions", error)
		})
	};

	loadStaffs = () => {
		let url = global.ServerUrl + "staff/list";
		Net.post(url, null, (res) => {
			console.log("loadStaffs res", res)
			if (res.result === 0) {
				ModelManager.staffList = res.ret;
			}
		}, error => {
			console.log("loadStaffs", error)
		})
	};

	loadProducts = () => {
		let url = global.ServerUrl + "product/list";
		Net.post(url, null, (res) => {
			console.log("loadProducts", res)
			if (res.result === 0) {
				ModelManager.productList = res.ret;
			}
		}, error => {
			console.log("loadProducts", error);
		})
	};

	loadAllAccessory = () => {
		let url = global.ServerUrl + "accessory/list";
		Net.post(url, null, (res) => {
			if (res.result === 0) {
				ModelManager.accessoryList = res.ret;
			}
		}, error => {
			console.log("loadAllAccessory", error);
		})
	};

	loadIris = () => {
		let url = global.ServerUrl + "iris/list";
		let query = {
			offset: 0,
			limit: 1000,
		};
		Net.post(url, query, (res) => {
			console.log("loadIris", res)
			if (res.result === 0) {
				ModelManager.IrisCodeList = res.ret;
			}
		}, error => {
		})
	};

	render() {
		const { locale } = this.state;
		return (
			<ConfigProvider locale={locale}>
				<Router>
					<Switch>
						<Route path="/login" component={Login} />
						<Route path="/view" component={ViewImage} />
						{/* <Route path="/home" component={Home} /> */}
						<PrivateRoute path="/sys" component={Routes} />
						<Redirect exact from="/" to="/login" />
					</Switch>
				</Router>
			</ConfigProvider>
		)
	}
}


