import React from 'react';
import { Image } from 'antd';
import I18n from "../../language/I18n";
import ModelManager from '../../domain/ModelManager';

function UrlDecode(zipStr) {
    var uzipStr = "";
    for (var i = 0; i < zipStr.length; i++) {
        var chr = zipStr.charAt(i);
        if (chr == "+") {
            uzipStr += " ";
        } else if (chr == "%") {
            var asc = zipStr.substring(i + 1, i + 3);
            if (parseInt("0x" + asc) > 0x7f) {
                uzipStr += decodeURI("%" + asc.toString() + zipStr.substring(i + 3, i + 9).toString());
                i += 8;
            } else {
                uzipStr += AsciiToString(parseInt("0x" + asc));
                i += 2;
            }
        } else {
            uzipStr += chr;
        }
    }

    return uzipStr;
}

// function StringToAscii(str) {
//     return str.charCodeAt(0).toString(16);
// }
function AsciiToString(asccode) {
    return String.fromCharCode(asccode);
}

export default class ViewImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };
    componentDidMount() {
        console.log("props.location", this.props.location)
        let url = this.getQueryString("id");
        let name = this.getQueryString("name");
        let uploadTime = this.getQueryString("uploadTime");
        if (uploadTime) {
            if (uploadTime.includes("Z")) {
                uploadTime = ModelManager.formatDate(global.getDate(uploadTime), "YYYY-MM-DD HH:mm:ss");
            } else {
                uploadTime = ModelManager.formatDate(uploadTime, "YYYY-MM-DD HH:mm:ss");
            }
        }
        console.log("url", url)
        console.log("uploadTime", uploadTime)
        this.setState({ url, name: UrlDecode(name), uploadTime });
    };
    getQueryString(name) {
        let qString = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for (let i = 0; i < qString.length; i++) {
            let urlParam = qString[i].split('=');
            if (urlParam[0] === name) {
                return urlParam[1];
            }
        }
    }
    render() {
        let { url, name, uploadTime } = this.state;
        return (
            <div>
                <div style={{
                    width: "100vw", backgroundColor: "#000", color: "#FFF", textAlign: "center", fontSize: 20, height: 40, lineHeight: "40px",
                }}>
                    {global.transLabel("拍照人")}{name} {uploadTime} ({I18n.t("点击查看大图")})
                </div>
                <Image src={global.ServerUrl + "photo/" + url} style={{ maxHeight: "calc(100vh - 40px)", maxWidth: "100vw" }} />
            </div>
        )
    }
}

