import React from 'react';
import {
    Button, Table, message, Modal,
} from 'antd';

import ModelManager from "../../../domain/ModelManager";
import AppStatus from "../../../domain/AppStatus";
import Split from "../../../components/Split";
import Net from "../../../utils/net";
import I18n from "../../../language/I18n";

import FixAppEdit from "./FixAppEdit";

const dateFormat = I18n.t("YYYY年MM月DD日 HH:mm");

export default class EmergencyFixApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            sort: [["createDate", 'DESC']],
            loading: false,
            visible: false,
            action: "",
        };
    };

    componentDidMount() {
        this.loadEmergency();
    }

    componentWillUnmount() {
    }
    onClose = () => {
        this.props.onClose && this.props.onClose();
    };
    loadEmergency = () => {
        this.setState({ loading: true });
        let url = global.ServerUrl + "fixApp/emergency";
        Net.post(url, { staff: ModelManager.loginUser }, (res) => {
            console.log("loadEmergency", res);
            if (res.result === 0) {
                this.setState({ dataSource: res.ret });
            } else {
                this.setState({ dataSource: [] });
            }
            this.setState({ loading: false });
        }, error => {
            console.log("loadEmergency", error);
            this.setState({ dataSource: [], loading: false });
        })
    };
    getFixApp = (appId, callback) => {
        this.state.spining = true;
        this.setState({ spining: true });
        let body = document.querySelector("body")
        if (body) {
            body.style.cursor = "wait";
        }
        let url = global.ServerUrl + "fixApp/get";
        Net.post(url, { id: appId, version: global.version, }, (res) => {
            console.log("getFixApp", res)
            if (res.result === 0) {
                callback(res.ret);
            } else {
                message.error(I18n.t("维修单获取失败"));
            }
            this.state.spining = false;
            this.setState({ spining: false });
            if (body) {
                body.style.cursor = "auto";
            }
        }, error => {
            this.state.spining = false;
            this.setState({ spining: false });
            if (body) {
                body.style.cursor = "auto";
            }
            console.log("getFixApp", error);
            message.error(I18n.t("维修单获取失败"));
        });
    };

    onRowClick = (record) => {
        this.state.doc = record;
        this.setState({ doc: record })
    };

    onEdit = (record) => {
        console.log("onEdit", this.state.spining)
        if (this.state.spining) {
            return message.warn(I18n.t("正在获取数据，请稍候..."));
        }
        let now = new Date().getTime();
        this.lastEditTimeStamp = this.lastEditTimeStamp || 0;
        if (now - this.lastEditTimeStamp < 1000) {
            return message.warn(I18n.t("操作太快"));
        }
        if (record == null && this.state.doc == null) {
            return message.error(I18n.t("请选择需要修改的维修单数据"));
        }
        if (record == null && this.state.doc) {
            record = this.state.doc;
        }
        this.lastEditTimeStamp = now;
        global.reloadBaseInfo();
        this.getFixApp(record.id, (fixApp) => {
            if (fixApp.partsList) {
                console.log("onEdit partsList", fixApp.appNo, fixApp.partsList,)
            }
            this.setState({ visible: true, action: "edit", doc: fixApp });
        });
    };
    handleCancel = (refresh) => {
        this.setState({ visible: false, });
    };
    onTableChange = (pagination, filters, sorter, extra) => {
        //: { currentDataSource: [], action: paginate | sort | filter }
        console.log("onTableChange", pagination, filters, sorter, extra)
        if (sorter.field) {
            this.state.sort = [];
            this.state.sort.push([sorter.field, sorter.order == "descend" ? "DESC" : "ASC"])
            console.log("onTableChange sort", this.state.sort)
            if (sorter.order) {
                if (sorter.order == "descend") { // 降序
                    this.state.dataSource.sort((a, b) => {
                        if (typeof (a[sorter.field]) == 'string') {
                            return b[sorter.field].localeCompare(a[sorter.field])
                        }
                        return b[sorter.field] - a[sorter.field];
                    })
                } else { // 升序
                    this.state.dataSource.sort((a, b) => {
                        if (typeof (a[sorter.field]) == 'string') {
                            return a[sorter.field].localeCompare(b[sorter.field])
                        }
                        return a[sorter.field] - b[sorter.field];
                    })
                }
            }
        } else {

        }
    };

    onGetReturnStatus = (item) => {
        let temp = "";
        if (item.feedbackStatus === 2)
            return "完成回访";
        if (item.feedbackStatus === 1)
            return "待继续回访";
        return temp;
    };

    onGetStatus = (item) => {
        if (item.status !== 99 && item.applyDiscard) {
            return "申请作废";
        }
        return AppStatus.GetStatusLabel(item.status, item.type);
    };
    initColumns = () => {
        let columns = [
            {
                title: I18n.t("序号"), dataIndex: 'index', key: 'index', width: 80,
                render: (text, record, index) => {
                    return (
                        <span>{index + 1}</span>
                    )
                }
            },
            {
                title: I18n.t("单号"), dataIndex: 'appNo', key: 'appNo', width: 200,
                sorter: (a, b) => {
                    return a.appNo - b.appNo;
                },
            },
            {
                title: I18n.t("维修站"), dataIndex: 'stationNo', key: 'stationNo', width: 130,
                render: (text) => {
                    let region
                    if (text) {
                        region = ModelManager.getRegionById(text);
                    }
                    return <span>{region && region.name}</span>
                },
                sorter: (a, b) => {
                    return a.stationNo - b.stationNo;
                },
            },
            {
                title: I18n.t("创建日期"), dataIndex: 'createDate', key: 'createDate', width: 180,
                render: (text) => {
                    return ModelManager.formatDate(global.getDate(text), dateFormat)
                },
                sorter: (a, b) => {
                    return a.createDate - b.createDate;
                },
            },
            {
                title: I18n.t("创建人"), dataIndex: 'empId', key: 'empId', width: 90,
                render: (text) => {
                    let staff
                    if (text) {
                        staff = ModelManager.getStaffById(text);
                    }
                    return (
                        <span>{staff && staff.empName}</span>
                    )
                },
                sorter: (a, b) => {
                    return a.empId - b.empId;
                },
            },
            {
                title: I18n.t("状态"), dataIndex: 'status', key: 'status', width: 90,
                render: (text, record) => {
                    return (
                        <span>{I18n.t(this.onGetStatus(record))}</span>
                    )
                },
                sorter: (a, b) => {
                    return a.status - b.status;
                },
            },
            {
                title: I18n.t("保修依据"), dataIndex: 'guarantee', key: 'guarantee', width: 150,
                render: (text) => {
                    return (
                        <span>{text ? I18n.t(ModelManager.guaranteeList[text]) : null}</span>
                    )
                },
            },
            {
                title: I18n.t("最新操作"), dataIndex: 'lastProcess', key: 'lastProcess', width: 400,
            },
            {
                title: I18n.t("服务类型"), dataIndex: 'type', key: 'type', width: 130,
                render: (text) => {
                    return I18n.t(ModelManager.typeList[text].label);
                },
                sorter: (a, b) => {
                    return a.type - b.type;
                },
            },
            {
                title: I18n.t("结账单"), dataIndex: 'checkoutAppNo', key: 'checkoutAppNo', width: 180,
                sorter: (a, b) => {
                    return a.checkoutAppNo - b.checkoutAppNo;
                },
            },
            {
                title: I18n.t("品牌"), dataIndex: 'brand', key: 'brand', width: 100,
                render: (text) => {
                    return text ? I18n.t(ModelManager.brandList[text]) : "";
                },
                sorter: (a, b) => {
                    return a.brand - b.brand;
                },
            },
            {
                title: I18n.t("机器型号"), dataIndex: 'productType', key: 'productType', width: 150,
                sorter: (a, b) => {
                    return a.productType - b.productType;
                },
            },
            {
                title: I18n.t("派单时间"), dataIndex: 'assignedDate', key: 'assignedDate', width: 180,
                render: (text) => {
                    return ModelManager.formatDate(global.getDate(text), dateFormat)
                },
                sorter: (a, b) => {
                    return a.assignedDate - b.assignedDate;
                },
            },
            {
                title: I18n.t("上门日期"), dataIndex: 'appointment', key: 'appointment', width: 180,
                render: (text) => {
                    return text && ModelManager.formatDate(global.getDate(text), dateFormat)
                },
                sorter: (a, b) => {
                    return a.appointment - b.appointment;
                },
            },
            {
                title: I18n.t("完成日期"), dataIndex: 'fixDate', key: 'fixDate', width: 180,
                render: (text) => {
                    return ModelManager.formatDate(global.getDate(text), dateFormat)
                },
                sorter: (a, b) => {
                    return a.fixDate - b.fixDate;
                },
            },
            {
                title: I18n.t("是否保修"), dataIndex: 'warranty', key: 'warranty', width: 100,
                render: (text) => {
                    return I18n.t(ModelManager.warrantyList[text]);
                },
                sorter: (a, b) => {
                    return a.warranty - b.warranty;
                },
            },
            {
                title: I18n.t("维修性质"), dataIndex: 'nature', key: 'nature', width: 100,
                render: (text) => {
                    return I18n.t(ModelManager.natureList[text]);
                },
                sorter: (a, b) => {
                    return a.nature - b.nature;
                },
            },
            {
                title: I18n.t("维修费"), dataIndex: 'fixFee', key: 'fixFee', width: 100,
                sorter: (a, b) => {
                    return a.fixFee - b.fixFee;
                },
            },
            {
                title: I18n.t("上门费用"), dataIndex: 'visitFee', key: 'visitFee', width: 100,
                sorter: (a, b) => {
                    return a.visitFee - b.visitFee;
                },
            },
            {
                title: I18n.t("机器编号"), dataIndex: 'productCode', key: 'productCode', width: 200,
                sorter: (a, b) => {
                    return a.productCode - b.productCode;
                },
            },
            {
                title: I18n.t("通知日期"), dataIndex: 'noticeDate', key: 'noticeDate', width: 180,
                render: (text) => {
                    return ModelManager.formatDate(global.getDate(text), dateFormat)
                },
                sorter: (a, b) => {
                    return a.noticeDate - b.noticeDate;
                },
            },
            {
                title: I18n.t("取货日期"), dataIndex: 'pickupDate', key: 'pickupDate', width: 180,
                render: (text) => {
                    return ModelManager.formatDate(global.getDate(text), dateFormat)
                },
                sorter: (a, b) => {
                    return a.pickupDate - b.pickupDate;
                },
            },
            {
                title: I18n.t("故障描述"), dataIndex: 'description', key: 'description',
            },
            {
                title: I18n.t("SAP系统单号"), dataIndex: 'sapAppNo', key: 'sapAppNo', width: 150,
            },
        ];
        // if (global.isHK) {
        //     columns.splice(9, 0, {
        //         title: I18n.t("收機方法"), dataIndex: 'getBackHow', key: 'getBackHow', width: 150,
        //     });
        // }
        return columns;
    };
    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 105px)" }}>
                <Table size="small"
                    id="green-table"
                    style={{ height: "calc(100vh - 150px)" }}
                    bordered={true}
                    // scroll={{ x: 4000, y: "calc(100vh - 263px)" }}
                    scroll={{ x: 4300, y: "calc(100vh - 303px)" }}
                    dataSource={this.state.dataSource}
                    columns={this.initColumns()}
                    pagination={false}
                    loading={this.state.loading}
                    rowKey={record => record.id}
                    rowClassName={(record, index) => {
                        if (this.state.doc && record.id === this.state.doc.id) {
                            return "tableRowHighDark";
                        }
                        if (index % 2 === 0) {
                            return "tableRowDark";
                        }
                    }}
                    onRow={record => {
                        return {
                            onClick: () => this.onRowClick(record), // 点击行
                            onDoubleClick: () => this.onEdit(record),
                        };
                    }}
                    onChange={this.onTableChange}
                />
                <Split />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: 42, padding: 5, paddingRight: 10 }}>
                    <Button onClick={() => this.loadEmergency()} disabled={this.state.loading} type="primary" style={Styles.btnStyle}>{I18n.t("刷新")}</Button>
                    <Button onClick={() => this.onClose()}>关闭</Button>
                </div>
                <Modal className="FullScreenModel"
                    title="Modal 1000px width"
                    destroyOnClose
                    visible={this.state.visible}
                    width={"calc(100vw)"}
                    style={{
                        width: "calc(100vw)", maxWidth: "calc(100vw)",
                        height: "calc(100vh)", maxHeight: "calc(100vh)",
                        top: 0, padding: 0,
                    }}
                    bodyStyle={{ overflowY: "auto" }}
                    closable={false}
                    footer={null}
                >
                    <FixAppEdit
                        ref={comp => this.FixAppEdit = comp}
                        doc={this.state.doc}
                        action={this.state.action}
                        goBack={this.handleCancel}
                    />
                </Modal>
            </div>
        )
    }
}

const Styles = {
    btnStyle: { marginRight: 5, fontSize: 13, height: 30 },
    filterItemStyle: { display: "flex", marginBottom: 5, alignItems: "center", },
    filterItem: { flex: 1, marginLeft: 10 },
}

