import Net from './net';

let DataStore = function (modelName) {
	this.modelName = modelName;
	this.BaseUrl = DataStore.BaseUrl + modelName;
	/*
    新增数据到数据库
    */
	this.add = function () {
		let url = this.BaseUrl;
		let _this = this;
		return new Promise(function (resolve, reject) {
			Net.post(url, _this, function (res) {
				if (res.result === 0) {
					_this.id = res.ret;
					resolve(res.ret);
				} else {
					reject(Error(res.ret));
				}
			}, function (error) {
				reject(Error(error));
			})
		});
	};
	/*
    保存数据到数据库
    */
	this.save = function (updateObj) {
		let url = this.BaseUrl;
		updateObj = updateObj || this;
		return new Promise(function (resolve, reject) {
			Net.put(url, updateObj, function (res) {
				if (res.result === 0)
					resolve(res.ret);
				else
					reject(Error(res.ret));
			}, function (error) {
				reject(Error(error));
			})
		});
	};
	/*
    更新一个属性，并保存数据到数据库
    */
	this.updateAttr = function (attr, val) {
		this[attr] = val;
		let updateObj = {id: this.id};
		updateObj[attr] = val;
		return this.save(updateObj);
	};
	/*
    批量更新一个属性
    */
	this.batchUpdate = function (query, attr, val) {
		let _this = this;
		let url = this.BaseUrl + "/update";
		let updateObj = {
			filter: query,
			attr: attr,
			val: val
		};
		return new Promise(function (resolve, reject) {
			Net.post(url, updateObj, function (res) {
				let list = [];
				list = _this.copyFromArr(res.ret);
				resolve(list);
			}, function (err) {
				reject(err);
				// reject(Error(err));
			})
		});
	};
	this.queryAndUpdate = function (query, set, ids) {
		let url = this.BaseUrl + "/queryAndUpdate";
		let updateObj = {
			filter: query,
			set: set,
		};
		if(ids && ids.length > 0) {
			updateObj.ids = ids;
		}
		return new Promise(function (resolve, reject) {
			Net.post(url, updateObj, function (res) {
				resolve(res);
			}, function (err) {
				reject(Error(err));
			})
		});
	};
	/*
    从数据库删除一个对象
    */
	this.delete = function () {
		let url = this.BaseUrl + "/" + (this.id || this._id);
		return new Promise(function (resolve, reject) {
			Net.delete(url, function (ret) {
				resolve(ret);
			}, function (error) {
				reject(Error(error));
			})
		});
	};
	/*
    从数据库删除符合条件的对象
    */
	this.queryAndDelete = function (query, ids) {
		let url = this.BaseUrl + "/delete";
		let deleteObj = {
			filter: query,
		};
		if(ids && ids.length > 0) {
			deleteObj.ids = ids;
		}
		return new Promise(function (resolve, reject) {
			Net.post(url, deleteObj,function (ret) {
				resolve(ret);
			}, function (error) {
				reject(Error(error));
			})
		});
	};
	/*
    从数据库加载一个对象
    */
	this.loadById = function (id) {
		let url = this.BaseUrl + "/" + id;
		let _this = this;
		return new Promise(function (resolve, reject) {
			Net.get(url, function (result) {
				if (result.result !== 0) {
					reject('NOTFOUND');
					return;
				}
				let xObj = new DataStore(_this.modelName);
				xObj.copyFromObj(result.ret);
				xObj.id = result.ret._id;
				resolve(xObj);
			}, function (err) {
				reject(err);
			})
		});
	};
	this.query = function (filter, sort) {
		let url = this.BaseUrl + "/query";
		let _this = this;
		let query = {filter: filter, sort: sort};
		return new Promise(function (resolve, reject) {
			Net.post(url, query, function (res) {
				let list = _this.copyFromArr(res.ret);
				resolve(list);
			}, function (err) {
				reject(err);
			})
		});
	};
	this.queryProj = function (filter, proj, sort) {
		let url = this.BaseUrl + "/queryProj";
		let that = this;
		let query = { filter: filter, proj: proj, sort: sort };
		return new Promise(function (resolve, reject) {
			Net.post(url, query, function (res) {
				let list = [];
				if (res.ret) {
					list = that.copyFromArr(res.ret);
				}
				resolve(list);
			}, function (err) {
				if (err)
					reject(Error(err));
			})
		});
	};
	this.queryGeneral = function (ids, filter, sort) {
		let url = this.BaseUrl + "/queryGeneral";
		let _this = this;
		let query = {ids: ids, filter: filter, sort: sort};
		return new Promise(function (resolve, reject) {
			Net.post(url, query, function (res) {
				let list = _this.copyFromArr(res.ret);
				resolve(list);
			}, function (err) {
				reject(err);
			})
		});
	};
	this.queryProj = function (filter, projection, sort) {
		let url = this.BaseUrl + "/queryProj";
		let that = this;
		let query = { filter: filter, proj: projection, sort: sort };
		return new Promise(function (resolve, reject) {
			Net.post(url, query, function (res) {
				let list = [];
				if (res.ret) {
					list = that.copyFromArr(res.ret);
				}
				resolve(list);
			}, function (err) {
				if (err)
					reject(Error(err));
			})
		});
	};
	this.queryByIds = function (ids) {
		let url = this.BaseUrl + "/queryByIds";
		let _this = this;
		let query = {ids: ids};
		return new Promise(function (resolve, reject) {
			Net.post(url, query, function (res) {
				let list = _this.copyFromArr(res.ret);
				resolve(list);
			}, function (err) {
				reject(err);
			})
		});
	};
	this.count = function (query) {
		let url = this.BaseUrl + "/count";
		return new Promise(function (resolve, reject) {
			Net.post(url, query, function (res) {
				resolve(res.ret);
			}, function (err) {
				reject(Error(err));
			})
		});
	};
	this.queryPage = function (filter, sort, skip, limit) {
		let url = this.BaseUrl + "/queryPage";
		let _this = this;
		let query = {filter: filter, sort: sort};
		query.skip = skip;
		query.limit = limit;
		return new Promise(function (resolve, reject) {
			Net.post(url, query, function (res) {
				// let list = _this.copyFromArr(res.ret);
				// res.ret = list;
				res.ret = _this.copyFromArr(res.ret);
				resolve(res);
			}, function (err) {
				reject(Error(err));
			})
		});
	};
	this.queryPageGeneral = function (ids, filter, sort, skip, limit) {
		let url = this.BaseUrl + "/queryPageGeneral";
		let _this = this;
		let query = {ids: ids, filter: filter, sort: sort};
		query.skip = skip;
		query.limit = limit;
		return new Promise(function (resolve, reject) {
			Net.post(url, query, function (res) {
				res.ret = _this.copyFromArr(res.ret);
				resolve(res);
			}, function (err) {
				reject(Error(err));
			})
		});
	};
	//filter 查询条件 projection:数据中需要返回的参数 sort:排序 skip:跳过前n条记录 limit 返回n条
	this.queryPageProj = function (filter, projection, sort, skip, limit) {
		let url = this.BaseUrl + "/queryPageProj";
		let that = this;
		let query = {
			filter: filter,
			proj: projection,
			sort: sort,
			skip: skip,
			limit: limit,
		};
		return new Promise(function (resolve, reject) {
			Net.post(url, query, function (res) {
				res.ret = that.copyFromArr(res.ret);
				resolve(res);
			}, function (err) {
				reject(Error(err));
			})
		});
	};
	//加载全部数据 一般用于数据字典类的数据
	this.loadAll = function (sort) {
		let url = this.BaseUrl + "/query";
		let _this = this;
		return new Promise(function (resolve, reject) {
			Net.post(url, {sort: sort}, function (res) {
				let list = [];
				list = _this.copyFromArr(res.ret);
				resolve(list);
			}, function (err) {
				// reject(Error(err));
				reject(err);
			})
		});
	};
	this.copyFromObj = function (plainObj) {
		for (let key in plainObj) {
			this[key] = plainObj[key];
		}
		return this;
	};
	this.clone = function () {
		let cloned = {};
		for (let key in this) {
			cloned[key] = this[key];
		}
		return cloned;
	};
	this.set = function (plainObj) {
		for (let key in plainObj) {
			this[key] = plainObj[key];
		}
		return this;
	};
	this.copyFromArr = function (arr, modelName) {
		let newArr = [];
		let name = modelName || this.modelName;
		arr.forEach(function (plainObj) {
			let xObj = new DataStore(name);
			newArr.push(xObj.copyFromObj(plainObj));
			xObj.id = plainObj._id;
		});
		return newArr;
	};
	return this;
};
DataStore.newObj = function (modelName, plainObj) {
	let xObj = new DataStore(modelName);
	xObj.copyFromObj(plainObj);
	return xObj;
};
DataStore.loadAll = function (modelName, sort) {
	let url = this.BaseUrl + modelName + "/query";
	return new Promise(function (resolve, reject) {
		Net.post(url, {sort: sort}, function (res) {
			let list = [];
			let xObj = new DataStore(modelName);
			list = xObj.copyFromArr(res.ret, modelName);
			resolve(list);
		}, function (err) {
			// reject(Error(err));
			reject(err);
		})
	});
};
/*
上传文件到后台
*/
DataStore.upload = function (file, data) {
	let url = this.BaseUrl + 'media/upload';
	let formData = new FormData();
	formData.append('file', file, file.name);
	if (data) {
		for (let key in data) {
			formData.append(key, data[key]);
		}
	}
	return new Promise(function (resolve, reject) {
		Net.upload(url, formData, function (res) {
			if (res.result === 0) {
				resolve(res.ret);
			} else {
				reject(Error(res.ret));
			}
		}, function (error) {
			reject(Error(error));
		}, file.name)
	});
};
DataStore.getImgPath = function (uuid) {
	// return 'http://edustore.xidigo.com/'+uuid;
	return this.BaseUrl + 'media/' + uuid;
};
DataStore.getServerImgPath = function (uuid) {
	return this.BaseUrl + 'media/' + uuid;
};
DataStore.getMediumImgPath = function (uuid) {
	return 'http://edustore.xidigo.com/' + uuid + '/0/h/128';
	// return this.BaseUrl + 'media/' + uuid;
};
/*
从Cache加载一个对象
*/
DataStore.loadFromCache = function (id) {
	let url = this.BaseUrl + "cache/" + id;
	return new Promise(function (resolve, reject) {
		Net.get(url, function (result) {
			if (result.result !== 0) {
				reject(Error());
				return;
			}
			resolve(result.ret);
		}, function (err) {
			reject(Error(err));
		})
	});
};

DataStore.fetchItunesInfo = function (id) {
	let url = this.BaseUrl + "app/getFormItunes/" + id;
	return new Promise(function (resolve, reject) {
		Net.get(url, function (res) {
			if (res.result === 0) {
				resolve(res.ret);
			} else {
				reject(Error(res.ret));
			}
		}, function (error) {
			reject(Error(error));
		})
	});
};

DataStore.getAppRank = function (doc) {
	let url = this.BaseUrl + "app/getAppRank";
	return new Promise(function (resolve, reject) {
		Net.post(url, doc, function (res) {
			if (res.result === 0) {
				resolve(res.ret);
			} else {
				reject(Error(res.ret));
			}
		}, function (error) {
			reject(Error(error));
		})
	});
};

export default DataStore;
