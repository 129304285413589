import EventEmitter from 'wolfy87-eventemitter';
import config from "./index";
import DataStore from "../utils/db";
import ModelManager from "../domain/ModelManager";
// import zhCN from 'antd/es/date-picker/locale/zh_CN';
import zhCN from 'antd/es/locale/zh_CN';
import zhHK from 'antd/es/locale/zh_HK';
import enUS from 'antd/es/locale/en_US';
import I18n from '../language/I18n';
import { Modal, message } from 'antd';

global.zhCN = zhCN;
global.zhHK = zhHK;
global.enUS = enUS;

global.EE = new EventEmitter();
global.DateLocal = zhCN;

global.isDev = process.env.NODE_ENV === 'development';
const BaseUrl = global.isDev ? config.baseUrl.dev : config.baseUrl.pro;
const BaseImageUrl = global.isDev ? config.baseImageUrl.dev : config.baseImageUrl.pro;
const TemplateUrl = global.isDev ? config.templateUrl.dev : config.templateUrl.pro;
DataStore.BaseUrl = BaseUrl;
global.ServerUrl = BaseUrl;
global.BaseImageUrl = BaseImageUrl;
global.TemplateUrl = TemplateUrl;
console.log("global.ServerUrl", global.ServerUrl)

global.provinceList = ModelManager.zh_CN_provinceList;
global.cityList = ModelManager.zh_CN_cityList;
global.version = config.version || "1.0.0";
global.locale = config.locale || "zh_CN";
global.isHK = false;
global.rowLightColor = config.defaultTheme.rowLightColor;
global.rowDarkColor = config.defaultTheme.rowDarkColor;
global.rowHighDarkColor = config.defaultTheme.rowHighDarkColor;
global.editBackColor = config.defaultTheme.editBackColor;
global.MainContentHeight = config.MainContentHeight;
global.actionName = {
    add: "新增",
    edit: "编辑",
    view: "查看",
    delete: "删除",
};
global.transLabel = (label) => {
    return `${I18n.t(label)}${I18n.t("：")}`;
}
global.isEN = () => {
    return global.lang === "en_US";
}
// 开放疫情模块
global.openEpidemicModule = () => {
    // if (global.isDev) return true;
    return false;
}
// 检查密码复杂度
global.checkPwdComplex = (newPwd) => {
    if (!newPwd || newPwd.length < 8) {
        message.error("密码长度不小于8位！");
        return false;
    }
    let regex = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}');
    if (!regex.test(newPwd)) {
        message.error("您的密码复杂度太低（密码中必须包含大小字母、数字、特殊字符）！");
        return false;
    }
    return true;
}
global.delParam = (paramKey) => {
    var url = window.location.href;    //页面url
    var urlParam = window.location.search.substr(1);   //页面参数
    // console.log("url=" + url);
    var beforeUrl = url; //页面主地址（参数之前地址）
    if (url.indexOf("?") > 0)
        beforeUrl = url.substr(0, url.indexOf("?"));   //页面主地址（参数之前地址）
    // console.log("beforeUrl=" + beforeUrl);
    var nextUrl = "";

    var arr = new Array();
    if (urlParam != "") {
        var urlParamArr = urlParam.split("&"); //将参数按照&符分成数组
        for (var i = 0; i < urlParamArr.length; i++) {
            var paramArr = urlParamArr[i].split("="); //将参数键，值拆开
            //如果键雨要删除的不一致，则加入到参数中
            if (paramArr[0] != paramKey) {
                arr.push(urlParamArr[i]);
            }
        }
    }
    if (arr.length > 0) {
        nextUrl = "?" + arr.join("&");
    }
    url = beforeUrl + nextUrl;
    return url;
}
global.reloadPage = () => {
    window.location.reload();
}
global.showRefreshModal = () => {
    Modal.error({
        title: I18n.t("WEB页面更新"),
        content: (
            <div>{I18n.t("WEB页面已更新，当前WEB端版本不是最低要求版本，请刷新页面")}</div>
        ),
        okText: I18n.t("刷新页面"),
        onOk: () => global.reloadPage(),
    });
}
global.defaultNotifyInfo = { type: "info", message: "", description: "" };
global.judgeMessageType = (original) => {
    let { code, message } = original;
    switch (code) {
        case "ETIMEOUT":
            return { type: "error", message: "请求超时", description: "服务器请求超时，请重试" }
        case "EREQUEST":
            return { type: "error", message: "请求失败", description: "请检查数据格式是否正确" }
        case "ERROR":
            return { type: "error", message: "请求失败", description: message }
        default:
            return { type: "info", message: code, description: message };
    }
}
global.getFileType = (suffix) => {
    let type = "";
    if (suffix) {
        suffix = suffix.toLowerCase();
    }
    if (["mp3", "wav"].includes(suffix)) {
        type = "audio";
    } else if (["mp4", "mov", "avi", "ogv", "ogg", "webm"].includes(suffix)) {
        type = "video";
    } else if (["jpg", "jpeg", "png"].includes(suffix)) {
        type = "picture";
    } else if (suffix === "txt") {
        type = "document";
    } else if (suffix === "pdf") {
        type = "pdf";
    } else if (["ppt", "pptx"].includes(suffix)) {
        type = "ppt";
    } else if (["xls", "xlsx"].includes(suffix)) {
        type = "xlsx";
    } else if (["doc", "docx"].includes(suffix)) {
        type = "doc";
    } else if (["zip", "rar"].includes(suffix)) {
        type = "zip";
    }
    return type;
};
global.getFileFormat = (format) => {
    let type = 'OTHER';
    if (["mp3", "wav"].includes(format)) {
        type = "AUDIO";
    } else if (["mp4", "mov", "ogv", "ogg", "webm"].includes(format)) {
        type = "VIDEO";
    } else if (["jpg", "jpeg", "png"].includes(format)) {
        type = "PICTURE";
    } else if (format === "txt") {
        type = "DOCUMENT";
    } else if (format === "pdf") {
        type = "PDF";
    } else if (["ppt", "pptx"].includes(format)) {
        type = "PPT";
    } else if (["xls", "xlsx"].includes(format)) {
        type = "XLS";
    } else if (["doc", "docx"].includes(format)) {
        type = "DOC";
    } else if (["zip", "rar"].includes(format)) {
        type = "ZIP";
    } else if (format === "usdz") {
        type = "AR";
    }
    return type;
};
// 只能输入英文字母或数字
global.checkNumAndLetter = (value) => {
    let Regx = /^[A-Za-z0-9]*$/;
    if (Regx.test(value)) {
        return true;
    }
    else {
        return false;
    }
};
// 只能输入数字
global.isNum = (value) => {
    let Regx = /^[0-9]*$/;
    if (Regx.test(value)) {
        return true;
    }
    return false;
};
// 手机号格式
global.isPhone = (value) => {
    let reg_phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (reg_phone.test(value)) {

        return true;
    }
    return false;
};

global.checkOtherFee = (value) => {
    let Regx = /^[0-9.\-]*$/;
    if (Regx.test(value)) {
        return true;
    }
    else {
        return false;
    }
};

global.downloadURI = function (uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
window.downloadURI = global.downloadURI;

global.getDate = (dateText) => {
    if (dateText) {
        return dateText.substring(0, dateText.length - 5);
    }
    return dateText;
};

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
// 例子： 
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423 
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18 
Date.prototype.Format = function (fmt) { //author: meizz 
    var o = {
        "M+": this.getMonth() + 1, //月份 
        "d+": this.getDate(), //日 
        "h+": this.getHours(), //小时 
        "m+": this.getMinutes(), //分 
        "s+": this.getSeconds(), //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}


function accDiv(arg1, arg2) {
    let t1 = 0, t2 = 0, t3 = 0, r1, r2;
    try {
        t1 = arg1.toString().split(".")[1].length
    } catch (e) {
    }
    try {
        t2 = arg2.toString().split(".")[1].length
    } catch (e) {
    }
    r1 = Number(arg1.toString().replace(".", ""));
    r2 = Number(arg2.toString().replace(".", ""));
    if (r2 === 0)
        return 0;
    let result = String(r1 / r2);
    try {
        t3 = result.toString().split(".")[1].length
    } catch (e) {
    }
    let index = t2 - t1 - t3;
    if (index < 0) {
        result = result.replace(".", "");
        while (result.length <= Math.abs(index)) {
            result = '0' + result;
        }
        let start = result.substring(0, result.length + index);
        let end = result.substring(result.length + index, result.length);
        result = start + '.' + end;
        return Number(result);
    } else if (index > 0) {
        result = result.replace(".", "");
        while (result.length <= Math.abs(index)) {
            result += '0';
        }
        return Number(result);
    } else return Number(result.replace(".", ""));
}

//给Number类型增加一个div方法，调用起来更加方便。
//除法
Number.prototype.div = function (arg) {
    return accDiv(this, arg);
};

function accMul(arg1, arg2) {
    let m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length
    } catch (e) {
    }
    try {
        m += s2.split(".")[1].length
    } catch (e) {
    }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)

}

// 乘法
Number.prototype.mul = function (arg) {
    return accMul(arg, this);
};

function accAdd(arg1, arg2) {
    let r1, r2, m, c;
    try {
        r1 = arg1.toString().split(".")[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = arg2.toString().split(".")[1].length
    } catch (e) {
        r2 = 0
    }
    c = Math.abs(r1 - r2);
    m = Math.pow(10, Math.max(r1, r2));
    if (c > 0) {
        let cm = Math.pow(10, c);
        if (r1 > r2) {
            arg1 = Number(arg1.toString().replace(".", ""));
            arg2 = Number(arg2.toString().replace(".", "")) * cm;
        } else {
            arg1 = Number(arg1.toString().replace(".", "")) * cm;
            arg2 = Number(arg2.toString().replace(".", ""));
        }
    } else {
        arg1 = Number(arg1.toString().replace(".", ""));
        arg2 = Number(arg2.toString().replace(".", ""));
    }
    return (arg1 + arg2) / m

}

// 加法
Number.prototype.add = function (arg) {
    return accAdd(arg, this);
};

//减法函数，用来得到精确的减法结果     
// 说明：javascript的减法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的减法结果。     
// 调用：accSub(arg1,arg2)     
// 返回值：arg1减去arg2的精确结果         
function accSub(arg1, arg2) {
    let r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = arg2.toString().split(".")[1].length
    } catch (e) {
        r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2));
    //last modify by deeka      
    // 动态控制精度长度      
    n = (r1 >= r2) ? r1 : r2;
    return Number(((arg2 * m - arg1 * m) / m).toFixed(n));
}

//给Number类型增加一个add方法，调用起来更加方便。     
Number.prototype.sub = function (arg) {
    return accSub(arg, this);
};