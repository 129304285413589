import en from './en'
// import zh from './zh'
import zh_HANT from './zh_HANT'

var locale;

function setLanguage(lan) {
    locale = lan;
}

function getTranslate(string, params) {
    let result = string;
    // 替换对应参数为value的值  
    let regExp = new RegExp(); //替换资源中参数的正则  
    for (var k in params) {
        regExp = eval("/{{:" + k + "}}/g");
        result = result.replace(regExp, params[k]);
    }
    return result;
}

function t(name, params = {}) {
    // if (window.location.host === 'afs.idelonghi.cn')
    //     locale = "zh";
    if (locale === "zh" || locale === "zh_CN") {
        return getTranslate(name, params);
        // return name;
    } else if (locale === "zh_HANT") {
        if (zh_HANT[name]) {
            return getTranslate(zh_HANT[name], params);
            // return zh_HANT[name];
        }
    } else if (locale === "en_US") {
        if (en[name]) {
            return getTranslate(en[name], params);
            // return en[name];
        }
    }
    if (zh_HANT[name]) {
        return getTranslate(zh_HANT[name], params);
        return zh_HANT[name];
    }
    if (en[name]) {
        return getTranslate(en[name], params);
        return en[name];
    }
    if (getTranslate(name, params)) {
        return getTranslate(name, params);
    }
    return name;
}

export default { setLanguage, t };