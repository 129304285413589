import React from "react";

export default class Split extends React.Component {
    render() {
        const Style = this.props.style || {};
        return (
            <div style={{ ...Style, display: "flex", flexDirection: 'column', height: 3, justifyContent: "center" }}>
                <div style={{ display: "flex", borderTop: '0.5px solid #888', borderBottom: '1px solid #FFF', height: 0, justifyContent: "flex-end", }} />
            </div>
        )
    }
}