const ERR_TOKEN_EXPIRED = -12;
let net = {
    load: function (url, callback, errCallback) {
        fetch(url, {
            method: "GET",
            headers: {
                'Accept': 'application/json; charset=utf-8',
                'Content-Type': 'application/json; charset=utf-8',
                'x-access-token': window.token,
                'lang': global.lang,
                'platform': "WEB",
            }
        }).then(function (response) {
            return response.json()
        }).then(function (ret) {
            if (ret.ret === ERR_TOKEN_EXPIRED) {
                global.goToLogin();
                return;
            }
            let list = ret;
            if (ret.list) {
                list = ret.list;
            }
            list.forEach(function (item) {
                if (item.objectId) {
                    item.id = item.objectId;
                }
            });
            callback(ret);
        }).catch(function (error) {
            if (errCallback) {
                errCallback(error);
            }
            console.log(error);
        }).done();
    },
    loadZip: function (url, callback, errCallback) {
        fetch(url, {
            method: "GET",
            headers: {
                'Accept-Encoding': 'gzip',
                'Content-Type': 'application/json; charset=utf-8',
                'x-access-token': window.token,
                'lang': global.lang,
                'platform': "WEB",
            }
        }).then(function (response) {
            return response.json()
        }).then(function (ret) {
            if (ret.ret === ERR_TOKEN_EXPIRED) {
                global.goToLogin();
                return;
            }
            let list = ret;
            if (ret.list) {
                list = ret.list;
            }
            list.forEach(function (item) {
                if (item.objectId)
                    item.id = item.objectId;
            });
            callback(ret);
        }).catch(function (error) {
            if (errCallback) {
                errCallback(error);
            }
            console.log(error);
        }).done();
    },
    loadOne: function (url, callback, errCallback) {
        fetch(url, {
            method: "GET",
            headers: {
                'Accept': 'application/json; charset=utf-8',
                'Content-Type': 'application/json; charset=utf-8',
                'x-access-token': window.token,
                'lang': global.lang,
                'platform': "WEB",
            }
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            if (data.ret === ERR_TOKEN_EXPIRED) {
                global.goToLogin();
                return;
            }
            callback(data);
        }).catch(function (error) {
            console.log(error);
            if (errCallback) {
                errCallback(error);
            }
        }).done();
    },
    get: function (url, callback, errorCallback) {
        fetch(url, {
            headers: {
                'x-access-token': window.token,
                'lang': global.lang,
                'platform': "WEB",
            }
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            if (data.ret === ERR_TOKEN_EXPIRED) {
                global.goToLogin();
                return;
            }
            callback(data);
        }).catch(function (error) {
            console.log(error);
            if (errorCallback) {
                errorCallback(error);
            }
        });
    },
    delete: function (url, callback, errorCallback) {
        fetch(url, {
            method: "DELETE",
            headers: {
                'x-access-token': window.token,
                'lang': global.lang,
                'platform': "WEB",
            }
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            if (data.ret === ERR_TOKEN_EXPIRED) {
                global.goToLogin();
                return;
            }
            callback(data);
        }).catch(function (error) {
            if (errorCallback) {
                errorCallback(error);
            }
        });
    },
    put: function (url, obj, callback, errCallback) {
        // let headers = new Headers();
        // if(global.loginUser)
        //   headers.append("X-API-TOKEN", global.loginUser.token);
        fetch(url, {
            method: "PUT",
            headers: {
                'x-access-token': window.token,
                'lang': global.lang,
                'platform': "WEB",
            },
            body: JSON.stringify(obj)
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            if (data.ret === ERR_TOKEN_EXPIRED) {
                global.goToLogin();
                return;
            }
            callback(data);
        }).catch(function (error) {
            console.log(error);
            if (errCallback) {
                errCallback(error);
            }
        });
    },
    post: function (url, data, callback, errCallback) {
        fetch(url, {
            method: "POST",
            headers: {
                'x-access-token': window.token,
                'lang': global.lang,
                'platform': "WEB",
            },
            body: JSON.stringify(data)
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            if (data.ret === ERR_TOKEN_EXPIRED) {
                global.goToLogin();
                return;
            }
            callback(data);
        }).catch(function (error) {
            console.log(error);
            if (errCallback) {
                errCallback(error);
            }
        });
    },

    upload: function (url, formData, callback, errCallback, name) {
        // let n = name;
        // if (formData.get) {
        //     n = formData.get('name');
        // }
        fetch(url, {
            method: "POST",
            headers: {
                // "Raw-Name":n,
                'x-access-token': window.token,
                'lang': global.lang,
                'platform': "WEB",
            },
            body: formData
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            callback(data);
        }).catch(function (error) {
            console.log(error);
            if (errCallback) {
                errCallback(error);
            }
        });
    },
    // exportFile:function(url,query,file) {
    //     var xhttp = new XMLHttpRequest();
    //     xhttp.onreadystatechange = function() {
    //         var a;
    //         if (xhttp.readyState === 4 && xhttp.status === 200) {
    //             // Trick for making downloadable link
    //             a = document.createElement('a');
    //             a.href = window.URL.createObjectURL(xhttp.response);
    //             // Give filename you wish to download
    //             a.download = file;
    //             a.style.display = 'none';
    //             document.body.appendChild(a);
    //             a.click();
    //         }
    //     };
    //     // Post data to URL which handles post request
    //     xhttp.open("POST", url);
    //     xhttp.setRequestHeader("Content-Type", "application/json");
    //     // You should set responseType as blob for binary responses
    //     xhttp.responseType = 'blob';
    //     xhttp.send(JSON.stringify(query));
    // },
    exportFile: function (url, query, file) {
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            var a;
            if (xhttp.readyState === 4 && xhttp.status === 200) {
                // Trick for making downloadable link
                a = document.createElement('a');
                a.href = window.URL.createObjectURL(xhttp.response);
                // Give filename you wish to download
                a.download = file;
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
            }
        };
        // Post data to URL which handles post request
        xhttp.open("GET", url);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.setRequestHeader("x-access-token", window.token);
        xhttp.setRequestHeader("platform", "WEB");
        xhttp.setRequestHeader("lang", global.lang);
        // You should set responseType as blob for binary responses
        xhttp.responseType = 'blob';
        xhttp.send();
    },
    downloadExcel: function (url, file) {
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            var a;
            if (xhttp.readyState === 4 && xhttp.status === 200) {
                // Trick for making downloadable link
                a = document.createElement("a");
                a.href = window.URL.createObjectURL(xhttp.response);
                // Give filename you wish to download
                a.download = file;
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
            }
        };
        // Post data to URL which handles post request
        xhttp.open("GET", url);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.setRequestHeader("x-access-token", window.token);
        xhttp.setRequestHeader("platform", "WEB");
        xhttp.setRequestHeader("lang", global.lang);
        // You should set responseType as blob for binary responses
        xhttp.responseType = "blob";
        xhttp.send();
    },
    downloadExcelByFilter: function (url, query, file) {
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            var a;
            if (xhttp.readyState === 4 && xhttp.status === 200) {
                // Trick for making downloadable link
                a = document.createElement("a");
                a.href = window.URL.createObjectURL(xhttp.response);
                // Give filename you wish to download
                a.download = file;
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
            }
        };
        // Post data to URL which handles post request
        xhttp.open("POST", url);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.setRequestHeader("x-access-token", window.token);
        xhttp.setRequestHeader("platform", "WEB");
        xhttp.setRequestHeader("lang", global.lang);
        // You should set responseType as blob for binary responses
        xhttp.responseType = "blob";
        xhttp.send(JSON.stringify(query));
    },
    downloadExcelByStandard: function (url, file) {
        console.log('downloadExcelByStandard', url);
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            var a;
            if (xhttp.readyState === 4 && xhttp.status === 200) {
                // Trick for making downloadable link
                a = document.createElement("a");
                a.href = window.URL.createObjectURL(xhttp.response);
                // Give filename you wish to download
                a.download = file;
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
            }
        };
        // Post data to URL which handles post request
        xhttp.open("GET", url);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.setRequestHeader("x-access-token", window.token);
        xhttp.setRequestHeader("platform", "WEB");
        xhttp.setRequestHeader("lang", global.lang);
        // You should set responseType as blob for binary responses
        xhttp.responseType = "blob";
        xhttp.send(JSON.stringify({}));
    },
    downloadText: function (url, file) {
        console.log('downloadText', url);
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            var a;
            if (xhttp.readyState === 4 && xhttp.status === 200) {
                // Trick for making downloadable link
                a = document.createElement("a");
                a.href = window.URL.createObjectURL(xhttp.response);
                // Give filename you wish to download
                a.download = file;
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
            }
        };
        // Post data to URL which handles post request
        xhttp.open("GET", url);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.setRequestHeader("x-access-token", window.token);
        xhttp.setRequestHeader("platform", "WEB");
        xhttp.setRequestHeader("lang", global.lang);
        // You should set responseType as blob for binary responses
        xhttp.responseType = "blob";
        xhttp.send();
    },
    postDownload: function (url, data, file, errCallback) {
        // let headers = new Headers();
        // if(global.loginUser)
        //   headers.append("X-API-TOKEN", global.loginUser.token);
        fetch(url, {
            method: "POST",
            headers: {
                'x-access-token': window.token,
                'platform': "WEB",
                'lang': global.lang,
            },
            body: JSON.stringify(data)
        }).then(function (response) {
            return response.blob()
        }).then((blob) => {
            var a = document.createElement("a");
            a.href = window.URL.createObjectURL(blob);
            // Give filename you wish to download
            a.download = file;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
        }).catch(function (error) {
            console.log(error);
            if (errCallback) {
                errCallback(error);
            }
        });
    },
};

export default net; 
