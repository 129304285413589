import React from 'react';
import {
    Form, Row, Col, Input, InputNumber, Select, DatePicker, Radio, Checkbox, Button, message,
    Modal, Table, AutoComplete, Upload, Image, Divider, Popconfirm
} from 'antd';
import I18n from "../../../language/I18n";
import ModelManager from "../../../domain/ModelManager";
import AppStatus from "../../../domain/AppStatus";
import Staff from "../../../domain/Staff";
import Split from "../../../components/Split";
import Net from "../../../utils/net";
import config from "../../../config";
import moment from "moment";
import 'moment/locale/zh-cn';

import FixAppPrint from "./FixAppPrint2";

const dateFormat = I18n.t("YYYY年MM月DD日");
const dateTimeFormat = I18n.t("YYYY年MM月DD日 HH:mm");
const dateFullFormat = I18n.t("YYYY年MM月DD日 HH:mm:ss");

const assignRoles = [Staff.ROLE_ASSISTANT, Staff.ROLE_MANAGER]; // 指派维修站
// 20220728
const autoCoffeeInfoType = ["EC9665.M", "EC9355.M", "EC9155.W", "EC9335.M"];
const AutoProductNames = ["自动咖啡机", "自動咖啡機", I18n.t("自动咖啡机")];
const ProductNames = ["泵压式咖啡机EC系列", "泵壓式咖啡機EC系列", I18n.t("泵压式咖啡机EC系列")];

const FixOrder = [
    AppStatus.TYPE_VISIT, AppStatus.TYPE_ACCESS, AppStatus.TYPE_PART_SUPPLY, AppStatus.TYPE_EXPRESS
];

const VisitFeeMap = {
    "noGuarantee": [
        { max: 0, fee: 0 },
        { min: 0, max: 20, fee: 40 },
        { min: 20, max: 25, fee: 50 },
        { min: 25, max: 30, fee: 60 },
        { min: 30, max: 35, fee: 70 },
        { min: 35, max: 40, fee: 80 },
        { min: 40, max: 45, fee: 90 },
        { min: 45, max: 50, fee: 100 },
        { min: 50, max: 55, fee: 110 },
        { min: 55, max: 60, fee: 120 },
        { min: 60, max: 65, fee: 130 },
        { min: 65, max: 70, fee: 140 },
        { min: 70, max: 75, fee: 150 },
        { min: 75, max: 80, fee: 160 },
        { min: 80, max: 85, fee: 170 },
        { min: 85, max: 90, fee: 180 },
        { min: 90, max: 95, fee: 190 },
        { min: 95, max: 100, fee: 200 },
        { min: 100, max: 105, fee: 210 },
        { min: 105, max: 110, fee: 220 },
        { min: 110, max: 115, fee: 230 },
        { min: 115, max: 120, fee: 240 },
        { min: 120, max: 125, fee: 250 },
        { min: 125, max: 130, fee: 260 },
        { min: 130, max: 135, fee: 270 },
        { min: 135, max: 140, fee: 280 },
        { min: 140, max: 145, fee: 290 },
        { min: 145, max: 150, fee: 300 },
        { min: 150, fee: 300 },
    ],
    "Guarantee": [
        { max: 0, fee: 0 },
        { min: 0, max: 30, fee: 30 },
        { min: 30, max: 60, fee: 60 },
        { min: 60, max: 90, fee: 90 },
        { min: 90, max: 120, fee: 120 },
        { min: 120, max: 150, fee: 150 },
        { min: 150, max: 180, fee: 180 },
        { min: 180, max: 210, fee: 210 },
        { min: 210, fee: 210 },
    ]
}

export default class FixAppEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            doc: props.doc || {},
            action: props.action,
            // enableEdit: props.action === "view" ? false : true,
            rpDetailTypeList: [],
            rpMoneyEditable: false,
            autoCoffeeInfoRowVisible: false,
            guaranteeEnabled: true,
            typeList: ModelManager.typeList || [],
            userCityDataProvider: [],
            isModalVisible: false,
            fixFeeEditable: false,
            otherFeeCanEdit: false,
            partGridHeadRowVisible: true,
            rpHeadRowVisible: true,
            guaranteeHBoxEnabled: true,
            processVBoxVisible: true,
            warrantTxt: "",
            addImgVisible: true,
            orderList: [],
            selectedOrder: null,
            selectedOrderInfo: null,
            orderHeadDivVisible: false,
            // guaranteeOrderInputText: "",
            // jdOrderInputText: "",
            // guaranteeOrderInputVisible: false,
            // jdOrderInputVisible: false,
            // orderSearchBtnVisible: false,
            ProductTypeOptions: ModelManager.productList || [],
            applyDiscardStatus: props.doc.applyDiscard ? true : false,
            imageGroupVisible: false,
        };
    }
    componentDidMount() {
        this.state.doc = this.props.doc || {};
        this.setApp(this.props.doc);
        this.loadIrisCode();
        // 指派维修站
        if (!global.isHK && assignRoles.some(ritem => ritem === ModelManager.loginUser.role)) {
            console.log("start loadAllWorkScopeInterval************")
            global.loadAllWorkScope();
            this.loadAllWorkScopeInterval();
        }
    };

    loadAllWorkScopeInterval = () => {
        this.iTimer && clearInterval(this.iTimer);
        this.iTimer = setInterval(() => {
            console.log("loadAllWorkScopeInterval")
            global.loadAllWorkScope();
        }, 1000 * 60)
    };

    shouldComponentUpdate(nextProps) {
        if (nextProps.doc !== this.props.doc) {
            console.log("nextProps doc", this.props.doc, nextProps.doc)
            this.product = null;
            this.state = {};
            this.state.doc = nextProps.doc || {};
            this.setState({
                doc: nextProps.doc || {},
                // enableEdit: nextProps.action === "view" ? false : true,
                rpDetailTypeList: [],
                rpMoneyEditable: false,
                autoCoffeeInfoRowVisible: false,
                guaranteeEnabled: true,
                typeList: ModelManager.typeList || [],
                userCityDataProvider: [],
                fixFeeEditable: false,
                partGridHeadRowVisible: true,
                rpHeadRowVisible: true,
                guaranteeHBoxEnabled: true,
                processVBoxVisible: true,
                warrantTxt: "",
                addImgVisible: true,
                orderList: [],
                selectedOrder: null,
                selectedOrderInfo: null,
                orderHeadDivVisible: false,
                // orderSearchBtnVisible: false,
                ProductTypeOptions: ModelManager.productList || [],
                photo1BtnVisible: false,
                photo1LabelVisible: false,
                tipInfo: "",
                guaranteeBuyDateVisible: false,
                // zbOptionVisible: false,
                // zbConfirmInputVisible: false,
                // zbConfirmLabelVisible: false,
                // jdOrderInputVisible: false,
                // jdOrderInputText: "",
                // guaranteeOrderInputVisible: false,
                // guaranteeOrderInputText: "",
                // kpUnitRowVisible: false,
                noWarrantDetail: null,
                applyDiscardStatus: nextProps.doc.applyDiscard ? true : false
            });
            console.log("shouldComponentUpdate orderList", this.state);
            this.forceUpdate();
            this.setApp(nextProps.doc);
            return true;
        }
        return false;
    }

    componentWillUnmount() {
        this.iTimer && clearInterval(this.iTimer);
        this.clearOrderChildren();
    }

    onDateFormat = (date, format) => {
        if (date) {
            return moment(date).format(format);
        }
        return "";
    };

    loadIrisCode = () => {
        let url = global.ServerUrl + "iris/list";
        let query = {
            offset: 0,
            limit: 1000,
        };
        Net.post(url, query, (res) => {
            if (res.result === 0) {
                ModelManager.IrisCodeList = res.ret;
            }
        }, error => {
        })
    };

    resetValue = (doc) => {
        // console.log("createDate", moment(new Date(global.getDate(doc.createDate))));
        this.formRef.setFieldsValue({
            id: doc.id,
            appNo: doc.appNo, //系统单号
            empId: doc.empId,
            workerId: doc.workerId,
            userName: doc.userName, //用户姓名
            userCorp: doc.userCorp, //公司名称
            userTel: doc.userTel, //移动电话
            userExt: doc.userExt, //固定电话
            userCity: doc.userCity, //市
            userAddress: doc.userAddress, //详细地址
            createDate: doc.createDate ? moment(new Date(global.getDate(doc.createDate))) : moment(), //接单日期
            stationNo: doc.stationNo, //维修站
            type: doc.type, //服务类型
            formNo: doc.formNo, //服务单编号
            brand: doc.brand, //品牌
            cup: doc.cup, //制作杯数
            clean: doc.clean, //除垢次数
            productName: doc.productName, //机器名称
            productType: doc.productType ? doc.productType : null, //机器型号
            productTypeForExcel: doc.productTypeForExcel, // 机器类别 导出Excel文件时需要
            productCode: doc.productCode, //机器编号
            purchaseDate: doc.purchaseDate && doc.purchaseDate.indexOf("1970-01-01") === -1 ? moment(global.getDate(doc.purchaseDate)) : null, // 出仓日期
            shop: doc.shop,
            invoiceDate: doc.invoiceDate ? moment(global.getDate(doc.invoiceDate)) : null,
            invoiceCode: doc.invoiceCode,
            noInvoice: doc.noInvoice,
            accessories: doc.accessories,
            description: doc.description, // 故障描述
            expectedDate: doc.expectedDate ? moment(global.getDate(doc.expectedDate)) : null, // 预计完成日期
            assigned: doc.assigned,
            assignedDate: doc.assignedDate ? moment(global.getDate(doc.assignedDate)) : null,
            appointment: doc.appointment ? moment(global.getDate(doc.appointment)) : null,
            appointmentDate: doc.appointmentDate ? moment(global.getDate(doc.appointmentDate)) : null, // 预约确认日期
            fixType: doc.fixType,
            EKPNo: doc.EKPNo,
            warranty: doc.warranty,
            nature: doc.nature,
            checkRecord: doc.checkRecord,
            fixRecord: doc.fixRecord,
            distance: doc.distance,
            visitFee: doc.visitFee,
            partsFee: doc.partsFee,
            fixFee: doc.fixFee,
            otherFee: doc.otherFee,
            otherDetail: doc.otherDetail,
            finishDate: doc.finishDate ? moment(global.getDate(doc.finishDate)) : null,
            fixDate: doc.fixDate ? moment(global.getDate(doc.fixDate)) : null, // 实际完成日期
            notice: doc.notice,
            noticeDate: doc.noticeDate ? moment(global.getDate(doc.noticeDate)) : null, // 通知日期
            pickup: doc.pickup,
            pickupDate: doc.pickupDate ? moment(global.getDate(doc.pickupDate)) : null, // 取货日期
            remark: doc.remark,
            status: doc.status,
            returnId: doc.returnId,
            headReturnId: doc.headReturnId,
            checkoutAppNo: doc.checkoutAppNo,
            serviceCard: doc.serviceCard,
            special: doc.special,
            servicePhone: doc.servicePhone,
            userProvince: doc.userProvince,
            invoiceInfo: doc.invoiceInfo, // 开票单位
            shopType: doc.shopType,
            shopProvince: doc.shopProvince,
            shopCity: doc.shopCity,
            isSubRegion: doc.isSubRegion,
            warter: doc.warter, //使用水量
            steam: doc.steam, //蒸汽次数
            shopRegion: doc.shopRegion,
            fixColor: doc.fixColor,
            fixHome: doc.fixHome,
            overseasType: doc.overseasType,
            overseasOrderCode: doc.overseasOrderCode,
            ecomOrderCode: doc.ecomOrderCode ? doc.ecomOrderCode : null,
            officalWarrantyCard: doc.officalWarrantyCard,
            officalBuyTime: doc.officalBuyTime,
            officalShopName: doc.officalShopName,
            orderIds: doc.orderIds,
            photo1: doc.photo1, // 保修凭证
            photo2: doc.photo2, // 机身编号
            guarantee: doc.guarantee,
            guaranteeBuyDate: doc.guaranteeBuyDate ? moment(global.getDate(doc.guaranteeBuyDate)) : null,
            rpType: doc.rpType,
            rpDetail: doc.rpDetail,
            rpMoney: doc.rpMoney,
            zbOption: doc.zbOption,
            channel: doc.channel,
            visitCount: doc.visitCount,
            visitQuality: doc.visitQuality,
            visitSpeed: doc.visitSpeed,
            visitService: doc.visitService,
            visitAgree: doc.visitAgree,
            visitSuggest: doc.visitSuggest,
            feedbackStatus: doc.feedbackStatus,
            confirmUser: doc.confirmUser,
            photo1UserName: doc.photo1UserName,
            photo1UploadTime: doc.photo1UploadTime,
            photo2UserName: doc.photo2UserName,
            photo2UploadTime: doc.photo2UploadTime,
            visitNote: doc.visitNote,
            visitTime: doc.visitTime,
            visitPerson: doc.visitPerson,
            pickupPerson: doc.pickupPerson,
            repairPerson: doc.repairPerson,
            productNo: doc.productNo,
            photo3: doc.photo3, // 插座检查
            photo3UserName: doc.photo3UserName,
            photo3UploadTime: doc.photo3UploadTime,
            isWarrant: doc.isWarrant,
            warrantInfo: doc.warrantInfo,
            logisticsNo: doc.logisticsNo,
            logisticsNo2: doc.logisticsNo2,
            oaAppNo: doc.oaAppNo,
            processList: doc.processList,
            partsList: doc.partsList || [],
            noGuarantee: doc.noGuarantee,
            isReturn: doc.isReturn,
            getAppTime: doc.getAppTime,
            irisCode: doc.irisCode,
            irisCodeDesc: doc.irisCodeDesc,
            guaranteeEnabled: !doc.noGuarantee,
            noWarrantDetail: doc.noWarrantDetail,
            outletFailure: doc.outletFailure,
            photo4: doc.photo4, //故障现象
            photo4UserName: doc.photo4UserName,
            photo4UploadTime: doc.photo4UploadTime,
            photo5: doc.photo5, //纸质工单
            photo5UserName: doc.photo5UserName,
            photo5UploadTime: doc.photo5UploadTime,
            photo6: doc.photo6, //京东订单
            photo6UserName: doc.photo6UserName,
            photo6UploadTime: doc.photo6UploadTime,
            photo7: doc.photo7, //京东订单
            photo7UserName: doc.photo7UserName,
            photo7UploadTime: doc.photo7UploadTime,
            getBackHow: doc.getBackHow,
            EmergencyOrder: doc.EmergencyOrder, // 紧急工单
            isEmergency: doc.isEmergency,
            // firstFix: doc.firstFix, // 首次维修
            multipleFix: doc.multipleFix, // 多次维修
            appNo400: doc.appNo400,
            sapAppNo: doc.sapAppNo,
            agentUniqueParam: doc.agentUniqueParam,
            complainUnHandle: doc.complainUnHandle ? true : false, // 400投诉处理状态
            totalCup: doc.totalCup, // 饮品总杯数
            totalLitres: doc.totalLitres, // 饮品总升数

            photo8: doc.photo8, //拉花照片
            photo8UserName: doc.photo8UserName,
            photo8UploadTime: doc.photo8UploadTime,
            flowerFee: ModelManager.getFlowerFee(doc), // 拉花费用
            appNoApplet: doc.appNoApplet,
        });
    };

    appIsUndone = (app) => {
        if (app.status < AppStatus.S_FINISH ||
            [AppStatus.S_TOBESEND1, AppStatus.S_TOBESEND2, AppStatus.S_TOBEREPAIRED].some(sitem => sitem === app.status)) {
            return true;
        }
        return false;
    };

    userIsPickup = (app) => {
        // S_PICKUP = 6;	//已取货
        if (app.status < AppStatus.S_PICKUP ||
            [AppStatus.S_TOBESEND1, AppStatus.S_TOBESEND2, AppStatus.S_TOBEREPAIRED].some(sitem => sitem === app.status)) {
            return true;
        }
        return false;
    };

    setApp(fixApp) {
        if (fixApp.purchaseDate && moment(global.getDate(fixApp.purchaseDate)).valueOf() === 0) {
            fixApp.purchaseDate = null;
        }
        this.resetValue(fixApp);
        let app = this.getFieldValue();
        // app = ObjectUtil.copy(fixApp);

        // brandGroup.selectedValue = app.brand;
        // natureGroup.selectedValue = app.nature;
        // warrantyGroup.selectedValue = app.warranty;

        // fixHomeGroup.selectedValue = app.fixHome;
        this.state.hboxFixColorVisible = false;
        this.state.hboxFixHomeVisible = false;
        if (app.fixType == 16) {
            this.state.hboxFixColorVisible = true;
            this.state.hboxFixHomeVisible = true;
        }
        this.setState({
            hboxFixColorVisible: this.state.hboxFixColorVisible,
            hboxFixHomeVisible: this.state.hboxFixHomeVisible,
        });
        this.setProductName(app.productName);
        this.state.productTipInfo = "";
        if (app.productType != "") {
            for (let i = 0; i < ModelManager.productList.length; i++) {
                const pro = ModelManager.productList[i];
                if (pro.model == app.productType) {
                    if (pro.reserve == 1) this.state.productTipInfo = "可上门服务";
                    break;
                }
            }
            // if (ProductNames.some(codeItem => codeItem === app.productName)) {
            //     this.setState({ autoCoffeeInfoRowVisible: true });
            //     console.log("setApp autoCoffeeInfoRowVisible", true)
            // }
            let Names = AutoProductNames.concat(ProductNames);
            console.log("setProductName Names", Names)
            if (Names.some(nItem => nItem === app.productName)) {
                this.setState({ autoCoffeeInfoRowVisible: true })
            } else {
                this.setState({ autoCoffeeInfoRowVisible: false })
            }
        }
        this.state.irisObj = null;
        this.state.irisCodeDesc = null;
        if (app.irisCode != "" && app.irisCode != null) {
            console.log('setting irisCode')
            for (let i = 0; i < ModelManager.IrisCodeList.length; i++) {
                const pro = ModelManager.IrisCodeList[i];
                if (pro.irisDefectCode == app.irisCode) {
                    console.log(pro)
                    this.state.irisObj = pro;
                    if (pro.extraInfo)
                        this.state.irisCodeDesc = pro.extraInfo + ' - ' + pro.areaChs + ' - ' + pro.irisDefectDescChs;
                    else
                        this.state.irisCodeDesc = pro.areaChs + ' - ' + pro.irisDefectDescChs;
                    this.formRef.setFieldsValue({ irisCode: app.irisCode, irisCodeDesc: app.irisCodeDesc });
                    break;
                }
            }
            // } else {
            //     this.state.irisObj = null;
        }
        this.setState({
            irisObj: this.state.irisObj,
            irisCodeDesc: this.state.irisCodeDesc,
            productTipInfo: this.state.productTipInfo,
            noWarrantDetail: app.noWarrantDetail
        });
        this.provinceSelectedIndex = 0;
        for (let i = 0; i < global.provinceList.length; i++) {
            const name = global.provinceList[i];
            if (fixApp.userProvince === name.toString()) {
                this.provinceSelectedIndex = i;
                this.formRef.setFieldsValue({ userProvince: name });
                break;
            }
        }
        this.state.userCityDataProvider = global.cityList[this.provinceSelectedIndex];
        for (let i = 0; i < this.state.userCityDataProvider.length; i++) {
            const name = this.state.userCityDataProvider[i];
            if (fixApp.userCity === name.toString()) {
                this.formRef.setFieldsValue({ userCity: name });
                break;
            }
        }
        let processText = "";
        app.processList = app.processList || [];
        for (let i = 0; i < app.processList.length; i++) {
            const p = app.processList[i];
            processText += moment(p.handleDate.substring(0, p.handleDate.length - 5)).format(dateFullFormat) + "  ";
            processText += ModelManager.getStaffName(p.empId) + "：" + I18n.t(AppStatus.GetProcessLabel(p.result));
            if (p.remark != null && p.remark.length > 0)
                processText += "  " + global.transLabel("备注") + p.remark;
            processText += "\n";
        }
        this.state.processText = processText;
        this.setState({ processText });
        //可编辑状态
        if (this.appIsUndone(app)) {
            if (app.isSubRegion == true) {
                if (app.stationNo === ModelManager.loginUser.regionId || ModelManager.loginUser.regionId === 2)
                    this.state.canEditUser = true;
                else
                    this.state.canEditUser = false;
            } else {
                this.state.canEditUser = true;
            }
        } else {
            this.state.canEditUser = false;
        }

        if (app.status === AppStatus.S_DRAFT || app.status === AppStatus.S_TOBESEND1 || app.status === AppStatus.S_TOBESEND2)
            this.state.typeEnabled = true;
        else
            this.state.typeEnabled = false;

        //设置服务类型，维修站只能新建2类
        if (ModelManager.loginUser.role === Staff.ROLE_STATION_ADMIN && (!app.id || app.status === AppStatus.S_DRAFT || app.status === AppStatus.S_TOBESEND1 || app.status === AppStatus.S_TOBESEND2)) {
            this.state.typeList = ModelManager.typeListForAdd;
            // this.state.typeSelectedIndex = app.type;
            // if (app.type == 10)
            //     this.state.typeSelectedIndex = 4;
            // if (app.type == 9)
            //     this.state.typeSelectedIndex = 3;
        }


        if (app.id && (
            app.type === AppStatus.TYPE_ACCESS && app.status === AppStatus.S_TOBESEND1 ||
            app.type === 10 && app.status === AppStatus.S_TOBESEND2)) {
            this.state.repairButtonVisible = true;
        } else {
            this.state.repairButtonVisible = false;
        }

        this.setSendButton();

        //指派维修站
        if ((app.status <= AppStatus.S_ASSIGN ||
            [AppStatus.S_TOBESEND1, AppStatus.S_TOBESEND2, AppStatus.S_TOBEREPAIRED].some(sitem => sitem === app.status))
            && (app.type < AppStatus.TYPE_OTHER || app.type == 10 || app.type == 9)	//上门处理,接修
            && assignRoles.some(ritem => ritem === ModelManager.loginUser.role)
            // && (ModelManager.loginUser.role == Staff.ROLE_ASSISTANT || ModelManager.loginUser.role == Staff.ROLE_MANAGER)
            // && ModelManager.loginUser.regionId === 2
        ) {
            this.state.assignButtonVisible = true;
        } else {
            this.state.assignButtonVisible = false;
        }

        //退回修改按钮
        if (app.status >= AppStatus.S_FINISH && app.status !== AppStatus.S_DISCARD
            && ![AppStatus.S_TOBESEND1, AppStatus.S_TOBESEND2, AppStatus.S_TOBEREPAIRED].some(sitem => sitem === app.status)
            && (app.type < AppStatus.TYPE_OTHER || app.type == 10 || app.type == 9)
            && ModelManager.loginUser.regionId === 2 && !app.isReturn
            && (ModelManager.loginUser.role == Staff.ROLE_ASSISTANT || ModelManager.loginUser.role == Staff.ROLE_MANAGER || ModelManager.loginUser.role == Staff.ROLE_ADMIN)) {
            this.state.returnButtonVisible = true;
        } else {
            this.state.returnButtonVisible = false;
        }

        //指派维修人按钮
        /*	if(ModelManager.loginUser.role == Staff.ROLE_STATION_ADMIN
                && ModelManager.loginUser.regionId == app.stationNo
                && app.status < AppStatus.S_FINISH) {
                workerButton.visible = true;
                workerButton.includeInLayout = true;
            } else {
                workerButton.visible = false;
                workerButton.includeInLayout = false;
            }
            setWorkerButton();*/

        //预约上门按钮
        if (ModelManager.loginUser.role == Staff.ROLE_STATION_ADMIN
            && ModelManager.loginUser.regionId == app.stationNo
            && (app.status < AppStatus.S_APPOINT
                || [AppStatus.S_TOBESEND1, AppStatus.S_TOBESEND2, AppStatus.S_TOBEREPAIRED].some(sitem => sitem === app.status))
            // && (app.type < AppStatus.TYPE_OTHER || app.type == 10 || app.type == 9)
            && app.type == AppStatus.TYPE_VISIT
        ) {
            this.state.appointButtonVisible = true;
        } else {
            this.state.appointButtonVisible = false;
        }
        /**
         * TYPE_EXPRESS = 10; //快递寄修
         * 20220706
         * 寄修的流程 维修完成——待通知取货——顾客已取货
         * 在顾客已取货之前  可以修改 “其他费用，维修站物流单号”
         */
        if (this.userIsPickup(app) && app.type === AppStatus.TYPE_EXPRESS) {
            this.state.otherFeeCanEdit = true;
        } else {
            this.state.otherFeeCanEdit = false;
        }
        this.setState({
            otherFeeCanEdit: this.state.otherFeeCanEdit,
            canEditUser: this.state.canEditUser,
            typeEnabled: this.state.typeEnabled,
            assignButtonVisible: this.state.assignButtonVisible,
            returnButtonVisible: this.state.returnButtonVisible,
            appointButtonVisible: this.state.appointButtonVisible,
            repairButtonVisible: this.state.repairButtonVisible,
        });
        this.setAppointButton();


        //维修完成
        if (ModelManager.loginUser.role == Staff.ROLE_STATION_ADMIN && app.status < AppStatus.S_FINISH
            // && this.appIsUndone(app)
            // && (app.status < AppStatus.S_FINISH
            // || [AppStatus.S_TOBESEND1, AppStatus.S_TOBESEND2, AppStatus.S_TOBEREPAIRED].some(sitem => sitem === app.status))
            && app.stationNo == ModelManager.loginUser.regionId
            && (app.type < AppStatus.TYPE_OTHER || app.type == 10 || app.type == 9)) {
            this.state.canEdit = true;
            this.state.finishButtonVisible = true;
        } else {
            this.state.canEdit = false;
            this.state.finishButtonVisible = false;
        }
        if (app.id && ModelManager.isAgency()) {
            this.state.AgencyCanEdit = false;
        } else {
            this.state.AgencyCanEdit = true;
        }
        this.setState({
            AgencyCanEdit: this.state.AgencyCanEdit,
            canEdit: this.state.canEdit,
            finishButtonVisible: this.state.finishButtonVisible,
        });
        this.setFinishButton();
        //作废按钮
        if ((ModelManager.loginUser.empId === app.empId
            || ModelManager.loginUser.regionId === app.stationNo
            || ModelManager.loginUser.role === Staff.ROLE_ASSISTANT//ROLE_ASSISTANT 维修站助理 ROLE_MANAGER 维修部经理
            || ModelManager.loginUser.role === Staff.ROLE_MANAGER)
            && app.status !== AppStatus.S_DISCARD
            // && (app.status < AppStatus.S_FINISH || [AppStatus.S_TOBESEND1, AppStatus.S_TOBESEND2, AppStatus.S_TOBEREPAIRED].some(sitem => sitem === app.status))
            && this.appIsUndone(app)
            && app.id) {
            this.state.discardButtonVisible = true;
            //已申请作废的单子维修站不显示作废按钮
            if (this.state.applyDiscardStatus && ModelManager.loginUser.regionId !== 2) {
                this.state.discardButtonVisible = false;
            }
        } else {
            this.state.discardButtonVisible = false;
        }
        this.state.applyDiscardButtonVisible = false;//申请作废按钮
        if (app.id && ModelManager.loginUser.regionId !== 2 && !this.state.applyDiscardStatus && this.state.discardButtonVisible && this.isZBDispatch(app.empId)) {//总部派单
            this.state.applyDiscardButtonVisible = true;
            this.state.discardButtonVisible = false;
        }
        this.state.cancelApplyDiscardButtonVisible = false;
        if (app.id && app.status !== 99 && ModelManager.loginUser.regionId !== 2 && this.state.applyDiscardStatus && this.isZBDispatch(app.empId)) {//总部派单
            this.state.cancelApplyDiscardButtonVisible = true;
        }
        this.state.zbDiscardButtonVisible = false;
        if (app.status !== 99 && this.state.applyDiscardStatus && ModelManager.loginUser.regionId === 2) {
            if (ModelManager.loginUser.role === Staff.ROLE_ASSISTANT//ROLE_ASSISTANT 维修站助理 ROLE_MANAGER 维修部经理
                || ModelManager.loginUser.role === Staff.ROLE_MANAGER
                || ModelManager.loginUser.role === Staff.ROLE_ADMIN) {
                this.state.zbDiscardButtonVisible = true;
            }
            this.state.discardButtonVisible = false;
        }
        this.setState({
            discardButtonVisible: this.state.discardButtonVisible,
            applyDiscardButtonVisible: this.state.applyDiscardButtonVisible,
            zbDiscardButtonVisible: this.state.zbDiscardButtonVisible,
            cancelApplyDiscardButtonVisible: this.state.cancelApplyDiscardButtonVisible,
        });

        //系统管理员可修改
        let adminCanEdit = false;
        if (ModelManager.loginUser.role === Staff.ROLE_ADMIN) {	//&& app.status < AppStatus.S_AGREE
            this.state.canEdit = true;
            this.state.canEditUser = true;
            if (!this.state.applyDiscardStatus && app.status !== AppStatus.S_DISCARD) {
                this.state.discardButtonVisible = true;
            }

            adminCanEdit = true;
        }
        this.setState({
            canEdit: this.state.canEdit,
            canEditUser: this.state.canEditUser,
            discardButtonVisible: this.state.discardButtonVisible,
        });

        //上门公里数,上门费用
        if (app.type === AppStatus.TYPE_VISIT && (adminCanEdit || ModelManager.loginUser.regionId === app.stationNo
            && ModelManager.loginUser.role === Staff.ROLE_STATION_ADMIN
            && this.appIsUndone(app)
            // && (app.status < AppStatus.S_FINISH || [AppStatus.S_TOBESEND1, AppStatus.S_TOBESEND2, AppStatus.S_TOBEREPAIRED].some(sitem => sitem === app.status))
        )) {
            this.state.distanceEditable = true;
        } else {
            this.state.distanceEditable = false;
        }
        this.setState({
            distanceEditable: this.state.distanceEditable,
        });

        app.partsList = app.partsList || [];
        for (let i = 0; i < app.partsList.length; i++) {
            const parts = app.partsList[i];
            // partsIndex++;
            // var ps: PartsSample = new PartsSample;
            // ps.canEdit = canEdit;
            // ps.app = app;
            // ps.parts = parts;
            // ps.partsIndex = partsIndex;
            // grid.addChildAt(ps, grid.getChildIndex(GridRow1));

        }

        //通知取货
        if (ModelManager.loginUser.role == Staff.ROLE_STATION_ADMIN
            && ModelManager.loginUser.regionId == app.stationNo
            && app.status == AppStatus.S_FINISH && app.notice) {
            this.state.noticeButtonVisible = true;
        } else {
            this.state.noticeButtonVisible = false;
        }
        this.setState({
            noticeButtonVisible: this.state.noticeButtonVisible,
        });
        //取货确认
        if (ModelManager.loginUser.role === Staff.ROLE_STATION_ADMIN
            && ModelManager.loginUser.regionId === app.stationNo
            && app.status === AppStatus.S_NOTICE && app.notice) {
            this.state.pickupButtonVisible = true;
        } else {
            this.state.pickupButtonVisible = false;
        }
        this.setState({
            pickupButtonVisible: this.state.pickupButtonVisible,
        });

        if (app.status !== AppStatus.S_DISCARD &&
            ![AppStatus.S_TOBESEND1, AppStatus.S_TOBESEND2, AppStatus.S_TOBEREPAIRED].some(sitem => sitem === app.status) &&
            (app.status >= AppStatus.S_PICKUP || app.status >= AppStatus.S_FINISH && !app.notice)) {	//可进行回访

            //总部回访按钮
            // if (app.status < AppStatus.S_FINISH && app.fixType === AppStatus.TYPE_MACHINE && (ModelManager.loginUser.regionId === 2)) {
            //     this.state.oaButtonVisible = true;
            // }
            if (app.feedbackStatus < 2 && (ModelManager.loginUser.role == Staff.ROLE_ASSISTANT || ModelManager.loginUser.role == Staff.ROLE_MANAGER)) {
                this.state.visit2ButtonVisible = true;
                this.state.visitContinueButtonVisible = true;
                this.state.canVisitEdit = true;
                this.state.VisitScore2Visible = true;
            }
        }

        if (app.feedbackStatus > 0) {
            this.state.VisitScore2Visible = true;
        }
        this.setState({
            // oaButtonVisible: this.state.oaButtonVisible,
            visit2ButtonVisible: this.state.visit2ButtonVisible,
            visitContinueButtonVisible: this.state.visitContinueButtonVisible,
            canVisitEdit: this.state.canVisitEdit,
            VisitScore2Visible: this.state.VisitScore2Visible,
        });

        this.state.noticeEnabled = false;
        if (app.type == AppStatus.TYPE_ACCESS || app.type == 9 || app.type == 10)	//接修
            if (app.type == AppStatus.TYPE_VISIT
                && this.appIsUndone(app)
                // && (app.status < AppStatus.S_FINISH || [AppStatus.S_TOBESEND1, AppStatus.S_TOBESEND2, AppStatus.S_TOBEREPAIRED].some(sitem => sitem === app.status))
                && ModelManager.loginUser.role == Staff.ROLE_STATION_ADMIN && ModelManager.loginUser.regionId == app.stationNo)
                this.state.noticeEnabled = true;
        this.setState({
            noticeEnabled: this.state.noticeEnabled,
        });

        this.computePartsMoney();
        if (!app.id && app.status == AppStatus.S_DRAFT)	//new app
            this.getServerAppDate();

        if (app.photo2 == null || app.photo2 == "") {
            this.state.photo2LabelVisible = true;
            this.state.photo2BtnVisible = false;
        } else {
            this.state.photo2LabelVisible = false;
            this.state.photo2BtnVisible = true;
        }

        if (app.photo3 == null || app.photo3 == "") {
            this.state.photo3LabelVisible = true;
            this.state.photo3BtnVisible = false;
        } else {
            this.state.photo3LabelVisible = false;
            this.state.photo3BtnVisible = true;
        }

        if (app.photo4 == null || app.photo4 == "") {
            this.state.photo4BtnVisible = false;
        } else {
            this.state.photo4BtnVisible = true;
        }

        if (app.photo5 == null || app.photo5 == "") {// 纸质工单
            this.state.photo5LabelVisible = true;
            this.state.photo5BtnVisible = false;
        } else {
            this.state.photo5LabelVisible = false;
            this.state.photo5BtnVisible = true;
        }


        if (app.photo8 == null || app.photo8 == "") {// 拉花照片
            this.state.photo8LabelVisible = true;
            this.state.photo8BtnVisible = false;
        } else {
            this.state.photo8LabelVisible = false;
            this.state.photo8BtnVisible = true;
        }
        this.setState({
            photo2LabelVisible: this.state.photo2LabelVisible,
            photo2BtnVisible: this.state.photo2BtnVisible,
            photo3LabelVisible: this.state.photo3LabelVisible,
            photo3BtnVisible: this.state.photo3BtnVisible,
            photo4BtnVisible: this.state.photo4BtnVisible,
            photo5LabelVisible: this.state.photo5LabelVisible,
            photo5BtnVisible: this.state.photo5BtnVisible,
            photo8LabelVisible: this.state.photo8LabelVisible,
            photo8BtnVisible: this.state.photo8BtnVisible,
        });

        if (app.warranty == 1) {
            this.state.warrantVBoxEnabled = true;
            // 20220630 保修依据里的：港台凭证，海外机身号，售前机 三项 没有延保
            if ([5, 8, 10].includes(app.guarantee)) {
                this.state.warrantVBoxEnabled = false;
                this.setState({
                    warrantVBoxEnabled: false,
                    warrantHBoxVisible: false,
                    warrantTxtVisible: false,
                    warrantTxt: "",
                });
            }
            if (app.guarantee == 1) {
                this.state.guaranteeBuyDateVisible = true;
                // this.state.kpUnitRowVisible = true;
                this.state.tipInfo = "该选项保修两年";
                if (app.photo1 == null || app.photo1 == "") {
                    this.state.photo1LabelVisible = true;
                    this.state.photo1BtnVisible = false;
                } else {
                    this.state.photo1LabelVisible = false;
                    this.state.photo1BtnVisible = true;
                }
                this.setState({
                    guaranteeBuyDateVisible: this.state.guaranteeBuyDateVisible,
                    // kpUnitRowVisible: this.state.kpUnitRowVisible,
                    tipInfo: this.state.tipInfo,
                    photo1LabelVisible: this.state.photo1LabelVisible,
                    photo1BtnVisible: this.state.photo1BtnVisible,
                });
                // } else if (app.guarantee == 2 || app.guarantee == 3 || app.guarantee == 4 || app.guarantee == 8) { // "订单号查询", "手机号查询", "保修卡号查询", "海外机身号查询"
                // } else if ([2, 3, 4, 8].includes(app.guarantee)) { // "订单号查询", "手机号查询", "保修卡号查询", "海外机身号查询"
            } else if ([2, 3, 4].includes(app.guarantee)) { // "订单号查询", "手机号查询", "保修卡号查询", "海外机身号查询"
                // this.state.guaranteeOrderInputVisible = true;
                // this.state.jdOrderInputVisible = false;
                // this.state.orderSearchBtnVisible = true;
                if (app.guarantee == 4 || app.guarantee == 2) {
                    if (app.photo1 == null || app.photo1 == "") {
                        this.state.photo1LabelVisible = true;
                        this.state.photo1BtnVisible = false;
                    } else {
                        this.state.photo1LabelVisible = false;
                        this.state.photo1BtnVisible = true;
                    }
                }
                this.setState({
                    // guaranteeOrderInputVisible: this.state.guaranteeOrderInputVisible,
                    // jdOrderInputVisible: this.state.jdOrderInputVisible,
                    // orderSearchBtnVisible: this.state.orderSearchBtnVisible,
                    photo1LabelVisible: this.state.photo1LabelVisible,
                    photo1BtnVisible: this.state.photo1BtnVisible,
                });
                if (app.ecomOrderCode != "") {
                    this.searchOrderByIds();
                    // var event: DataReqEvent = new DataReqEvent(DataReqEvent.SEARCH_ORDER_BYIDS);
                    // event.name = app.orderIds;
                    // dispatchEvent(event);
                } else {
                    this.state.orderList = [];
                    this.state.selectedOrder = null;
                    this.state.selectedOrderInfo = null;
                    this.state.tipInfo = "";
                    this.state.orderHeadDivVisible = false;
                    this.setState({
                        orderList: [],
                        selectedOrder: null,
                        selectedOrderInfo: null,
                        tipInfo: "",
                        orderHeadDivVisible: false,
                    });
                }
            } else if (app.guarantee === 5) {
                this.state.tipInfo = "该选项保修一年";
                this.state.guaranteeBuyDateVisible = true;
                if (app.photo1 == null || app.photo1 == "") {
                    this.state.photo1LabelVisible = true;
                    this.state.photo1BtnVisible = false;
                } else {
                    this.state.photo1LabelVisible = false;
                    this.state.photo1BtnVisible = true;
                }
                // this.state.zbConfirmLabelVisible = true;
                // this.state.zbConfirmInputVisible = true;
                // this.state.zbConfirmInputEnabled = false;
                // if (ModelManager.loginUser.role == Staff.ROLE_ADMIN || ModelManager.loginUser.regionId == 2) {
                //     this.state.zbConfirmInputEnabled = true;
                // }
                this.setState({
                    tipInfo: this.state.tipInfo,
                    guaranteeBuyDateVisible: this.state.guaranteeBuyDateVisible,
                    photo1LabelVisible: this.state.photo1LabelVisible,
                    photo1BtnVisible: this.state.photo1BtnVisible,
                    // zbConfirmLabelVisible: this.state.zbConfirmLabelVisible,
                    // zbConfirmInputVisible: this.state.zbConfirmInputVisible,
                    // zbConfirmInputEnabled: this.state.zbConfirmInputEnabled,
                });
            } else if (app.guarantee === 6) {
                this.state.tipInfo = "";
                this.state.guaranteeBuyDateVisible = true;
                if (app.photo1 == null || app.photo1 == "") {
                    this.state.photo1LabelVisible = true;
                    this.state.photo1BtnVisible = false;
                } else {
                    this.state.photo1LabelVisible = false;
                    this.state.photo1BtnVisible = true;
                }
                // this.state.zbOptionVisible = true;
                // this.state.zbOptionEnabled = false;
                // if (ModelManager.loginUser.role == Staff.ROLE_ADMIN || ModelManager.loginUser.regionId == 2) {
                //     this.state.zbOptionEnabled = true;
                // }
                if (app.zbOption == 1) {
                    this.state.tipInfo = "该选项保修两年";
                } else if (app.zbOption == 2) {
                    this.state.tipInfo = "该选项保修两年";
                } else if (app.zbOption == 3) {
                    this.state.tipInfo = "该选项保修两年";
                } else if (app.zbOption == 4) {
                    this.state.tipInfo = "该选项保修一年";
                }
                this.setState({
                    tipInfo: this.state.tipInfo,
                    guaranteeBuyDateVisible: this.state.guaranteeBuyDateVisible,
                    photo1LabelVisible: this.state.photo1LabelVisible,
                    photo1BtnVisible: this.state.photo1BtnVisible,
                    // zbOptionVisible: this.state.zbOptionVisible,
                    // zbOptionEnabled: this.state.zbOptionEnabled,
                });
            } else if (app.guarantee === 7 || app.guarantee === 8) { // 20240712 海外机身号
                this.state.tipInfo = "该选项保修两年";
                this.setState({
                    tipInfo: this.state.tipInfo,
                });
            } else if (app.guarantee === 9 || app.guarantee === 12) {//总部
                this.state.tipInfo = "";
                // this.state.zbConfirmLabelVisible = true;
                // this.state.zbConfirmInputVisible = true;
                // this.state.zbConfirmInputEnabled = false;
                // if (ModelManager.loginUser.role == Staff.ROLE_ADMIN || ModelManager.loginUser.regionId == 2) {
                //     this.state.zbConfirmInputEnabled = true;
                // }
                // this.state.zbOptionVisible = true;
                // this.state.zbOptionEnabled = false;
                // if (ModelManager.loginUser.role == Staff.ROLE_ADMIN || ModelManager.loginUser.regionId == 2) {
                //     this.state.zbOptionEnabled = true;
                // }
                if (app.zbOption == 1) {
                    this.state.tipInfo = "该选项保修两年";
                } else if (app.zbOption == 2) {
                    this.state.tipInfo = "该选项保修两年";
                } else if (app.zbOption == 3) {
                    this.state.tipInfo = "该选项保修两年";
                } else if (app.zbOption == 4) {
                    this.state.tipInfo = "该选项保修一年";
                }
                this.setState({
                    tipInfo: this.state.tipInfo,
                    guaranteeBuyDateVisible: app.servicePhone ? false : true,
                    // zbConfirmLabelVisible: this.state.zbConfirmLabelVisible,
                    // zbConfirmInputVisible: this.state.zbConfirmInputVisible,
                    // zbConfirmInputEnabled: this.state.zbConfirmInputEnabled,
                    // zbOptionVisible: this.state.zbOptionVisible,
                    // zbOptionEnabled: this.state.zbOptionEnabled,
                });
            } else if (app.guarantee === 11) { // 京东自营
                this.state.tipInfo = "";
                // this.state.jdOrderInputVisible = true;
                // this.state.guaranteeOrderInputVisible = false;
                this.state.guaranteeBuyDateVisible = true;
                // this.state.zbConfirmLabelVisible = true;
                // this.state.zbConfirmInputVisible = true;
                // this.state.zbConfirmInputEnabled = false;
                // if (ModelManager.loginUser.role == Staff.ROLE_ADMIN || ModelManager.loginUser.regionId == 2) {
                //     this.state.zbConfirmInputEnabled = true;
                // }
                this.setState({
                    tipInfo: this.state.tipInfo,
                    // jdOrderInputVisible: this.state.jdOrderInputVisible,
                    // guaranteeOrderInputVisible: this.state.guaranteeOrderInputVisible,
                    guaranteeBuyDateVisible: this.state.guaranteeBuyDateVisible,
                    // zbConfirmLabelVisible: this.state.zbConfirmLabelVisible,
                    // zbConfirmInputVisible: this.state.zbConfirmInputVisible,
                    // zbConfirmInputEnabled: this.state.zbConfirmInputEnabled,
                });
            }
        }
        // rpDetailType.selectedIndex = 0;
        if (app.rpType == 1) {
            this.state.rpDetailTypeList = ModelManager.rewardList;
            for (let i = 0; i < ModelManager.rewardList.length; i++) {
                const item = ModelManager.rewardList[i];
                if (app.rpDetail == item.value) {
                    // rpDetailType.selectedIndex = i;
                    break;
                }
            }
        } else if (app.rpType == 2) {
            this.state.rpDetailTypeList = ModelManager.punishList;
            for (let i = 0; i < ModelManager.punishList.length; i++) {
                const item = ModelManager.punishList[i];
                if (app.rpDetail == item.value) {
                    // rpDetailType.selectedIndex = i;
                    break;
                }
            }
        }
        this.state.rpRowEnabled = false;
        if (ModelManager.loginUser.role == Staff.ROLE_ADMIN) {
            this.state.rpRowEnabled = true;
        }
        this.setState({
            rpRowEnabled: this.state.rpRowEnabled,
        });
        if (app.warranty == 2) {
            app.noGuarantee = true;
            this.formRef.setFieldsValue({ noGuarantee: true });
            this.state.guaranteeHBoxEnabled = false;
            this.setState({
                guaranteeHBoxEnabled: this.state.guaranteeHBoxEnabled,
            });
        }

        if (app.isWarrant) {
            // this.formRef.setFieldsValue({isWarrant: true});
            this.state.warrantHBoxVisible = true;
            this.setState({
                warrantHBoxVisible: this.state.warrantHBoxVisible,
            });
        }

        // if (app.nature == 1 || app.nature == 3) {
        //     this.state.guaranteeEnabled = false;
        //     this.setState({
        //         guaranteeEnabled: this.state.guaranteeEnabled,
        //     });
        // } else {
        this.setState({
            guaranteeEnabled: !app.noGuarantee
        });
        // }

        if (app.visitCount == 0)
            this.state.visitQualityText = '';
        else
            this.state.visitQualityText = app.visitQuality;

        if (app.visitCount == 0)
            this.state.visitServiceText = '';
        else
            this.state.visitServiceText = app.visitService;
        if (app.visitCount == 0)
            this.state.visitSpeedText = '';
        else
            this.state.visitSpeedText = app.visitSpeed;
        if (app.visitCount == 0)
            this.state.visitAgreeText = '';
        else
            this.state.visitAgreeText = app.visitAgree;
        if (app.visitCount == 0)
            this.state.visitSuggestText = '';
        else
            this.state.visitSuggestText = app.visitSuggest;
        this.setState({
            visitQualityText: this.state.visitQualityText,
            visitServiceText: this.state.visitServiceText,
            visitSpeedText: this.state.visitSpeedText,
            visitAgreeText: this.state.visitAgreeText,
            visitSuggestText: this.state.visitSuggestText,
        });

        this.state.visitNoteRowVisible = false;
        if (ModelManager.loginUser.role == Staff.ROLE_ADMIN || ModelManager.loginUser.regionId == 2) {
            this.state.visitNoteRowVisible = true;
        }
        this.setState({
            visitNoteRowVisible: this.state.visitNoteRowVisible,
        });
        if (app.isWarrant) {
            // warrant.selected = true;
            this.state.warrantHBoxVisible = true;
            if (app.warrantInfo && app.warrantInfo != "") {
                var infoArr = app.warrantInfo.split(";");
                this.state.warrantTxtVisible = true;
                this.state.warrantTxt = global.transLabel("订单编号") + infoArr[1] + "," + global.transLabel("手机号") + infoArr[2] + "," + global.transLabel("购买型号") + infoArr[3] + "," + global.transLabel("购买数量") + infoArr[4] + "," + global.transLabel("购买平台") + infoArr[5] + "," + global.transLabel("机身编号") + infoArr[6];
                if (infoArr.length > 7) {
                    console.log("infoArr[7]", infoArr[7])
                    let time = moment(global.getDate(infoArr[7])).format(dateFormat);
                    this.state.warrantTxt += "," + global.transLabel("购买时间") + time;
                    // this.state.warrantTxt += "," + global.transLabel("购买时间") + infoArr[7];
                }
                if (infoArr.length > 8) {
                    console.log("infoArr[8]", infoArr[8])
                    let time = moment(global.getDate(infoArr[8])).format(dateFormat);
                    this.state.warrantTxt += "," + global.transLabel("注册时间") + time;
                    // this.state.warrantTxt += "," + global.transLabel("注册时间") + infoArr[8];
                }
            }
            this.setState({
                warrantHBoxVisible: this.state.warrantHBoxVisible,
                warrantTxtVisible: this.state.warrantTxtVisible,
                warrantTxt: this.state.warrantTxt,
            });
        }

        // if (this.appIsUndone(app) && app.fixType == AppStatus.TYPE_MACHINE && (ModelManager.loginUser.regionId == 2)) {
        //     this.state.oaButtonVisible = true;
        //     this.setState({
        //         oaButtonVisible: this.state.oaButtonVisible,
        //     });
        // }
        this.setEditServicePhone();
        // console.log("setApp orderList", this.state);
        this.forceUpdate();
    }

    isZBDispatch = (empId) => {
        let empInfo = ModelManager.getStaffById(empId);
        if (empInfo.regionId === 2) {
            return true;
        }
        return false;
    };

    getFieldValue = () => {
        if (this.formRef) {
            return this.formRef.getFieldValue();
        } else {
            return {};
        }
    };

    getServerAppDate = () => {
        let url = global.ServerUrl + "fixApp/getServerAppDate";
        Net.get(url, res => {
            console.log("getServerAppDate", res)
            if (res.result === 0) {
                this.formRef.setFieldsValue({
                    createDate: moment(res.ret),
                });
            }
        }, error => {
        })
    };

    productName_changeHandler = (value) => {
        let Names = AutoProductNames.concat(ProductNames);
        console.log("productName_changeHandler Names", Names);
        if (Names.some(nItem => nItem === value)) {
            this.state.autoCoffeeInfoRowVisible = true;
        } else {
            this.state.autoCoffeeInfoRowVisible = false;
        }
        console.log("productName_changeHandler", this.state.autoCoffeeInfoRowVisible)
        this.setState({
            autoCoffeeInfoRowVisible: this.state.autoCoffeeInfoRowVisible,
        });
        this.forceUpdate();
    };

    onHideImageGroup = () => {
        console.log("onHideImageGroup")
        this.setState({ imageGroupVisible: false, imageGroup: [] }, () => {
            this.forceUpdate();
        })
    };

    showImage = (num) => {
        if (this.state.uploading) {
            return message.error(I18n.t("上传中，请稍候..."));
        }
        let app = this.getFieldValue();
        console.log("showImage", app);
        if (global.isHK) {
            // window.open(global.BaseImageUrl + app["photo" + num], "_blank");
            this.setState({
                imageType: num,
                imageVisible: true,
                imageUrl: num === 4 ? JSON.parse(app["photo" + num]) : app["photo" + num],
                imageUserName: app["photo" + num + "UserName"],
                imageUploadTime: app["photo" + num + "UploadTime"],
            }, () => {
                this.forceUpdate();
                console.log("imageUrl", this.state.imageUrl);
            });
            return;
        }
        if (num == 6) {
            if (app.photo6 && app.photo6 != "" && app.photo7 && app.photo7 != "") {
                let imageGroup = [
                    {
                        url: app.photo6, userName: app.photo6UserName,
                        uploadTime: moment(global.getDate(app.photo6UploadTime)).format("YYYY.MM.DD HH:mm:ss"),
                    }
                ];
                if (app.photo7) {
                    imageGroup.push({
                        url: app.photo7, userName: app.photo7UserName,
                        uploadTime: moment(global.getDate(app.photo7UploadTime)).format("YYYY.MM.DD HH:mm:ss")
                    })
                }
                this.setState({
                    imageGroupVisible: true,
                    imageGroup: imageGroup,
                }, () => {
                    this.forceUpdate();
                });
                return;
            }
        }
        // let imageUrl = "http://afs.idelonghi.cn/view.html?id=";
        let imageUrl = 'https://' + window.location.host + '/#/view?id=';
        if (global.isDev) {
            imageUrl = 'http://' + window.location.host + '/#/view?id=';
        }
        let name = app.photo2UserName == null ? '' : app.photo2UserName;
        let strUrl = imageUrl + app.photo2 + "&name=" + name + "&type=2";
        if (num === 1) {
            name = app.photo1UserName == null ? '' : app.photo1UserName;
            strUrl = imageUrl + app.photo1 + "&name=" + name + "&type=1";
            if (app.photo1UploadTime) {
                strUrl += "&uploadTime=" + app.photo1UploadTime;
            }
        } else if (num == 2) {
            name = app.photo2UserName == null ? '' : app.photo2UserName;
            strUrl = imageUrl + app.photo2 + "&name=" + name + "&type=2";
            if (app.photo2UploadTime) {
                strUrl += "&uploadTime=" + app.photo2UploadTime;
            }
        } else if (num == 3) {
            name = app.photo3UserName == null ? '' : app.photo3UserName;
            strUrl = imageUrl + app.photo3 + "&name=" + name + "&type=15";
            if (app.photo3UploadTime) {
                strUrl += "&uploadTime=" + app.photo3UploadTime;
            }
        } else if (num == 5) {
            name = app.photo5UserName == null ? '' : app.photo5UserName;
            strUrl = imageUrl + app.photo5 + "&name=" + name + "&type=5";
            if (app.photo5UploadTime) {
                strUrl += "&uploadTime=" + app.photo5UploadTime;
            }
        } else if (num == 6) {
            name = app.photo6UserName == null ? '' : app.photo6UserName;
            strUrl = imageUrl + app.photo6 + "&name=" + name + "&type=6";
            if (app.photo6UploadTime) {
                strUrl += "&uploadTime=" + app.photo6UploadTime;
            }
        } else if (num == 8) {
            name = app.photo8UserName == null ? '' : app.photo8UserName;
            strUrl = imageUrl + app.photo8 + "&name=" + name + "&type=8";
            if (app.photo8UploadTime) {
                strUrl += "&uploadTime=" + app.photo8UploadTime;
            }
        }
        console.log("showImage strUrl", strUrl)
        window.open(strUrl, "_blank");
    };

    showFlowerPhoto = (item) => {
        // 显示条件：1、上门售后工单 2、产品名称为：自动咖啡机、泵压式咖啡机EC系列
        const Names = AutoProductNames.concat(ProductNames);
        if (item.type === AppStatus.TYPE_VISIT && item.nature === 2 && Names.includes(item.productName)) {
            return true;
        }
        return false;
    }

    handlePhotoChange = (info, photoType) => {
        if (info.file.status === "uploading") {
            console.log("handlePhotoChange", info);
            this.setState({ uploading: true, imageVisible: false }, () => {
                this.forceUpdate();
            })
            return;
        }
        if (info.file.status === "error") {
            console.log(info.file);
            this.setState({ uploading: false, imageVisible: false }, () => {
                this.forceUpdate();
            })
            return message.error(I18n.t("图片上传失败"));
        }
        if (info.file.status === "done") {
            if (info.file.response && 0 === info.file.response.result) {
                console.log('file', info.file);
                this.updatePhoto(photoType, info.file.response.ret);
            }
            this.setState({ uploading: false }, () => {
                this.forceUpdate();
            })
        }
    };

    updatePhoto = (photoType, photoName) => {
        if (photoType === 1) {
            this.formRef.setFieldsValue({ photo1: photoName, photo1UserName: ModelManager.loginUser.empName });
            this.setState({ photo1LabelVisible: false, photo1BtnVisible: true }, () => {
                this.forceUpdate();
            });
        } else if (photoType === 4) {
            let app = this.getFieldValue();
            let photo4Arr = app.photo4 ? JSON.parse(app.photo4) : [];
            photo4Arr.push(photoName);
            if (photo4Arr.length > 3) {
                photo4Arr = photo4Arr.slice(photo4Arr.length - 3, photo4Arr.length);
            }
            console.log("photo4Arr", photo4Arr)
            this.formRef.setFieldsValue({ photo4: JSON.stringify(photo4Arr), photo4UserName: ModelManager.loginUser.empName });
            this.setState({ photo4BtnVisible: true }, () => {
                this.forceUpdate();
            });
        }
    };

    showWarrantSearchView = () => {
        // DataReqEvent.SEARCH_WARRANT_INTIME
        let warrantInputText = this.warrantInput.input.value || "";
        if (warrantInputText.trim() == "") {
            return message.warning(I18n.t("请输入搜索条件"));
        }
        let app = this.getFieldValue();
        let url = global.ServerUrl + "warrant/searchByCodeInTime";
        console.log("SEARCH_WARRANT_INTIME warrantInputText", warrantInputText, app.createDate);
        Net.post(url, { code: warrantInputText, createDate: app.createDate }, res => {
            console.log("SEARCH_WARRANT_INTIME", res);
            if (res.result === 0) {
                this.setState({ isModalVisible: true, warrantList: res.ret });
            } else {
            }
            this.forceUpdate();
        }, error => {
            console.log("SEARCH_WARRANT_INTIME", error);
        })
    };

    setWarrantInfo = () => {
        this.state.selectedWarrantInfo = this.state.selectedWarrant;
        this.state.isModalVisible = false;
        this.state.warrantTxtVisible = true;
        this.state.warrantTxt = global.transLabel("订单编号") + this.state.selectedWarrantInfo.orderNo + "," + global.transLabel("手机号") + this.state.selectedWarrantInfo.phone + "," + global.transLabel("购买型号") + this.state.selectedWarrantInfo.model + "," + global.transLabel("购买数量") + this.state.selectedWarrantInfo.amount + "," + global.transLabel("购买平台") + this.state.selectedWarrantInfo.platform + "," + global.transLabel("机身编号") + this.state.selectedWarrantInfo.code;
        if (this.state.selectedWarrantInfo.buyTime) {
            var x = moment(new Date(global.getDate(this.state.selectedWarrantInfo.buyTime))).format(dateFormat);
            if (x.indexOf("1899") == 0 || x.indexOf("1900") == 0)
                x = "";
            this.state.warrantTxt += "," + global.transLabel("购买时间") + x;
        }
        if (this.state.selectedWarrantInfo.uploadTime) {
            this.state.warrantTxt += "," + global.transLabel("注册时间") + moment(new Date(global.getDate(this.state.selectedWarrantInfo.uploadTime))).format(dateFormat);
        }
        this.setState({
            selectedWarrantInfo: this.state.selectedWarrantInfo,
            isModalVisible: this.state.isModalVisible,
            warrantTxtVisible: this.state.warrantTxtVisible,
            warrantTxt: this.state.warrantTxt,
        });
        this.forceUpdate();
    };

    cancelSelectWarrant = () => {
        this.setState({
            isModalVisible: false,
            selectedWarrant: null,
        });
        this.forceUpdate();
    };

    onEditUserTel = (userTel) => {
        let app = this.getFieldValue();
        const statusArr = [AppStatus.S_DRAFT, AppStatus.S_TOBESEND1, AppStatus.S_TOBESEND2, , AppStatus.S_TOBEREPAIRED];
        if (app.userTel.length > 3 && statusArr.some(sitem => sitem === app.status)
            // &&app.status == AppStatus.S_DRAFT
        ) {	//8位号码以上才能查到
            console.log("onEditUserTel")
            // var event: FixAppEvent = new FixAppEvent(FixAppEvent.GET_FIXAPP_USER);
            // event.appNo = userTel.text;
            // dispatchEvent(event);
            this.getAppByUserTel(userTel);
        }
    };

    getAppByUserTel = (tel) => {
        if (!tel || tel.length <= 3) {
            return;
        }
        let url = global.ServerUrl + "fixApp/getAppByUserTel";
        Net.post(url, { tel }, res => {
            if (res.result === 0) {
                let app = this.getFieldValue();
                if (res.tel === app.userTel)
                    this.setState({ userList: res.ret, userModalVisible: true });
                this.forceUpdate();
            } else {
                this.setState({ userList: [] });
            }
        }, error => {
            console.log("getAppByUserTel", error);
            this.setState({ userList: [] });
        })
    };

    userTelChange = (userTel) => {
        this.setState({ userTel });
        if (userTel.length > 3) {	//8位号码以上才能查到
            // var event: FixAppEvent = new FixAppEvent(FixAppEvent.GET_FIXAPP_USER);
            // event.appNo = userTel.text;
            // dispatchEvent(event);
            // this.getAppByUserTel(userTel);
        }
    };

    setUserInfo = () => {
        let user = this.state.selectedUser;
        if (user != null) {
            this.provinceSelectedIndex = 0;
            for (let i = 0; i < global.provinceList.length; i++) {
                const element = global.provinceList[i];
                if (user.userProvince == element) {
                    this.provinceSelectedIndex = i;
                    break;
                }
            }
            let userCityDataProvider = global.cityList[this.provinceSelectedIndex];
            this.setState({ userCityDataProvider });
            this.formRef.setFieldsValue({
                userName: user.userName,
                userCorp: user.userCorp,
                userTel: user.userTel,
                userExt: user.userExt,
                userProvince: user.userProvince,
                userCity: user.userCity,
                userAddress: user.userAddress,
            });

        }
        this.setState({ userModalVisible: false });
        this.forceUpdate();
    };
    setUserAllInfo = () => {
        let user = this.state.selectedUser;
        if (user != null) {
            this.setProductName(user.productName);
            this.formRef.setFieldsValue({
                userName: user.userName,
                userCorp: user.userCorp,
                userTel: user.userTel,
                userExt: user.userExt,
                userProvince: user.userProvince,
                userCity: user.userCity,
                userAddress: user.userAddress,
                brand: user.brand,
                productType: user.productType,
                productCode: user.productCode,
                purchaseDate: user.purchaseDate ? moment(global.getDate(user.purchaseDate)) : null,
            });
            this.provinceSelectedIndex = 0;
            for (let i = 0; i < global.provinceList.length; i++) {
                const element = global.provinceList[i];
                if (user.userProvince == element) {
                    this.provinceSelectedIndex = i;
                    break;
                }
            }
            let userCityDataProvider = global.cityList[this.provinceSelectedIndex];
            this.setState({ userCityDataProvider });
        }
        this.setState({ userModalVisible: false });
        this.forceUpdate();
    };

    isUserExt = (value) => {
        let Regx = /^[0-9*#\-]*$/;
        if (Regx.test(value)) {
            return true;
        }
        else {
            return false;
        }
    };

    provinceChangeHandler = (value) => {
        this.provinceSelectedIndex = 0;
        for (let i = 0; i < global.provinceList.length; i++) {
            const element = global.provinceList[i];
            if (element === value) {
                this.provinceSelectedIndex = i;
                break;
            }
        }
        let userCityDataProvider = global.cityList[this.provinceSelectedIndex];
        this.setState({ userCityDataProvider });
        // this.formRef.setFieldsValue({ userCity: null });
        this.formRef.setFieldsValue({ userCity: userCityDataProvider.length > 0 ? userCityDataProvider[0] : null });
    };

    onFieldsChange = (changedFields, allFields) => {
        if (this.props.onFieldsChange) {
            this.props.onFieldsChange(changedFields, allFields);
        }
        if (changedFields.length > 0) {
            let { name, value } = changedFields[0];
            console.log("onFieldsChange", name, value)
            this.state[name[0]] = value;
            if (name.indexOf("formNo") !== -1) {
                if (!global.isNum(value)) {
                    value = value.replace(/\D/g, '');
                    this.formRef.setFieldsValue({ formNo: value });
                    this.setState({ showFormNoAlarm: true })
                } else {
                    this.setState({ showFormNoAlarm: false })
                }
            } else if (name.indexOf("userTel") !== -1) {
                if (!global.isNum(value)) {
                    value = value.replace(/\D/g, '');
                    this.formRef.setFieldsValue({ userTel: value });
                    this.setState({ showUserTelAlarm: true })
                } else {
                    this.setState({ showUserTelAlarm: false })
                }
            } else if (name.indexOf("userExt") !== -1) {
                if (!this.isUserExt(value)) {
                    value = value.replace(/[^0-9*#-]/g, '');
                    this.formRef.setFieldsValue({ userExt: value });
                    this.setState({ showUserExtAlarm: true })
                } else {
                    this.setState({ showUserExtAlarm: false })
                }
            } else if (name.indexOf("otherFee") !== -1) {
                if (!global.checkOtherFee(value)) {
                    // value = value.replace(/[^0-9.\-]/g, '');
                    // this.formRef.setFieldsValue({ otherFee: value });
                    this.setState({ showOtherFeeAlarm: true })
                } else {
                    this.setState({ showOtherFeeAlarm: false })
                }
            } else if (name.indexOf("EmergencyOrder") !== -1) {
                this.formRef.setFieldsValue({ isEmergency: value });
            }
            if (name.indexOf("productCode") !== -1 && !global.checkNumAndLetter(value)) {
                value = value.replace(/[^\w\/]/ig, '');
                this.formRef.setFieldsValue({ productCode: value });
                this.setState({ showProductCodeAlarm: true })
            } else {
                this.setState({ showProductCodeAlarm: false })
            }
            this.forceUpdate();
        }

    };

    setEditServicePhone = () => {
        if (global.isHK) return;
        let { canEditServicePhone } = this.state;
        canEditServicePhone = true;
        let values = this.getFieldValue();
        // (1) 总部指派的 上门工单 可以勾选
        if (values.type === AppStatus.TYPE_VISIT) {
            if (values.assigned) {
                canEditServicePhone = true;
            } else {
                canEditServicePhone = false;
            }
            // （2）产品名称为 “自动咖啡机” 和“ 泵压式咖啡机EC系列” 维修站自己开单，也可以勾选“电话完成”
            let arr = AutoProductNames.concat(ProductNames);
            if (arr.includes(values.productName)) {
                canEditServicePhone = true;
            }
        }
        if (!canEditServicePhone) {
            this.formRef.setFieldsValue({ servicePhone: null });
        }
        this.state.canEditServicePhone = canEditServicePhone;
        this.setState({
            canEditServicePhone: this.state.canEditServicePhone,
        });
    };

    onDisableEditServicePhone = () => {
        let app = this.getFieldValue();
        let { canEdit, canEditUser, canEditServicePhone, AgencyCanEdit } = this.state;
        if (!((canEdit || canEditUser || app.isReturn || AgencyCanEdit) && canEditServicePhone && app.nature == 2)) {
            return true;
        }
        return false;
    };

    onChangePhone = () => {
        /**
         * 2021.11.19
         * 维修站账户勾选电话完成，后保修凭证，自动跳转“总部凭证确认”并锁定，不可修改。
         * 总部账户填写完成后，总部可对工单直接做“维修完成”步骤。
         *（维修完成要做系统判定，除了基础产品信息顾客信息必填以外，对总部凭证填写的三个字段也做必填判定。
         * 日期，默认开单日期，不需修改，）
         * 2022.10.19
         * 点【电话完成】后 ，除了维修费，其他跟费用有关的 全部清零
         * 2023.01.30
         * 电话完成的话  预约相关的 两个时间 自动清除
         */
        let app = this.formRef.getFieldValue();
        if (app.servicePhone) {
            this.state.fixFeeEditable = false;
            this.formRef.setFieldsValue({
                fixFee: 10, fixType: 4,
                guarantee: 9,
                guaranteeBuyDate: app.createDate,
                visitFee: null, distance: null,
                appointment: null,
            });
            this.onSelectGuarantee();
            // this.formRef.setFieldsValue({ guaranteeBuyDate: moment() });
        } else {
            this.state.fixFeeEditable = true;
            this.formRef.setFieldsValue({ fixFee: null, fixType: null });
            this.setFixFee();
        }
        // app = this.formRef.getFieldValue();
        // console.log("onChangePhone", app)
        this.setState({ fixFeeEditable: this.state.fixFeeEditable });
        this.forceUpdate();
    };

    // 20240708 保内保外上门费用分开计算
    distance_changeHandler = () => {
        let app = this.getFieldValue();
        let visitFee = null;
        if (app.distance != null) {
            var num = Number(app.distance)
            let visitFeeInfo = [];
            if (app.noGuarantee) { // 非保修
                visitFeeInfo = VisitFeeMap["noGuarantee"];
            } else {
                visitFeeInfo = VisitFeeMap["Guarantee"];
                // if (num == 0) {
                //     visitFee = 0;
                // } else if (num > 0 && num <= 30) {
                //     visitFee = 30;
                // } else if (num > 30 && num <= 60) {
                //     visitFee = 60;
                // } else if (num > 60 && num <= 90) {
                //     visitFee = 90;
                // } else if (num > 90 && num <= 120) {
                //     visitFee = 120;
                // } else if (num > 120 && num <= 150) {
                //     visitFee = 150;
                // } else if (num > 150 && num <= 180) {
                //     visitFee = 180;
                // } else if (num > 180 && num <= 210) {
                //     visitFee = 210;
                // } else if (num > 210) {
                //     visitFee = 210;
                // }
            }
            for (let i = 0; i < visitFeeInfo.length; i++) {
                const { min, max, fee } = visitFeeInfo[i];
                console.log("distance_changeHandler fee", num, min, max, fee)
                if (min != null && max != null) {
                    if (num > min && num <= max) {
                        visitFee = fee;
                        break;
                    }
                } else if (min != null) {
                    if (num > min) {
                        visitFee = fee;
                        break;
                    }
                } else if (max != null) {
                    if (num <= max) {
                        visitFee = fee;
                        break;
                    }
                }
            }
            console.log("distance_changeHandler distance", num, visitFee)
        }
        this.formRef.setFieldsValue({ visitFee });
    };

    fixType_changeHandler = () => {
        let app = this.getFieldValue();
        if (app.fixType === AppStatus.TYPE_MACHINE) {//更换整机
            this.state.hboxFixColorVisible = true;
            this.state.hboxFixHomeVisible = true;
        } else {
            this.state.hboxFixColorVisible = false;
            this.state.hboxFixHomeVisible = false;
        }
        this.setState({
            hboxFixColorVisible: this.state.hboxFixColorVisible,
            hboxFixHomeVisible: this.state.hboxFixHomeVisible,
        });
        if (app.fixType !== AppStatus.TYPE_PART) {//更换配件
            // var num = app.partsList.length;
            // if (num >= 1) {
            //     for (var i: int = 0; i < num; i++) {
            //         grid.removeChildAt(grid.getChildIndex(GridRow1) - 1);
            //     }
            // }
            // partsIndex = 0;
            // app.partsList.removeAll();
            this.formRef.setFieldsValue({ partsList: null });
            this.computePartsMoney();
        }
        this.forceUpdate();
    };

    setSendButton = () => {
        let { doc } = this.state;
        // console.log("setSendButton", doc, this.props.doc)
        let values = this.getFieldValue();
        if ((doc == null || !doc.id) && values.type === AppStatus.TYPE_ACCESS && ModelManager.loginUser.regionId !== 2) {//站点接修
            this.state.sendButton1Visible = true;
        } else if (values.type === AppStatus.TYPE_ACCESS && values.status === AppStatus.S_ASSIGN) {
            this.state.sendButton1Visible = true;
        } else {
            this.state.sendButton1Visible = false;
        }
        if ((doc == null || !doc.id) && values.type === 10 && ModelManager.loginUser.regionId !== 2) {//快递寄修
            this.state.sendButton2Visible = true;
        } else if (values.type === 10 && values.status === AppStatus.S_ASSIGN) {
            this.state.sendButton1Visible = true;
        } else {
            this.state.sendButton2Visible = false;
        }
        this.setState({
            sendButton1Visible: this.state.sendButton1Visible,
            sendButton2Visible: this.state.sendButton2Visible
        });
        this.forceUpdate();
    }

    setAppointButton = () => {
        let values = this.getFieldValue();
        if (values.type === AppStatus.TYPE_VISIT)	//需上门处理
            this.state.appointButtonEnabled = true;
        else
            this.state.appointButtonEnabled = false;
        this.setState({ appointButtonEnabled: this.state.appointButtonEnabled });
        this.forceUpdate();
    };

    setFinishButton = () => {
        let values = this.getFieldValue();
        // TYPE_ACCESS: 2 接修; TYPE_VISIT: 1 上门处理; S_APPOINT: 预约上门,
        if (values.servicePhone || values.type === AppStatus.TYPE_ACCESS || values.type === 10 || values.type === 9 || (values.type === AppStatus.TYPE_VISIT && values.status === AppStatus.S_APPOINT))
            this.state.finishButtonEnabled = true;
        else
            this.state.finishButtonEnabled = false;
        this.setState({ finishButtonEnabled: this.state.finishButtonEnabled });
        this.forceUpdate();
    };

    setFixFee() {
        let app = this.getFieldValue();
        // if (this.product == null)
        this.findProduct(app.productType);
        if (!app.servicePhone) {
            if ((app.type < 3 || app.type == 10 || app.type == 9) && this.product != null) {
                if (app.warranty == 1)
                    this.formRef.setFieldsValue({ fixFee: this.product.freeCharge });//app.fixFee = product.freeCharge;
                else if (app.warranty == 2)
                    this.formRef.setFieldsValue({ fixFee: this.product.standard });//app.fixFee = product.standard;
                else if (app.warranty == 3)
                    this.formRef.setFieldsValue({ fixFee: this.product.detection });//app.fixFee = product.detection;
            } else
                this.formRef.setFieldsValue({ fixFee: null });//app.fixFee = NaN;

            // fixFee.text = isNaN(app.fixFee) ? '' : app.fixFee.toString();
        }
    }
    onSelectType = (type) => {
        let app = this.getFieldValue();
        // if (this.state.typeList.length < 6) {
        //     if (app.type == 10)
        //         this.state.typeSelectedIndex = 4;
        //     if (app.type == 9)
        //         this.state.typeSelectedIndex = 3;
        // }
        //setWorkerButton();	//指派维修人按钮
        this.setAppointButton();	//预约上门按钮
        this.setFinishButton();
        this.setSendButton();
        // if (!app.id) {
        if (app.type === AppStatus.TYPE_ACCESS) {
            app.status = AppStatus.S_TOBESEND1;
        } else if (app.type === AppStatus.TYPE_EXPRESS) {
            app.status = AppStatus.S_TOBESEND2;
        } else {
            app.status = AppStatus.S_DRAFT;
        }
        // }
        // 20220830 服务类型从【上门】改为【接修】【上门公里数】和【上门费】清零 暗掉不填
        if (app.type !== AppStatus.TYPE_VISIT) {
            this.formRef.setFieldsValue({ distance: null, visitFee: null });
        }
        console.log("app.status", app.status)
        this.formRef.setFieldsValue({ notice: 0, status: app.status });
        this.state.noticeEnabled = false;
        this.setState({ noticeEnabled: false });
        if (app.type === AppStatus.TYPE_ACCESS || app.type === 9 || app.type === 10)	//接修
            this.formRef.setFieldsValue({ notice: 1 });
        ///?else if(app.type == AppStatus.TYPE_VISIT && app.workerId == ModelManager.loginUser.empId)
        if (app.type === AppStatus.TYPE_VISIT && ModelManager.loginUser.regionId === app.stationNo
            && ModelManager.loginUser.role === Staff.ROLE_STATION_ADMIN
            && this.appIsUndone(app)
        ) {
            this.state.distanceEditable = true;
        } else {
            this.state.distanceEditable = false;
        }
        this.setState({ distanceEditable: this.state.distanceEditable });
        this.setFixFee();
        this.setEditServicePhone();
    };

    onNatureGroupChange = () => {
        let app = this.getFieldValue();
        if (app.nature === 1) {
            this.formRef.setFieldsValue({
                guarantee: 10, servicePhone: null,
                fixFee: null, fixType: null,
                // outletFailure: null
            });
            this.onSelectGuarantee();
        } else if (app.nature === 2) {
            this.formRef.setFieldsValue({ guarantee: null });
            this.onSelectGuarantee();
        } else if (app.nature === 3) {
            this.formRef.setFieldsValue({
                guarantee: 9, servicePhone: null,
                fixFee: null, fixType: null,
                // outletFailure: null
            });
            this.onSelectGuarantee();
        }
    };

    PartsAmount = () => {
        let values = this.getFieldValue();
        values.partsList = values.partsList || [];
        var total = 0;
        var hasItem = false;
        for (let i = 0; i < values.partsList.length; i++) {
            const p = values.partsList[i];
            if (isNaN(p.amount))
                continue;
            // total += p.amount;
            total = total.add(p.amount);
            // hasItem = true;
        }
        // let _PartsAmount;
        // if (hasItem && !isNaN(total))
        //     _PartsAmount = total.toString();
        // else
        //     this._PartsAmount = '';
        values._PartsAmount = total;
        // return _PartsAmount;
        this.formRef.setFieldsValue({ _PartsAmount: total });
        this.forceUpdate();
    };

    getPartsMoney = (part) => {
        if (part.price == null || part.amount == null || isNaN(part.price) || isNaN(part.amount)) {
            // _partsMoney = '';
            return NaN;
        }

        // _partsMoney = part.price.mul(part.amount).toFixed(2);// _partsMoney = (part.price * part.amount).toFixed(2);
        return part.price.mul(part.amount);
    };

    PartsMoney = () => {
        let values = this.getFieldValue();
        values.partsList = values.partsList || [];
        let total = 0;
        let hasItem = false;
        for (let i = 0; i < values.partsList.length; i++) {
            let p = values.partsList[i];
            p.partsMoney = this.getPartsMoney(p);
            if (isNaN(p.partsMoney))
                continue;
            total = total.add(p.partsMoney);
            hasItem = true;
        }

        if (hasItem && !isNaN(total))
            this.formRef.setFieldsValue({ _PartsMoney: total.toFixed(2) });
        else
            this.formRef.setFieldsValue({ _PartsMoney: null });

        values.partsFee = total;
        this.formRef.setFieldsValue({ partsFee: total });
        this.forceUpdate();
    };

    computePartsMoney = () => {
        this.PartsAmount();
        this.PartsMoney();
    };

    onSelectGuarantee() {
        let app = this.getFieldValue();
        this.clearOrderChildren();
        // this.state.guaranteeOrderInputText = "";
        // this.formRef.setFieldsValue({ ecomOrderCode: "", guaranteeBuyDate: null });
        // if (!(app.guarantee === 9 || app.guarantee === 12)) { // 9: "总部凭证确认" 12: "总部保修确认"
        this.formRef.setFieldsValue({ guaranteeBuyDate: null, zbOption: null });
        // }
        if (app.guarantee === 9 && app.servicePhone) {
            this.formRef.setFieldsValue({ guaranteeBuyDate: app.createDate, });
        }
        console.log("onSelectGuarantee", app.guarantee)
        if (app.guarantee > 0) {
            app.warranty = 1;
            this.state.warrantVBoxEnabled = true;
            if (!global.isHK) {
                // 20220630 保修依据里的：港台凭证，海外机身号，售前机 三项 没有延保
                // if (app.guarantee === 5 || app.guarantee === 8 || app.guarantee === 10) {
                if ([5, 8, 10].includes(app.guarantee)) {
                    // app.warranty = 2;
                    this.formRef.setFieldsValue({ isWarrant: false, warrantInfo: "" });
                    this.state.warrantVBoxEnabled = false;
                    this.setState({
                        warrantVBoxEnabled: false,
                        warrantHBoxVisible: false,
                        warrantTxtVisible: false,
                        warrantTxt: "",
                    });
                }
            }
            app.partsList = app.partsList || [];
            app.partsList.forEach(parts => {//需修改价格
                if (parts.code.length > 4) {
                    for (let i = 0; i < ModelManager.accessoryList.length; i++) {
                        const p = ModelManager.accessoryList[i];
                        if (p.code === parts.code) {
                            if (app.warranty === 1)	//保修
                                parts.price = p.price || 0;
                            else
                                parts.price = p.charge || 0;
                            break;
                        }
                    }
                }
            });
            this.formRef.setFieldsValue({ warranty: app.warranty, partsList: app.partsList });
            this.setFixFee();
            this.computePartsMoney();
        }
        // this.state.zbOptionVisible = false;
        // this.state.zbConfirmInputVisible = false;
        // this.state.zbConfirmLabelVisible = false;
        this.state.photo1BtnVisible = false;
        this.state.photo1LabelVisible = false;
        // this.state.jdOrderInputVisible = false;
        // this.state.kpUnitRowVisible = false;
        this.setState({
            // zbOptionVisible: false,
            // zbConfirmInputVisible: false,
            // zbConfirmLabelVisible: false,
            photo1BtnVisible: false,
            photo1LabelVisible: false,
            // jdOrderInputVisible: false,
            // kpUnitRowVisible: false,
        });
        /**
         * [
         *     { 0: "请选择" }, { 1: "国内发票" }, { 2: "订单号查询" }, { 3: "手机号查询" },
         *     { 4: "保修卡号查询" }, { 5: "港澳地区凭证" }, { 6: "其他凭证上传（需总部确认）" },
         *     { 7: "出仓日期保修" }, { 8: "海外机身号查询" }, { 9: "总部凭证确认" },
         *     { 10: "售前机" }, { 11: "京东自营" }, { 12: "总部保修确认" }
         * ]
         * 5、6、9、11、12 需总部确认
         */

        if (app.guarantee === 1 || app.guarantee === 5) {
            // if (app.guarantee === 1) { //国内发票
            //     this.state.kpUnitRowVisible = true;
            // }
            this.state.guaranteeBuyDateVisible = true;
            // this.state.guaranteeOrderInputVisible = false;
            // this.state.orderSearchBtnVisible = false;
            if (app.guarantee === 5) { //港澳地区凭证
                // this.state.zbConfirmLabelVisible = true;
                // this.state.zbConfirmInputVisible = true;
                // this.state.zbConfirmInputEnabled = false;
                // if (ModelManager.loginUser.role === Staff.ROLE_ADMIN || ModelManager.loginUser.regionId === 2) {
                //     this.state.zbConfirmInputEnabled = true;
                // }
            }
            if (app.photo1 == null || app.photo1 === "") {
                this.state.photo1LabelVisible = true;
            } else {
                this.state.photo1BtnVisible = true;
            }
            this.setState({
                // kpUnitRowVisible: this.state.kpUnitRowVisible,
                guaranteeBuyDateVisible: this.state.guaranteeBuyDateVisible,
                // guaranteeOrderInputVisible: this.state.guaranteeOrderInputVisible,
                // orderSearchBtnVisible: this.state.orderSearchBtnVisible,
                // zbConfirmLabelVisible: this.state.zbConfirmLabelVisible,
                // zbConfirmInputVisible: this.state.zbConfirmInputVisible,
                // zbConfirmInputEnabled: this.state.zbConfirmInputEnabled,
                photo1LabelVisible: this.state.photo1LabelVisible,
                photo1BtnVisible: this.state.photo1BtnVisible,
            });
            // } else if (app.guarantee === 2 || app.guarantee === 3 || app.guarantee === 4 || app.guarantee === 8) { // "订单号查询", "手机号查询", "保修卡号查询", "海外机身号查询"
        } else if ([2, 3, 4].includes(app.guarantee)) { // "订单号查询", "手机号查询", "保修卡号查询"
            // this.state.guaranteeOrderInputVisible = true;
            // this.state.jdOrderInputVisible = false;
            // this.state.orderSearchBtnVisible = true;
            this.state.guaranteeBuyDateVisible = false;
            if (app.guarantee === 4 || app.guarantee === 2) { // 2: "订单号查询" 4: "保修卡号查询"
                if (app.photo1 == null || app.photo1 == "") {
                    this.state.photo1LabelVisible = true;
                } else {
                    this.state.photo1BtnVisible = true;
                }
            }
            this.setState({
                // jdOrderInputVisible: this.state.jdOrderInputVisible,
                // guaranteeOrderInputVisible: this.state.guaranteeOrderInputVisible,
                // orderSearchBtnVisible: this.state.orderSearchBtnVisible,
                guaranteeBuyDateVisible: this.state.guaranteeBuyDateVisible,
                photo1LabelVisible: this.state.photo1LabelVisible,
                photo1BtnVisible: this.state.photo1BtnVisible,
            });
        } else if (app.guarantee === 6) { //其他凭证上传（需总部确认）
            this.state.guaranteeBuyDateVisible = true;
            // this.state.guaranteeOrderInputVisible = false;
            // this.state.orderSearchBtnVisible = false;
            // this.state.zbOptionVisible = true;
            // this.state.zbOptionEnabled = false;
            // if (ModelManager.loginUser.role === Staff.ROLE_ADMIN || ModelManager.loginUser.regionId === 2) {
            //     this.state.zbOptionEnabled = true;
            // }
            if (app.photo1 == null || app.photo1 === "") {
                this.state.photo1LabelVisible = true;
            } else {
                this.state.photo1BtnVisible = true;
            }
            this.setState({
                guaranteeBuyDateVisible: this.state.guaranteeBuyDateVisible,
                // guaranteeOrderInputVisible: this.state.guaranteeOrderInputVisible,
                // orderSearchBtnVisible: this.state.orderSearchBtnVisible,
                // zbOptionVisible: this.state.zbOptionVisible,
                // zbOptionEnabled: this.state.zbOptionEnabled,
                photo1LabelVisible: this.state.photo1LabelVisible,
                photo1BtnVisible: this.state.photo1BtnVisible,
            });
        } else {
            // this.state.guaranteeOrderInputVisible = false;
            // this.state.orderSearchBtnVisible = false;
            this.state.guaranteeBuyDateVisible = false;
            if (app.guarantee === 9 || app.guarantee === 12) { // 9: "总部凭证确认" 12: "总部保修确认"
                // this.state.zbConfirmLabelVisible = true;
                // this.state.zbConfirmInputVisible = true;
                // this.state.zbConfirmInputEnabled = false;
                // if (ModelManager.loginUser.role === Staff.ROLE_ADMIN || ModelManager.loginUser.regionId === 2) {
                //     this.state.zbConfirmInputEnabled = true;
                // }
                // this.state.zbOptionVisible = true;
                // this.state.zbOptionEnabled = false;
                // if (ModelManager.loginUser.role === Staff.ROLE_ADMIN || ModelManager.loginUser.regionId === 2) {
                //     this.state.zbOptionEnabled = true;
                // }
                /**
                 * 20220712 电话完成勾选   保修依据是自动跳转“总部凭证确认”
                 * 保修依据选项里的 日期 隐藏掉，后台数据 默认保存 开单日期
                 */
                if (!app.servicePhone) {
                    this.state.guaranteeBuyDateVisible = true;
                }
            }
            if (app.guarantee == 11) { // 京东自营
                // this.state.jdOrderInputVisible = true;
                // this.state.guaranteeOrderInputVisible = false;
                this.state.guaranteeBuyDateVisible = true;
                // this.state.zbConfirmLabelVisible = true;
                // this.state.zbConfirmInputVisible = true;
                // this.state.zbConfirmInputEnabled = false;
                // if (ModelManager.loginUser.role === Staff.ROLE_ADMIN || ModelManager.loginUser.regionId === 2) {
                //     this.state.zbConfirmInputEnabled = true;
                // }
            }
            this.setState({
                guaranteeBuyDateVisible: this.state.guaranteeBuyDateVisible,
                // guaranteeOrderInputVisible: this.state.guaranteeOrderInputVisible,
                // orderSearchBtnVisible: this.state.orderSearchBtnVisible,
                // zbConfirmLabelVisible: this.state.zbConfirmLabelVisible,
                // zbConfirmInputVisible: this.state.zbConfirmInputVisible,
                // zbConfirmInputEnabled: this.state.zbConfirmInputEnabled,
                // zbOptionVisible: this.state.zbOptionVisible,
                // zbOptionEnabled: this.state.zbOptionEnabled,
                // jdOrderInputVisible: this.state.jdOrderInputVisible,
            });
        }
        let tipInfo = "";
        if (app.guarantee === 1 || app.guarantee === 7 || app.guarantee === 8) {
            tipInfo = "该选项保修两年";
        } else if (app.guarantee == 5) {
            tipInfo = "该选项保修一年";
        }
        this.setState({ tipInfo });
        let code = app.productCode ? app.productCode.trim().toUpperCase() : "";
        if (code.length >= 17 || code.length === 9) {
            this.getPurchaseDate(code);
        }
    }

    onSelectZbOption = () => {
        let tipInfo = "";
        let app = this.getFieldValue();
        if (app.zbOption == 1 || app.zbOption == 2 || app.zbOption == 3) {
            tipInfo = "该选项保修两年";
        } else if (app.zbOption == 4) {
            tipInfo = "该选项保修一年";
        }
        this.setState({ tipInfo });
    };

    onSelectNoWarrantOption = (val) => {
        console.log(val);
        // var detail = ModelManager.noWarrantOptionList[val];
        this.setState({ noWarrantDetail: val });
        // console.log(detail);
        // let app = this.getFieldValue();
    };
    onIrisCodeDescChange = (val) => {
        console.log(val);
        let irisObj = {};
        ModelManager.IrisCodeList.forEach(function (iris) {
            if (iris.irisDefectCode == val) {
                irisObj = iris;
            }
        })
        console.log("onIrisCodeDescChange irisObj", irisObj)
        let irisCodeDesc = null;
        if (irisObj.extraInfo)
            irisCodeDesc = irisObj.extraInfo + ' - ' + irisObj.areaChs + ' - ' + irisObj.irisDefectDescChs;
        else
            irisCodeDesc = irisObj.areaChs + ' - ' + irisObj.irisDefectDescChs;
        this.setState({
            irisCode: irisObj.irisDefectCode,
            irisCodeDesc, irisObj
        });
        let app = this.getFieldValue();
        console.log("onIrisCodeDescChange", app)
    };
    searchOrderByTime = () => {
        let app = this.getFieldValue();
        this.state.selectedOrder = null;
        let url = global.ServerUrl + "order/searchByCodeInTime";
        Net.post(url, { code: app.ecomOrderCode, type: app.guarantee }, res => {
            this.state.isOrderModalVisible = true;
            if (res.result === 0) {
                res.ret = res.ret || [];
                // if (res.ret.length > 0) {
                //     this.state.isOrderModalVisible = true;
                // }
                if (res.ret.length === 1) {
                    this.state.selectedOrderInfo = res.ret[0];
                    this.state.tipInfo = this.state.selectedOrderInfo.note;
                    this.setState({
                        selectedOrderInfo: this.state.selectedOrderInfo,
                        tipInfo: this.state.tipInfo,
                    });
                }
                this.setState({
                    orderList: res.ret,
                });
            } else {
                this.setState({ orderList: [] });
            }
            this.forceUpdate();
        }, error => {
            console.log("searchOrderByTime", error);
            message.error(I18n.t("获取数据失败"))
            this.setState({ orderList: [] });
        })
    };

    searchOrderByIds = () => {
        let app = this.getFieldValue();
        console.log("searchOrderByIds", app)
        let url = global.ServerUrl + "order/searchOrderByIds";
        Net.post(url, { orderIds: app.orderIds }, res => {
            if (res.result === 0) {
                res.ret = res.ret || [];
                // if (res.ret.length > 0) {
                //     this.state.isOrderModalVisible = true;
                // }
                if (res.ret.length === 1) {
                    this.state.selectedOrder = res.ret[0];
                    this.state.selectedOrderInfo = res.ret[0];
                    this.state.tipInfo = this.state.selectedOrderInfo.note;
                    this.state.orderHeadDivVisible = true;
                }
                if (res.ret.length === 0) {
                    this.state.selectedOrder = null;
                    this.state.selectedOrderInfo = null;
                    this.state.tipInfo = "";
                    this.state.orderHeadDivVisible = false;
                }
                this.setState({
                    orderList: res.ret,
                    selectedOrder: this.state.selectedOrder,
                    selectedOrderInfo: this.state.selectedOrderInfo,
                    tipInfo: this.state.tipInfo,
                    orderHeadDivVisible: this.state.orderHeadDivVisible,
                });
            } else {
                this.setState({
                    orderList: [],
                    selectedOrder: null,
                    selectedOrderInfo: null,
                    tipInfo: "",
                    orderHeadDivVisible: false,
                });
            }
            this.forceUpdate();
        }, error => {
            console.log("searchOrderByIds", error);
            this.setState({
                orderList: [],
                selectedOrder: null,
                selectedOrderInfo: null,
                tipInfo: "",
                orderHeadDivVisible: false,
            });
        })
    };

    showOrderSearchView = () => {
        let app = this.getFieldValue();
        // let { guaranteeOrderInputText } = this.state;
        // if (guaranteeOrderInputText == null || guaranteeOrderInputText.trim() === "") {
        //     this.state.guaranteeOrderInputText = app.ecomOrderCode;
        // }
        if (app.ecomOrderCode == null || app.ecomOrderCode === "") {
            return message.warning(I18n.t("请输入搜索条件"));
        }
        this.clearOrderChildren();
        this.searchOrderByTime();
    };

    clearOrderChildren = () => {
        this.setState({ orderList: [], selectedOrder: null, orderHeadDivVisible: false });
    };

    onOrderInputChange = (value, tag) => {
        if (tag === "jd") {
            // this.state.jdOrderInputText = value;
            // this.setState({ jdOrderInputText: value });
        } else {
            // this.state.guaranteeOrderInputText = value;
            // this.setState({ guaranteeOrderInputText: value });
        }
        this.clearOrderChildren();
        this.forceUpdate();
    };

    onNoGuarantee = () => {
        let app = this.getFieldValue();
        console.log("onNoGuarantee", app.noGuarantee)
        if (this.state.guaranteeEnabled) {
            app.warranty = 2;
            this.formRef.setFieldsValue({ warranty: 2, guarantee: 0, isWarrant: false, warrantInfo: "" });
            this.state.warrantVBoxEnabled = false;
            this.state.warrantHBoxVisible = false;
            // warrant.selected = false;
            this.state.warrantTxtVisible = false;
            this.state.warrantTxt = "";
            this.state.guaranteeHBoxEnabled = false;
            // guarantee.selectedIndex = 0;
            this.setState({
                warrantVBoxEnabled: false,
                warrantHBoxVisible: false,
                warrantTxtVisible: false,
                warrantTxt: "",
                guaranteeHBoxEnabled: false,
                guaranteeEnabled: false,
            });
            if (!this.state.noWarrantDetail) {
                // this.setState({
                //     noWarrantDetail: ModelManager.noWarrantOptionList[0]
                // })
            }
            this.onSelectGuarantee();
            console.log(this.state.guaranteeEnabled)
        } else {
            app.warranty = 1;
            this.formRef.setFieldsValue({ warranty: 1 });
            this.state.guaranteeHBoxEnabled = true;
            this.state.guaranteeEnabled = true;
            this.setState({
                guaranteeHBoxEnabled: true,
                guaranteeEnabled: true,
            });
        }

        this.setFixFee();
        app.partsList = app.partsList || [];
        for (let i = 0; i < app.partsList.length; i++) { //需修改价格
            const parts = app.partsList[i];
            if (parts.code.length > 4) {
                for (let j = 0; j < ModelManager.accessoryList.length; j++) {
                    const p = ModelManager.accessoryList[j];
                    if (p.code === parts.code) {
                        if (app.warranty === 1)	//保修
                            parts.price = p.price || 0;
                        else
                            parts.price = p.charge || 0;
                        break;
                    }
                }
            }
        }
        this.formRef.setFieldsValue({ partsList: app.partsList });
        this.computePartsMoney();
        this.distance_changeHandler();
        this.forceUpdate();
    };

    onWarrant = () => {
        let app = this.formRef.getFieldValue();
        if (app.isWarrant) {
            // this.formRef.setFieldsValue({ isWarrant: 1 });
            // this.state.warrantHBoxVisible = true;
        } else {
            // this.formRef.setFieldsValue({ isWarrant: 0 });
            // this.state.warrantHBoxVisible = true;
        }
        this.setState({ warrantHBoxVisible: true });
    };

    // 输入了产品编号
    onEditProductCode = (productCode) => {
        let code = productCode ? productCode.trim().toUpperCase() : "";
        if (code.length >= 17 || code.length === 9) {	//需17-19位     新产品位数少，9位也查一次
            this.getPurchaseDate(code);
        } else {
            this.formRef.setFieldsValue({ purchaseDate: null });
        }
    };

    searchAutoCoffeeByCode = (code) => {
        let url = global.ServerUrl + "autoCoffee/searchAutocoffeeByCode";
        let data = { code: code };
        Net.post(url, data, (res) => {
            console.log("searchAutoCoffeeByCode", res)
            if (res.result === 0) {
                let purchaseDate = null;
                if (res.ret && res.ret.length > 0) {
                    let cof = res.ret[0];
                    if (cof.saleTime) {
                        purchaseDate = moment(global.getDate(cof.saleTime));
                    }
                }
                console.log("searchAutoCoffeeByCode purchaseDate", purchaseDate)
                this.formRef.setFieldsValue({ purchaseDate });
            }
        }, error => {
            console.log("searchAutoCoffeeByCode", error);
        })
    };

    getPurchaseDate = (code) => {
        if (code.length >= 17 || code.length === 9) {
            let app = this.getFieldValue();
            let url = global.ServerUrl + "autoCoffee/searchAutocoffeeByCode";
            if (app.guarantee === 8) { // 海外机身号查询
                url = global.ServerUrl + "overseas/searchOverseasByCode";
            }
            console.log("getPurchaseDate url", url)
            const data = { code: code };
            Net.post(url, data, (res) => {
                console.log("getPurchaseDate", res)
                if (res.result === 0) {
                    let purchaseDate = null;
                    if (res.ret && res.ret.length > 0) {
                        let cof = res.ret[0];
                        if (cof.saleTime) {
                            purchaseDate = moment(global.getDate(cof.saleTime));
                        }
                    }
                    console.log("getPurchaseDate purchaseDate", purchaseDate)
                    this.formRef.setFieldsValue({ purchaseDate });
                }
            }, error => {
                console.log("getPurchaseDate", error);
            })
        }
    }

    handleProductTypeSearch = (searchText) => {
        let options = [];
        if (searchText) {
            for (let i = 0; i < ModelManager.productList.length; i++) {
                const element = ModelManager.productList[i];
                if (element.model.indexOf(searchText.toUpperCase()) !== -1) {
                    options.push(element);
                }
            }
        } else {
            options = ModelManager.productList;
        }
        this.setState({ ProductTypeOptions: options });
    };

    onEditProductType = (value) => {
        let type = value ? value.trim().toUpperCase() : "";
        console.log("onEditProductType", type);
        if (type.length > 0) {
            let app = this.getFieldValue();
            this.findProduct(type);
            if (this.product !== null) {
                this.setProductName(this.product.name);
                this.state.productTipInfo = "";
                if (this.product.reserve == 1) {
                    this.state.productTipInfo = "可上门服务";
                }
                this.formRef.setFieldsValue({ brand: this.product.brand, productTypeForExcel: this.product.type });
                console.log("onEditProductType values", this.getFieldValue());
                this.setFixFee();
                this.setEditServicePhone();
            }
            // 20220614【EDG250】这个型号，不能选择：出仓日期保修
            if (type === "EDG250" && app.guarantee === 7) {
                this.formRef.setFieldsValue({ guarantee: null });
                this.onSelectGuarantee();
            }
        }
        this.setState({ productTipInfo: this.state.productTipInfo });
    }

    findProduct = (type) => {
        let app = this.getFieldValue();
        this.product = null;	//need reset product here
        if (type && type.length > 1) {
            for (let i = 0; i < ModelManager.productList.length; i++) {
                const p = ModelManager.productList[i];
                if (p.model === type) {
                    this.product = p;
                    console.log("findProduct", this.product)
                    return;
                }
            }
        }
        if (this.product == null) {
            let type2 = app.productType;
            if (type2 && type2.length > 1) {
                for (let i = 0; i < ModelManager.productList.length; i++) {
                    const p = ModelManager.productList[i];
                    if (p.model === type2) {
                        this.product = p;
                        console.log("findProduct", this.product)
                        return;
                    }
                }
            }
        }
    };

    setProductName = (name) => {
        name = name || "";
        name = name.trim();
        console.log("setProductName name", name)
        if (name !== null && name.length > 0) {
            for (let i = 0; i < ModelManager.modelList.length; i++) {
                const n = ModelManager.modelList[i];
                if (n === name || I18n.t(n) === name) {
                    this.formRef.setFieldsValue({ productName: name });
                    let Names = AutoProductNames.concat(ProductNames);
                    console.log("setProductName Names", Names)
                    if (Names.some(nItem => nItem === name)) {
                        this.setState({ autoCoffeeInfoRowVisible: true })
                    } else {
                        this.setState({ autoCoffeeInfoRowVisible: false })
                    }
                    break;
                }
            }
            if (!ModelManager.modelList.some(mItem => mItem === name)) {
                ModelManager.modelList.push(name);
            }
            // ModelManager.modelList.push(name);
            // this.formRef.setFieldsValue({ productName: name });
            this.formRef.setFieldsValue({ productName: I18n.t(name) });
            this.forceUpdate();
        }
    };

    onRpType = (rpType) => {
        let rpDetailTypeList = [];
        if (rpType === 1) {
            rpDetailTypeList = ModelManager.rewardList;
        } else if (rpType === 2) {
            rpDetailTypeList = ModelManager.punishList;
        } else {
            rpDetailTypeList = [];
        }
        this.formRef.setFieldsValue({ rpDetailType: null, rpMoney: null });
        this.setState({ rpDetailTypeList });
    };

    onRpDetailChange = (value) => {
        let { rpDetailTypeList } = this.state;
        let rpMoneyEditable = false;
        let rpMoney = null;
        let selectedLabel = null;
        rpDetailTypeList = rpDetailTypeList || [];
        for (let i = 0; i < rpDetailTypeList.length; i++) {
            if (rpDetailTypeList[i].value === value) {
                selectedLabel = rpDetailTypeList[i].label;
                break;
            }
        }
        if (selectedLabel === "其他") {
            rpMoney = null;
            rpMoneyEditable = true;
        } else {
            rpMoneyEditable = false;
            if (selectedLabel === "顾客来电表扬") {
                rpMoney = 100;
            } else if (selectedLabel === "特殊贡献奖") {
                rpMoney = null;
                rpMoneyEditable = true;
            } else if (selectedLabel === "工单延误") {
                rpMoney = 50;
            } else if (selectedLabel === "服务态度") {
                rpMoney = 100;
            } else if (selectedLabel === "维修质量") {
                rpMoney = 50;
            } else if (selectedLabel === "请选择") {
                rpMoney = null;
            }
        }
        this.formRef.setFieldsValue({ rpMoney: rpMoney });
        this.setState({ rpMoneyEditable });
    };

    goBack = (refresh) => {
        if (this.props.goBack) {
            this.props.goBack(refresh);
        }
    };

    verifyUserData = (action) => {
        let app = this.getFieldValue();
        if (!app.type) {
            message.error(I18n.t("请选择服务类型!"));
            return false;
        }
        // console.log("verifyUserData```", app.type, app.nature)
        // if (FixOrder.includes(app.type)) {
        //     console.log("verifyUserData111", app.type, app.nature)
        //     if (!app.nature || Number(app.nature) == 0) {
        //         message.error(I18n.t("请选择产品性质!"));
        //         return false;
        //     }
        // }
        console.log("verifyUserData nature", app.nature)
        if (app.type < 3 || app.type > 8) {
            if (!app.nature || Number(app.nature) == 0) {
                message.error(I18n.t("请选择产品性质!"));
                return false;
            }
        }
        if (FixOrder.includes(app.type)) {
            if (app.multipleFix == null) {
                if (!app.id && ModelManager.loginUser.regionId == 2 || action == "Assign") {

                } else {
                    message.error(I18n.t("请选择是否两次或以上维修!"));
                    return false;
                }
            }
        }

        let userName = app.userName || "";
        if (userName.trim() == "") {
            message.error(I18n.t("请填写用户姓名!"));
            return false;
        }
        let userTel = app.userTel || "";
        let userExt = app.userExt || "";
        if (userTel.trim() == "" && userExt.trim() == "") {
            message.error(I18n.t("请填写用户联系电话!"));
            return false;
        }
        if (!global.isHK) {
            if (userTel.trim() !== "" && userTel.trim().length !== 11) {
                message.error(I18n.t("移动电话必须填写11位号码!"));
                return false;
            }
            if (userTel.trim() !== "" && !global.isPhone(userTel.trim())) {
                message.error(I18n.t("请输入正确格式的手机号!"));
                return false;
            }
        }

        if (!app.userProvince) {
            message.error(I18n.t("请选择用户居住省份!"));
            return false;
        }
        if (app.type < AppStatus.TYPE_OTHER || app.type == 10 || app.type == 9) {	//需维修
            if (!app.brand || Number(app.brand) == 0) {
                message.error(I18n.t("请选择品牌!"));
                return false;
            }
            if (!app.expectedDate) {
                message.error(I18n.t("请填写预计完成日期!"));
                return false;
            }
            let description = app.description || "";
            if (description.trim() == "") {
                message.error(I18n.t("请填写故障描述!"));
                return false;
            }

            // 上门处理 站点接续 配件补发 异地机修 保存时机器型号必填
            if (app.type == AppStatus.TYPE_VISIT || app.type == AppStatus.TYPE_ACCESS || app.type == 9 || app.type == 10) {	//接修的需填写机器型号
                let productType = app.productType || "";
                if (productType.trim() == "") {
                    message.error(I18n.t("请填写机器型号!"));
                    return false;
                }
                // if (this.product == null)
                this.findProduct();
                if (this.product == null) {	//未找到一致的有效产品型号
                    message.error(I18n.t("请填写有效的机器型号!"));
                    return false;
                }
                if (!app.productName) {
                    message.error(I18n.t("请选择机器名称!"));
                    return false;
                }
            }
        }

        if (app.type == AppStatus.TYPE_ACCESS || app.type == 10) {	//接修
            let accessories = app.accessories || "";
            if (accessories.trim() == "") {
                message.error(I18n.t("请填写随机附件!"));
                return false;
            }
        }

        if (app.type == AppStatus.TYPE_VISIT) {	//上门处理
            let userAddress = app.userAddress || "";
            if (userAddress.trim() == "") {
                message.error(I18n.t("请填写用户联系地址!"));
                return false;
            }
        }
        if (app.fixType === AppStatus.TYPE_MACHINE) {//更换整机
            let txtFixColor = app.fixColor || "";
            if (txtFixColor.trim() == "") {
                message.error(I18n.t("请填写颜色"));
                return false;
            }
            let fixHomeValue = app.fixHome || 0;
            if (Number(fixHomeValue) == 0) {
                message.error(I18n.t("请选择是否送货到家"));
                return false;
            }
        }
        return true;
    };

    partsCheck = (parts) => {
        if (parts.name.length > 0 || parts.code.length > 0) {
            if (parts.name.length == 0 || parts.code.length == 0 || isNaN(parts.price) || isNaN(parts.amount))
                return false;
        }
        return true;
    };

    verifyFixData = () => {
        let app = this.getFieldValue();
        //				if(Number(warrantyGroup.selectedValue) == 0) {
        //					Alert.show("请选择保修类型!");
        //					return false;
        //				}
        if (!app.nature || Number(app.nature) == 0) {
            message.warn(I18n.t("请选择产品性质!"));
            return false;
        }

        if (Number(app.nature) == 2) {
            if ((app.guarantee == 0 || app.guarantee == null) && (!app.noGuarantee || app.noGuarantee == false)) {
                message.warn(I18n.t("请选择保修依据!"));
                return false;
            }
            if (app.guarantee == 10) {
                message.warn(I18n.t("请选择正确的保修依据!"));
                return false;
            }
        }

        if (app.fixType < 1) {
            message.warn(I18n.t("请选择维修类型!"));
            return false;
        }
        let checkRecord = app.checkRecord || "";
        if (checkRecord.trim() === "") {
            message.warn(I18n.t("请填写检查记录!"));
            return false;
        }
        let fixRecord = app.fixRecord || "";
        if (fixRecord.trim() === "") {
            message.warn(I18n.t("请填写维修记录!"));
            return false;
        }
        let formNo = app.formNo || "";
        if (!global.isHK) {
            if (formNo.trim() === "") {
                message.warn(I18n.t("请填写服务单编号!"));
                return false;
            }
            if (formNo.trim().length != 7) {
                message.warn(I18n.t("请填写7位服务单编号!"));
                return false;
            }
        }

        if (app.type === AppStatus.TYPE_VISIT) {
            // if (app.distance == null) {
            if (!app.distance && !global.isHK) {
                message.warn(I18n.t("请填写上门公里数!"));
                return false;
            }
            if (app.visitFee == null && !global.isHK) {
                message.warn(I18n.t("请填写上门费用!"));
                return false;
            }
        } else if (app.type === 9 || app.type === AppStatus.TYPE_EXPRESS) {// 配件补发  TYPE_EXPRESS: 快递寄修
            let logisticsNo = app.logisticsNo || "";
            if (logisticsNo.trim() === "" && !global.isHK) {
                message.warn(I18n.t("请填写维修站物流单号!"));
                return false;
            }
        }
        /*	if(Number(app.visitFee) > 50) {
                message.warn(I18n.t("上门费用超过标准!"));
                return false;
            }*/

        //验证机器型号
        let productType = app.productType || "";
        if (productType.trim() === "") {
            message.warn(I18n.t("请填写机器型号!"));
            return false;
        }
        this.findProduct();
        if (this.product == null) {	//未找到一致的有效产品型号
            message.warn(I18n.t("请填写有效的机器型号!"));
            return false;
        }
        if (!app.productName) {
            message.warn(I18n.t("请选择机器名称!"));
            return false;
        }

        // 20220728【"EC9665.M", "EC9355.M", "EC9155.W", "EC9335.M"】咖啡水升数、蒸汽水升数、热水升数、除垢次数 必填
        if (ProductNames.some(nItem => nItem === app.productName)) {
            let productCode = app.productCode || "";
            if (productCode.trim().length != 18 && !global.isHK) {
                message.warn(I18n.t("请填写18位机器编号!"));
                return false;
            }
            if (app.cup == null) {
                message.warn(I18n.t("请填写咖啡水升数!"));
                return false;
            }
            if (app.steam == null) {
                message.warn(I18n.t("请填写蒸汽水升数!"));
                return false;
            }
            if (app.warter == null) {
                message.warn(I18n.t("请填写热水升数!"));
                return false;
            }
            if (app.clean == null) {
                message.warn(I18n.t("请填写除垢次数!"));
                return false;
            }
            if (app.totalCup == null) {
                message.warn(I18n.t("请填写饮品总杯数!"));
                return false;
            }
            if (app.totalLitres == null) {
                message.warn(I18n.t("请填写饮品总升数!"));
                return false;
            }
        }

        if (AutoProductNames.some(nItem => nItem === app.productName)) { //自动咖啡机必填
            let productCode = app.productCode || "";
            // 20230313 自动咖啡机19位
            if (productCode.trim().length != 19 && !global.isHK) {
                message.warn(I18n.t("请填写19位机器编号!"));
                return false;
            }
            if (app.cup == null) {
                message.warn(I18n.t("请填写制作杯数!"));
                return false;
            }
            if (app.clean == null) {
                message.warn(I18n.t("请填写除垢次数!"));
                return false;
            }
            if (app.warter == null) {
                message.warn(I18n.t("请填写使用水量!"));
                return false;
            }
            // if (app.steam == null) {
            //     message.warn(I18n.t("请填写蒸汽次数!"));
            //     return false;
            // }
        }

        let productNameSelectedIndex = ModelManager.getModelListIndex(app.productName);
        if (productNameSelectedIndex > 1) {
            let productCode = app.productCode || "";
            if (productCode.trim().length === 0 && !global.isHK) {
                message.warn(I18n.t("请填写机器编号!"));
                return false;
            }
        }

        this.hasParts = false;
        for (let i = 0; i < app.partsList.length; i++) {
            const p = app.partsList[i];
            if (!this.partsCheck(p)) {
                message.warn(I18n.t("请填写完整的零件信息!"));
                return false;
            }
            p.invalid = true;
            if (p.code.length > 4) {
                for (let j = 0; j < ModelManager.accessoryList.length; j++) {
                    const parts = ModelManager.accessoryList[j];
                    if (p.code === parts.code) {
                        p.invalid = false;
                        break;
                    }
                }
            } else if (p.code.length < 1)
                p.invalid = false;

            if (p.invalid) {
                message.warn("'" + p.code + "‘ " + I18n.t("不是有效的零件编码!"));
                return false;
            }

            if (p.name.length > 0 && p.code.length > 0)
                this.hasParts = true;
        }
        // 更换配件
        if (app.fixType === AppStatus.TYPE_PART && !this.hasParts) {
            message.warn(I18n.t("请添加零件信息!"));
            return false;
        }


        if (app.fixType === AppStatus.TYPE_MACHINE) { // 更换整机
            let txtFixColor = app.fixColor || "";
            if (txtFixColor === "") {
                message.warn(I18n.t("请填写颜色"));
                return false;
            }
            let fixHomeValue = app.fixHome || 0;
            if (Number(fixHomeValue) === 0) {
                message.warn(I18n.t("请选择是否送货到家"));
                return false;
            } else if (Number(fixHomeValue) === 1) {
                let logisticsNo = app.logisticsNo || "";
                if (logisticsNo.trim() === "" && !global.isHK) {
                    // message.warn(I18n.t("请填写维修站物流单号!"));
                    message.warn(I18n.t("请输入维修站物流单号！1、送货到家，请联系总部索要单号。2、维修站寄出自行填写"));
                    return false;
                }
            }
            if (!global.isHK) {
                // let logisticsNo = app.logisticsNo || "";
                // if (logisticsNo.trim() === "") {
                //     // message.warn(I18n.t("请填写维修站物流单号!"));
                //     message.warn(I18n.t("请输入维修站物流单号！1、送货到家，请联系总部索要单号。2、维修站寄出自行填写"));
                //     return false;
                // }
                let EKPNo = app.EKPNo || "";
                if (EKPNo.trim() === "") {
                    message.warn(I18n.t("请填写EKP单号"));
                    return false;
                }
            }
        }

        if (app.fixFee == null) {	//维修费用
            message.warn(I18n.t("请填写维修费用!"));
            return false;
        }
        if (!global.isHK && app.warranty === 1 && app.fixFee === 0) {
            message.warn(I18n.t("请填写维修费用!"));
            return false;
        }

        if (app.isWarrant) {
            let warrantTxt = this.state.warrantTxt || "";
            if (warrantTxt === "") {
                message.warn(I18n.t("请选择延保数据!"));
                return false;
            }

        }
        let otherDetail = app.otherDetail || "";
        if (app.otherFee !== null && otherDetail.trim() === "" && app.otherFee !== 0) {
            message.warn(I18n.t("请填写其它费用明细!"));
            return false;
        }
        if (otherDetail.trim() !== "" && app.otherFee == null) {
            message.warn(I18n.t("请填写其它费用!"));
            return false;
        }
        if (!app.servicePhone && app.type !== 9) {
            if (!global.isHK && (app.photo2 == null || app.photo2 === "")) {
                message.warn(I18n.t("请上传机身编号"));
                return false;
            }
        }
        if ((false == global.isHK) && app.type === 1 && app.nature === 2) {
            if (!global.isHK && (app.photo3 == null || app.photo3 === "")) {
                message.warn(I18n.t("请上传插座检查照片"));
                return false;
            }
            if (app.outletFailure == null || app.outletFailure === "") {
                message.warn(I18n.t("请选择插座故障"));
                return false;
            }
        }

        if (!global.isHK && (app.photo5 == null || app.photo5 === "")) {
            message.warn(I18n.t("请上传纸质工单照片"));
            return false;
        }

        // 6 其他凭证上传（需总部确认）, 9 总部凭证确认, 12 总部保修确认
        if (app.guarantee === 6 || app.guarantee === 9 || app.guarantee === 12) {
            if ((!app.zbOption || app.zbOption == 0) && !global.isHK) {
                message.warn(I18n.t("需总部确认"));
                return false;
            }
        }
        // 20230918 所有保修依据 保修日期都是必填项 需总部确认的 都由总部账号填写
        if (app.guarantee === 9 || app.guarantee === 12) {
            if (app.guaranteeBuyDate == null) {
                message.warn(I18n.t("需总部选择保修日期"));
                return false;
            }
        }
        // 5 港澳地区凭证, 9 总部凭证确认, 12 总部保修确认
        if (app.guarantee === 5 || app.guarantee === 9 || app.guarantee === 12) {
            let zbconfirmUser = app.confirmUser || "";
            if (zbconfirmUser === "" && !global.isHK) {
                message.warn(I18n.t("需总部确认"));
                return false;
            }
        }

        // 1 国内发票, 5 港澳地区凭证, 6 其他凭证上传（需总部确认）
        if (app.guarantee === 1 || app.guarantee === 5 || app.guarantee === 6) {
            if (app.guaranteeBuyDate == null) {
                message.warn(I18n.t("请选择保修日期"));
                return false;
            }
            if (app.photo1 == null || app.photo1 === "") {
                message.warn(I18n.t("请上传保修凭证"));
                return false;
            }
            if (app.guarantee === 1) {
                let invoiceInfo = app.invoiceInfo || "";
                if (invoiceInfo === "") {
                    message.warn(I18n.t("请输入开票单位"));
                    return false;
                }
            }
        }

        if (app.guarantee === 2) { // 订单号查询
            if (app.photo1 == null || app.photo1 === "") {
                message.warn(I18n.t("请上传订单照片"));
                return false;
            }
        }

        if (app.guarantee === 4) { // 保修卡号查询
            if (app.photo1 == null || app.photo1 === "") {
                message.warn(I18n.t("请上传保修凭证"));
                return false;
            }
        }

        // 2 订单号查询, 3 手机号查询, 4 保修卡号查询, 8 海外机身号查询
        // if (app.guarantee === 2 || app.guarantee === 3 || app.guarantee === 4 || app.guarantee === 8) {
        if ([2, 3, 4].includes(app.guarantee)) {
            if (this.state.orderList.length === 0) {
                message.warn(I18n.t("请输入保修订单信息"));
                return false;
            }
        }

        if (app.guarantee === 7 || app.guarantee === 8) { // 出仓日期保修 20240712 海外机身号
            if (app.purchaseDate == null) {
                message.warn(I18n.t("请选择出仓日期"));
                return false;
            }
        }

        if (app.guarantee === 11) { // 京东自营
            if (app.photo6 == null || app.photo6 === "") {
                message.warn(I18n.t("请上传京东订单照片"));
                return false;
            }
            // let zbconfirmUser = app.confirmUser || "";
            // if (zbconfirmUser === "" && !global.isHK) {
            //     message.warn(I18n.t("需总部确认"));
            //     return false;
            // }
            if (app.guaranteeBuyDate == null) {
                message.warn(I18n.t("请选择保修日期"));
                return false;
            }
            // let jdOrderInput = this.state.jdOrderInputText || "";
            let jdOrderInput = app.ecomOrderCode || "";
            if (jdOrderInput === "") {
                message.warn(I18n.t("请输入单号"));
                return false;
            }
        }

        return true;
    };

    setAppData = () => {
        let app = this.getFieldValue();
        console.log("setAppData", app)
        //接机时填写
        app.userName = app.userName ? app.userName.trim() : null;
        app.userCorp = app.userCorp ? app.userCorp.trim() : null;
        app.userTel = app.userTel ? app.userTel.trim() : null;
        app.userExt = app.userExt ? app.userExt.trim() : null;
        app.userCity = app.userCity ? app.userCity.trim() : null;
        app.userAddress = app.userAddress ? app.userAddress.trim() : null;
        app.productType = app.productType ? app.productType.trim() : null;
        app.productCode = app.productCode ? app.productCode.toUpperCase().trim() : null;

        app.accessories = app.accessories ? app.accessories.trim() : null;
        app.description = app.description ? app.description.trim() : null;
        let isSubRegion = false;
        for (let i = 0; i < ModelManager.RegionList.length; i++) {
            const region = ModelManager.RegionList[i];
            if (region.id == app.stationNo) {
                if (region.PDeptNo != 1) {
                    isSubRegion = true;
                }
                break;
            }
        }
        app.isSubRegion = isSubRegion;
        app.formNo = app.formNo ? app.formNo.trim() : null;
        if (app.fixType == 16) {
            app.fixColor = app.fixColor;
            app.fixHome = app.fixHome;
        }
        app.checkRecord = app.checkRecord ? app.checkRecord.trim() : null;
        app.fixRecord = app.fixRecord ? app.fixRecord.trim() : null;
        app.remark = app.remark ? app.remark.trim() : null;

        app.otherDetail = app.otherDetail ? app.otherDetail.trim() : null;
        app.productNo = app.productNo ? app.productNo.trim() : null;
        if (this.state.irisObj) {
            app.irisCode = this.state.irisObj.irisDefectCode;
            app.irisCodeDesc = this.state.irisCodeDesc;
        }
        app.noWarrantDetail = this.state.noWarrantDetail;

        if (app.warranty == 1) {
            if (this.state.selectedWarrantInfo != null) {
                app.warrantInfo = this.state.selectedWarrantInfo.id + ";" + this.state.selectedWarrantInfo.orderNo + ";" + this.state.selectedWarrantInfo.phone + ";" + this.state.selectedWarrantInfo.model + ";" + this.state.selectedWarrantInfo.amount + ";" + this.state.selectedWarrantInfo.platform + ";" + this.state.selectedWarrantInfo.code;
                if (this.state.selectedWarrantInfo.buyTime) {
                    app.warrantInfo += ";" + this.state.selectedWarrantInfo.buyTime;
                }
                if (this.state.selectedWarrantInfo.uploadTime) {
                    app.warrantInfo += ";" + this.state.selectedWarrantInfo.uploadTime;
                }
            }
            if (app.guarantee == 1) {
                app.channel = "国内销售";
                // app.guaranteeBuyDate = guaranteeBuyDate.selectedDate;
                app.invoiceInfo = app.invoiceInfo;
                // } else if (app.guarantee == 2 || app.guarantee == 3 || app.guarantee == 4 || app.guarantee == 8) {
            } else if ([2, 3, 4].includes(app.guarantee)) {
                if (this.state.selectedOrderInfo != null) {
                    app.orderIds = "" + this.state.selectedOrderInfo.id;
                    app.channel = this.state.selectedOrderInfo.channel;
                    app.guaranteeBuyDate = this.state.selectedOrderInfo.createOrderTime;
                    // 20221129 1、购买时间没有的 默认填写开单时间 且时间不能晚于失效时间
                    if (app.guaranteeBuyDate == null) {
                        app.guaranteeBuyDate = app.createDate;
                    }
                }
                // app.ecomOrderCode = this.state.guaranteeOrderInputText;
            } else if (app.guarantee == 5) {
                // app.guaranteeBuyDate = guaranteeBuyDate.selectedDate;
                app.channel = "港澳销售";
                // app.confirmUser = this.state.zbconfirmInput.text;
            } else if (app.guarantee == 6) {
                // app.guaranteeBuyDate = guaranteeBuyDate.selectedDate;
                // app.zbOption = zbOption.selectedIndex;
                app.channel = "";
                if (app.zbOption == 1) {
                    app.channel = "国内销售";
                } else if (app.zbOption == 2) {
                    app.channel = "海外旗舰店";
                } else if (app.zbOption == 3) {
                    app.channel = "海外分销商";
                } else if (app.zbOption == 4) {
                    app.channel = "港澳销售";
                }
            } else if (app.guarantee === 7) {
                app.channel = "国内销售";
            } else if (app.guarantee === 8) {
                app.channel = "海外分销商";
            } else if (app.guarantee === 9 || app.guarantee === 12) {
                // app.confirmUser = zbconfirmInput.text;
                // app.zbOption = zbOption.selectedIndex;
                app.channel = "";
                if (app.zbOption == 1) {
                    app.channel = "国内销售";
                } else if (app.zbOption == 2) {
                    app.channel = "海外旗舰店";
                } else if (app.zbOption == 3) {
                    app.channel = "海外分销商";
                } else if (app.zbOption == 4) {
                    app.channel = "港澳销售";
                }
            } else if (app.guarantee == 10) {
                app.channel = "国内销售";
            } else if (app.guarantee == 11) {
                app.channel = "国内销售";
                // app.ecomOrderCode = this.state.jdOrderInputText;
            }
        }
        app.rpMoney = app.rpMoney || 0;
        app.pickupPerson = app.pickupPerson ? app.pickupPerson.trim() : null;
        app.repairPerson = app.repairPerson ? app.repairPerson.trim() : null;
        app.logisticsNo = app.logisticsNo ? app.logisticsNo.trim() : null;
        app.logisticsNo2 = app.logisticsNo2 ? app.logisticsNo2.trim() : null;
        return app;
    };

    verifyFinish = () => {
        let app = this.getFieldValue();
        let delayYear = 2;
        if (app.isWarrant)
            delayYear = 3;

        if (Number(app.nature) == 2) {
            if ((app.guarantee == 0 || app.guarantee == null) && (!app.noGuarantee || app.noGuarantee == false)) {
                message.warn(I18n.t("请选择保修依据!"));
                return false;
            }
        }
        // const appCreateDate = moment(app.createDate);
        // "国内发票", "港澳地区凭证", "其他凭证上传（需总部确认）", "总部凭证确认", "京东自营", "总部保修确认"
        if ([1, 5, 6, 9, 11, 12].some(item => item === app.guarantee)) {
            let nowDate = moment(app.createDate).format("YYYY/MM/DD 00:00:00.000");
            nowDate = moment(nowDate);
            let guaranteeBuyDate = moment(app.guaranteeBuyDate).format("YYYY/MM/DD 00:00:00.000");
            guaranteeBuyDate = moment(guaranteeBuyDate);
            const delayDate = moment(guaranteeBuyDate).add(delayYear, "years");
            // if (nowDate.valueOf() - guaranteeBuyDate.valueOf() > delayYear * 365 * 24 * 60 * 60 * 1000) {
            if (nowDate.valueOf() > delayDate.valueOf()) {
                if (app.guarantee === 1) {
                    message.warn(I18n.t("发票日期不符合"));
                } else {
                    message.warn(I18n.t("保修日期不符合"));
                }
                return false;
            }
        }
        // 2 订单号查询, 3 手机号查询, 4 保修卡号查询, 8 海外机身号查询
        // 20220630 海外机身号 没有延保
        // if (app.guarantee == 2 || app.guarantee == 3 || app.guarantee == 4 || app.guarantee == 8) {
        if (app.guarantee == 2 || app.guarantee == 3 || app.guarantee == 4) {
            let orderInfo = this.state.orderList[0];
            console.log("orderInfo", orderInfo)
            let invalidCount = 0;
            if (orderInfo.invalidDate != null && orderInfo.createOrderTime != null) {
                let time = moment(global.getDate(orderInfo.invalidDate));
                if (time.valueOf() < moment(app.createDate).valueOf()) {
                    invalidCount++;
                }
            } else {
                if (orderInfo.invalidDate != null) {
                    const time = moment(global.getDate(orderInfo.invalidDate));
                    if (time.valueOf() < moment(app.createDate).valueOf()) {
                        invalidCount++;
                    }
                }
                if (orderInfo.createOrderTime != null) {
                    const time = moment(global.getDate(orderInfo.createOrderTime));
                    const delayDate = moment(time).add(delayYear, "years");
                    if (moment(app.createDate).valueOf() > moment(delayDate).valueOf()) {
                        invalidCount++;
                    }
                }
            }
            if (invalidCount >= 1) {
                message.warn(I18n.t("订单日期不符合"));
                return false;
            }
        }
        if (app.guarantee === 7 || app.guarantee === 8) { // 出仓日期保修 20240712 海外机身号
            // if (moment(app.createDate).valueOf() - moment(app.purchaseDate).valueOf() > delayYear * 365 * 24 * 60 * 60 * 1000) {
            //     message.warn(I18n.t("出仓日期不符合"));
            //     return false;
            // }
            if (moment(app.createDate).valueOf() > moment(app.purchaseDate).add(delayYear, "years").valueOf()) {
                message.warn(I18n.t("出仓日期不符合"));
                return false;
            }
        }
        return true;
    };

    //检查服务单号是否重复
    checkFixAppFormNo = (app, callback) => {
        // 20220902 校验服务单编号  限定在 保修工单 warranty == 2 [非保修]
        if (global.isHK || app.servicePhone || app.warranty == 2) {
            if (callback) {
                return callback();
            }
        }
        if (!global.isHK && app.formNo && app.formNo.trim().length === 7) {
            let data = {
                stationNo: app.stationNo,
                formNo: app.formNo,
            };
            let url = global.ServerUrl + "fixApp/getFixAppByFormNo";
            Net.post(url, data, res => {
                console.log("checkFixAppFormNo", res);
                if (res.result === 0) {
                    if (res.ret.length < 2) {
                        let appIndex;
                        if (res.ret.length > 0) { // 只有一条记录
                            appIndex = res.ret.findIndex(rItem => rItem.appNo === app.appNo);
                        }
                        if (appIndex === -1) { // 获取到的记录与当前工单不是同一个工单
                            return message.error("该维修站已存在相同服务单号的工单");
                        } else {
                            if (callback) {
                                callback();
                            }
                        }
                    } else {
                        return message.error("该维修站存在两个相同服务单号的工单");
                    }
                } else {
                    return message.error("数据查询失败，请重试");
                }
            }, error => {
                return message.error("数据查询失败，请重试");
            });
        } else {
            if (callback) {
                callback();
            }
        }
    };

    onFinish = () => {
        let app = this.getFieldValue();
        if (app.multipleFix == null) {
            message.error(I18n.t("请选择是否两次或以上维修!"));
            return false;
        }

        if (global.isHK && ModelManager.loginUser && ModelManager.loginUser.loginName === "admin") {

        } else {
            if (app.status === AppStatus.S_DRAFT)
                if (!this.verifyUserData())
                    return;
            if (!app.servicePhone) {
                if (!this.verifyFixData()) {
                    return;
                }
            } else {
                if ((!app.confirmUser || app.confirmUser.trim() === "") && !global.isHK) {
                    return message.warn(I18n.t("需总部确认"));
                }
                if ((!app.zbOption || app.zbOption == 0) && !global.isHK) {
                    return message.warn(I18n.t("需总部确认"));
                }
                // 2022年07月11日 勾选【电话完成】，【保修依据】是自动跳转【总部凭证确认】的，【日期】 隐藏掉，后台数据默认保存开单日期
                if (app.guarantee === 9 && app.guaranteeBuyDate !== app.createDate) {
                    app.guaranteeBuyDate = app.createDate;
                }
            }
            if ((app.irisCode == null && this.state.irisObj == null)) {
                return message.warn(I18n.t("请选择故障分类"));
            }
            if (!this.verifyWarrant()) {
                return;
            }
            if (!this.verifyFinish()) {
                return;
            }
        }
        app = this.setAppData();
        // 2022.06.06 勾选电话完成，这个选项的话，是没开工单的。要加一个设定，默认7个0，且可以重复
        if (app.servicePhone) {
            app.formNo = app.formNo ? app.formNo : "0000000";
        }
        this.checkFixAppFormNo(app, () => {
            this.fixApp = app;
            this.commitFunction = this.finishFix.bind(this);
            this.state.selectDateTitleString = "请选择实际完成日期";
            this.state.selectDateVisible = true;
            this.state.reviewContent = null;
            this.forceUpdate();
        })
    }
    finishFix = () => {
        if (!this.state.selectDate) {
            return message.warn(I18n.t("请选择完成日期"));
        }
        if (this.state.selectDate.valueOf() < this.fixApp.createDate.valueOf()) {
            return message.error(I18n.t("完成日期早于接单日期!"));
        }
        let selectDateStr = moment(this.state.selectDate).format("YYYY/MM/DD HH:mm:ss");
        let appointmentStr = moment(this.fixApp.appointment).format("YYYY/MM/DD HH:mm:ss");
        if (moment(selectDateStr).valueOf() < moment(appointmentStr).valueOf()) {
            let dateStr = moment(appointmentStr).format("YYYY年MM月DD日");
            // return message.error(I18n.t("该维修单的预约上门时间为：") + dateStr + I18n.t("请修改上门日期"));
            return message.error(I18n.t("该维修单的预约上门时间为：{{:1}}，请修改上门日期", { 1: dateStr }));
        }
        this.fixApp.fixDate = this.state.selectDate;
        /** 20220507 START */
        let repairDays;
        if (this.fixApp.type === AppStatus.TYPE_ACCESS || this.fixApp.type === 10) { // 接修&快递寄修 取货待维修时间-维修完成待维修
            let sendDate = moment(global.getDate(this.fixApp.sendDate));
            repairDays = moment(this.fixApp.fixDate).diff(sendDate, "days");
        } else if (this.fixApp.type === AppStatus.TYPE_VISIT) { // 上门处理 预约上门时间-完成维修时间
            repairDays = moment(this.fixApp.fixDate).diff(moment(this.fixApp.appointment), "days");
        } else {
            repairDays = moment(this.fixApp.fixDate).diff(moment(this.fixApp.createDate), "days");
        }
        /** 20220507 END */
        // repairDays = this.fixApp.fixDate.diff(this.fixApp.createDate, "days");
        this.fixApp.repairDays = repairDays;
        this.myProcess = AppStatus.S_FINISH;
        this.updateFixApp();
    };

    onAppoint = () => {
        let app = this.getFieldValue();
        if (app.status == AppStatus.S_DRAFT) {
            if (!this.verifyUserData())
                return;
            if (!this.verifyWarrant())
                return;
            app = this.setAppData();
        }
        this.fixApp = app;
        this.state.reviewContent = null;
        this.commitFunction = this.appointMent.bind(this);
        this.state.selectDateTitleString = "请选择预约上门日期";
        this.state.selectDateVisible = true;
        // this.state.selectDate = moment();
        this.forceUpdate();
    };

    appointMent = () => {
        if (!this.state.selectDate) {
            return message.warn(I18n.t("请选择预约上门时间"));
        }
        if (this.state.selectDate != null) {
            if (moment(this.state.selectDate).valueOf() < moment().valueOf()) {
                return message.warning(I18n.t("预约上门时间不能小于当前时间"));
            }
        }
        this.fixApp.appointment = this.state.selectDate;
        this.myProcess = AppStatus.S_APPOINT;
        console.log("appointMent", this.fixApp);
        this.updateFixApp();
    };
    disabledDate4 = (current) => {
        return current < moment().startOf('day');
    };
    disabledDate3 = (current) => {
        let app = this.getFieldValue();
        return current < moment(app.createDate).startOf('day');
    };
    disabledDate2 = (current) => {
        let app = this.getFieldValue();
        return current > moment(app.createDate).endOf('day');
    };

    disabledDate = (current) => {
        // Can not select days before today and today
        if (this.state.selectDateTitleString === "请选择实际完成日期") {
            return current > moment().endOf('day');
        }
        if (this.state.selectDateTitleString === "请选择预约上门日期") {
            // let app = this.getFieldValue();
            // return current < moment(app.createDate).startOf('day');
            return current < moment().startOf('day');
        }
    };

    onSelectDateChange = (value, dateString) => {
        this.state.selectDate = value;
        this.state.selectDateString = dateString;
    };

    onSelectDateOk = (value) => {
        console.log('onSelectDateOk: ', value);
    };

    onHandle = () => {
        this.fixApp = this.setAppData();
        this.myProcess = AppStatus.S_HANDLE_COMPLAIN;
        this.commitFunction = this.updateFixApp.bind(this);
        this.state.reviewVisible = true;
        this.forceUpdate();
    };

    onToBeSend1 = () => {
        if (!this.verifyUserData())
            return;
        if (!this.verifyWarrant())
            return;
        this.fixApp = this.setAppData();
        this.myProcess = AppStatus.S_TOBESEND1;
        this.commitFunction = this.updateFixApp.bind(this);
        this.state.reviewVisible = true;
        this.forceUpdate();
    };

    onToBeSend2 = () => {
        if (!this.verifyUserData())
            return;
        if (!this.verifyWarrant())
            return;
        this.fixApp = this.setAppData();
        this.myProcess = AppStatus.S_TOBESEND2;
        this.commitFunction = this.updateFixApp.bind(this);
        this.state.reviewVisible = true;
        this.forceUpdate();
    };

    onToBeRepaired = () => {
        if (!this.verifyWarrant())
            return;
        this.fixApp = this.setAppData();
        this.myProcess = AppStatus.S_TOBEREPAIRED;
        this.commitFunction = this.updateFixApp.bind(this);
        this.state.reviewVisible = true;
        this.forceUpdate();
    };

    onNotice = () => {
        this.fixApp = this.setAppData();
        this.myProcess = AppStatus.S_NOTICE;
        this.commitFunction = this.updateFixApp.bind(this);
        this.state.reviewVisible = true;
        this.forceUpdate();
    };

    updateFixAppProcess = () => {
        let url = global.ServerUrl + 'fixApp/addprocess';
        this.fixApp = this.setAppData();
        let data = {
            app: { id: this.fixApp.id },
            staff: ModelManager.loginUser,
            remark: "发送取货提醒短信",
        }
        Net.post(url, data, res => {
            console.log("updateFixAppProcess", res)
            if (res.result === 0) {
            }
        }, error => {
        });
    };

    onNoSendSms = (app) => {
        // 20221027送货到家   短信通知这个按钮不能点击
        if (this.state.applyDiscardStatus || app.fixType === AppStatus.TYPE_DETECT || app.nature !== 2 || app.fixHome == 1) {
            console.log("onNoSendSms", true);
            return true;
        }
        console.log("onNoSendSms", false);
        return false;
    };

    onSendSmsNotice = () => {
        this.fixApp = this.setAppData();
        let url = global.ServerUrl + "sms/notifycustomer";
        let data = {
            telephone: this.fixApp.userTel,
            type: this.fixApp.type,
            appNo: this.fixApp.appNo,
        };
        if (global.isDev) {
            data.telephone = "15852666739";
        }
        Net.post(url, data, res => {
            console.log("onSendSmsNotice", res)
            // if (res.result === 0) {
            //     message.success(I18n.t("短信已发送"));
            // }
            message.success(I18n.t("短信已发送"));
            this.updateFixAppProcess();
        }, error => {
            console.log("onSendSmsNotice", error)
            message.error(I18n.t("短信发送失败，请重试"));
        });
    };

    onPickup = () => {
        this.fixApp = this.setAppData();
        this.myProcess = AppStatus.S_PICKUP;
        this.commitFunction = this.updateFixApp.bind(this);
        this.state.reviewVisible = true;
        this.forceUpdate();
    };

    onReturn = () => {
        this.fixApp = this.setAppData();
        this.fixApp.isReturn = true;
        this.myProcess = AppStatus.S_BACK;//退回修改
        this.commitFunction = this.updateFixApp.bind(this);
        this.state.reviewVisible = true;
        this.forceUpdate();
    };

    verifyVisitScore = () => {
        let app = this.getFieldValue();
        if (app.visitSuggest == null) {
            message.warn(I18n.t("请填写产品推荐值评分!"));
            return false;
        }
        if (app.visitAgree == null) {
            message.warn(I18n.t("请填写产品满意度评分!"));
            return false;
        }
        if (app.visitService == null) {
            message.warn(I18n.t("请填写售后服务评分!"));
            return false;
        }
        if (app.visitSpeed == null) {
            message.warn(I18n.t("请填写维修速度评分!"));
            return false;
        }
        if (app.visitQuality == null) {
            message.warn(I18n.t("请填写维修质量评分!"));
            return false;
        }
        return true;
    };

    onVisit2 = () => {
        if (!this.verifyVisitScore()) {
            return;
        }
        let app = this.getFieldValue();
        app.visitPerson = ModelManager.loginUser.empName;
        app.visitCount++;
        app.visitTime = moment();
        app.feedbackStatus = 2;
        if (app.visitAgree > 10)
            app.visitAgree = 10;
        if (app.visitQuality > 10)
            app.visitQuality = 10;
        if (app.visitService > 10)
            app.visitService = 10;
        if (app.visitSpeed > 10)
            app.visitSpeed = 10;
        if (app.visitSuggest > 10)
            app.visitSuggest = 10;
        this.fixApp = app;
        this.myProcess = AppStatus.S_RETURN2;
        this.updateFixApp();
        // var event: FixAppEvent = new FixAppEvent(FixAppEvent.UPDATE_FIXAPP);
        // event.staff = ModelManager.loginedUser;
        // dispatchEvent(event);
        // ReturnBack();
    };

    onContinueVisit = () => {
        let app = this.getFieldValue();
        app.visitPerson = ModelManager.loginUser.empName;
        if (app.visitAgree > 10)
            app.visitAgree = 10;
        if (app.visitQuality > 10)
            app.visitQuality = 10;
        if (app.visitService > 10)
            app.visitService = 10;
        if (app.visitSpeed > 10)
            app.visitSpeed = 10;
        if (app.visitSuggest > 10)
            app.visitSuggest = 10;
        if (app.visitCount == 2) {
            if (!this.verifyVisitScore()) {
                return;
            }
            app.visitCount++;
            app.visitTime = moment();
            app.feedbackStatus = 2;
            this.fixApp = app;
            this.myProcess = AppStatus.S_RETURN2;
            this.updateFixApp();
            // var event: FixAppEvent = new FixAppEvent(FixAppEvent.UPDATE_FIXAPP);
            // event.staff = ModelManager.loginedUser;
            // dispatchEvent(event);
        } else {
            app.visitCount++;
            app.feedbackStatus = 1;
            this.fixApp = app;
            this.myProcess = AppStatus.S_RETURN1;
            this.updateFixApp();
            // var event: FixAppEvent = new FixAppEvent(FixAppEvent.UPDATE_FIXAPP);
            // event.app = app;
            // event.processing = AppStatus.S_RETURN1;
            // event.staff = ModelManager.loginedUser;
            // dispatchEvent(event);
        }
        // ReturnBack();
    };

    onZBDiscard = () => {
        this.fixApp = this.getFieldValue();
        this.myProcess = AppStatus.S_DISCARD;
        this.commitFunction = this.updateFixApp.bind(this);
        this.state.reviewVisible = true;
        this.forceUpdate();
    };

    onDiscard = () => {
        this.fixApp = this.getFieldValue();
        this.commitFunction = this.discardFunc.bind(this);
        this.setState({
            applyDiscardVisible: true,
            applyDiscardTitle: "作废",
            discardReason: null,
            repeatAppNo: null,
            discardDesc: null
        }, () => {
            this.forceUpdate();
        })
    };

    discardFunc = () => {
        let { discardReason, repeatAppNo, discardDesc } = this.state;
        if (discardReason == null) {
            return message.warn(I18n.t("请选择作废理由!"));
        }
        this.fixApp.discardReason = discardReason;
        if (discardReason === 1) {
            if (!repeatAppNo || repeatAppNo.trim().length === 0) {
                return message.warn(I18n.t("请填写重复单号!"));
            } else {
                repeatAppNo = repeatAppNo.trim();
                let noStr = repeatAppNo.substring(1, 18);
                noStr = Number(noStr);
                if (repeatAppNo.length !== 18 || repeatAppNo.indexOf("F") !== 0 || isNaN(noStr)) {
                    return message.warn(I18n.t("请检查重复单号格式!"));
                }
                if (repeatAppNo === this.fixApp.appNo) {
                    return message.warn(I18n.t("重复单号不能与当前系统单号相同!"));
                }
            }
            this.fixApp.repeatAppNo = repeatAppNo;
        } else if (discardReason === 4) {
            if (!discardDesc || discardDesc.trim().length === 0) {
                return message.warn(I18n.t("请填写作废说明!"));
            }
            this.fixApp.discardDesc = discardDesc;
        }
        this.state.reviewContent = ModelManager.discardReasons[discardReason].label;
        if (this.fixApp.repeatAppNo) {
            this.state.reviewContent = this.state.reviewContent + " " + global.transLabel("重复单号") + this.fixApp.repeatAppNo;
        }
        if (this.fixApp.discardDesc) {
            this.state.reviewContent = this.state.reviewContent + " " + this.fixApp.discardDesc;
        }
        this.myProcess = AppStatus.S_DISCARD;
        this.updateFixApp();
    };

    onCancelApplyDiscard = () => {
        this.fixApp = this.getFieldValue();
        this.myProcess = AppStatus.S_CANCEL_APPLY_DISCARD;
        this.updateFixApp();
    };

    onBackDiscard = () => {
        this.fixApp = this.getFieldValue();
        this.myProcess = AppStatus.S_RETURN_APPLY_DISCARD;
        this.updateFixApp();
    };

    onApplyDiscard = () => {
        this.fixApp = this.getFieldValue();
        this.commitFunction = this.applyDiscardFunc.bind(this);
        this.setState({
            applyDiscardVisible: true,
            applyDiscardTitle: "申请作废",
            discardReason: null,
            repeatAppNo: null,
            discardDesc: null
        }, () => {
            this.forceUpdate();
        })
    };

    applyDiscardFunc = () => {
        let { discardReason, repeatAppNo, discardDesc } = this.state;
        this.fixApp.discardReason = discardReason;
        if (discardReason === 1) {
            if (!repeatAppNo || repeatAppNo.trim().length === 0) {
                return message.warn(I18n.t("请填写重复单号!"));
            } else {
                repeatAppNo = repeatAppNo.trim();
                let noStr = repeatAppNo.substring(1, 18);
                noStr = Number(noStr);
                if (repeatAppNo.length !== 18 || repeatAppNo.indexOf("F") !== 0 || isNaN(noStr)) {
                    return message.warn(I18n.t("请检查重复单号格式!"));
                }
                if (repeatAppNo === this.fixApp.appNo) {
                    return message.warn(I18n.t("重复单号不能与当前系统单号相同!"));
                }
            }
            this.fixApp.repeatAppNo = repeatAppNo;
        } else if (discardReason === 4) {
            if (!discardDesc || discardDesc.trim().length === 0) {
                return message.warn(I18n.t("请填写作废说明!"));
            }
            this.fixApp.discardDesc = discardDesc;
        }
        this.state.reviewContent = ModelManager.discardReasons[discardReason].label;
        if (this.fixApp.repeatAppNo) {
            this.state.reviewContent = this.state.reviewContent + " " + global.transLabel("重复单号") + this.fixApp.repeatAppNo;
        }
        if (this.fixApp.discardDesc) {
            this.state.reviewContent = this.state.reviewContent + " " + this.fixApp.discardDesc;
        }
        this.myProcess = AppStatus.S_APPLY_DISCARD;
        this.updateFixApp();
    };

    discardReasons_changeHandler = (value) => {
        this.setState({ discardReason: value }, () => {
            this.forceUpdate();
        });
    };

    setValueChange = (prop, value) => {
        this.state[prop] = value;
        this.forceUpdate();
    };

    onAssign = () => {
        let app = this.getFieldValue();
        if (app.status == AppStatus.S_DRAFT) {
            if (!this.verifyUserData("Assign"))
                return;
            if (!this.verifyWarrant())
                return;
            app = this.setAppData();
        }
        this.fixApp = app;
        this.state.reviewContent = null;
        this.commitFunction = this.assignStation.bind(this);
        this.state.selectListTitleString = "请选择维修站";
        // this.state.selectList = ModelManager.StationListForSearch();
        this.state.selectList = ModelManager.RegionList;
        this.state.selectListVisible = true;
        this.state.selectRegion = null;
        this.forceUpdate();
    };

    onRegionChange = (value) => {
        this.state.selectRegion = value;
        this.forceUpdate();
    };

    assignStation = () => {
        let { selectRegion } = this.state;
        if (!selectRegion) {
            return message.error(I18n.t("请选择维修站"));
        }
        this.fixApp.stationNo = selectRegion;
        //app.assigned = true;	//move to serve
        //app.assignedDate = new Date;	//TODO service time

        // var event:FixAppEvent = new FixAppEvent(FixAppEvent.UPDATE_FIXAPP);
        this.myProcess = AppStatus.S_ASSIGN;
        // event.staff = ModelManager.loginedUser;
        // dispatchEvent(event);
        // ReturnBack();
        this.updateFixApp();
    };

    // onPostToOA = () => {
    //     let app = this.getFieldValue();
    //     let path = "https://oa.idelonghi.cn//afs.html?uid=" + ModelManager.loginUser.empId + "&u=" + ModelManager.loginUser.loginName + "&did=" + app.appNo;
    //     window.open(path, "_blank")
    // };

    onPrint = () => {
        console.log("onPrint")
        this.setState({ printVisible: true }, () => {
            this.forceUpdate()
        });
    };

    /**
     * 20220626
     * 延保做一个限定
     * 注册时间和保修依据里的购买时间做判定，
     * 延保注册时间必须在延保有效期（购买时间+2年）以内，否则延保无效
     * 如果只填延保/填了延保但和保修依据匹配不满足的时候 ，
     * 点击保存工单的时候就判定 不能保存
     * 20220630 保修依据里的：售前机，港台凭证，海外机身号 三项 没有延保
     */
    verifyWarrant = () => {
        let app = this.getFieldValue();
        if (app.isWarrant) {
            let warrantTxt = this.state.warrantTxt || "";
            if (warrantTxt.trim().length === 0) {
                message.warn(I18n.t("请选择延保数据!"));
                return false;
            }
            if ((app.guarantee == 0 || app.guarantee == null) && (!app.noGuarantee || app.noGuarantee == false)) {
                message.warn(I18n.t("请选择保修依据!"));
                return false;
            }
            let uploadTime; // 注册时间
            let infoArr = warrantTxt.split(",");
            if (infoArr.length > 7) {
                uploadTime = infoArr[7];
            } else {
                uploadTime = infoArr[6];
            }
            if (uploadTime.indexOf("注册时间：") !== -1) {
                uploadTime = uploadTime.replace("注册时间：", "").replace("年", "/").replace("月", "/").replace("日", "");
            }
            if (uploadTime.indexOf("註冊時間：") !== -1) {
                uploadTime = uploadTime.replace("註冊時間：", "").replace("年", "/").replace("月", "/").replace("日", "");
            }
            console.log("uploadTime1", uploadTime)
            uploadTime = moment(uploadTime).format("YYYY/MM/DD 00:00:00.000");
            uploadTime = moment(uploadTime);
            console.log("uploadTime2", uploadTime, uploadTime.valueOf())
            // "国内发票", "港澳地区凭证", "其他凭证上传（需总部确认）", "总部凭证确认", "京东自营", "总部保修确认"
            if ([1, 5, 6, 9, 11, 12].some(item => item === app.guarantee)) {
                if (app.guaranteeBuyDate == null && !app.servicePhone) {
                    message.warn(I18n.t("请选择保修日期"));
                    return false;
                }
                let guaranteeBuyDate = app.guaranteeBuyDate.format("YYYY/MM/DD 00:00:00.000");
                // 20220630 保修日期要小于等于延保的注册时间
                if (moment(guaranteeBuyDate).valueOf() > uploadTime.valueOf()) {
                    message.warn(I18n.t("延保不符合"));
                    return false;
                }
                let newTime = moment(guaranteeBuyDate).add(2, "years"); // 购买时间+2年
                console.log("newTime", newTime, newTime.valueOf())
                if (uploadTime.valueOf() > newTime.valueOf()) {
                    message.warn(I18n.t("无效的延保"));
                    return false;
                }
            } else if (app.guarantee === 2 || app.guarantee === 3 || app.guarantee === 4) {
                let orderInfo = this.state.orderList[0];
                let time;
                // if (orderInfo.invalidDate != null && orderInfo.createOrderTime != null) {
                //     time = moment(global.getDate(orderInfo.invalidDate));
                // } else {
                //     if (orderInfo.invalidDate != null) {
                //         time = moment(global.getDate(orderInfo.invalidDate));
                //     }
                //     if (orderInfo.createOrderTime != null) {
                //         time = moment(global.getDate(orderInfo.createOrderTime));
                //     }
                // }
                if (orderInfo.createOrderTime != null) {
                    time = moment(global.getDate(orderInfo.createOrderTime));
                }
                if (time) {
                    if (time.valueOf() > uploadTime.valueOf()) {
                        message.warn(I18n.t("延保不符合"));
                        return false;
                    }
                    let newTime = moment(time).add(2, "years"); // 购买时间+2年
                    console.log("newTime", newTime, newTime.valueOf())
                    if (uploadTime.valueOf() > newTime.valueOf()) {
                        message.warn(I18n.t("无效的延保"));
                        return false;
                    }
                }
            } else if (app.guarantee === 7 || app.guarantee === 8) {
                if (moment(app.purchaseDate).valueOf() > uploadTime.valueOf()) {
                    message.warn(I18n.t("延保不符合"));
                    return false;
                }
                let newTime = moment(app.purchaseDate).add(2, "years"); // 购买时间+2年
                console.log("newTime", newTime, newTime.valueOf())
                if (uploadTime.valueOf() > newTime.valueOf()) {
                    message.warn(I18n.t("无效的延保"));
                    return false;
                }
            }
        }
        return true;
    };

    onSave = () => {
        if (ModelManager.loginUser.regionId !== 2) {
            if (!this.verifyUserData()) return;
        }
        if (!this.verifyWarrant()) return;
        let app = this.setAppData();
        console.log("onSave", app)
        if (app.purchaseDate) {
            if (moment(app.purchaseDate).format("YYYY-MM-DD") === "1970-01-01") {
                app.purchaseDate = null;
            } else {
                if (!app.guaranteeBuyDate) {
                    app.guaranteeBuyDate = app.purchaseDate
                }
            }
        }
        if (app.guarantee > 0 && app.warranty == 0) {
            app.warranty = 1;
        }
        if (app.isReturn) {
            app.isReturn = false;
        }
        // if (app.status >= 4 && app.status != 25 && app.status != 26 && app.status != 27) {
        //     app.isEmergency = false;
        // }
        console.log("onSave app", app)
        this.fixApp = app;
        this.myProcess = AppStatus.S_DRAFT;
        this.state.reviewVisible = true;
        this.state.reviewContent = null;
        this.commitFunction = this.updateFixApp.bind(this);
        this.forceUpdate();
    };

    reviewContentChange = (value) => {
        console.log("reviewContentChange", value)
        this.setState({ reviewContent: value });
        this.forceUpdate();
    };
    onCheckPartsList = (app) => {
        if (app.partsList && app.partsList.length > 0) {
            let list = [];
            for (let i = 0; i < app.partsList.length; i++) {
                const element = app.partsList[i];
                if (element.amount !== 0) {
                    list.push(element);
                }
            }
            app.partsList = list;
        }
        return app;
    };
    updateFixApp = () => {
        if (this.state.updating) {
            return message.warning(I18n.t("正在提交，请不要重复操作"));
        }
        let app = this.fixApp;
        // 上门处理 站点接续 配件补发 异地机修 保存时机器型号必填
        if (app.type == AppStatus.TYPE_VISIT || app.type == AppStatus.TYPE_ACCESS || app.type == 9 || app.type == 10) {	//接修的需填写机器型号
            this.findProduct(app.productType);
            if (this.product == null) {	//未找到一致的有效产品型号
                return message.error(I18n.t("请填写有效的机器型号!"));
            }
        }
        this.updateTime = this.updateTime || 0;
        let now = new Date().getTime();
        if (now - this.updateTime < 2000) {
            return message.warning(I18n.t("请不要重复操作"));
        }

        if (AutoProductNames.includes(app.productName)) {
            // 20230413 自动咖啡机的维修工单帮我做个限制 制作杯数 大于等于10000 不能做保修
            if (app.cup >= 10000 && !app.noGuarantee) {
                return message.warning(I18n.t("制作杯数大于等于10000不能做保修"));
            }
        }
        let { doc } = this.state;
        let appointmentText = "";
        if (this.myProcess === AppStatus.S_DRAFT && doc.appointment) {
            let appointmentStr = moment(global.getDate(doc.appointment)).format("YYYY.MM.DD HH:mm");
            if (app.appointment) {
                let currAppointmentStr = app.appointment.format("YYYY.MM.DD HH:mm");
                if (appointmentStr !== currAppointmentStr) {
                    if (moment(app.appointment).valueOf() < moment().valueOf()) {
                        return message.warning(I18n.t("预约上门时间不能小于当前时间"));
                    }
                    appointmentText = I18n.t("预约上门时间发生变化") + "【" + appointmentStr + "】" + I18n.t("改到") + "【" + currAppointmentStr + "】";
                }
            }
        }
        if (appointmentText.length > 0) {
            this.state.reviewContent = this.state.reviewContent || "";
            this.state.reviewContent += appointmentText;
        }
        // if (app.servicePhone && app.guaranteeBuyDate == null) {
        //     app.guaranteeBuyDate = app.createDate;
        // }

        // 2022年07月11日 勾选【电话完成】，【保修依据】是自动跳转【总部凭证确认】的，【日期】 隐藏掉，后台数据默认保存开单日期
        if (app.servicePhone && app.guarantee === 9) {
            app.guaranteeBuyDate = app.createDate;
        }
        // app = this.onCheckPartsList(app);
        this.updateTime = new Date().getTime();
        this.state.updating = true;
        this.setState({ updating: true }, () => {
            this.forceUpdate();
        });
        let data = {
            app: app,
            processing: this.myProcess,
            staff: ModelManager.loginUser,
            remark: this.state.reviewContent,
            version: global.version,
            platform: "web",
        }
        let url = global.ServerUrl;
        if (app.id) {
            url += "fixApp/update";
        } else {
            url += "fixApp/add";
        }
        Net.post(url, data, res => {
            console.log("updateFixApp", res)
            if (res.result === 0) {
                this.handleCancel();
                // if(this.props.action === "add") {
                //     this.goBack(true);
                // } else {
                this.goBack();
                // }
            } else if (res.result === -10) {
                message.error(I18n.t("工单数据已变更，请先获取最新工单"))
            }
            this.state.updating = false;
            this.setState({ updating: false }, () => {
                this.forceUpdate();
            });
        }, error => {
            this.state.updating = false;
            this.setState({ updating: false }, () => {
                this.forceUpdate();
            });
            console.log("updateFixApp", error)
        });
    };

    onPreviewClosed = () => {
        //显示出仓日期
        this.state.partGridHeadRowVisible = true;
        this.state.GridRow1Visible = true;
        if (this.hasParts) {
            // try {
            //     var partCount = app.partsList.length;
            //     var partIndex: int = partPrintGrid.getChildIndex(printGridHead);
            //     var children: Array = partPrintGrid.getChildren();
            //     for (var i: int = 1; i <= partCount; i++) {
            //         grid.addChildAt(children[partIndex + i], grid.getChildIndex(GridRow1));
            //     }
            // } catch (error: Error) {

            // }

            this.state.partPrintVBoxVisible = false;
        }
        if (this.state.feedBackVisible == true) {
            this.state.VisitScore2Visible = true;
        }
        this.state.addImgVisible = this.state.canEdit;
        this.state.processVBoxVisible = true;
        this.state.rpHeadRowVisible = true;
        this.state.rpRowVisible = true;
        this.setState({
            partGridHeadRowVisible: true,
            GridRow1Visible: true,
            partPrintVBoxVisible: this.state.partPrintVBoxVisible,
            VisitScore2Visible: this.state.VisitScore2Visible,
            addImgVisible: this.state.addImgVisible,
            processVBoxVisible: this.state.processVBoxVisible,
            rpHeadRowVisible: this.state.rpHeadRowVisible,
            rpRowVisible: this.state.rpRowVisible,
        });
        this.forceUpdate();
    }

    printReport = (report1) => {
        this.hasParts = false;
        let app = this.getFieldValue();
        if (app.partsList != null && app.partsList.length > 0) {
            this.state.partPrintVBoxVisible = true;
            this.hasParts = true;
            this.setState({ partPrintVBoxVisible: true });
        }
        this.state.partGridHeadRowVisible = false;
        this.state.GridRow1Visible = false;
        this.state.addImgVisible = false;
        if (this.state.VisitScore2Visible == true) {
            this.state.feedBackVisible = true;
            this.state.VisitScore2Visible = false;
        } else {
            this.state.feedBackVisible = false;
        }

        this.state.processVBoxVisible = false;
        this.state.rpHeadRowVisible = false;
        this.state.rpRowVisible = false;
        this.setState({
            print: true,
            partGridHeadRowVisible: false,
            GridRow1Visible: false,
            addImgVisible: false,
            processVBoxVisible: false,
            rpHeadRowVisible: false,
            rpRowVisible: false,
            feedBackVisible: this.state.feedBackVisible,
            VisitScore2Visible: this.state.VisitScore2Visible,
        });
        this.forceUpdate();
        setTimeout(() => {
            let printHtml = document.getElementById("FixAppEditForm").innerHTML;
            let wind = window.open("", 'newwindow', 'height=300, width=700, top=100, left=100, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no');
            wind.document.body.innerHTML = printHtml;
            wind.document.title = app.appNo;
            wind.print();
            this.onPreviewClosed();
            this.setState({ print: false });
            this.forceUpdate();
            return false;
        }, 200);

        // report1.prepare();
        // report1.validateNow();
        // var reportDoc: Document = new Document(report1, new Object(), PaperFormat.A4);
        // ModelManager.printPreview.doc = (reportDoc);
        // report1.visible = true;
        // report1.resetVisible();
        // var pEvent: MyPrintPreviewEvent = new MyPrintPreviewEvent(MyPrintPreviewEvent.PREVIEW_START);
        // dispatchEvent(pEvent);
    };

    exportFile = (type) => {
        let app = this.getFieldValue();
        let url = global.ServerUrl;
        let fileName = app.formNo;
        if (type === "CSV") {
            url += "fixApp/exportFixAppToCSV";
            fileName += ".csv";
        } else if (type === "TXT") {
            url += "fixApp/exportFixAppToTXT";
            fileName += ".txt";
        }
        let data = {
            app: app,
        }
        Net.postDownload(url, data, fileName, error => {
            console.log("exportFile", error)
        });
    };

    setOrderRowClassName = (record, index) => {
        if (this.state.selectedOrder && record.id === this.state.selectedOrder.id) {
            return "tableRowHighDark";
        }
        if (index % 2 === 0) {
            return "tableRowDark";
        }
        return "tableRowLight";
    };

    setRowClassName = (record, index) => {
        if (this.state.selectedWarrant && record.id === this.state.selectedWarrant.id) {
            return "tableRowHighDark";
        }
        if (index % 2 === 0) {
            return "tableRowDark";
        }
        return "tableRowLight";
    };

    setUserRowClassName = (record, index) => {
        if (this.state.selectedUser && record.id === this.state.selectedUser.id) {
            return "tableRowHighDark";
        }
        if (index % 2 === 0) {
            return "tableRowDark";
        }
        return "tableRowLight";
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            userModalVisible: false,
            reviewVisible: false,
            selectListVisible: false,
            selectDateVisible: false,
            selectDate: null,
            reviewContent: null,
            isOrderModalVisible: false,
            applyDiscardVisible: false,
            printVisible: false,
            updating: false,
        }, () => {
            this.forceUpdate();
        });
    };

    setOrderInfo = () => {
        if (!this.state.selectedOrder) {
            return message.error(I18n.t("请选择一条订单"));
        }
        this.state.selectedOrderInfo = this.state.selectedOrder;
        // orderList.addItem(order);
        this.state.orderHeadDivVisible = true;
        this.state.isOrderModalVisible = false;
        // var num: int = orderVBox.getChildren().length;
        // if (num > 1) {
        //     for (var j: int = num - 1; j > 0; j--) {
        //         orderVBox.removeChildAt(j);
        //     }
        // }
        // var ps: OrderItem = new OrderItem;
        // ps.orderInfo = order;
        // orderVBox.addChildAt(ps, orderVBox.getChildren().length);
        // var head: OrderHead = new OrderHead;
        // orderVBox.addChildAt(head, orderVBox.getChildren().length);
        // var ps2: OrderItemSecond = new OrderItemSecond;
        // ps2.orderInfo = order;
        // orderVBox.addChildAt(ps2, orderVBox.getChildren().length);
        this.state.tipInfo = "";
        this.state.tipInfo = this.state.selectedOrder.note;
        this.setState({
            selectedOrderInfo: this.state.selectedOrderInfo,
            orderHeadDivVisible: this.state.orderHeadDivVisible,
            isOrderModalVisible: this.state.isOrderModalVisible,
            tipInfo: this.state.tipInfo,
        });
        this.forceUpdate();
    }

    cancelSelectOrder = () => {
        this.setState({
            isOrderModalVisible: false,
            selectedOrder: null,
        });
        this.forceUpdate();
    };

    renderOrderItem = (item) => {
        return (
            <div>
                <Row style={Styles.rowStyle}>
                    <Col span={4} style={{ width: "16.6%" }}>
                        <div style={Styles.colItemStyle}>
                            <div style={Styles.label2Style}>{I18n.t("购买时间")}</div>
                        </div>
                    </Col>
                    <Col span={4} style={{ width: "16.6%" }}>
                        <div style={Styles.colItemStyle}>
                            <div style={Styles.label2Style}>{I18n.t("失效时间")}</div>
                        </div>
                    </Col>
                    <Col span={7} style={{ width: "29.1%" }}>
                        <div style={Styles.colItemStyle}>
                            <div style={Styles.label2Style}>{I18n.t("购买型号")}</div>
                        </div>
                    </Col>
                    <Col span={3} style={{ width: "12.7%" }}>
                        <div style={Styles.colItemStyle}>
                            <div style={Styles.label2Style}>{I18n.t("购买数量")}</div>
                        </div>
                    </Col>
                    <Col span={6} style={{ width: "25%" }}>
                        <div style={Styles.colItemStyle}>
                            <div style={Styles.label2Style}>{I18n.t("购买平台")}</div>
                        </div>
                    </Col>
                </Row>
                <Row style={Styles.rowStyle}>
                    <Col span={4} style={{ width: "16.6%" }}>
                        <div style={Styles.colItemStyle}>
                            {/* 购买时间 */}
                            <div style={Styles.label2Style}>{item.createOrderTime && item.createOrderTime.substring(0, 10)}</div>
                        </div>
                    </Col>
                    <Col span={4} style={{ width: "16.6%" }}>
                        <div style={Styles.colItemStyle}>
                            {/* 失效时间 */}
                            <div style={Styles.label2Style}>{item.invalidDate && item.invalidDate.substring(0, 10)}</div>
                            {/* <div style={Styles.label2Style}>{item.invalidDate}</div> */}
                        </div>
                    </Col>
                    <Col span={7} style={{ width: "29.1%" }}>
                        <div style={Styles.colItemStyle}>
                            {/* 购买型号 */}
                            <div style={Styles.label2Style}>{item.orderModel}</div>
                        </div>
                    </Col>
                    <Col span={3} style={{ width: "12.7%" }}>
                        <div style={Styles.colItemStyle}>
                            {/* 购买数量 */}
                            <div style={Styles.label2Style}>{item.orderNum}</div>
                        </div>
                    </Col>
                    <Col span={6} style={{ width: "25%" }}>
                        <div style={Styles.colItemStyle}>
                            {/* 购买平台 */}
                            <div style={Styles.label2Style}>{item.orderPlatform}</div>
                        </div>
                    </Col>
                </Row>
                <Row style={Styles.rowStyle}>
                    <Col span={9} style={{ width: "37.6%" }}>
                        <div style={Styles.colItemStyle}>
                            <div style={Styles.label2Style}>{I18n.t("订单号")}</div>
                        </div>
                    </Col>
                    <Col span={5} style={{ width: "20.8%" }}>
                        <div style={Styles.colItemStyle}>
                            <div style={Styles.label2Style}>{I18n.t("手机号")}</div>
                        </div>
                    </Col>
                    <Col span={5} style={{ width: "20.8%" }}>
                        <div style={Styles.colItemStyle}>
                            <div style={Styles.label2Style}>{I18n.t("保修卡号")}</div>
                        </div>
                    </Col>
                    <Col span={5} style={{ width: "20.8%" }}>
                        <div style={Styles.colItemStyle}>
                            <div style={Styles.label2Style}>{I18n.t("机身编号")}</div>
                        </div>
                    </Col>
                </Row>
                <Row style={Styles.rowStyle}>
                    <Col span={9} style={{ width: "37.6%" }}>
                        <div style={Styles.colItemStyle}>
                            {/* 订单号 */}
                            <div style={Styles.label2Style}>{item.orderCode}</div>
                        </div>
                    </Col>
                    <Col span={5} style={{ width: "20.8%" }}>
                        <div style={Styles.colItemStyle}>
                            {/* 手机号 */}
                            <div style={Styles.label2Style}>{item.orderTelephone}</div>
                        </div>
                    </Col>
                    <Col span={5} style={{ width: "20.8%" }}>
                        <div style={Styles.colItemStyle}>
                            {/* 保修卡号 */}
                            <div style={Styles.label2Style}>{item.guaranteeCode}</div>
                        </div>
                    </Col>
                    <Col span={5} style={{ width: "20.8%" }}>
                        <div style={Styles.colItemStyle}>
                            {/* 机身编号 */}
                            <div style={Styles.label2Style}>{item.overseaCode}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    };
    renderTableHeader = () => {
        return (
            <Row style={Styles.rowStyle}>
                <Col span={3} style={{ width: "12.5%" }}>
                    <div style={Styles.colItem3Style}>
                        <div style={Styles.label2Style}>{I18n.t("序号")}</div>
                    </div>
                </Col>
                <Col span={7} style={{ width: "29%" }}>
                    <div style={Styles.colItem3Style}>
                        <div style={Styles.label2Style}>{I18n.t("零件编码")}</div>
                    </div>
                </Col>
                <Col span={7} style={{ width: "29%" }}>
                    <div style={Styles.colItem3Style}>
                        <div style={Styles.label2Style}>{I18n.t("零件名称")}</div>
                    </div>
                </Col>
                <Col span={2} style={{ width: "8.5%" }}>
                    <div style={Styles.colItem3Style}>
                        <div style={Styles.label2Style}>{I18n.t("数量")}</div>
                    </div>
                </Col>
                <Col span={2} style={{ width: "8.5%" }}>
                    <div style={Styles.colItem3Style}>
                        <div style={Styles.label2Style}>{I18n.t("单价")}</div>
                    </div>
                </Col>
                <Col span={3} style={{ width: "12.5%" }}>
                    <div style={Styles.colItem3Style}>
                        <div style={Styles.label2Style}>{I18n.t("金额")}</div>
                    </div>
                </Col>
            </Row>
        )
    };

    addParts = () => {
        let app = this.getFieldValue();
        let partsList = app.partsList || [];
        partsList.push({});
        this.formRef.setFieldsValue({ partsList: partsList });
        this.forceUpdate();
    };

    renderTableFooter = (item) => {
        return (
            <Row style={Styles.rowStyle}>
                <Col span={3} style={{ width: "12.5%" }}>
                    <div style={this.state.print ? Styles.colItem4Style : Styles.colItem5Style}>
                        <div style={Styles.label2Style}>
                            {/* 更换配件 */}
                            {item.fixType == AppStatus.TYPE_PART && this.state.addImgVisible &&
                                <Button onClick={() => this.addParts()}
                                    style={{
                                        height: 28, display: "flex",
                                        alignItems: "center",
                                        padding: "0px 10px",
                                        margin: 1,
                                    }}
                                >+ Add</Button>
                            }
                        </div>
                    </div>
                </Col>
                <Col span={14} style={{ width: "58%" }}>
                    <div style={this.state.print ? Styles.colItemEnd2Style : Styles.colItemEndStyle}>
                        <div style={Styles.label2Style}>{I18n.t("合计")}</div>
                    </div>
                </Col>
                <Col span={2} style={{ width: "8.5%" }}>
                    <div style={Styles.colItem3Style}>
                        <div style={Styles.label2Style}>{item._PartsAmount}</div>
                    </div>
                </Col>
                <Col span={2} style={{ width: "8.5%" }}>
                    <div style={this.state.print ? Styles.colItem4Style : Styles.colItem5Style}>
                        <div style={Styles.label2Style}></div>
                    </div>
                </Col>
                <Col span={3} style={{ width: "12.5%" }}>
                    <div style={Styles.colItem3Style}>
                        {/* <div style={Styles.label2Style}>{item.partsFee != null ? item.partsFee.toFixed(2) : ""}</div> */}
                        <div style={Styles.label2Style}>{item._PartsMoney}</div>
                    </div>
                </Col>
            </Row>
        )
    };
    checkMultiParts = (value, partsList) => {
        partsList = partsList || [];
        let count = 0
        for (let i = 0; i < partsList.length; i++) {
            const p = partsList[i];
            if (p.code === value) {
                count++;
            }
        }
        if (count > 0) {
            return true;
        }
        return false;
    }
    onAccessoryItemChange = (value, index) => {
        console.log("onAccessoryItemChange", value)
        let app = this.getFieldValue();
        if (value && this.checkMultiParts(value, app.partsList)) {
            return message.error(I18n.t("配件重复"));
        }
        let partItem = app.partsList[index];
        partItem.code = value ? value.trim().toUpperCase() : "";
        //遍历零部件列表
        partItem.invalid = true;	//var invalid:Boolean = true;
        if (partItem.code.length > 4) {
            if (!partItem.amount) {
                partItem.amount = 1;
            }
            for (let i = 0; i < ModelManager.accessoryList.length; i++) {
                const p = ModelManager.accessoryList[i];
                if (p.code == partItem.code) {
                    partItem.name = p.name;
                    partItem.model = p.model;
                    if (app.warranty == 1)	//保修
                        partItem.price = p.price;
                    else
                        partItem.price = p.charge;
                    partItem.invalid = false;
                    break;
                }
            }
        } else if (partItem.code.length < 1) {
            partItem.name = "";
            //partItem.amount = NaN;
            partItem.price = NaN;
            partItem.invalid = false;
        }

        if (partItem.invalid) {
            // _partsColor = 0xff0000;
            partItem.name = "";
            partItem.price = NaN;
        } else {
            // _partsColor = 0x000000;
        }

        //partsMoney.text = isNaN(parts.partsMoney)?"":parts.partsMoney.toFixed(2);	move to computePartsMoney()
        this.computePartsMoney();
        // if (partItem.code.length > 0) {
        //     _partsList = ModelManager.accessoryListForFilter;	//此处不能用accessoryList
        //     codeInput.selectedIndex = -1;
        //     codeInput.text = parts.code;
        // } else
        //     _partsList = null;

        
        this.setState({ app });
        // this.formRef.setFieldsValue({ partsList: app.partsList });
        this.onEditAmount(partItem.amount, index);
    };

    onSearch(val) {
        console.log('search:', val);
    }

    onEditAmount = (amount, index) => {
        if(amount == null) return;
        let app = this.getFieldValue();
        let partItem = app.partsList[index];
        partItem.amount = amount;
        partItem.partsMoney = partItem.amount.mul(partItem.price);
        this.formRef.setFieldsValue({ partsList: app.partsList });
        this.computePartsMoney();
    };
    deleteProcess = (id) => {
        let url = global.ServerUrl + "appProcess/delete";
        let data = { id: id };
        console.log("deleteProcess", data);
        Net.post(url, data, (res) => {
            console.log("deleteProcess", res);
        }, error => {
            console.log("deleteProcess", error);
        })
    };

    renderTableItem = (item, index) => {
        let app = this.getFieldValue();
        return (
            <div key={"partsItem" + index}>
                <Row style={Styles.rowStyle}>
                    <Col span={3} style={{ width: "12.5%" }}>
                        <div style={Styles.colItem3Style}>
                            <Input readOnly value={index + 1} style={{ border: "none", minHeight: 32, width: "100%" }} />
                        </div>
                    </Col>
                    <Col span={7} style={{ width: "29%" }}>
                        <div style={Styles.colItem3Style}>
                            {this.state.print ?
                                <Input readOnly value={item.code} style={{ border: "none", minHeight: 32, flex: 1 }} />
                                :
                                <Select
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    allowClear
                                    onChange={(value) => this.onAccessoryItemChange(value, index)}
                                    onSearch={(val) => this.onSearch(val, index)}
                                    value={item.code}
                                    disabled={!(this.state.canEdit || app.isReturn)}
                                    filterOption={(input, option) => {
                                        console.log("option", option)
                                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }}
                                >
                                    {
                                        ModelManager.accessoryList.map((item) => {
                                            if (!item.code)
                                                return (
                                                    <Select.Option key={item.id} value={""}>{""}</Select.Option>
                                                )
                                            return (
                                                <Select.Option key={item.id} value={item.code}>{item.code}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            }

                        </div>
                    </Col>
                    <Col span={7} style={{ width: "29%" }}>
                        <div style={Styles.colItem3Style}>
                            <Input readOnly value={item.name} placeholder={I18n.t("零件名称")} style={{ border: "none", minHeight: 32, flex: 1 }} />
                        </div>
                    </Col>
                    <Col span={2} style={{ width: "8.5%" }}>
                        <div style={Styles.colItem3Style}>
                            {this.state.print ?
                                <Input readOnly value={item.amount} style={{ border: "none", minHeight: 32, width: "100%" }} />
                                :
                                <InputNumber min={0} value={item.amount} style={{ width: "100%", border: "none", minHeight: 32 }} onChange={value => this.onEditAmount(value, index)} />
                            }
                        </div>
                    </Col>
                    <Col span={2} style={{ width: "8.5%" }}>
                        <div style={Styles.colItem3Style}>
                            <Input readOnly value={item.price} style={{ border: "none", minHeight: 32, width: "100%" }} />
                        </div>
                    </Col>
                    <Col span={3} style={{ width: "12.5%" }}>
                        <div style={Styles.colItem3Style}>
                            {/* <div style={Styles.label2Style}>{item.amount}</div> */}
                            <Input readOnly value={item.partsMoney ? item.partsMoney.toFixed(2) : ""} style={{ border: "none", minHeight: 32, width: "100%" }} />
                        </div>
                    </Col>
                </Row>
                <Row style={Styles.rowStyle}>
                    <Col span={3} style={{ width: "12.5%" }}>
                        <div style={Styles.colItem3Style}>
                            <div style={Styles.label4Style}>{I18n.t("适用型号")}</div>
                        </div>
                    </Col>
                    <Col span={21} style={{ width: "87.5%" }}>
                        <div style={Styles.colItem3Style}>
                            <Input readOnly value={item.model} style={{ border: "none", minHeight: 32, width: "100%" }} />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    };

    initOrderListTableHeader = () => {
        let columns = [
            {
                title: I18n.t("序号"), dataIndex: 'index', key: 'index', width: 80,
                render: (text, record, index) => {
                    return (
                        <span>{index + 1}</span>
                    )
                }
            },
            {
                title: I18n.t("订单号"), dataIndex: 'orderCode', key: 'orderCode', width: 180
            },
            {
                title: I18n.t("手机号"), dataIndex: 'orderTelephone', key: 'orderTelephone', width: 180
            },
            {
                title: I18n.t("保修卡号"), dataIndex: 'guaranteeCode', key: 'guaranteeCode', width: 180
            },
            {
                title: I18n.t("机器型号"), dataIndex: 'orderModel', key: 'orderModel', width: 180
            },
            {
                title: I18n.t("数量"), dataIndex: 'orderNum', key: 'orderNum', width: 80
            },
            {
                title: I18n.t("购买平台"), dataIndex: 'orderPlatform', key: 'orderPlatform', width: 230
            },
            {
                title: I18n.t("购买时间"), dataIndex: 'createOrderTime', key: 'createOrderTime', width: 180,
                render: (text) => {
                    if (text) {
                        return moment(global.getDate(text)).format(dateFormat);
                    }
                    return "";
                }
            },
            {
                title: I18n.t("凭证失效时间"), dataIndex: 'invalidDate', key: 'invalidDate', width: 180,
                render: (text) => {
                    if (text)
                        return moment(global.getDate(text)).format(dateFormat);
                    return "";
                }
            },
            {
                title: I18n.t("机身编号"), dataIndex: 'overseaCode', key: 'overseaCode', width: 100,
            },
            {
                title: I18n.t("备注"), dataIndex: 'note', key: 'note', width: 100,
            },
            {
                title: I18n.t("销售渠道"), dataIndex: 'channel', key: 'channel', width: 90
            },
        ];
        return columns;
    };

    initWarrantListTableHeader = () => {
        let columns = [
            {
                title: I18n.t("序号"), dataIndex: 'index', key: 'index', width: 80,
                render: (text, record, index) => {
                    return (
                        <span>{index + 1}</span>
                    )
                }
            },
            {
                title: I18n.t("订单号"), dataIndex: 'orderNo', key: 'orderNo', width: 180
            },
            {
                title: I18n.t("手机号"), dataIndex: 'phone', key: 'phone', width: 180
            },
            {
                title: I18n.t("购买型号"), dataIndex: 'model', key: 'model', width: 180
            },
            {
                title: I18n.t("购买数量"), dataIndex: 'amount', key: 'amount', width: 180
            },
            {
                title: I18n.t("购买平台"), dataIndex: 'platform', key: 'platform', width: 240
            },
            {
                title: I18n.t("机身编号"), dataIndex: 'code', key: 'code', width: 200,
            },
            {
                title: I18n.t("购买时间"), dataIndex: 'buyTime', key: 'buyTime', width: 230,
                render: (text) => {
                    if (text) {
                        if (text.indexOf("1899") == 0 || text.indexOf("1900") == 0) {
                            return "";
                        }
                        return moment(global.getDate(text)).format(dateFormat);
                    }
                    return "";
                }
            },
            {
                title: I18n.t("注册时间"), dataIndex: 'uploadTime', key: 'uploadTime', width: 230,
                render: (text) => {
                    if (text)
                        return moment(global.getDate(text)).format(dateFormat);
                    return "";
                }
            },
        ];
        return columns;
    };

    initUserListTableHeader = () => {
        let columns = [
            {
                title: I18n.t("序号"), dataIndex: 'index', key: 'index', width: 80,
                render: (text, record, index) => {
                    return (
                        <span>{index + 1}</span>
                    )
                }
            },
            {
                title: I18n.t("姓名"), dataIndex: 'userName', key: 'userName', width: 80
            },
            {
                title: I18n.t("公司"), dataIndex: 'userCorp', key: 'userCorp', width: 230
            },
            {
                title: I18n.t("电话"), dataIndex: 'userTel', key: 'userTel', width: 180
            },
            {
                title: I18n.t("城市"), dataIndex: 'userCity', key: 'userCity', width: 80
            },
            {
                title: I18n.t("保修"), dataIndex: 'warranty', key: 'warranty', width: 80,
                render: (text) => {
                    return ModelManager.warrantyList[text] ? I18n.t(ModelManager.warrantyList[text]) : null;
                    // return ModelManager.warrantyList[text];
                }
            },
            {
                title: I18n.t("性质"), dataIndex: 'nature', key: 'nature', width: 100,
                render: (text) => {
                    return ModelManager.natureList[text] ? I18n.t(ModelManager.natureList[text]) : null;
                    // return ModelManager.natureList[text];
                }
            },
            {
                title: I18n.t("品牌"), dataIndex: 'brand', key: 'brand', width: 80,
                render: (text) => {
                    if (text == AppStatus.MKT_DL)
                        return I18n.t("德龙");
                    if (text == AppStatus.MKT_KW)
                        return I18n.t("凯伍德");
                    if (text == AppStatus.MKT_BL)
                        return I18n.t("博朗");
                    return "";
                }
            },
            {
                title: I18n.t("机器型号"), dataIndex: 'productType', key: 'productType', width: 200
            },
            {
                title: I18n.t("机器名称"), dataIndex: 'productName', key: 'productName', width: 150
            },
            {
                title: I18n.t("机器编号"), dataIndex: 'productCode', key: 'productCode', width: 200
            },
            {
                title: I18n.t("接单时间"), dataIndex: 'createDate', key: 'createDate', width: 180,
                render: (text) => {
                    return this.onDateFormat(text, "YYYY.MM.DD HH:mm");
                }
            },
            {
                title: I18n.t("地址"), dataIndex: 'userAddress', key: 'userAddress',
            },
        ];
        return columns;
    };

    checkEditAuth = (param) => {
        if (ModelManager.isAgency()) {
            if ([].includes(param)) {

            }
            return false;
        }
        return true;
    }

    render() {
        const RevisitNotes = [
            "回访注释：未成功接通电话，请点击下方待继续回访；每个工单最多能被回访三次产品。",
            "成功接通后被挂断或不愿评分请填0",
            "前四项，评分范围：满意、一般、不满意",
            "推荐值，评分范围1-10。"
        ];
        const orderColumns = this.initOrderListTableHeader();
        const warrantColumns = this.initWarrantListTableHeader();
        const userColumns = this.initUserListTableHeader();
        let { AgencyCanEdit, otherFeeCanEdit, canEdit, canEditUser, canEditServicePhone, selectRegion } = this.state;
        let app = this.getFieldValue();
        let stationName = ModelManager.getRegionName(app.stationNo);
        // console.log("FixAppEdit app", app)
        // console.log("FixAppEdit", this.state.doc)
        let workScope1, workScope2;
        if (selectRegion) {
            workScope1 = ModelManager.workScopeMap[AppStatus.STATISTIC_EPIDEMIC][selectRegion];
            workScope2 = ModelManager.workScopeMap[AppStatus.STATISTIC_HOLIDAY][selectRegion] || [];
        }
        // console.log("FixAppEdit workScope", workScope1, workScope2)
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100vw" }}>
                {this.state.tipInfo &&
                    <div style={{ position: "fixed", fontSize: 20, fontWeight: "bold", top: 150, left: "calc(50vw - 400px - 170px)", maxWidth: 160 }}>
                        {I18n.t(this.state.tipInfo)}
                    </div>
                }
                {this.state.productTipInfo &&
                    <div style={{ position: "fixed", fontSize: 20, fontWeight: "bold", top: 100, left: "calc(50vw - 400px - 170px)", maxWidth: 160 }}>
                        {I18n.t(this.state.productTipInfo)}
                    </div>
                }
                <div style={{ height: "calc(100vh - 40px)", overflowY: "auto", width: "100%" }}>
                    <Form layout="horizontal"
                        id="FixAppEditForm"
                        className="editForm"
                        ref={comp => this.formRef = comp}
                        style={{
                            backgroundColor: "#FFF",
                            padding: "20px 10px",
                            width: 800,
                            marginLeft: "calc(50vw - 400px)",
                            fontSize: 14,
                        }}
                        onFieldsChange={this.onFieldsChange}
                    >
                        <div style={Styles.contentStyle}>
                            <div style={{ marginTop: 20, marginBottom: 10 }}>
                                <div style={{ textAlign: "center", fontSize: 25, fontWeight: "bold" }}>{I18n.t("维修服务工作单")}</div>
                                <div style={{ textAlign: "center", fontSize: 14 }}>{global.transLabel("热线电话")}400 827 1668</div>
                            </div>
                            <Split />
                            <Row style={Styles.rowStyle}>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("维修站")}</div>
                                        <div style={{ flex: 1, lineHeight: "30px", fontSize: 14 }} >{stationName}</div>
                                    </div>
                                </Col>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("系统单号")}</div>
                                        <Form.Item name="appNo" style={Styles.formItemStyle}>
                                            <Input readOnly style={Styles.inputStyle} />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("服务类型")}</div>
                                        {this.state.print ?
                                            <Input readOnly value={I18n.t(ModelManager.getTypeLabel(app.type, this.state.typeList))} style={Styles.selectStyle} />
                                            :
                                            <Form.Item name="type" style={Styles.formItemStyle}>
                                                <Select allowClear disabled={!canEditUser || !this.state.typeEnabled || !AgencyCanEdit} style={Styles.selectStyle}
                                                    onChange={this.onSelectType}
                                                >
                                                    {
                                                        this.state.typeList.map((item, index) => {
                                                            if (item.label === "请选择") {
                                                                return (
                                                                    <Select.Option key={"type" + index} style={{ display: "none" }} value={item.data}>{I18n.t(item.label)}</Select.Option>
                                                                )
                                                            }
                                                            return (
                                                                <Select.Option key={"type" + index} value={item.data}>{I18n.t(item.label)}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("服务单编号")}</div>
                                        <Form.Item name="formNo" style={Styles.formItemStyle}>
                                            <Input maxLength={7} readOnly={!(canEditUser || app.isReturn) || !AgencyCanEdit || this.state.print} style={Styles.inputStyle} />
                                        </Form.Item>
                                        {this.state.showFormNoAlarm &&
                                            <div style={{ fontSize: 12, color: "red", backgroundColor: "#FFF", display: "flex", alignItems: "center" }}>{I18n.t("只能输入数字")}</div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("客服系统单号")}</div>
                                        <Form.Item name="appNo400" style={Styles.formItemStyle}>
                                            <Input readOnly style={Styles.datePickerStyle} />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("客服系统工号")}</div>
                                        <Form.Item name="agentUniqueParam" style={Styles.formItemStyle}>
                                            <Input readOnly style={Styles.datePickerStyle} />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("小程序单号")}</div>
                                        <Form.Item name="appNoApplet" style={Styles.formItemStyle}>
                                            <Input readOnly style={Styles.datePickerStyle} />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("用户姓名")}</div>
                                        <Form.Item name="userName" style={Styles.formItemStyle}>
                                            <Input readOnly={!(canEditUser || app.isReturn) || !AgencyCanEdit || this.state.print} style={Styles.inputStyle} />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("接单日期")}</div>
                                        {this.state.print ?
                                            <Input readOnly value={this.onDateFormat(app.createDate, dateTimeFormat)} style={Styles.selectStyle} />
                                            :
                                            <Form.Item name="createDate" style={Styles.formItemStyle}>
                                                {/* <Input readOnly value={moment(app.createDate).format(dateTimeFormat)} style={Styles.inputStyle} /> */}
                                                <DatePicker
                                                    placeholder={I18n.t("请选择日期")} disabled
                                                    format={dateTimeFormat}
                                                    style={Styles.datePickerStyle}
                                                />
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("公司名称")}</div>
                                        <Form.Item name="userCorp" style={Styles.formItemStyle}>
                                            <Input readOnly={!(canEditUser || app.isReturn) || !AgencyCanEdit || this.state.print} style={Styles.inputStyle} />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("品牌")}</div>
                                        {this.state.print ?
                                            <div style={{ display: "flex", alignItems: "center", fontSize: 14 }}>
                                                <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="brand" value={1} checked={app.brand === 1} />{I18n.t("德龙")}
                                                <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="brand" value={2} checked={app.brand === 2} />{I18n.t("凯伍德")}
                                                <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="brand" value={3} checked={app.brand === 3} />{I18n.t("博朗")}
                                            </div>
                                            :
                                            <Form.Item name="brand" style={Styles.formItemStyle}>
                                                <Radio.Group disabled={true || !(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit}>
                                                    <Radio value={1}>{I18n.t("德龙")}</Radio>
                                                    <Radio value={2}>{I18n.t("凯伍德")}</Radio>
                                                    <Radio value={3}>{I18n.t("博朗")}</Radio>
                                                    <Radio value={0} style={{ display: "none" }}></Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("移动电话")}</div>
                                        <Form.Item name="userTel" style={Styles.formItemStyle}>
                                            <Input maxLength={20} readOnly={!(canEditUser || app.isReturn) || !AgencyCanEdit || this.state.print} style={Styles.inputStyle}
                                                // onChange={(e) => this.onEditUserTel(e.target.value)}
                                                // onkeyup="this.value=this.value.replace(/[^\d]/g,'') "
                                                onPressEnter={e => this.onEditUserTel(e.target.value)}
                                            />
                                        </Form.Item>
                                        {this.state.showUserTelAlarm &&
                                            <div style={{ fontSize: 12, color: "red", backgroundColor: "#FFF", display: "flex", alignItems: "center" }}>{I18n.t("只能输入数字")}</div>
                                        }
                                    </div>
                                </Col>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("机器型号")}</div>
                                        {this.state.print ?
                                            <Input readOnly value={app.productType} style={Styles.selectStyle} />
                                            :
                                            <Form.Item name="productType" style={Styles.formItemStyle}>
                                                {/* <AutoComplete allowClear
                                                    style={Styles.selectStyle}
                                                    disabled={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit}
                                                    onSearch={this.handleProductTypeSearch}
                                                    onChange={this.onEditProductType}
                                                >
                                                    {
                                                        this.state.ProductTypeOptions.map((item) => {
                                                            return (
                                                                <AutoComplete.Option key={item.model} value={item.model}>{item.model}</AutoComplete.Option>
                                                            )
                                                        })
                                                    }
                                                </AutoComplete> */}
                                                <Select allowClear showSearch
                                                    style={Styles.selectStyle}
                                                    disabled={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit}
                                                    onChange={this.onEditProductType}
                                                    // onSearch={this.handleProductTypeSearch}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                                                    }
                                                >
                                                    {this.state.ProductTypeOptions.map((item) => {
                                                        return (
                                                            <Select.Option key={item.model} value={item.model}>{item.model}</Select.Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("固定电话")}</div>
                                        <Form.Item name="userExt" style={Styles.formItemStyle}>
                                            <Input maxLength={15} readOnly={!(canEditUser || app.isReturn) || !AgencyCanEdit || this.state.print} style={Styles.inputStyle} />
                                        </Form.Item>
                                        {this.state.showUserExtAlarm &&
                                            <div style={{ fontSize: 12, color: "red", backgroundColor: "#FFF", display: "flex", alignItems: "center" }}>{I18n.t("只能输入数字")}0-9*#-</div>
                                        }
                                    </div>
                                </Col>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("机器名称")}</div>
                                        {this.state.print ?
                                            <Input readOnly value={app.productName} style={Styles.selectStyle} />
                                            :
                                            <Form.Item name="productName" style={Styles.formItemStyle}>
                                                <Select allowClear disabled={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit} style={Styles.selectStyle}
                                                    onChange={this.productName_changeHandler}>
                                                    {ModelManager.modelList.map((item) => {
                                                        if (item === "请选择") {
                                                            return (
                                                                <Select.Option key={item} style={{ display: "none" }} value={I18n.t(item)}>{I18n.t(item)}</Select.Option>
                                                            )
                                                        }
                                                        return (
                                                            <Select.Option key={item} value={I18n.t(item)}>{I18n.t(item)}</Select.Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.label2Style}>{global.transLabel("联系地址")}</div>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                            {/* <div style={Styles.colStyle}> */}
                                            <div style={{ display: "flex", fontSize: 14 }}>
                                                {this.state.print ?
                                                    <Input readOnly value={I18n.t(app.userProvince)} style={Styles.select2Style} />
                                                    :
                                                    <Form.Item name="userProvince" style={Styles.formItemStyle}>
                                                        <Select placeholder={global.isHK ? I18n.t("区域") : I18n.t("省")} allowClear disabled={!(canEditUser || app.isReturn) || !AgencyCanEdit} style={Styles.selectStyle}
                                                            onChange={this.provinceChangeHandler}>
                                                            {
                                                                global.provinceList.map((item) => {
                                                                    if (item === "请选择") {
                                                                        return (
                                                                            <Select.Option key={item} style={{ display: "none" }} value={item}>{I18n.t(item)}</Select.Option>
                                                                        )
                                                                    }
                                                                    return (
                                                                        <Select.Option key={item} value={item}>{I18n.t(item)}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                }
                                                {!global.isHK &&
                                                    <div style={{ display: "flex", alignItems: "center", padding: 5 }}>{I18n.t("省")}</div>
                                                }
                                                {this.state.print ?
                                                    <Input readOnly value={I18n.t(app.userCity)} style={Styles.select2Style} />
                                                    :
                                                    <Form.Item name="userCity" style={Styles.formItemStyle}>
                                                        <Select placeholder={global.isHK ? I18n.t("分区") : I18n.t("市")} allowClear disabled={!(canEditUser || app.isReturn) || !AgencyCanEdit} style={Styles.selectStyle}>
                                                            {
                                                                this.state.userCityDataProvider.map((item) => {
                                                                    if (item === "请选择") {
                                                                        return (
                                                                            <Select.Option key={item} style={{ display: "none" }} value={item}>{I18n.t(item)}</Select.Option>
                                                                        )
                                                                    }
                                                                    return (
                                                                        <Select.Option key={item} value={item}>{I18n.t(item)}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                }
                                                {!global.isHK &&
                                                    <div style={{ display: "flex", alignItems: "center", padding: 5 }}>{I18n.t("市")}</div>
                                                }
                                            </div>
                                            <Form.Item name="userAddress" style={{ flex: 1, marginBottom: 0, marginTop: 3 }}>
                                                <Input.TextArea rows={2} maxLength={120} readOnly={!(canEditUser || app.isReturn) || !AgencyCanEdit || this.state.print} style={this.state.print ? Styles.textArea2Style : Styles.textAreaStyle} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12} style={Styles.halfColStyle2}>
                                    <div style={Styles.colItem2Style}>
                                        <div style={Styles.labelStyle}>{global.transLabel("机器编号")}</div>
                                        <Form.Item name="productCode" style={Styles.formItemStyle}>
                                            <Input maxLength={19} readOnly={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit || this.state.print} style={Styles.inputStyle} onChange={e => this.onEditProductCode(e.target.value)} />
                                        </Form.Item>
                                        {this.state.showProductCodeAlarm &&
                                            <div style={{ display: "flex", fontSize: 12, alignItems: "center", color: "red", backgroundColor: "#FFF" }}>{I18n.t("只能输入英文字母或数字")} 0-9\a-z\A-Z</div>
                                        }
                                    </div>
                                    <div style={Styles.colItem2Style}>
                                        <div style={Styles.label2Style}>{global.transLabel("随机附件")}</div>
                                        <Form.Item name="accessories" style={Styles.formItemStyle}>
                                            <Input.TextArea rows={2} maxLength={40} readOnly={!(canEditUser || app.isReturn) || !AgencyCanEdit || this.state.print} style={this.state.print ? Styles.textArea2Style : Styles.textAreaStyle} />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("产品性质")}</div>
                                        {this.state.print ?
                                            <div style={{ display: "flex", alignItems: "center", fontSize: 14 }}>
                                                <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="nature" value={1} checked={app.nature === 1} />{I18n.t("售前")}
                                                <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="nature" value={2} checked={app.nature === 2} />{I18n.t("售后")}
                                                <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="nature" value={3} checked={app.nature === 3} />{I18n.t("样机")}
                                                {/* <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="nature" value={4} checked={app.nature === 4} />{I18n.t("折扣机")} */}
                                            </div>
                                            :
                                            <Form.Item name="nature" style={Styles.formItemStyle}>
                                                <Radio.Group disabled={!(canEdit || canEditUser) || !AgencyCanEdit || this.state.print} onChange={this.onNatureGroupChange}>
                                                    <Radio value={1}>{I18n.t("售前")}</Radio>
                                                    <Radio value={2}>{I18n.t("售后")}</Radio>
                                                    <Radio value={3}>{I18n.t("样机")}</Radio>
                                                    {/* <Radio value={4}>{I18n.t("折扣机")}</Radio> */}
                                                    <Radio value={0} style={{ display: "none" }}></Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("机身编号")}</div>
                                        {this.state.photo2LabelVisible &&
                                            <Form.Item name="photo2" style={Styles.formItemStyle}>
                                                <Input readOnly placeholder={I18n.t("未上传")} value={this.state.photo2Label} style={Styles.inputStyle} />
                                            </Form.Item>
                                        }
                                        {this.state.photo2BtnVisible &&
                                            <Button onClick={() => this.showImage(2)}>{I18n.t("查看")}</Button>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            {(false == global.isHK) &&
                                <>
                                    <Row style={Styles.rowStyle}>
                                        <Col span={12} style={Styles.halfColStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.labelStyle}>{global.transLabel("插座检查")}</div>
                                                {this.state.photo3LabelVisible &&
                                                    <Form.Item name="photo3" style={Styles.formItemStyle}>
                                                        <Input readOnly placeholder={I18n.t("未上传")} value={this.state.photo3Label} style={Styles.inputStyle} />
                                                    </Form.Item>
                                                }
                                                {this.state.photo3BtnVisible &&
                                                    <Button onClick={() => this.showImage(3)}>{I18n.t("查看")}</Button>
                                                }
                                            </div>
                                        </Col>
                                        <Col span={12} style={Styles.halfColStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.labelStyle}>{global.transLabel("插座故障")}</div>
                                                <Form.Item name="outletFailure" style={Styles.formItemStyle}>
                                                    <Select
                                                        allowClear={this.appIsUndone(app)}
                                                        // allowClear={app.status < AppStatus.S_FINISH}
                                                        disabled={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit} style={Styles.selectStyle}>
                                                        {
                                                            ModelManager.outletFailureList.map((item, index) => {
                                                                if (item === "请选择") {
                                                                    return (
                                                                        <Select.Option key={item} style={{ display: "none" }} value={index}>{I18n.t(item)}</Select.Option>
                                                                    )
                                                                }
                                                                return (
                                                                    <Select.Option key={item} value={index}>{I18n.t(item)}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={Styles.rowStyle}>
                                        <Col span={12} style={Styles.halfColStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.labelStyle}>{global.transLabel("纸质工单")}</div>
                                                {this.state.photo5LabelVisible &&
                                                    <Form.Item name="photo5" style={Styles.formItemStyle}>
                                                        <Input readOnly placeholder={I18n.t("未上传")} value={this.state.photo5Label} style={Styles.inputStyle} />
                                                    </Form.Item>
                                                }
                                                {this.state.photo5BtnVisible &&
                                                    <Button onClick={() => this.showImage(5)}>{I18n.t("查看")}</Button>
                                                }
                                            </div>
                                        </Col>
                                        <Col span={12} style={Styles.halfColStyle}>
                                            {/* <div style={Styles.colItemStyle}>
                                                <div style={Styles.labelStyle}>{global.transLabel("首次维修")}</div>
                                                {this.state.print ?
                                                    <div style={{ display: "flex", alignItems: "center", fontSize: 14, height: 30 }}>
                                                        <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="firstFix" value={1} checked={app.firstFix} />{I18n.t("是")}
                                                        <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="firstFix" value={0} checked={!app.firstFix} />{I18n.t("否")}
                                                    </div>
                                                    :
                                                    <div style={{ display: "flex", alignItems: "center", flex: 1, }}>
                                                        <Form.Item name="firstFix" style={Styles.formItemStyle}>
                                                            <Radio.Group disabled={!(canEdit || canEditUser) || this.state.print} >
                                                                <Radio value={1}>{I18n.t("是")}</Radio>
                                                                <Radio value={0}>{I18n.t("否")}</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                        <span style={{ color: "red" }}>【{I18n.t("不包括调试")}】</span>
                                                    </div>
                                                }
                                            </div> */}
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.labelStyle}>{global.transLabel("两次或以上维修")}</div>
                                                {this.state.print ?
                                                    <div style={{ display: "flex", alignItems: "center", fontSize: 14, height: 30 }}>
                                                        <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="multipleFix" value={1} checked={app.multipleFix} />{I18n.t("是")}
                                                        <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="multipleFix" value={0} checked={!app.multipleFix} />{I18n.t("否")}
                                                    </div>
                                                    :
                                                    <div style={{ display: "flex", alignItems: "center", flex: 1, }}>
                                                        <Form.Item name="multipleFix" style={Styles.formItemStyle}>
                                                            <Radio.Group disabled={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit || this.state.print} >
                                                                <Radio value={1}>{I18n.t("是")}</Radio>
                                                                <Radio value={0}>{I18n.t("否")}</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                        <span style={{ color: "red" }}>【{I18n.t("不包括调试")}】</span>
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            }
                            {(this.showFlowerPhoto(app)) &&
                                <Row style={Styles.rowStyle}>
                                    <Col span={12} style={Styles.halfColStyle}>
                                        <div style={Styles.colItemStyle}>
                                            <div style={Styles.labelStyle}>{global.transLabel("拉花照片")}</div>
                                            {this.state.photo8LabelVisible &&
                                                <Form.Item name="photo8" style={Styles.formItemStyle}>
                                                    <Input readOnly placeholder={I18n.t("未上传")} value={this.state.photo8Label} style={Styles.inputStyle} />
                                                </Form.Item>
                                            }
                                            {this.state.photo8BtnVisible &&
                                                <Button onClick={() => this.showImage(8)}>{I18n.t("查看")}</Button>
                                            }
                                        </div>
                                    </Col>
                                    <Col span={12} style={Styles.halfColStyle}>
                                        <div style={Styles.colItemStyle}>
                                            <div style={Styles.labelStyle}>{global.transLabel("拉花费用")}</div>
                                            <Form.Item name="flowerFee" style={Styles.formItemStyle}>
                                                {this.state.print ?
                                                    <Input readOnly value={app.flowerFee} style={Styles.selectStyle} />
                                                    :
                                                    <Form.Item name="flowerFee" style={Styles.formItemStyle}>
                                                        <InputNumber readOnly={!(ModelManager.userIsZB() && app.photo8 != null && app.photo8 != "")} style={Styles.datePickerStyle} />
                                                    </Form.Item>
                                                }
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {(true == global.isHK) &&
                                <Row style={Styles.rowStyle}>
                                    <Col span={24} style={Styles.halfColStyle}>
                                        <div style={Styles.colItemStyle}>
                                            <div style={Styles.labelStyle}>{global.transLabel("收机方法")}</div>
                                            <Form.Item name="getBackHow" style={Styles.formItemStyle}>
                                                <Select
                                                    allowClear={this.appIsUndone(app)}
                                                    disabled={!(canEdit || canEditUser) || !AgencyCanEdit} style={Styles.selectStyle}>
                                                    {ModelManager.GetBackHowList.map((item, index) => {
                                                        return (
                                                            <Select.Option key={item} value={item}>{I18n.t(item)}</Select.Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            <Row style={Styles.rowStyle}>
                                <Col span={24} style={Styles.fullColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("保修依据")}</div>
                                        {this.state.print ?
                                            <Input readOnly value={I18n.t(ModelManager.guaranteeList[app.guarantee])} style={Styles.selectStyle} />
                                            :
                                            <Form.Item name="guarantee" style={{ marginBottom: 0 }}>
                                                <Select allowClear disabled={!(canEditUser && this.state.guaranteeEnabled) || !AgencyCanEdit || !app.nature || app.nature === 1 || app.servicePhone}
                                                    style={{ width: "max-content", minWidth: 150 }}
                                                    onChange={() => this.onSelectGuarantee()}>
                                                    {
                                                        ModelManager.guaranteeList.map((item, index) => {
                                                            if (item === "请选择") {
                                                                return (
                                                                    <Select.Option key={item} value={index} style={{ display: "none" }}>{I18n.t(item)}</Select.Option>
                                                                );
                                                            }
                                                            if (global.isHK && item !== "保修卡号查询" && item !== "港澳地区凭证") {
                                                                return (
                                                                    <Select.Option key={item} value={index} style={{ display: "none" }}>{I18n.t(item)}</Select.Option>
                                                                );
                                                            }
                                                            // 20220614【EDG250】这个型号，不能选择：出仓日期保修
                                                            if (item === "出仓日期保修" && app.productType === "EDG250") {
                                                                return (
                                                                    <Select.Option key={item} value={index} disabled>{I18n.t(item)}</Select.Option>
                                                                );
                                                            }
                                                            //     if (!global.isHK && item === "海外机身号查询") {
                                                            //         return (
                                                            //             <Select.Option key={item} value={index} disabled>{I18n.t(item)}</Select.Option>
                                                            //         );
                                                            //     }
                                                            return (
                                                                <Select.Option key={item} value={index}>{I18n.t(item)}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        }
                                        {/* {["国内发票", "港澳地区凭证", "其他凭证上传（需总部确认）", "京东自营确认"].indexOf(this.state.guarantee) !== -1 && */}
                                        {this.state.guaranteeBuyDateVisible && this.state.guaranteeEnabled &&
                                            <>
                                                {this.state.print ?
                                                    <Input readOnly value={this.onDateFormat(app.guaranteeBuyDate, "YYYY-MM-DD")} style={app.guaranteeBuyDate ? Styles.selectStyle : { display: "none" }} />
                                                    :
                                                    <Form.Item name="guaranteeBuyDate" style={{ marginBottom: 0 }}>
                                                        <DatePicker placeholder={I18n.t("请选择日期")} locale={global.DateLocal}
                                                            // disabled={!(this.state.guaranteeHBoxEnabled && canEditUser)}
                                                            disabled={
                                                                !(this.state.guaranteeHBoxEnabled && canEditUser) || !AgencyCanEdit ||
                                                                ([9, 12].includes(app.guarantee) && ModelManager.loginUser.regionId !== 2)
                                                            }
                                                            disabledDate={this.disabledDate2}
                                                            format={"YYYY-MM-DD"}
                                                            style={{ border: "none", borderRadius: 0, marginLeft: 3 }}
                                                        />
                                                    </Form.Item>
                                                }
                                            </>
                                        }
                                        {/* {["国内发票", "订单号查询", "保修卡号查询", "港澳地区凭证", "其他凭证上传（需总部确认）"].indexOf(this.state.guarantee) !== -1 && */}
                                        {!global.isHK && this.state.photo1LabelVisible &&
                                            <div style={{ display: "flex", alignItems: "center", padding: 3, fontSize: 14 }}>{I18n.t("未上传")}</div>
                                        }
                                        {global.isHK && this.state.photo1LabelVisible &&
                                            <Upload
                                                action={global.ServerUrl + "media/upload"}
                                                headers={{ 'x-access-token': window.token }}
                                                accept="image/*"
                                                showUploadList={false}
                                                onChange={(info) => this.handlePhotoChange(info, 1)}
                                            >
                                                <Button loading={this.state.uploading}>{I18n.t("上传")}</Button>
                                            </Upload>
                                        }
                                        {this.state.photo1BtnVisible &&
                                            <Button loading={this.state.uploading} onClick={() => this.showImage(1)}>{I18n.t("查看")}</Button>
                                        }
                                        {/* {["其他凭证上传（需总部确认）", "总部凭证确认", "总部保修确认"].indexOf(this.state.guarantee) !== -1 && */}
                                        {/* this.state.zbOptionVisible && this.state.guaranteeEnabled && */}
                                        {[6, 9, 12].includes(app.guarantee) && this.state.guaranteeEnabled &&
                                            <>
                                                {this.state.print ?
                                                    <Input readOnly value={I18n.t(ModelManager.zbOptionList[app.zbOption])} style={app.zbOption ? Styles.selectStyle : { display: "none" }} />
                                                    :
                                                    <Form.Item name="zbOption" style={{ marginBottom: 0 }}>
                                                        <Select allowClear
                                                            // disabled={!this.state.zbOptionEnabled || !this.state.guaranteeHBoxEnabled}
                                                            disabled={!(ModelManager.loginUser.role == Staff.ROLE_ADMIN || ModelManager.loginUser.regionId == 2) || !AgencyCanEdit || !this.state.guaranteeHBoxEnabled}
                                                            style={{ width: 110, marginLeft: 3 }}
                                                            onChange={this.onSelectZbOption}>
                                                            {
                                                                ModelManager.zbOptionList.map((item, index) => {
                                                                    if (item === "请选择") {
                                                                        return (
                                                                            <Select.Option key={item} value={index} style={{ display: "none" }}>{I18n.t(item)}</Select.Option>
                                                                        );
                                                                    }
                                                                    return (
                                                                        <Select.Option key={item} value={index}>{I18n.t(item)}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                }
                                            </>
                                        }
                                        {/* {["订单号查询", "手机号查询", "保修卡号查询", "海外机身号查询", "京东自营确认"].indexOf(this.state.guarantee) !== -1 && */}
                                        {/* {this.state.guaranteeOrderInputVisible && */}
                                        {/* "订单号查询", "手机号查询", "保修卡号查询", "海外机身号查询" */}
                                        {/* {(app.guarantee == 2 || app.guarantee == 3 || app.guarantee == 4 || app.guarantee == 8) && */}
                                        {/* {([2, 3, 4, 8].includes(app.guarantee)) && */}
                                        {([2, 3, 4].includes(app.guarantee)) &&
                                            <Form.Item name="ecomOrderCode" style={{ marginBottom: 0, marginLeft: 3, width: 130 }}>
                                                <Input disabled={!this.state.guaranteeHBoxEnabled || !AgencyCanEdit} style={Styles.inputStyle} onChange={(e) => this.onOrderInputChange(e.target.value)} />
                                            </Form.Item>
                                        }
                                        {/* 京东自营 */}
                                        {app.guarantee == 11 &&
                                            <Form.Item name="ecomOrderCode" style={{ marginBottom: 0, marginLeft: 3, width: 130 }}>
                                                <Input disabled={!this.state.guaranteeHBoxEnabled || !AgencyCanEdit} style={Styles.inputStyle} placeholder="单号" onChange={(e) => this.onOrderInputChange(e.target.value, "jd")} />
                                            </Form.Item>
                                        }
                                        {/* ["订单号查询", "手机号查询", "保修卡号查询", "海外机身号查询"] */}
                                        {/* {this.state.orderSearchBtnVisible && */}
                                        {/* {([2, 3, 4, 8].includes(app.guarantee)) && */}
                                        {([2, 3, 4].includes(app.guarantee)) &&
                                            <Button disabled={!this.state.guaranteeHBoxEnabled || !(canEdit || canEditUser) || !AgencyCanEdit} style={{ marginLeft: 3 }} onClick={() => this.showOrderSearchView()}>{I18n.t("搜索")}</Button>
                                        }
                                        {/* ["港澳地区凭证", "总部凭证确认", "京东自营确认", "总部保修确认"] */}
                                        {([5, 9, 12].includes(app.guarantee)) && this.state.guaranteeEnabled &&
                                            <>
                                                <div style={{ display: "flex", alignItems: "center", padding: 3, fontSize: 14 }}>{I18n.t("确认人")}</div>
                                                <Form.Item name="confirmUser" style={{ marginBottom: 0, marginLeft: 3, width: 130 }}>
                                                    <Input disabled={!this.state.guaranteeHBoxEnabled} readOnly={!(ModelManager.loginUser.role === Staff.ROLE_ADMIN || ModelManager.loginUser.regionId === 2) || !AgencyCanEdit} style={Styles.inputStyle} />
                                                </Form.Item>
                                            </>
                                        }
                                        {app.guarantee == 11 &&
                                            <>
                                                {app.photo6 && app.photo6 != "" ?
                                                    <Button loading={this.state.uploading} onClick={() => this.showImage(6)}>{I18n.t("查看")}</Button>
                                                    :
                                                    <div style={{ display: "flex", alignItems: "center", padding: 3, fontSize: 14 }}>{I18n.t("未上传")}</div>
                                                }
                                            </>
                                        }
                                        {/* {["港澳地区凭证", "总部凭证确认", "京东自营确认", "总部保修确认"].indexOf(this.state.guarantee) !== -1 && */}
                                        {/* {this.state.zbConfirmInputVisible && this.state.guaranteeEnabled && */}
                                        {/* {([5, 9, 11, 12].includes(app.guarantee)) && this.state.guaranteeEnabled &&
                                            <Form.Item name="confirmUser" style={{ marginBottom: 0, marginLeft: 3, width: 130 }}>
                                                <Input disabled={!this.state.guaranteeHBoxEnabled} readOnly={!(ModelManager.loginUser.role === Staff.ROLE_ADMIN || ModelManager.loginUser.regionId === 2)} style={Styles.inputStyle} />
                                            </Form.Item>
                                        } */}
                                        <div style={{ flex: 1 }} />
                                        {false == this.state.guaranteeEnabled &&
                                            <>
                                                {this.state.print ?
                                                    <div style={{ display: "flex", alignItems: "center", fontSize: 14 }}>
                                                        <input readOnly name="noWarrantDetail" value={I18n.t(app.noWarrantDetail)} />
                                                    </div>
                                                    :
                                                    <Form.Item name="noWarrantDetail" style={{ marginBottom: 0, display: 'flex', alignItems: "center" }}>
                                                        <Select disabled={this.state.guaranteeEnabled || !AgencyCanEdit}
                                                            style={{ width: 210, marginLeft: 3 }}
                                                            onChange={(e) => this.onSelectNoWarrantOption(e)}>
                                                            {
                                                                ModelManager.noWarrantOptionList.map((item, index) => {
                                                                    return (
                                                                        <Select.Option key={index} value={item}>{I18n.t(item)}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                }
                                            </>
                                        }
                                        {this.state.print ?
                                            <div style={{ display: "flex", alignItems: "center", fontSize: 14 }}>
                                                <input readOnly type="checkbox" name="noGuarantee" value="noGuarantee" checked={app.noGuarantee} />{I18n.t("非保修")}
                                            </div>
                                            :
                                            <Form.Item name="noGuarantee" valuePropName="checked" style={{ marginBottom: 0, marginLeft: 3 }}>
                                                <Checkbox disabled={!(canEdit || canEditUser) || !AgencyCanEdit} onChange={this.onNoGuarantee} style={{ fontSize: 14 }} >{I18n.t("非保修")}</Checkbox>
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            {/* {["国内发票"].indexOf(this.state.guarantee) !== -1 && */}
                            {/* {this.state.kpUnitRowVisible && */}
                            {app.guarantee == 1 &&
                                <Row style={Styles.rowStyle}>
                                    <Col span={24} style={Styles.fullColStyle}>
                                        <div style={Styles.colItemStyle}>
                                            <div style={Styles.labelStyle}>{global.transLabel("开票单位")}</div>
                                            <Form.Item name="invoiceInfo" style={Styles.formItemStyle}>
                                                <Input readOnly={!(canEditUser || canEditUser) || !AgencyCanEdit || this.state.print} style={Styles.inputStyle} />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {this.state.orderHeadDivVisible && this.state.selectedOrder &&
                                <div>{this.renderOrderItem(this.state.selectedOrder)}</div>
                            }
                            <Row style={Styles.rowStyle}>
                                <Col span={24} style={Styles.fullColStyle}>
                                    <div style={{
                                        width: "100%",
                                        border: "0.5px solid #333",
                                        margin: .5,
                                        minHeight: 32,
                                    }}>
                                        <div style={{ display: "flex", }}>
                                            {this.state.print ?
                                                <div style={{ display: "flex", alignItems: "center", fontSize: 14 }}>
                                                    <input readOnly type="checkbox" name="isWarrant" value="isWarrant" checked={app.isWarrant} />{I18n.t("延保")}
                                                </div>
                                                :
                                                <Form.Item name="isWarrant" valuePropName="checked" style={{ marginBottom: 0, marginLeft: 3 }}>
                                                    <Checkbox
                                                        style={{ fontSize: 14 }}
                                                        disabled={!this.state.warrantVBoxEnabled || !(canEdit || canEditUser) || !AgencyCanEdit}
                                                        onChange={this.onWarrant}
                                                    >{I18n.t("延保")}</Checkbox>
                                                </Form.Item>
                                            }
                                            {this.state.warrantHBoxVisible &&
                                                <Input ref={inst => this.warrantInput = inst} style={{ width: 150, border: "none", borderRadius: 0 }} readOnly={!canEditUser || !AgencyCanEdit || this.state.print} placeholder={I18n.t("订单编号/手机号")} />
                                            }
                                            {this.state.warrantHBoxVisible &&
                                                <Button disabled={!(canEdit || canEditUser) || !AgencyCanEdit} onClick={() => this.showWarrantSearchView()}>{I18n.t("搜索")}</Button>
                                            }
                                        </div>
                                        {this.state.warrantTxtVisible &&
                                            <Input.TextArea readOnly value={this.state.warrantTxt} style={this.state.print ? Styles.textArea2Style : { flex: 1, border: "none", borderRadius: 0, borderTop: "1px solid #333" }} />
                                        }
                                    </div>
                                </Col>
                            </Row>

                            <Row style={Styles.rowStyle}>
                                <Col span={24} style={Styles.fullColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.label2Style}>{global.transLabel("故障描述")}</div>
                                        <Form.Item name="description" style={Styles.formItemStyle}>
                                            <Input.TextArea readOnly={!(canEditUser || app.isReturn) || !AgencyCanEdit || this.state.print} style={this.state.print ? Styles.textArea2Style : Styles.textAreaStyle} />
                                        </Form.Item>
                                        {this.state.print ?
                                            <div style={{ display: "flex", alignItems: "center", fontSize: 14 }}>
                                                <input readOnly type="checkbox" name="servicePhone" value="servicePhone" checked={app.servicePhone} />{I18n.t("电话完成")}
                                            </div>
                                            :
                                            <Form.Item name="servicePhone" valuePropName="checked" style={{ marginBottom: 0, marginLeft: 3 }}>
                                                <Checkbox
                                                    style={{ fontSize: 14 }}
                                                    // disabled={!(canEdit || canEditUser || app.isReturn || canEditServicePhone) || app.nature !== 2}
                                                    disabled={this.onDisableEditServicePhone()}
                                                    onChange={this.onChangePhone}
                                                >{I18n.t("电话完成")}</Checkbox>
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("出仓日期")}</div>
                                        {this.state.print ?
                                            <Input readOnly value={this.onDateFormat(app.purchaseDate, dateFormat)} style={Styles.selectStyle} />
                                            :
                                            <Form.Item name="purchaseDate" style={Styles.formItemStyle}>
                                                <DatePicker placeholder={I18n.t("请选择日期")} disabled style={Styles.datePickerStyle}
                                                    format={dateFormat}
                                                />
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.label2Style}>{global.transLabel("预计完成日期")}</div>
                                        {this.state.print ?
                                            <Input readOnly value={this.onDateFormat(app.expectedDate, dateFormat)} style={Styles.selectStyle} />
                                            :
                                            <Form.Item name="expectedDate" style={Styles.formItemStyle}>
                                                <DatePicker placeholder={I18n.t("请选择日期")} locale={global.DateLocal}
                                                    disabled={!canEditUser || !AgencyCanEdit} allowClear={false}
                                                    style={Styles.datePickerStyle}
                                                    format={dateFormat}
                                                    disabledDate={this.disabledDate3}
                                                />
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("维修费用")}</div>
                                        {this.state.print ?
                                            <Input readOnly value={app.fixFee} style={Styles.selectStyle} />
                                            :
                                            <Form.Item name="fixFee" style={Styles.formItemStyle}>
                                                {/* <InputNumber readOnly={!this.state.fixFeeEditable} style={Styles.datePickerStyle} /> */}
                                                <InputNumber readOnly style={Styles.datePickerStyle} />
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.label2Style}>{global.transLabel("实际完成日期")}</div>
                                        {this.state.print ?
                                            <Input readOnly value={this.onDateFormat(app.fixDate, dateTimeFormat)} style={Styles.selectStyle} />
                                            :
                                            <Form.Item name="fixDate" style={Styles.formItemStyle}>
                                                <DatePicker placeholder={I18n.t("请选择日期")} disabled style={Styles.datePickerStyle}
                                                    format={dateTimeFormat}
                                                />
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div style={Styles.contentStyle}>
                            <Row style={Styles.rowStyle}>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("预约上门时间")}</div>
                                        {this.state.print ?
                                            <Input readOnly value={this.onDateFormat(app.appointment, dateTimeFormat)} style={Styles.selectStyle} />
                                            :
                                            <Form.Item name="appointment" style={Styles.formItemStyle}>
                                                {/* 20220830 维修工单页面 修改预约上门时间需要具体到时间 */}
                                                <DatePicker placeholder={I18n.t("请选择日期")} locale={global.DateLocal}
                                                    disabled={app.status !== AppStatus.S_APPOINT || app.servicePhone || !AgencyCanEdit} allowClear={false}
                                                    style={Styles.datePickerStyle}
                                                    format={dateTimeFormat}
                                                    showTime={{ format: 'HH:mm' }}
                                                    disabledDate={this.disabledDate4}
                                                />
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.label2Style}>{global.transLabel("预约确认日期")}</div>
                                        {this.state.print ?
                                            <Input readOnly value={this.onDateFormat(app.appointmentDate, dateTimeFormat)} style={Styles.selectStyle} />
                                            :
                                            <Form.Item name="appointmentDate" style={Styles.formItemStyle}>
                                                <DatePicker placeholder={I18n.t("请选择日期")} disabled style={Styles.datePickerStyle}
                                                    format={dateTimeFormat}
                                                />
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("上门公里数")}</div>
                                        {/* 20220830 服务类型从【上门】改为【接修】【上门公里数】和【上门费】清零 暗掉不填 */}
                                        {this.state.print ?
                                            <Input readOnly value={app.distance} style={Styles.selectStyle} />
                                            :
                                            <Form.Item name="distance" style={Styles.formItemStyle}>
                                                <InputNumber step={1} min={0}
                                                    readOnly={!(this.state.distanceEditable || app.isReturn) || !AgencyCanEdit || app.type !== AppStatus.TYPE_VISIT}
                                                    style={Styles.datePickerStyle}
                                                    onChange={this.distance_changeHandler}
                                                />
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.label2Style}>{global.transLabel("上门费用")}</div>
                                        {this.state.print ?
                                            <Input readOnly value={app.visitFee} style={Styles.selectStyle} />
                                            :
                                            <Form.Item name="visitFee" style={Styles.formItemStyle}>
                                                <InputNumber max={999} readOnly style={Styles.datePickerStyle} />
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={24} style={Styles.fullColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("维修类型")}</div>
                                        {this.state.print ?
                                            <div style={{ display: "flex", alignItems: "center", fontSize: 14, height: 30 }}>
                                                <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="fixType" value={AppStatus.TYPE_PART} checked={app.fixType === AppStatus.TYPE_PART} />{I18n.t("更换配件")}
                                                <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="fixType" value={AppStatus.TYPE_MAINTEN} checked={app.fixType === AppStatus.TYPE_MAINTEN} />{I18n.t("维护保养")}
                                                <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="fixType" value={AppStatus.TYPE_NONE} checked={app.fixType === AppStatus.TYPE_NONE} />{I18n.t("无问题")}
                                                <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="fixType" value={AppStatus.TYPE_DEBUG} checked={app.fixType === AppStatus.TYPE_DEBUG} />{I18n.t("调试")}
                                                <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="fixType" value={AppStatus.TYPE_MACHINE} checked={app.fixType === AppStatus.TYPE_MACHINE} />{I18n.t("更换整机")}
                                                <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="fixType" value={AppStatus.TYPE_DETECT} checked={app.fixType === AppStatus.TYPE_DETECT} />{I18n.t("检测")}
                                                <input style={{ marginTop: 0 }} disabled readOnly type="radio" name="fixType" value={AppStatus.TYPE_FIX} checked={app.fixType === AppStatus.TYPE_FIX} />{I18n.t("维修")}
                                                {/* <Input readOnly value={ModelManager.fixTypeList[app.fixType]} style={Styles.selectStyle} /> */}
                                            </div>
                                            :
                                            <Form.Item name="fixType" style={Styles.formItemStyle}>
                                                <Radio.Group style={{ fontSize: 14 }} disabled={!(canEdit || app.isReturn) || !AgencyCanEdit || this.state.print} onChange={this.fixType_changeHandler}>
                                                    <Radio value={AppStatus.TYPE_PART}>{I18n.t("更换配件")}</Radio>
                                                    <Radio value={AppStatus.TYPE_MAINTEN}>{I18n.t("维护保养")}</Radio>
                                                    <Radio value={AppStatus.TYPE_NONE}>{I18n.t("无问题")}</Radio>
                                                    <Radio value={AppStatus.TYPE_DEBUG}>{I18n.t("调试")}</Radio>
                                                    <Radio value={AppStatus.TYPE_MACHINE}>{I18n.t("更换整机")}</Radio>
                                                    <Radio value={AppStatus.TYPE_DETECT}>{I18n.t("检测")}</Radio>
                                                    <Radio value={AppStatus.TYPE_FIX}>{I18n.t("维修")}</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            {app.fixType === AppStatus.TYPE_MACHINE &&
                                <Row style={Styles.rowStyle}>
                                    <Col span={24} style={Styles.fullColStyle}>
                                        <div style={Styles.colItemStyle}>
                                            <div style={Styles.labelStyle}>{global.transLabel("EKP单号")}</div>
                                            {this.state.print ?
                                                <Input readOnly value={app.EKPNo} style={Styles.selectStyle} />
                                                :
                                                <Form.Item name="EKPNo" style={Styles.formItemStyle}>
                                                    <Input style={Styles.datePickerStyle} readOnly={!canEditUser || !AgencyCanEdit || this.state.print || ModelManager.loginUser.regionId != 2} placeholder={I18n.t("EKP单号")} />
                                                </Form.Item>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {this.state.autoCoffeeInfoRowVisible && !ProductNames.some(codeItem => codeItem === app.productName) &&
                                <Row style={{ display: "flex" }}>
                                    <Col span={6} style={this.state.print ? { width: "25%" } : {}}>
                                        <div style={Styles.colItem2Style}>
                                            <div style={Styles.label2Style}>{global.transLabel("制作杯数")}</div>
                                            {this.state.print ?
                                                <Input readOnly value={app.cup} style={Styles.select2Style} />
                                                :
                                                <Form.Item name="cup" style={Styles.formItemStyle}>
                                                    <InputNumber min={0} max={99999} readOnly={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit} style={Styles.datePickerStyle} />
                                                </Form.Item>
                                            }
                                        </div>
                                    </Col>
                                    <Col span={6} style={this.state.print ? { width: "25%" } : {}}>
                                        <div style={Styles.colItem2Style}>
                                            <div style={Styles.label2Style}>{global.transLabel("除垢次数")}</div>
                                            {this.state.print ?
                                                <Input readOnly value={app.clean} style={Styles.select2Style} />
                                                :
                                                <Form.Item name="clean" style={Styles.formItemStyle}>
                                                    <InputNumber min={0} max={99999} readOnly={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit} style={Styles.datePickerStyle} />
                                                </Form.Item>
                                            }
                                        </div>
                                    </Col>
                                    <Col span={6} style={this.state.print ? { width: "25%" } : {}}>
                                        <div style={Styles.colItem2Style}>
                                            <div style={Styles.label2Style}>{global.transLabel("使用水量")}</div>
                                            {this.state.print ?
                                                <Input readOnly value={app.warter} style={Styles.select2Style} />
                                                :
                                                <Form.Item name="warter" style={Styles.formItemStyle}>
                                                    <InputNumber min={0} max={99999}
                                                        readOnly={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit}
                                                        style={Styles.datePickerStyle}
                                                    />
                                                </Form.Item>
                                            }
                                        </div>
                                    </Col>
                                    <Col span={6} style={this.state.print ? { width: "25%" } : {}}>
                                        <div style={Styles.colItem2Style}>
                                            {/* 20220728 【蒸汽次数】修改为【蒸汽水量】 */}
                                            <div style={Styles.label2Style}>{""}</div>
                                            <Form.Item name="steam" style={Styles.formItemStyle} />
                                            {/* <div style={Styles.label2Style}>{global.transLabel("蒸汽水量")}</div>
                                            {this.state.print ?
                                                <Input readOnly value={app.steam} style={Styles.select2Style} />
                                                :
                                                <Form.Item name="steam" style={Styles.formItemStyle}>
                                                    <InputNumber max={99999}
                                                        readOnly={!(canEdit || canEditUser || app.isReturn) || !autoCoffeeInfoType.includes(app.productType)}
                                                        style={{
                                                            ...Styles.datePickerStyle,
                                                            backgroundColor: (!autoCoffeeInfoType.includes(app.productType)) ? "transparent" : "#FFF"
                                                        }}
                                                    />
                                                </Form.Item>
                                            } */}
                                        </div>
                                    </Col>

                                </Row>
                            }
                            {this.state.autoCoffeeInfoRowVisible && ProductNames.some(codeItem => codeItem === app.productName) &&
                                // 20220728 咖啡水升数[cup]、蒸汽水升数[steam]、热水升数[warter]、除垢次数[clean]
                                // 20230517 饮品总杯数[totalCup]、饮品总升数[totalLitres]
                                <>
                                    <Row style={{ display: "flex" }}>
                                        <Col span={6} style={this.state.print ? { width: "25%" } : {}}>
                                            <div style={Styles.colItem2Style}>
                                                <div style={Styles.label2Style}>{global.transLabel("咖啡水升数")}</div>
                                                {this.state.print ?
                                                    <Input readOnly value={app.cup} style={Styles.select2Style} />
                                                    :
                                                    <Form.Item name="cup" style={Styles.formItemStyle}>
                                                        <InputNumber min={0} max={99999} readOnly={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit} style={Styles.datePickerStyle} />
                                                    </Form.Item>
                                                }
                                            </div>
                                        </Col>
                                        <Col span={6} style={this.state.print ? { width: "25%" } : {}}>
                                            <div style={Styles.colItem2Style}>
                                                <div style={Styles.label2Style}>{global.transLabel("蒸汽水升数")}</div>
                                                {this.state.print ?
                                                    <Input readOnly value={app.steam} style={Styles.select2Style} />
                                                    :
                                                    <Form.Item name="steam" style={Styles.formItemStyle}>
                                                        <InputNumber min={0} max={99999}
                                                            readOnly={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit}
                                                            style={Styles.datePickerStyle}
                                                        />
                                                    </Form.Item>
                                                }
                                            </div>
                                        </Col>
                                        <Col span={6} style={this.state.print ? { width: "25%" } : {}}>
                                            <div style={Styles.colItem2Style}>
                                                <div style={Styles.label2Style}>{global.transLabel("热水升数")}</div>
                                                {this.state.print ?
                                                    <Input readOnly value={app.warter} style={Styles.select2Style} />
                                                    :
                                                    <Form.Item name="warter" style={Styles.formItemStyle}>
                                                        <InputNumber min={0} max={99999}
                                                            readOnly={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit}
                                                            style={Styles.datePickerStyle}
                                                        />
                                                    </Form.Item>
                                                }
                                            </div>
                                        </Col>
                                        <Col span={6} style={this.state.print ? { width: "25%" } : {}}>
                                            <div style={Styles.colItem2Style}>
                                                <div style={Styles.label2Style}>{global.transLabel("除垢次数")}</div>
                                                {this.state.print ?
                                                    <Input readOnly value={app.clean} style={Styles.select2Style} />
                                                    :
                                                    <Form.Item name="clean" style={Styles.formItemStyle}>
                                                        <InputNumber min={0} max={99999} readOnly={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit} style={Styles.datePickerStyle} />
                                                    </Form.Item>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ display: "flex" }}>
                                        <Col span={12} style={this.state.print ? { width: "50%" } : {}}>
                                            <div style={Styles.colItem2Style}>
                                                <div style={Styles.label2Style}>{global.transLabel("饮品总杯数")}</div>
                                                {this.state.print ?
                                                    <Input readOnly value={app.totalCup} style={Styles.select2Style} />
                                                    :
                                                    <Form.Item name="totalCup" style={Styles.formItemStyle}>
                                                        <InputNumber min={0} max={99999} readOnly={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit} style={Styles.datePickerStyle} />
                                                    </Form.Item>
                                                }
                                            </div>
                                        </Col>
                                        <Col span={12} style={this.state.print ? { width: "50%" } : {}}>
                                            <div style={Styles.colItem2Style}>
                                                <div style={Styles.label2Style}>{global.transLabel("饮品总升数")}</div>
                                                {this.state.print ?
                                                    <Input readOnly value={app.totalLitres} style={Styles.select2Style} />
                                                    :
                                                    <Form.Item name="totalLitres" style={Styles.formItemStyle}>
                                                        <InputNumber min={0} max={99999}
                                                            readOnly={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit}
                                                            style={Styles.datePickerStyle}
                                                        />
                                                    </Form.Item>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            }
                            <Row style={Styles.rowStyle}>
                                <Col span={24} style={Styles.fullColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.label2Style}>{global.transLabel("检查记录")}</div>
                                        <Form.Item name="checkRecord" style={Styles.formItemStyle}>
                                            <Input.TextArea readOnly={!(canEdit || app.isReturn) || !AgencyCanEdit || this.state.print} style={this.state.print ? Styles.textArea2Style : Styles.textAreaStyle} />
                                        </Form.Item>
                                        {this.state.hboxFixColorVisible &&
                                            <div style={{ display: "flex", width: 200 }}>
                                                <div style={Styles.label2Style}>{global.transLabel("颜色")}</div>
                                                <Form.Item name="fixColor" style={Styles.formItemStyle}>
                                                    <Input.TextArea style={this.state.print ? Styles.textArea2Style : Styles.textAreaStyle} />
                                                </Form.Item>
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={24} style={Styles.fullColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.label2Style}>{global.transLabel("维修记录")}</div>
                                        <Form.Item name="fixRecord" style={Styles.formItemStyle}>
                                            <Input.TextArea readOnly={!(canEdit || app.isReturn) || !AgencyCanEdit || this.state.print} style={this.state.print ? Styles.textArea2Style : Styles.textAreaStyle} />
                                        </Form.Item>
                                        {this.state.hboxFixHomeVisible &&
                                            <div style={{ display: "flex", width: 200 }}>
                                                <div style={Styles.label2Style}>{global.transLabel("送货到家")}</div>
                                                <Form.Item name="fixHome" style={Styles.formItemStyle}>
                                                    <Radio.Group disabled={!canEdit || !AgencyCanEdit}>
                                                        <Radio value={1}>{I18n.t("是")}</Radio>
                                                        <Radio value={2}>{I18n.t("否")}</Radio>
                                                        <Radio value={0} style={{ display: "none" }}></Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={24} style={Styles.fullColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.label2Style}>{global.transLabel("故障分类")}</div>
                                        {this.state.print ?
                                            <Input readOnly value={app.irisCodeDesc} style={Styles.selectStyle} />
                                            :
                                            <Form.Item name="irisCode" style={Styles.formItemStyle}>
                                                <Select onChange={this.onIrisCodeDescChange} disabled={!AgencyCanEdit}>
                                                    {
                                                        ModelManager.IrisCodeList.map((item, index) => {
                                                            if (item === "请选择") {
                                                                return (
                                                                    <Select.Option key={item} value={index} style={{ display: "none" }}>{item}</Select.Option>
                                                                );
                                                            }
                                                            var label = item.areaChs + '-' + item.irisDefectDescChs;
                                                            if (item.extraInfo && item.extraInfo != '') {
                                                                label = item.extraInfo + '- ' + item.areaChs + '-' + item.irisDefectDescChs;
                                                            }
                                                            return (
                                                                <Select.Option key={item.irisDefectCode} value={item.irisDefectCode}>{label}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            {global.isHK &&
                                <Row style={Styles.rowStyle}>
                                    <Col span={24} style={Styles.fullColStyle}>
                                        <div style={Styles.colItemStyle}>
                                            <div style={Styles.label2Style}>{global.transLabel("故障现象")}</div>
                                            {this.state.print ?
                                                <Input readOnly value={app.irisCodeDesc} style={Styles.selectStyle} />
                                                :
                                                <div style={Styles.formItemStyle}>
                                                    {!this.state.photo4BtnVisible &&
                                                        <Upload
                                                            action={global.ServerUrl + "media/upload"}
                                                            accept="image/*,video/*"
                                                            headers={{ 'x-access-token': window.token }}
                                                            maxCount={3}
                                                            showUploadList={false}
                                                            onChange={(info) => this.handlePhotoChange(info, 4)}
                                                        >
                                                            <Button loading={this.state.uploading}>{I18n.t("上传")}</Button>
                                                        </Upload>
                                                    }
                                                    {/* {!this.state.photo4BtnVisible && !app.appNo &&
                                                        <div style={{ display: "flex", alignItems: "center", padding: 3, fontSize: 14 }}>{I18n.t("未上传")}</div>
                                                    } */}
                                                    {this.state.photo4BtnVisible &&
                                                        <Button loading={this.state.uploading} onClick={() => this.showImage(4)}>{I18n.t("查看")}</Button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {this.state.partGridHeadRowVisible &&
                                <div>
                                    {this.renderTableHeader()}
                                    {app.partsList && app.partsList.length > 0 &&
                                        <Form.Item style={Styles.formItemStyle}
                                            shouldUpdate={(prevValues, curValues) => prevValues.partsList !== curValues.partsList}
                                        >
                                            {({ getFieldValue }) => {
                                                const parts = getFieldValue('partsList') || [];
                                                return parts.length ? (
                                                    <div>
                                                        {
                                                            parts.map((item, index) => {
                                                                return this.renderTableItem(item, index)
                                                            })
                                                        }
                                                    </div>
                                                ) : null;
                                            }}
                                        </Form.Item>
                                    }
                                    <Form.Item style={Styles.formItemStyle}
                                        shouldUpdate={(prevValues, curValues) => prevValues.partsCount !== curValues.partsCount}
                                    >
                                        {({ getFieldValue }) => {
                                            const fixType = getFieldValue('fixType');
                                            const _PartsAmount = getFieldValue('_PartsAmount');
                                            const _PartsMoney = getFieldValue('_PartsMoney');
                                            const partsPrice = getFieldValue('partsPrice');
                                            const partsFee = getFieldValue('partsFee');
                                            return this.renderTableFooter({ fixType, _PartsAmount, partsPrice, partsFee, _PartsMoney });
                                        }}
                                    </Form.Item>
                                </div>
                            }
                            <Row style={Styles.rowStyle}>
                                <Col span={6} style={{ display: "flex", width: "25%" }}>
                                    <div style={{
                                        // width: "100%",
                                        border: "0.5px solid #333",
                                        margin: .5,
                                    }}>
                                        <div style={{ display: "flex" }}>
                                            <div style={Styles.label2Style}>{global.transLabel("其他费用")}</div>
                                            {this.state.print ?
                                                <Input readOnly value={app.otherFee} style={Styles.datePickerStyle} />
                                                :
                                                <Form.Item name="otherFee" style={Styles.formItemStyle}>
                                                    <InputNumber readOnly={!(otherFeeCanEdit || canEdit || app.isReturn) || !AgencyCanEdit} style={Styles.datePickerStyle} />
                                                </Form.Item>
                                            }
                                        </div>
                                        {this.state.showOtherFeeAlarm &&
                                            <div style={{ fontSize: 12, color: "red", backgroundColor: "#FFF", display: "flex", alignItems: "center" }}>{I18n.t("只能输入")} 0-9.\-</div>
                                            // <div style={{ fontSize: 12, color: "red", backgroundColor: "#FFF", display: "flex", alignItems: "center" }}>{I18n.t("只能输入 {{:a}}", { a: "0-9.\-" })}</div>
                                        }
                                    </div>
                                </Col>
                                <Col span={9} style={{ width: "37.5%" }}>
                                    <div style={Styles.colItem2Style}>
                                        <div style={Styles.label2Style}>{global.transLabel("其他费用明细")}</div>
                                        <Form.Item name="otherDetail" style={Styles.formItemStyle}>
                                            <Input.TextArea maxLength={120} readOnly={!(canEdit || app.isReturn) || !AgencyCanEdit || this.state.print} style={Styles.textArea2Style} />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={9} style={{ display: "flex", width: "37.5%" }}>
                                    <div style={Styles.colItem3Style}>
                                        <div style={Styles.label2Style}>{global.transLabel("新机生产编号")}</div>
                                        <Form.Item name="productNo" style={Styles.formItemStyle}>
                                            <Input readOnly={!(canEdit || app.isReturn) || !AgencyCanEdit || this.state.print} style={Styles.datePickerStyle} />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={6} style={{ display: "flex", width: "25%" }}>
                                    <div style={Styles.colItem3Style}>
                                        {this.state.print ?
                                            <div style={{ display: "flex", alignItems: "center", fontSize: 14, width: "100%" }}>
                                                <input readOnly type="checkbox" name="notice" value="notice" checked={app.notice} />{I18n.t("是否通知用户取货")}
                                            </div>
                                            :
                                            <Form.Item name="notice" valuePropName="checked" style={{ marginBottom: 0, marginLeft: 3 }}>
                                                <Checkbox disabled={!this.state.noticeEnabled || !AgencyCanEdit}>{I18n.t("是否通知用户取货")}</Checkbox>
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                                <Col span={9} style={{ width: "37.5%" }}>
                                    <div style={Styles.colItem2Style}>
                                        <div style={Styles.label2Style}>{global.transLabel("通知日期")}</div>
                                        {this.state.print ?
                                            <Input readOnly value={this.onDateFormat(app.noticeDate, dateTimeFormat)} style={Styles.input3Style} />
                                            :
                                            <Form.Item name="noticeDate" style={Styles.formItemStyle}>
                                                <DatePicker placeholder={I18n.t("请选择日期")} disabled style={Styles.datePickerStyle}
                                                    format={dateTimeFormat}
                                                />
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                                <Col span={9} style={{ width: "37.5%" }}>
                                    <div style={Styles.colItem2Style}>
                                        <div style={Styles.label2Style}>{global.transLabel("取货日期")}</div>
                                        {this.state.print ?
                                            <Input readOnly value={this.onDateFormat(app.pickupDate, dateTimeFormat)} style={Styles.input3Style} />
                                            :
                                            <Form.Item name="pickupDate" style={Styles.formItemStyle}>
                                                <DatePicker placeholder={I18n.t("请选择日期")} disabled style={Styles.datePickerStyle}
                                                    format={dateTimeFormat}
                                                />
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            {this.state.rpHeadRowVisible &&
                                <div>
                                    <Row style={Styles.rowStyle}>
                                        <Col span={6} style={{ display: "flex", width: "25%" }}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{I18n.t("奖励/处罚")}</div>
                                            </div>
                                        </Col>
                                        <Col span={9} style={{ display: "flex", width: "37.5%" }}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{I18n.t("奖励和处罚的项目")}</div>
                                            </div>
                                        </Col>
                                        <Col span={9} style={{ display: "flex", width: "37.5%" }}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{I18n.t("金额")}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={Styles.rowStyle}>
                                        <Col span={6} style={{ display: "flex", width: "25%" }}>
                                            <div style={Styles.colItemStyle}>
                                                <Form.Item name="rpType" style={{ marginBottom: 0, width: "100%" }}>
                                                    <Select allowClear disabled={!this.state.rpRowEnabled} style={{}}
                                                        onChange={this.onRpType}
                                                    >
                                                        {
                                                            ModelManager.rpList.map((item, index) => {
                                                                return (
                                                                    <Select.Option key={item} value={index}>{I18n.t(item)}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col span={9} style={{ display: "flex", width: "37.5%" }}>
                                            <div style={Styles.colItemStyle}>
                                                <Form.Item name="rpDetail" style={{ marginBottom: 0, width: "100%" }}>
                                                    <Select allowClear disabled={!this.state.rpRowEnabled} style={{}}
                                                        onChange={this.onRpDetailChange}
                                                    >
                                                        {
                                                            this.state.rpDetailTypeList.map((item) => {
                                                                return (
                                                                    <Select.Option key={item.label} value={item.value}>{I18n.t(item.label)}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col span={9} style={{ display: "flex", width: "37.5%" }}>
                                            <div style={Styles.colItemStyle}>
                                                {this.state.print ?
                                                    <Input readOnly value={app.rpMoney} style={Styles.input3Style} />
                                                    :
                                                    <Form.Item name="rpMoney" style={Styles.formItemStyle}>
                                                        <InputNumber min={0} readOnly={!this.state.rpRowEnabled || !this.state.rpMoneyEditable} style={Styles.datePickerStyle} />
                                                    </Form.Item>
                                                }

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <Row style={Styles.rowStyle}>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.label2Style}>{global.transLabel("接机人员")}</div>
                                        <Form.Item name="pickupPerson" style={Styles.formItemStyle}>
                                            <Input readOnly={!(canEdit || app.isReturn) || this.state.print} style={Styles.datePickerStyle} />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.label2Style}>{global.transLabel("维修人员")}</div>
                                        <Form.Item name="repairPerson" style={Styles.formItemStyle}>
                                            <Input readOnly={!(canEdit || app.isReturn) || this.state.print} style={Styles.datePickerStyle} />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.label2Style}>{global.transLabel("维修站物流单号")}</div>
                                        <Form.Item name="logisticsNo" style={Styles.formItemStyle}>
                                            <Input readOnly={!(otherFeeCanEdit || canEdit || app.isReturn) || this.state.print} placeholder={I18n.t("快递名称+单号")} style={Styles.datePickerStyle} />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={12} style={Styles.halfColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.label2Style}>{global.transLabel("顾客物流单号")}</div>
                                        <Form.Item name="logisticsNo2" style={Styles.formItemStyle}>
                                            <Input readOnly={!(canEdit || app.isReturn) || this.state.print} style={Styles.datePickerStyle} />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={24} style={Styles.fullColStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.label2Style}>{global.transLabel("备注")}</div>
                                        <Form.Item name="remark" style={Styles.formItemStyle}>
                                            <Input.TextArea maxLength={120} readOnly={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit || this.state.print} style={this.state.print ? Styles.textArea2Style : Styles.textAreaStyle} />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={Styles.rowStyle}>
                                <Col span={24} style={Styles.colStyle}>
                                    <div style={Styles.colItemStyle}>
                                        <div style={Styles.labelStyle}>{global.transLabel("加急工单")}</div>
                                        {this.state.print ?
                                            <div style={{ display: "flex", alignItems: "center", fontSize: 14, width: "100%" }}>
                                                <input readOnly type="checkbox" name="EmergencyOrder" value="EmergencyOrder" checked={app.EmergencyOrder} />{I18n.t("是否加急工单")}
                                            </div>
                                            :
                                            <Form.Item name="EmergencyOrder" valuePropName="checked" style={{ marginBottom: 0, paddingLeft: 10, backgroundColor: "#FFF", flex: 1, }}>
                                                <Checkbox disabled={!(canEdit || canEditUser || app.isReturn) || !AgencyCanEdit || this.state.print}>{I18n.t("是否加急工单")}</Checkbox>
                                            </Form.Item>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {!global.isDev && this.state.processVBoxVisible && this.state.processText &&
                            <Row style={Styles.rowStyle}>
                                <Col span={24} style={Styles.fullColStyle}>
                                    <div style={{
                                        width: "100%",
                                        border: "0.5px solid #333",
                                        padding: 5,
                                        whiteSpace: "pre-line",
                                        fontSize: 14,
                                        backgroundColor: global.rowLightColor,
                                    }}>
                                        {this.state.processText}
                                    </div>
                                </Col>
                            </Row>
                        }
                        {global.isDev && this.state.processVBoxVisible && app.processList && app.processList.length > 0 &&
                            <div style={{
                                width: "100%",
                                border: "0.5px solid #333",
                                padding: 5,
                                whiteSpace: "pre-line",
                                fontSize: 14,
                                backgroundColor: global.rowLightColor,
                            }}>
                                {app.processList.map(p => {
                                    return (
                                        <div key={"pppp" + p.id} style={{ display: "flex" }}>
                                            <div style={{ display: "flex", flex: 1 }}>
                                                <span style={{ marginRight: 5 }}>
                                                    {moment(p.handleDate.substring(0, p.handleDate.length - 5)).format(dateFullFormat)}
                                                    <span style={{ marginLeft: 5 }}>{ModelManager.getStaffName(p.empId) + "：" + I18n.t(AppStatus.GetProcessLabel(p.result))}</span>
                                                    {p.remark != null && p.remark.length > 0 &&
                                                        <span style={{ marginLeft: 5 }}>{global.transLabel("备注") + p.remark}</span>
                                                    }
                                                </span>
                                                {/* <div style={{ marginRight: 5 }}>{ModelManager.getStaffName(p.empId) + "：" + I18n.t(AppStatus.GetProcessLabel(p.result))}</div> */}
                                            </div>
                                            {global.isDev &&
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={"删除操作记录"}
                                                    onConfirm={() => this.deleteProcess(p.id)}
                                                    okText="删除"
                                                    cancelText="取消"
                                                >
                                                    <Button size="small">{I18n.t("删除")}</Button>
                                                </Popconfirm>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        {this.state.VisitScore2Visible &&
                            <div>
                                <div style={{ padding: 5, backgroundColor: "#FFF", fontSize: 14 }}>
                                    {
                                        RevisitNotes.map((item, index) => {
                                            return (
                                                <Row key={"RevisitNotes" + index}>
                                                    <Col span={24} style={Styles.colStyle}>
                                                        {I18n.t(item)}
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                </div>
                                <div style={Styles.contentStyle}>
                                    <Row style={Styles.rowStyle}>
                                        <Col span={24} style={Styles.fullColStyle}>
                                            <div style={{
                                                width: "100%", margin: 0.5,
                                                display: "flex", justifyContent: "center",
                                                border: "0.5px solid #333",
                                                fontWeight: "bold", fontSize: 18,
                                                lineHeight: "30px",
                                            }}>{I18n.t("回访内容")}</div>
                                        </Col>
                                    </Row>
                                    <Row style={Styles.rowStyle}>
                                        <Col span={4} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{I18n.t("维修结果")}</div>
                                            </div>
                                        </Col>
                                        <Col span={15} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{I18n.t("您之前在我们维修站维修的机器是否已经维修好了，现在是否可以正常工作？ 还需要我们进一步帮您什么么？")}</div>
                                            </div>
                                        </Col>
                                        <Col span={5} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{global.transLabel("评分")}</div>
                                                <Form.Item name="visitQuality" style={Styles.formItemStyle}>
                                                    {/* <Input readOnly={!this.state.canVisitEdit} style={Styles.input2Style} /> */}
                                                    <Select allowClear disabled={!this.state.canVisitEdit} style={Styles.selectStyle}>
                                                        {
                                                            ModelManager.EstimationOption.map((item, index) => {
                                                                return (
                                                                    <Select.Option key={"Estimation" + index} value={item.value}>{I18n.t(item.label)}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={Styles.rowStyle}>
                                        <Col span={4} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{I18n.t("维修时长")}</div>
                                            </div>
                                        </Col>
                                        <Col span={15} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{I18n.t("您的机器维修了多久时间？对于这个维修速度是否满意呢？ ")}</div>
                                            </div>
                                        </Col>
                                        <Col span={5} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{global.transLabel("评分")}</div>
                                                <Form.Item name="visitSpeed" style={Styles.formItemStyle}>
                                                    {/* <Input readOnly={!this.state.canVisitEdit} style={Styles.input2Style} /> */}
                                                    <Select allowClear disabled={!this.state.canVisitEdit} style={Styles.selectStyle}>
                                                        {
                                                            ModelManager.EstimationOption.map((item, index) => {
                                                                return (
                                                                    <Select.Option key={"Estimation" + index} value={item.value}>{I18n.t(item.label)}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={Styles.rowStyle}>
                                        <Col span={4} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{I18n.t("维修师服务 ")}</div>
                                            </div>
                                        </Col>
                                        <Col span={15} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{I18n.t("维修师傅的态度是否满意呢？")}</div>
                                            </div>
                                        </Col>
                                        <Col span={5} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{global.transLabel("评分")}</div>
                                                <Form.Item name="visitService" style={Styles.formItemStyle}>
                                                    {/* <Input readOnly={!this.state.canVisitEdit} style={Styles.input2Style} /> */}
                                                    <Select allowClear disabled={!this.state.canVisitEdit} style={Styles.selectStyle}>
                                                        {
                                                            ModelManager.EstimationOption.map((item, index) => {
                                                                return (
                                                                    <Select.Option key={"Estimation" + index} value={item.value}>{I18n.t(item.label)}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={Styles.rowStyle}>
                                        <Col span={4} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{I18n.t("产品使用")}</div>
                                            </div>
                                        </Col>
                                        <Col span={15} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{I18n.t("您产品使用到现在，对于这个产品还满意么？")}</div>
                                            </div>
                                        </Col>
                                        <Col span={5} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{global.transLabel("评分")}</div>
                                                <Form.Item name="visitAgree" style={Styles.formItemStyle}>
                                                    {/* <Input readOnly={!this.state.canVisitEdit} style={Styles.input2Style} /> */}
                                                    <Select allowClear disabled={!this.state.canVisitEdit} style={Styles.selectStyle}>
                                                        {
                                                            ModelManager.EstimationOption.map((item, index) => {
                                                                return (
                                                                    <Select.Option key={"Estimation" + index} value={item.value}>{I18n.t(item.label)}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={Styles.rowStyle}>
                                        <Col span={4} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{I18n.t("NPS打分")}</div>
                                            </div>
                                        </Col>
                                        <Col span={15} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{I18n.t("基于此次的体验，您对德龙品牌是否会推荐给您的朋友呢？10为一定推荐，1为不会推荐， 1到10，您会选几分？")}</div>
                                            </div>
                                        </Col>
                                        <Col span={5} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{global.transLabel("评分")}</div>
                                                <Form.Item name="visitSuggest" style={Styles.formItemStyle}>
                                                    <InputNumber min={0} max={10} readOnly={!this.state.canVisitEdit} style={Styles.input2Style} />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={Styles.rowStyle}>
                                        <Col span={4} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <div style={Styles.label2Style}>{I18n.t("备注")}</div>
                                            </div>
                                        </Col>
                                        <Col span={20} style={Styles.colStyle}>
                                            <div style={Styles.colItemStyle}>
                                                <Form.Item name="visitNote" style={Styles.formItemStyle}>
                                                    <Input.TextArea readOnly={!this.state.canVisitEdit} style={this.state.print ? Styles.textArea2Style : Styles.textAreaStyle} />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        }
                        {this.state.partPrintVBoxVisible &&
                            <div style={Styles.contentStyle}>
                                {this.renderTableHeader()}
                                <Form.Item style={Styles.formItemStyle}
                                    shouldUpdate={(prevValues, curValues) => prevValues.parts !== curValues.parts}
                                >
                                    {({ getFieldValue }) => {
                                        const parts = getFieldValue('partsList') || [];
                                        return parts.length ? (
                                            <div>
                                                {
                                                    parts.map((item, index) => {
                                                        return this.renderTableItem(item, index)
                                                    })
                                                }
                                            </div>

                                        ) : null;
                                    }}
                                </Form.Item>
                                <Form.Item style={Styles.formItemStyle}
                                    shouldUpdate={(prevValues, curValues) => prevValues.partsCount !== curValues.partsCount}
                                >
                                    {({ getFieldValue }) => {
                                        const fixType = getFieldValue('fixType');
                                        const _PartsAmount = getFieldValue('_PartsAmount');
                                        const partsPrice = getFieldValue('partsPrice');
                                        const partsFee = getFieldValue('partsFee');
                                        const _PartsMoney = getFieldValue('_PartsMoney');
                                        return this.renderTableFooter({ fixType, _PartsAmount, partsPrice, partsFee, _PartsMoney });
                                    }}
                                </Form.Item>
                            </div>
                        }
                    </Form>
                </div>

                <div style={{ position: "fixed", bottom: 0, backgroundColor: "#DDD", textAlign: "right", padding: 3, backgroundImage: "linear-gradient(#EEE, #CCC)" }}>
                    {/* 2024.01.09 经销商派单-只允许编辑一次 */}
                    {ModelManager.isAgency() && !app.id &&
                        // <Button onClick={this.onSave} disabled={this.state.applyDiscardStatus && app.status != AppStatus.S_DISCARD}>{I18n.t("保存")}</Button>
                        <Button onClick={this.onAssign} disabled={this.state.applyDiscardStatus}>{I18n.t("指派维修站")}</Button>
                    }
                    {!ModelManager.isViewer() && !ModelManager.isAgency() &&
                        <div>
                            {global.isHK && app.status !== AppStatus.S_DISCARD &&
                                <Button onClick={this.onPrint}>{I18n.t("发票")}</Button>
                            }
                            {/* {global.isHK && app.status >= AppStatus.S_INVOICE && app.status !== AppStatus.S_DISCARD &&
                                <Button onClick={this.onPrint}>{I18n.t("发票")}</Button>
                            } */}
                            {app.complainUnHandle && app.status !== AppStatus.S_DISCARD &&
                                <Button onClick={this.onHandle}>{I18n.t("投诉已处理")}</Button>
                            }
                            {(canEditUser || app.isReturn !== 0) &&
                                <Button onClick={this.onSave} loading={this.state.updating} disabled={this.state.updating || (this.state.applyDiscardStatus && app.status != AppStatus.S_DISCARD)}>{I18n.t("保存")}</Button>
                            }
                            {this.state.sendButton1Visible &&
                                <Button onClick={this.onToBeSend1} disabled={this.state.applyDiscardStatus}>{I18n.t("顾客送修")}</Button>
                            }
                            {this.state.sendButton2Visible &&
                                <Button onClick={this.onToBeSend2} disabled={this.state.applyDiscardStatus}>{I18n.t("顾客寄修")}</Button>
                            }
                            {this.state.repairButtonVisible && app.status === 25 &&
                                <Button onClick={this.onToBeRepaired} disabled={this.state.applyDiscardStatus}>{I18n.t("送修已收到")}</Button>
                            }
                            {this.state.repairButtonVisible && app.status === 26 &&
                                <Button onClick={this.onToBeRepaired} disabled={this.state.applyDiscardStatus}>{I18n.t("寄修已收到")}</Button>
                            }
                            {/* {this.state.oaButtonVisible &&
                                <Button onClick={this.onPostToOA} disabled={this.state.applyDiscardStatus}>{I18n.t("提交OA")}</Button>
                            } */}
                            {this.state.assignButtonVisible &&
                                <Button onClick={this.onAssign} disabled={this.state.applyDiscardStatus}>{I18n.t("指派维修站")}</Button>
                            }
                            {this.state.appointButtonVisible &&
                                <Button disabled={!this.state.appointButtonEnabled || this.state.applyDiscardStatus} onClick={this.onAppoint}>{I18n.t("预约上门")}</Button>
                            }
                            {!global.isHK && this.state.finishButtonVisible &&
                                <Button disabled={!this.state.finishButtonEnabled || this.state.applyDiscardStatus} onClick={this.onFinish}>{I18n.t("维修完成")}</Button>
                            }
                            {global.isHK &&
                                <Button onClick={this.onFinish}>{I18n.t("维修完成")}</Button>
                            }
                            {/* {global.isDev &&
                                <Button onClick={this.onFinish}>{I18n.t("维修完成")}</Button>
                            } */}
                            {this.state.noticeButtonVisible &&
                                <Button onClick={this.onNotice} disabled={this.state.applyDiscardStatus}>{I18n.t("通知取货")}</Button>
                            }
                            {/* 20220830 维修类型为：检测 时，没有【短信通知】按钮 */}
                            {/* 20220907 只有售后 才有短信的所有功能 */}
                            {!global.isHK && this.state.pickupButtonVisible && app.type === AppStatus.TYPE_ACCESS &&
                                <Button onClick={this.onSendSmsNotice}
                                    disabled={this.onNoSendSms(app)}
                                // disabled={
                                //     this.state.applyDiscardStatus || app.fixType === AppStatus.TYPE_DETECT || app.nature !== 2 || app.fixHome == 1
                                // }
                                >{I18n.t("短信通知")}</Button>
                            }
                            {/* {global.isDev &&
                                <Button onClick={this.onSendSmsNotice}
                                    disabled={this.state.applyDiscardStatus || app.fixType === AppStatus.TYPE_DETECT || app.nature !== 2}
                                >{I18n.t("短信通知")}</Button>
                            } */}
                            {this.state.pickupButtonVisible &&
                                <Button onClick={this.onPickup} disabled={this.state.applyDiscardStatus}>{I18n.t("取货确认")}</Button>
                            }
                            {this.state.visit2ButtonVisible &&
                                <Button onClick={this.onVisit2} disabled={this.state.applyDiscardStatus}>{I18n.t("完成回访")}</Button>
                            }
                            {this.state.visitContinueButtonVisible &&
                                <Button onClick={this.onContinueVisit} disabled={this.state.applyDiscardStatus}>{I18n.t("待继续回访")}</Button>
                            }
                            {this.state.returnButtonVisible &&
                                <Button onClick={this.onReturn}>{I18n.t("退回修改")}</Button>
                            }
                            {this.state.discardButtonVisible &&
                                <Button onClick={this.onDiscard}>{I18n.t("作废")}</Button>
                            }
                            {this.state.zbDiscardButtonVisible &&
                                <>
                                    <Button onClick={this.onZBDiscard}>{I18n.t("总部作废")}</Button>
                                    <Button onClick={this.onBackDiscard}>{I18n.t("退回作废申请")}</Button>
                                </>
                            }
                            {this.state.applyDiscardButtonVisible &&
                                <Button onClick={this.onApplyDiscard} danger>{I18n.t("申请作废")}</Button>
                            }
                            {this.state.cancelApplyDiscardButtonVisible &&
                                <Button onClick={this.onCancelApplyDiscard} danger>{I18n.t("取消作废申请")}</Button>
                            }
                            <Button onClick={this.printReport}>{I18n.t("打印")}</Button>
                            {global.isDev &&
                                <>
                                    <Button onClick={() => this.exportFile("CSV")}>{I18n.t("导出CSV")}</Button>
                                    <Button onClick={() => this.exportFile("TXT")}>{I18n.t("导出TXT")}</Button>
                                </>
                            }
                            <Button onClick={() => this.goBack()}>{I18n.t("返回")}</Button>
                        </div>
                    }
                    {(ModelManager.isViewer() || ModelManager.isAgency()) &&
                        <Button onClick={() => this.goBack()}>{I18n.t("返回")}</Button>
                    }
                </div>
                <Modal
                    title={I18n.t("选择订单")}
                    width={1000} maskClosable={false}
                    visible={this.state.isOrderModalVisible}
                    onOk={this.setOrderInfo}
                    onCancel={this.cancelSelectOrder}
                    footer={[
                        <Button key="submit" type="primary" onClick={this.setOrderInfo}>
                            {I18n.t("选择")}
                        </Button>,
                        <Button key="back" onClick={this.cancelSelectOrder}>
                            {I18n.t("返回")}
                        </Button>,
                    ]}
                >
                    <Table
                        bordered={true}
                        columns={orderColumns}
                        dataSource={this.state.orderList}
                        rowKey={record => record.id}
                        rowClassName={(record, index) => this.setOrderRowClassName(record, index)}
                        onRow={record => {
                            return {
                                onClick: () => {
                                    this.setState({ selectedOrder: record });
                                    this.forceUpdate();
                                }, // 点击行
                            };
                        }}
                        scroll={{ x: 2000 }}
                        pagination={false}
                    />
                </Modal>
                <Modal
                    title={I18n.t("选择订单")}
                    width={1024} maskClosable={false}
                    visible={this.state.isModalVisible}
                    onOk={this.setWarrantInfo}
                    onCancel={this.cancelSelectWarrant}
                    footer={[
                        <Button key="submit" type="primary" onClick={this.setWarrantInfo}>
                            {I18n.t("选择")}
                        </Button>,
                        <Button key="back" onClick={this.cancelSelectWarrant}>
                            {I18n.t("返回")}
                        </Button>,
                    ]}
                >
                    <Table
                        bordered={true}
                        columns={warrantColumns}
                        dataSource={this.state.warrantList}
                        rowKey={record => record.id}
                        rowClassName={(record, index) => this.setRowClassName(record, index)}
                        onRow={record => {
                            return {
                                onClick: () => {
                                    this.setState({ selectedWarrant: record });
                                    this.forceUpdate();
                                }, // 点击行
                            };
                        }}
                        scroll={{ x: 1200 }}
                        pagination={false}
                    />
                </Modal>
                <Modal
                    title={I18n.t("请选择用户信息")}
                    width={1000} maskClosable={false}
                    visible={this.state.userModalVisible}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button type="primary" onClick={this.setUserInfo}>
                            {I18n.t("用户信息")}
                        </Button>,
                        <Button type="primary" onClick={this.setUserAllInfo}>
                            {I18n.t("全部信息")}
                        </Button>,
                        <Button key="back" onClick={this.handleCancel}>
                            {I18n.t("返回")}
                        </Button>,
                    ]}
                >
                    <div>
                        <div style={{ display: "flex", marginBottom: 10 }}>
                            <Input placeholder={I18n.t("电话号码")} onChange={e => this.userTelChange(e.target.value)} />
                            <Button type="primary" onClick={() => this.getAppByUserTel(this.state.userTel)}>
                                {I18n.t("查询")}
                            </Button>
                        </div>
                        <Table
                            bordered={true}
                            columns={userColumns}
                            dataSource={this.state.userList}
                            rowKey={record => record.id}
                            rowClassName={(record, index) => this.setUserRowClassName(record, index)}
                            onRow={record => {
                                return {
                                    onClick: () => {
                                        this.setState({ selectedUser: record });
                                        this.forceUpdate();
                                    }, // 点击行
                                };
                            }}
                            scroll={{ x: 2500, y: 300 }}
                            pagination={false}
                        />
                    </div>
                </Modal>
                <Modal
                    title={I18n.t("请输入处理意见")}
                    width={600} maskClosable={false}
                    visible={this.state.reviewVisible}
                    onOk={this.commitFunction}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            {I18n.t("取消")}
                        </Button>,
                        <Button key="submit" type="primary" loading={this.state.updating} disabled={this.state.updating} onClick={this.commitFunction}>
                            {I18n.t("确定")}
                        </Button>,
                    ]}
                >
                    <AutoComplete style={{ width: "100%" }}
                        placeholder={I18n.t("请输入处理意见")}
                        value={this.state.reviewContent}
                        onChange={this.reviewContentChange}>
                        {ModelManager.opinionList.map((opinion) => (
                            <AutoComplete.Option key={opinion} value={I18n.t(opinion)}>
                                {I18n.t(opinion)}
                            </AutoComplete.Option>
                        ))}
                    </AutoComplete>
                </Modal>
                <Modal
                    width={450} maskClosable={false}
                    destroyOnClose
                    title={I18n.t(this.state.selectDateTitleString)}
                    visible={this.state.selectDateVisible}
                    onOk={this.commitFunction}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            {I18n.t("取消")}
                        </Button>,
                        <Button key="submit" type="primary" disabled={this.state.updating} onClick={this.commitFunction}>
                            {I18n.t("确定")}
                        </Button>,
                    ]}
                >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {I18n.t(this.state.selectDateTitleString && this.state.selectDateTitleString.substring(3))}
                        <DatePicker placeholder={I18n.t("请选择日期")} locale={global.DateLocal}
                            style={{ minWidth: 250, marginLeft: 10 }}
                            showTime={{ format: 'HH:mm' }}
                            format="YYYY-MM-DD HH:mm"
                            disabledDate={this.disabledDate}
                            onChange={this.onSelectDateChange}
                            onOk={this.onSelectDateOk}
                        />
                    </div>
                </Modal>
                <Modal
                    width={500} maskClosable={false}
                    title={I18n.t(this.state.selectListTitleString)}
                    visible={this.state.selectListVisible}
                    onOk={this.commitFunction}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            {I18n.t("取消")}
                        </Button>,
                        <Button key="submit" type="primary" disabled={this.state.updating} onClick={this.commitFunction}>
                            {I18n.t("确定")}
                        </Button>,
                    ]}
                >
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {I18n.t("维修站")}
                            <Select allowClear showSearch
                                style={{ minWidth: 300, marginLeft: 10 }}
                                value={this.state.selectRegion}
                                onChange={this.onRegionChange}
                                filterOption={(input, option) =>
                                    option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                                }
                            >
                                {
                                    this.state.selectList && this.state.selectList.map((item) => {
                                        if (item.isHistory) {
                                            return null;
                                        }
                                        return (
                                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                        {global.openEpidemicModule() && workScope1 && global.loginUser && global.loginUser.regionId === 2 &&
                            <div style={{ marginTop: 10, width: 350 }}>
                                <div style={{ fontWeight: "bold", fontSize: 15 }}>{I18n.t("疫情期间工作范围")}</div>
                                <div>{I18n.t("工作范围")}：{I18n.t(ModelManager.WorkScopeOption[workScope1.workScope].label)}</div>
                                {workScope1.workScope === 5 &&
                                    <div>{I18n.t("电话转接")}：{workScope1.transferService ? I18n.t("是") : I18n.t("否")}</div>
                                }
                                {workScope1.workScope && workScope1.workScope !== 1 &&
                                    <div>{I18n.t("预计恢复时间")}：{ModelManager.formatDate(global.getDate(workScope1.restoreDate), I18n.t("YYYY年MM月DD日"))}</div>
                                }
                                <div>{I18n.t("更新时间")}：{ModelManager.formatDate(global.getDate(workScope1.updateDate), I18n.t("YYYY年MM月DD日 HH:mm"))}</div>
                            </div>
                        }
                        {!global.isHK && workScope2 && workScope2.length > 0 && global.loginUser && global.loginUser.regionId === 2 &&
                            <div style={{ marginTop: 10, width: 350 }}>
                                <div style={{ fontWeight: "bold", fontSize: 15 }}>{I18n.t("节假日营业状态")}</div>
                                {workScope2.map(item => {
                                    let dateStr = "", startYear, endYear;
                                    let formatStr = I18n.t("YYYY年MM月DD日 HH:mm");
                                    if (item.startDate) {
                                        startYear = ModelManager.formatDate(global.getDate(item.startDate), "YYYY");
                                        dateStr += ModelManager.formatDate(global.getDate(item.startDate), formatStr);
                                    }
                                    dateStr += " - ";
                                    if (item.endDate) {
                                        endYear = ModelManager.formatDate(global.getDate(item.endDate), "YYYY");
                                        if (startYear === endYear) {
                                            formatStr = I18n.t("MM月DD日 HH:mm");
                                        }
                                        dateStr += ModelManager.formatDate(global.getDate(item.endDate), formatStr);
                                    }
                                    return (
                                        <div key={"workScope2" + item.id}>
                                            <div style={{ textAlign: "center", fontWeight: "bold" }}>{item.bulletinTitle}</div>
                                            <div>{I18n.t("营业状态")}：{I18n.t(ModelManager.WorkScopeOption[item.workScope].label)}</div>
                                            {item.workScope === 5 &&
                                                <div>{I18n.t("电话转接")}：{item.transferService ? I18n.t("是") : I18n.t("否")}</div>
                                            }
                                            {item.workScope && item.workScope !== 1 &&
                                                <div>{I18n.t("休假日期")}：{dateStr}</div>
                                            }
                                            <div>{I18n.t("更新时间")}：{ModelManager.formatDate(global.getDate(item.updateDate), I18n.t("YYYY年MM月DD日 HH:mm"))}</div>
                                            <Divider style={{ margin: "5px 0" }} />
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                </Modal>
                <Modal
                    title={I18n.t(this.state.applyDiscardTitle)}
                    width={600} maskClosable={false}
                    visible={this.state.applyDiscardVisible}
                    onOk={this.commitFunction}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            {I18n.t("取消")}
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.commitFunction}>
                            {I18n.t("确定")}
                        </Button>,
                    ]}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: 60, textAlign: "right" }}>{I18n.t("作废理由")}</div>
                        <Select allowClear style={{ width: 200, marginLeft: 10 }}
                            value={this.state.discardReason}
                            onChange={this.discardReasons_changeHandler}>
                            {
                                ModelManager.discardReasons.map((item) => {
                                    if (item.label === "请选择") {
                                        return (
                                            <Select.Option key={item.value} style={{ display: "none" }} value={item.value}>{I18n.t(item.label)}</Select.Option>
                                        )
                                    }
                                    return (
                                        <Select.Option key={item.value} value={item.value}>{I18n.t(item.label)}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    {this.state.discardReason === 1 &&
                        <div style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
                            <div style={{ width: 60, textAlign: "right" }}>{I18n.t("重复单号")}</div>
                            <Input value={this.state.repeatAppNo} onChange={e => this.setValueChange("repeatAppNo", e.target.value)} minLength={18} maxLength={18} style={{ flex: 1, marginLeft: 10 }} />
                        </div>
                    }
                    {this.state.discardReason === 4 &&
                        <div style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
                            <div style={{ width: 60, textAlign: "right" }}>{I18n.t("作废说明")}</div>
                            <Input value={this.state.discardDesc} onChange={e => this.setValueChange("discardDesc", e.target.value)} style={{ flex: 1, marginLeft: 10 }} />
                        </div>
                    }
                </Modal>
                <Modal className="FullScreenModel"
                    title="Modal 1000px width"
                    destroyOnClose
                    visible={this.state.printVisible}
                    width={"calc(100vw)"}
                    style={{
                        width: "calc(100vw)", maxWidth: "calc(100vw)",
                        height: "calc(100vh)", maxHeight: "calc(100vh)",
                        top: 0, padding: 0,
                    }}
                    bodyStyle={{ overflowY: "auto" }}
                    closable={false}
                    footer={null}
                >
                    <FixAppPrint
                        ref={comp => this.FixAppPrint = comp}
                        doc={this.state.doc}
                        goBack={() => this.setState({ printVisible: false }, () => {
                            this.forceUpdate()
                        })}
                    />
                </Modal>
                <Modal className="FullScreenModel"
                    title="Modal 1000px width"
                    destroyOnClose
                    visible={this.state.imageVisible}
                    width={"calc(100vw)"}
                    style={{
                        width: "calc(100vw)", maxWidth: "calc(100vw)",
                        height: "calc(100vh)", maxHeight: "calc(100vh)",
                        top: 0, padding: 0,
                    }}
                    bodyStyle={{ overflowY: "auto" }}
                    closable={false}
                    footer={null}
                >
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", background: "#000", padding: "0 10px" }}>
                            <Upload
                                action={global.ServerUrl + "media/upload"}
                                accept={this.state.imageType === 4 ? "image/*,video/*" : "image/*"}
                                headers={{ 'x-access-token': window.token }}
                                maxCount={this.state.imageType === 4 ? 3 : 1}
                                showUploadList={false}
                                onChange={(info) => this.handlePhotoChange(info, this.state.imageType)}
                            >
                                <Button loading={this.state.uploading}>{I18n.t("上传")}</Button>
                            </Upload>
                            <div style={{
                                display: "flex", flex: 1, justifyContent: "center",
                                fontSize: 18, fontWeight: "bold",
                                height: 50, color: "#FFF", lineHeight: "50px"
                            }}>
                                {global.transLabel("上传人")}{this.state.imageUserName}{I18n.t("（点击查看大图）")}
                                {/* <div>{global.transLabel("上传时间")}{this.state.imageUploadTime}</div> */}
                            </div>
                            <Button
                                onClick={() => this.setState({ imageVisible: false }, () => {
                                    this.forceUpdate()
                                })}
                            >{I18n.t("关闭")}</Button>
                        </div>
                        <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", }}>
                            {this.state.imageType === 1 &&
                                <Image src={global.BaseImageUrl + this.state.imageUrl} style={{ maxWidth: "100vw", maxHeight: "calc(100vh - 50px)" }} />
                            }
                            {this.state.imageType === 4 &&
                                <div style={{ display: "flex" }}>
                                    {this.state.imageUrl.map(item => {
                                        let suffix = item.substring(item.lastIndexOf(".") + 1);
                                        let fileType = global.getFileType(suffix);
                                        return (
                                            <div key={item}
                                                style={{
                                                    flex: 1, display: "flex", alignItems: "center", justifyContent: "center",
                                                    background: "#000"
                                                }}
                                            >
                                                {fileType === "video" &&
                                                    <video src={global.BaseImageUrl + item} controls style={{ maxWidth: "100%", maxHeight: "calc(100vh - 50px)" }} />
                                                }
                                                {fileType !== "video" &&
                                                    <Image src={global.BaseImageUrl + item} style={{ maxWidth: "100vw", maxHeight: "calc(100vh - 50px)" }} />
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                </Modal>
                <Modal
                    title={I18n.t("查看照片")}
                    destroyOnClose
                    visible={this.state.imageGroupVisible}
                    width={"100vw"}
                    style={{
                        width: "calc(100vw)", maxWidth: "calc(100vw)",
                        height: "calc(100vh)", maxHeight: "calc(100vh)",
                        top: 0, padding: 0,
                    }}
                    bodyStyle={{ overflowY: "auto", height: "calc(100vh - 55px)", backgroundColor: "#F0F0F0" }}
                    onCancel={this.onHideImageGroup}
                    footer={null}
                >
                    <Image.PreviewGroup>
                        <div style={{ display: "flex", flexWrap: "wrap", }}>
                            {this.state.imageGroup && this.state.imageGroup.length > 0 && this.state.imageGroup.map((item, index) => {
                                return (
                                    <div key={item.url + index} style={{ display: "flex", flexDirection: "column", width: 300, margin: 10, }}>
                                        <div style={{ width: 300, height: 300, display: "flex", justifyContent: "center", alignItems: "center", }}>
                                            <Image src={global.ServerUrl + "photo/" + item.url} style={{ maxWidth: 300, maxHeight: 300 }} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", background: "#000", padding: "0 10px" }}>
                                            <div style={{
                                                display: "flex", flexDirection: "column", justifyContent: "center", height: 50, color: "#FFF", fontSize: 14,
                                            }}>
                                                <div>{global.transLabel("上传人")}{item.userName}</div>
                                                <div>{global.transLabel("上传时间")}{item.uploadTime}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Image.PreviewGroup>
                </Modal>
            </div>
        );
    }
}

const Styles = {
    contentStyle: {
        padding: 1,
        border: "1px solid #333",
        marginBottom: 3,
        backgroundColor: global.rowLightColor,
    },
    rowStyle: {
        display: "flex",
    },
    colStyle: {
        display: "flex",
        // width: "50%"
    },
    halfColStyle: {
        display: "flex",
        width: "50%",
    },
    halfColStyle2: {
        display: "flex",
        width: "50%",
        flexDirection: "column",
    },
    fullColStyle: {
        display: "flex",
        width: "100%"
    },
    col2Style: {
        display: "flex",
        flexDirection: "column",
    },
    colItemStyle: {
        // width: "100%",
        flex: 1,
        display: "flex",
        border: "0.5px solid #333",
        margin: .5,
    },
    colItem2Style: {
        display: "flex",
        border: "0.5px solid #333",
        margin: .5,
    },
    colItem3Style: {
        display: "flex",
        border: "0.5px solid #333",
        margin: .5,
        flex: 1,
    },
    colItem4Style: {
        display: "flex",
        border: "0.5px solid #333",
        margin: .5,
        flex: 1,
        minHeight: 30,
    },
    colItem5Style: {
        display: "flex",
        border: "0.5px solid #333",
        margin: .5,
        flex: 1,
        minHeight: 32,
    },
    colItemEndStyle: {
        // width: "100%",
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
        border: "0.5px solid #333",
        margin: .5,
        minHeight: 32,
    },
    colItemEnd2Style: {
        // width: "100%",
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
        border: "0.5px solid #333",
        margin: .5,
        minHeight: 30,
    },
    rowItemStyle: {
        // display: "flex",
        border: "0.5px solid #333",
        margin: .5,
        padding: 5,
    },
    labelStyle: {
        paddingLeft: 5,
        paddingRight: 5,
        display: "flex",
        alignItems: "center",
        fontSize: 13,
        minWidth: 75,
    },
    label2Style: {
        paddingLeft: 5,
        paddingRight: 5,
        display: "flex",
        lineHeight: "30px",
        fontSize: 13,
        minHeight: 30,
        minWidth: 75,
    },
    label3Style: {
        paddingLeft: 5,
        paddingRight: 5,
        display: "flex",
        lineHeight: "30px",
        fontSize: 13,
        width: 75,
    },
    label4Style: {
        paddingLeft: 5,
        paddingRight: 5,
        display: "flex",
        lineHeight: "30px",
        fontSize: 13,
        minHeight: 32,
    },
    selectStyle: {
        border: "none",
        borderRadius: 0,
        flex: 1,
    },
    select2Style: {
        border: "none",
        borderRadius: 0,
        flex: 1,
        width: 80,
    },
    inputStyle: {
        display: "flex",
        border: "none",
        borderRadius: 0,
        height: 30,
        width: "100%",
        lineHeight: "28px",
        // flex: 1,
    },
    input2Style: {
        border: "none",
        borderRadius: 0,
        width: "100%",
    },
    input3Style: {
        border: "none",
        borderRadius: 0,
        height: 30,
        lineHeight: 28,
        flex: 1,
    },
    textAreaStyle: {
        border: "none",
        borderRadius: 0,
        width: "100%",
        minHeight: 32,
        fontSize: 14,
        // flex: 1,
    },
    textArea2Style: {
        border: "none",
        borderRadius: 0,
        width: "100%",
        minHeight: 64,
        fontSize: 14,
        // flex: 1,
    },
    datePickerStyle: {
        border: "none",
        borderRadius: 0,
        width: "100%",
        backgroundColor: "#FFF",
        height: 30,
    },
    formItemStyle: {
        marginBottom: 0,
        flex: 1,
        // width: "100%",
    }
};
