import config from "../config";

const LOGIN_COOKIE_NAME = 'sessionId';
const LOGIN_TYPE = 'loginType';
const LOGIN_USER = 'loginUser';
const USER_LIST = 'userList';
const REMEMBER_ME = 'AFS_rememberMe';
const LOGIN_TIME = 'AFS_loginTime';
const DICT = 'dict';
const ORGTREE = 'orgTree'
const CATEGORYS = 'categorys'
const LANG = 'language';

export function isAuthenticated() {
    return _getCookie(LOGIN_COOKIE_NAME)
}

export function authenticateSuccess(token, loginUser, loginType) {
    _setCookie(LOGIN_COOKIE_NAME, token);
    _setSessionStorage(LOGIN_USER, JSON.stringify(loginUser));
    _setCookie(LOGIN_TYPE, loginType);
}

export function loginType() {
    return _getCookie(LOGIN_TYPE);
}

export function saveUserList(userList) {
    _setSessionStorage(USER_LIST, JSON.stringify(userList));
}

export function saveCategorys(categorys) {
    _setSessionStorage(CATEGORYS, JSON.stringify(categorys));
}

export function loginUser() {
    let loginUser = {};
    try {
        loginUser = JSON.parse(_getSessionStorage(LOGIN_USER));
    } catch (error) {

    }
    return loginUser
}

export function dict() {
    let dict = {};
    try {
        dict = JSON.parse(_getSessionStorage(DICT));
    } catch (error) {

    }
    return dict;
}

export function orgTree() {
    let orgTree = {};
    try {
        orgTree = JSON.parse(_getSessionStorage(ORGTREE));
    } catch (error) {

    }
    return orgTree;
}

export function categorys() {
    let categorys = {};
    try {
        categorys = JSON.parse(_getSessionStorage(CATEGORYS));
    } catch (error) {

    }
    return categorys;
}

export function userList() {
    let userList = [];
    try {
        userList = JSON.parse(_getSessionStorage(USER_LIST));
    } catch (error) {

    }
    return userList;
}

export function logout() {
    _setCookie(LOGIN_COOKIE_NAME, '', 0);
    _setCookie(LOGIN_USER, '', 0);
    _clearSessionStorage();
}

export function getRemember() {
    let remember = {};
    try {
        remember = JSON.parse(_getLocalStorage(REMEMBER_ME));
    } catch (error) {

    }
    return remember
}

export function setRemember(remember) {
    _setLocalStorage(REMEMBER_ME, JSON.stringify(remember))
}

export function saveDefaultLang(lang) {
    _setLocalStorage(LANG, lang)
}

export function getDefaultLang() {
    let lang;
    try {
        lang = _getLocalStorage(LANG);
    } catch (error) {

    }
    if (!lang || lang === "null") {
        if (config.locale === "zh_HK") {
            lang = "zh_HANT";
        } else if (config.locale === "en_US") {
            lang = "en_US";
        } else {
            lang = "zh_CN";
        }
    }
    return lang
}

export function getLoginTime() {
    let loginTime;
    try {
        loginTime = JSON.parse(_getLocalStorage(LOGIN_TIME));
    } catch (error) {

    }
    return loginTime
}

export function setLoginTime(loginTime) {
    _setLocalStorage(LOGIN_TIME, JSON.stringify(loginTime))
}

export function clearRememberUser() {
    let remember = _getLocalStorage(REMEMBER_ME);
    remember.remember = false;
    remember.user = {};
    _setLocalStorage(REMEMBER_ME, JSON.stringify(remember));
}

export function clearAutoLogin() {
    let remember = _getLocalStorage(REMEMBER_ME);
    remember.autoLogin = false;
    _setLocalStorage(REMEMBER_ME, JSON.stringify(remember));
}

export function clearRemember() {
    _clearLocalStorage()
}

export function removeRemember(name) {
    _removeLocalStorage(name)
}

function _getCookie(name) {
    let start, end;
    if (document.cookie.length > 0) {
        start = document.cookie.indexOf(name + '=');
        if (start !== -1) {
            start = start + name.length + 1;
            end = document.cookie.indexOf(';', start);
            if (end === -1) {
                end = document.cookie.length;
            }
            return unescape(document.cookie.substring(start, end));
        }
    }
    return '';
}

function _setCookie(name, value, expire) {
    let date = new Date();
    date.setDate(date.getDate() + expire);
    document.cookie = name + '=' + escape(value) + '; path=/' +
        (expire ? ';expires=' + date.toGMTString() : '')
}

function _getSessionStorage(name) {
    return unescape(sessionStorage.getItem(name));
}

function _setSessionStorage(name, value) {
    sessionStorage.setItem(name, value);
}

// eslint-disable-next-line no-unused-vars
function _removeSessionStorage(name) {
    sessionStorage.removeItem(name);
}

function _clearSessionStorage() {
    sessionStorage.clear();
}

function _getLocalStorage(name) {
    return unescape(localStorage.getItem(name));
}

function _setLocalStorage(name, value) {
    localStorage.setItem(name, value);
}

// eslint-disable-next-line no-unused-vars
function _removeLocalStorage(name) {
    localStorage.removeItem(name);
}

function _clearLocalStorage() {
    localStorage.clear();
}

